import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import gsc from "../certificat_img/image 20.png"
import hungama from "../certificat_img/image 21.png"
import polycab from "../certificat_img/image 22.png"

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '10px',
  height:'200px',
  width:'300px',
  backgroundColor: '#fff', 
  padding: theme.spacing(1),
//   textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <Box sx={{ width: '100%', paddingTop: "5%" }}>
    <h1 style={{ color: '#139C32', textAlign: "center" }}>Clients  Reviews</h1>
    <Grid container spacing={9} columns={16} justifyContent="center" padding={'10px'}>
      <Grid item xs={6} className="review_card">
        <Item className='review_sub'>
          <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center',textAlign:'center' ,padding:'10px'}}>
            <div>
                <img src={gsc} alt='review gsc'></img>
              <p style={{color:'#161011'}}>One of the Best Programming company! Understand the Project Flow Very Well and finish in time.</p>
              <h2 style={{color:'#009243' , fontWeight:'400',fontFamily:'Roboto'}}>GSC Bank</h2>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} className="review_card1">
        <Item className='review_sub'>
          <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center',textAlign:'center' ,padding:'10px' }}>
            <div>
            <img src={hungama} alt='review hungama'></img>
              <p style={{color:'#161011'}}>Fastest Services!!!Excellent Service!! And Give Exactly what i needed!! Thanks.</p>
              <h2 style={{color:'#1686C4', fontWeight:'400',fontFamily:'Roboto'}}>Hungama</h2>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} className="review_card2">
        <Item className='review_sub'>
          <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center',textAlign:'center' ,padding:'10px' }}>
            <div>
            <img src={polycab} alt='polycab review'></img>
              <p style={{color:'#161011'}}>Excellent Communication!! Fastest!!! And Give Exactly what i needed!! Thanks.</p>
              <h2 style={{color:'#E31E25', fontWeight:'400',fontFamily:'Roboto'}}>Polycab</h2>
            </div>
          </div>
        </Item>
      </Grid>
  
    </Grid>
  </Box>
  
  );
}
