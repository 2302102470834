import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import cyber from "../../icons/career/cybersecurity 1.png"
import customer from "../../icons/career/customer-care 1.png"
import group from "../../icons/career/Group (1).png"
import php from "../../icons/career/php (2) 1.png"
import professional from "../../icons/career/professional.png"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    width: '30vw',
    border: '1.5px solid #C4D2EE',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <Box  className='pt-[3%]'>
            <Grid container spacing={4} columns={16}  className='bulk_card_container flex justify-center p-2.5 mb-5'>
                <Grid item xs={6} >
                    <Item className={"card sub_card"}>
                        <div>
                            <h4 className='mb-2 text-xl font-medium'>Transactional SMS</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px]'>Transactional short message service are extensively used globally by many sectors for marketing and for providing end-to-end information about the product or service. A transactional SMS service is an easy way to share information regarding your business.Your firm or company gets the benefits either way, i.e., account balance information and essential updates by opts, text messages, or transactional SMS service with a proper alert. A transnational SMS service is a form of simple text SMS used to send and receive alerts, updates, OTPs, requests, passwords, etc.Incorporate business, education, and banking sectors; CPaaS serviceis a green carpet to expand your customers’ expertise and satisfaction with your service.</p>
                        </div>
                    </Item>
                </Grid>


                <Grid item xs={6} >
                <Item className={"card sub_card"}>
                        <div>
                            <h4 className='mb-2 text-xl font-medium'>promotional SMS</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px]'>The promotional SMS service is extensively used by several companies for promotion, marketing, and other business purposes. Promotional SMS service is the most effective way to connect with customers by business brand or service in the modern era without building another infrastructure. Promotional SMS service is in the format of text, picture messages, or text messages.Leeway Softech Company knows the customer’s willingness and how to raise business, sales, or service with an effective strategy.Our SMS service provider expertise team knows how to reach the audience for promoting your business and how to get leads with the use of SMS service. Leeway Softech is committed to providing a secure promotional SMS service effectively.</p>
                        </div>
                    </Item>
                </Grid>
            </Grid>

        </Box>

    );
}
