import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import FormButtonComponent from "./FormButtonComponent";
import toast from "react-hot-toast";

const fileTypes = ["JPEG", "PNG", "JPG", "pdf", "doc", "docx"];

export default function App({ label, isRequired, handleFile }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const Line = () => (
    <div className="w-50">
      <div className="row">
        <div className="col">
          <hr />
        </div>
        <div className="col-auto align-items-center d-flex">
          <span className="or text-[10px]">OR</span>
        </div>
        <div className="col">
          <hr />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="file-upload-container">
        <FileUploader
          multiple={false} // Change to false for single file upload
          name="file"
          classes="drag-drop-zone"
          types={fileTypes}
          onTypeError={() => toast.error("Only allows .jpg, .png, and .gif files")}
        >
          <img alt="icon" src={require("../icons/upload.png")} className="h-10 w-10" />
          <p>Drag your Image to start uploading</p>
          <Line />
          <FormButtonComponent  className="career_btn" label={"Choose File"} outline={true} />
        </FileUploader>
      </div>
    </div>
  );
}