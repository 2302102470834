import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Cancel } from '@mui/icons-material';

const PopUpFromUi = ({ open, setOpen, body, header, ...rest }) => {
    //   const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
        <Dialog
            {...rest}
            open={open}
            onClose={handleClose}
            className='popup_dailog'
            PaperProps={{ sx: { borderRadius: "25px", position: "relative" } }}
        >
            <DialogTitle sx={{ color: '#4361EE', fontSize: 18, fontWeight: 600, marginBottom: -1, marginTop: 1 }} className='dialog_header'>
                {header}
            </DialogTitle>
    
            <DialogContent className="relative max-h-[60vh] dialog_content">
                <div className="text-[#7F828A] text-[14px] rounded-[20px] ">
                    {/* Close button */}
                    <div className="close-btn-container-popup absolute top-4 right-4" onClick={handleClose}>
                        <img className="max-w-[30px] h-[30px]" src={require("../icons/services/crossIcon.png")} alt="Close" />
                    </div>
    
                    {/* Popup body content */}
                    <div className="popup_body p-4">
                        {body}
                        
                        {/* Popup image */}
                        <div className="popup_image bg-[#DFEAFF] p-4 relative z-10 mx-auto top-[-66px] sm:max-w-[90%] md:max-w-[80%] lg:max-w-[70%] md:left-[5%] lg:left-[7%]">
                            <img
                                src={require("../icons/services/OBJECT.png")}
                                className="popup_img_container w-full h-auto object-contain"
                                alt="Popup Image"
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    </div>
    
    );
};

export default PopUpFromUi;
