import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import use from "../../icons/touch-screen 1.png"
import security from "../../icons/security-lock 1.png"
import flexible from "../../icons/flexible 1.png"
import scalable from "../../icons/scalable (1) 1.png"
import customer from "../../icons/Rating.png"
import Cms_services from "./Cms_services"
import budget from "../../icons/budget.png"
import content from "../../icons/content.png"
import business from "../../icons/Layer_x0020_1.png"
import SEO from '../../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '50px',
    width: '250px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '20vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));
export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Content Management System | Leeway Softech Pvt Ltd"
             description="A content management system (CMS) is a software application that allows users to create, edit, and manage digital content. Whether it's open-source CMS or commercial, Leeway's got you covered"
            />
            <Grid container spacing={1} className="cms_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Content Management System</h1>
                        <p className='text-lg font-normal'>Our Flutter development services can help you create beautiful, high-performance apps that are responsive and engaging. A content management system (CMS) is a software application that allows users to create, edit, and manage digital content. CMSs are used by businesses, organizations, and individuals to create and maintain websites, blogs, and other online content.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
                <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Benefits of using a CMS</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={use} alt='use'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Ease of use</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>
                                    <img src={security} alt='security'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Security</p>


                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={flexible} alt='flexible'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Flexibility</p>


                            </div>
                        </Item>
                    </Grid>

                </Grid>


                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div >

                                    <img src={scalable} alt='scalable'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Scalability</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={customer} alt='customer'></img>
                                </div>
                                <p className='ml-[10%]'>Improved customer satisfaction</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>



            </Box>


            <div className='text-3xl font-semibold text-[#139C32] text-center mt-5 mb-5'>Customer Management System Services</div>
            <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

                    <Grid items xs={6}>
                        <Content className='add_sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                    <img src={budget} alt='budget' className='inline mb-3'></img>
                                        <h3 className='mb-2  text-[#161011] text-xl font-medium'>Your budget</h3>
                                    </div>
                                    <p > 
                                    Your budget is a crucial factor when considering CMS services.CMS services can range in price from free to thousands of dollars per year.It's essential to select a CMS that aligns with your specific budget.
                                    </p>
                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='add_sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <img src={business} alt='business' className='inline mb-3'></img>
                                        <h3 className='mb-2  text-[#161011] text-xl font-medium'>Customize content</h3>
                                    </div>
                                    <p > 
                                    Customize Some CMSs are better suited for certain types of content than others. For example, WordPress is a good choice for creating blogs, while Drupal is a good choice for creating complex websites.
                                    </p>

                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='add_sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                    <img src={content} alt='content' className='inline mb-3'></img>
                                    <h3 className='mb-2  text-[#161011] text-xl font-medium'>The size of your business</h3>
                                    </div>
                                    <p > 
                                    If you have a small business, you may be able to get away with using a free or open source CMS. However, if you have a large business, you may need a commercial CMS that offers more features and support.
                                    </p>
                            </div>
                        </Content>
                    </Grid>
                </Grid>

            <Cms_services/>    
        </>
    );
}
