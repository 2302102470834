import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';

// Custom Paper component
const CustomPaper = (props) => {
    return (
        <Paper 
            {...props} 
            sx={{ 
                backgroundColor: 'transparent', // Set background color to transparent
                borderRadius: '25px', // Keep your desired border radius
                boxShadow: 'none', // Remove shadow if needed
            }} 
        />
    );
};

const PopUpFromUi = ({ open, setOpen, body, header, ...rest }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog 
                {...rest} 
                open={open} 
                onClose={handleClose} 
                PaperComponent={CustomPaper} // Use the custom Paper component
                className='cookie_dialog'
            >
                <DialogTitle sx={{ color: '#4361EE', fontSize: 18, fontWeight: 600, marginBottom: -1, marginTop: 1 }}>
                    {header}
                </DialogTitle>
                <DialogContent>
                    <div className='text-[#7F828A] text-[14px] mb-[10px]'>
                        {body}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PopUpFromUi;