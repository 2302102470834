import React, { useState, useEffect, useRef } from "react";
import "../main.css";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../images/Layer_1.png";
import FormButton from "../components/FormButton";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SocialMedia from "../../src/pages/SocialMedia"

import Grid from "@mui/material/Grid2";
import whatsApp from "../icons/services/social (1) 2.png";
const Header = () => {
  const techItems = [
    {
      id: "react",
      normal: require("../../src/icons/header/react_header.png"),
      hover: require("../../src/icons/header/header_hover/react.png"),
      label: "React Js",
      image: require("../../src/icons/header/header_img/reactjs.png"),
    },
    {
      id: "js",
      normal: require("../../src/icons/header/javascript 1.png"),
      hover: require("../../src/icons/header/header_hover/javascript 1.png"),
      label: "Js",
      image: require("../../src/icons/header/header_img/js.png"),
    },
    {
      id: "html",
      normal: require("../../src/icons/header/html (2) 1.png"),
      hover: require("../../src/icons/header/header_hover/html (2) 1.png"),
      label: "Html",
      image: require("../../src/icons/header/header_img/html.png"),
    },
    {
      id: "css",
      normal: require("../../src/icons/header/css (1) 1.png"),
      hover: require("../../src/icons/header/header_hover/css (1) 1.png"),
      label: "Css",
      image: require("../../src/icons/header/header_img/css.png"),
    },
  ];

  const backendItem = [
    {
      id: "Node Js",
      normal: require("../../src/icons/header/node-js (2) 1.png"),
      hover: require("../../src/icons/header/header_hover/Group 1000005925.png"),
      label: "Node Js",
      image: require("../../src/icons/header/header_img/nodejs.png"),
    },
    {
      id: "Php",
      normal: require("../../src/icons/header/Vector (1).png"),
      hover: require("../../src/icons/header/header_hover/php (4) 1.png"),
      label: "Php",
      image: require("../../src/icons/header/header_img/php.png"),
    },
    {
      id: "Python",
      normal: require("../../src/icons/header/python 1.png"),
      hover: require("../../src/icons/header/header_hover/python 1.png"),
      label: "Python",
      image: require("../../src/icons/header/header_img/python.png"),
    },
    {
      id: "Laravel",
      normal: require("../../src/icons/header/Group.png"),
      hover: require("../../src/icons/header/header_hover/Group 1000005928.png"),
      label: "Laravel",
      image: require("../../src/icons/header/header_img/laravel.png"),
    },
  ];

  const aiItem = [
    {
      id: "chat GPT",
      normal: require("../../src/icons/header/chat_gpt.png"),
      hover: require("../../src/icons/header/header_hover/gpt.png"),
      image: require("../../src/icons/header/header_img/gpt.png"),
      label: "chat GPT",
    },
    {
      id: "Pytorch",
      normal: require("../../src/icons/header/image 64.png"),
      hover: require("../../src/icons/header/header_hover/image 60.png"),
      image: require("../../src/icons/header/header_img/pytorch.png"),
      label: "Pytorch",
    },
    {
      id: "Tensorflow",
      normal: require("../../src/icons/header/image 65.png"),
      hover: require("../../src/icons/header/header_hover/image 62.png"),
      image: require("../../src/icons/header/header_img/tens.png"),
      label: "Tensorflow",
    },
    {
      id: "IBM",
      normal: require("../../src/icons/header/image 66.png"),
      hover: require("../../src/icons/header/header_hover/image 63.png"),
      image: require("../../src/icons/header/header_img/ibm.png"),

      label: "IBM",
    },
  ];

  const mobileItem = [
    {
      id: "ios",
      normal: require("../../src/icons/header/Vector (2).png"),
      hover: require("../../src/icons/header/header_hover/Vector.png"),
      label: "ios",
      image: require("../../src/icons/header/header_img/ios.png"),
    },
    {
      id: "android",
      normal: require("../../src/icons/header/Group (1).png"),
      hover: require("../../src/icons/header/header_hover/Group.png"),
      label: "Android",
      image: require("../../src/icons/header/header_img/android.png"),
    },
    {
      id: "Flutter",
      normal: require("../../src/icons/header/flutter (3) 1.png"),
      hover: require("../../src/icons/header/header_hover/flutter (3) 1.png"),
      label: "Flutter",
      image: require("../../src/icons/header/header_img/flutter.png"),
    },
    {
      id: "Xamarin",
      normal: require("../../src/icons/header/xamarin 1.png"),
      hover: require("../../src/icons/header/header_hover/xamarin 1.png"),
      label: "Xamarin",
      image: require("../../src/icons/header/header_img/xamarin.png"),
    },
  ];

  const e_commItem = [
    {
      id: "shopify",
      normal: require("../../src/icons/header/shopify.png"),
      hover: require("../../src/icons/header/header_hover/Group (2).png"),
      image: require("../../src/icons/header/header_img/shopify.png"),

      label: "Shopify",
    },
    {
      id: "wordpress",
      normal: require("../../src/icons/header/wordpress.png"),
      hover: require("../../src/icons/header/header_hover/Group (1).png"),
      image: require("../../src/icons/header/header_img/wordpress.png"),

      label: "Wordpress",
    },
    {
      id: "joomla",
      normal: require("../../src/icons/header/joomla.png"),
      hover: require("../../src/icons/header/header_hover/joomla_hover.png"),
      image: require("../../src/icons/header/header_img/joomla.png"),

      label: "joomla",
    },
    {
      id: "drupal",
      normal: require("../../src/icons/header/drupal.png"),
      hover: require("../../src/icons/header/header_hover/Vector (2).png"),
      image: require("../../src/icons/header/header_img/drupal.png"),

      label: "Drupal",
    },
  ];

  const databaseItem = [
    {
      id: "My SQl",
      normal: require("../../src/icons/header/mysql 1.png"),
      hover: require("../../src/icons/header/header_hover/mysql 1.png"),
      label: "My SQl",
      image: require("../../src/icons/header/header_img/sql.png"),
    },
    {
      id: "FireBase",
      normal: require("../../src/icons/header/firebase 1.png"),
      hover: require("../../src/icons/header/header_hover/firebase 1.png"),
      label: "FireBase",
      image: require("../../src/icons/header/header_img/database.png"),
    },
    {
      id: "MongoDB",
      normal: require("../../src/icons/header/mongodb 1.png"),
      hover: require("../../src/icons/header/header_hover/mongodb 2.png"),
      label: "MongoDB",
      image: require("../../src/icons/header/header_img/mongoDB.png"),
    },
    {
      id: "DynamoDB",
      normal: require("../../src/icons/header/20241202150015aws 1.png"),
      hover: require("../../src/icons/header/header_hover/20241202150015aws 1.png"),
      label: "DynamoDB",
      image: require("../../src/icons/header/header_img/dynamoDB.png"),
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [dropdownStates, setDropdownStates] = useState({
    company: false,
    platforms: false,
    solutions: false,
    media: false,
    blog: false,
    Enterprise_Solutions: false,
    Channels: false,
    CPaaS: false,
    Technologies: false,
  });

  const closeDropdowns = () => {
    setDropdownStates({
      company: false,
      platforms: false,
      solutions: false,
      company: false,
      platforms: false,
      solutions: false,
      media: false,
      blog: false,
      Enterprise_Solutions: false,
      Channels: false,
      CPaaS: false,
      Technologies: false,
    });
  };

  const dropdownRef = useRef(null);
  const location = useLocation();

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const [activeLink, setActiveLink] = useState("");

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 899) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Add or remove class to body based on sidebar state
    if (isSidebarOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.classList.remove("modal-open"); // Clean up on unmount
    };
  }, [isSidebarOpen]);

  const handleDropdownToggle = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  // Function to handle link click
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSubLinkClick = (link, path) => {
    setDropdownStates((prevState) => ({ ...prevState, [link]: false }));
    setActiveLink(link);
    setSidebarOpen(false);
    closeDropdowns();
    navigate(path); // Navigate to the specified path
    navigate(`${link}`);
  };

  const handleTechLinkClick = (link, path) => {
    setDropdownStates((prevState) => ({ ...prevState, [link]: false }));
    setActiveLink(link);
    setSidebarOpen(false);
    closeDropdowns();
    navigate(path); // Navigate to the specified path
    navigate(`${path}#${link}`);
  };
  useEffect(() => {
    closeDropdowns();
  }, [location]);

  const handleMouseEnter = (link) => {
    setDropdownStates((prevState) => ({ ...prevState, [link]: true }));
    setDropdownVisible(true);
  };

  const handleMouseLeave = (link) => {
    setDropdownStates((prevState) => ({ ...prevState, [link]: false }));
    setDropdownVisible(false);
  };

  const [hoveredId, setHoveredId] = useState(null);

  // dropdown hover image and icon

  const [hoveredLink, setHoveredLink] = useState(null); // State to store the hovered link
  // Define the images for each link
  const mediaImages = {
    about: {
      image: require("../icons/header/header_img/about.png"),
      icon: require("../icons/header/Layer 2 (1).png"),
    },
    infrastructure: {
      image: require("../icons/header/header_img/infra.png"),
      icon: require("../icons/header/infra.png"),
    },
    achievements: {
      image: require("../icons/header/header_img/achivment.png"),
      icon: require("../icons/header/achievement 1.png"),
    },
    ourClient: {
      image: require("../icons/header/header_img/client.png"),
      icon: require("../icons/header/client.png"),
    },
    media: {
      image: require("../icons/header/header_img/media.png"),
      icon: require("../icons/header/Layer_x0020_1.png"),
    },
    leeway: {
      image: require("../icons/header/header_img/lifeofleeway.png"),
      icon: require("../icons/header/Layer_x0020_1.png"),
    },
  };

  const solutionImage = {
    bank: {
      image: require("../icons/header/header_img/bank.png"),
      icon: require("../icons/header/solution/Vector.png"),
    },
    government: {
      image: require("../icons/header/header_img/goverment.png"),
      icon: require("../icons/header/solution/Group.png"),
    },
    religious: {
      image: require("../icons/header/header_img/religious.png"),
      icon: require("../icons/header/solution/religion (1) 1.png"),
    },
    education: {
      image: require("../icons/header/header_img/edu.png"),
      icon: require("../icons/header/solution/mortarboard (1) 1.png"),
    },
    corporate: {
      image: require("../icons/header/header_img/corporate.png"),
      icon: require("../icons/header/solution/corporation (1) 1.png"),
    },
    politics: {
      image: require("../icons/header/header_img/politics.png"),
      icon: require("../icons/header/solution/politics (2) 1.png"),
    },
    international: {
      image: require("../icons/header/header_img/international.png"),
      icon: require("../icons/header/solution/16-air delivery.png"),
    },
    e_commerce: {
      image: require("../icons/header/header_img/E_sector.png"),
      icon: require("../icons/header/enterprise/Vector.png"),
    },
  };

  const platformImage = {
    Mobile: {
      image: require("../icons/header/header_img/mobile.png"),
      icon: require("../icons/header/mob.png"),
    },
    Web: {
      image: require("../icons/header/header_img/web.png"),
      icon: require("../icons/header/app-development 1.png"),
    },
    Software: {
      image: require("../icons/header/header_img/software.png"),
      icon: require("../icons/header/software.png"),
    },
    RCS: {
      image: require("../icons/header/header_img/rcs.png"),
      icon: require("../icons/header/cpass/chat-box 1.png"),
    },
    IPBX: {
      image: require("../icons/header/header_img/ipbx.png"),
      icon: require("../icons/header/cpass/Layer 2.png"),
    },
    Whatsapp: {
      image: require("../icons/header/header_img/whatsapp.png"),
      icon: require("../icons/header/cpass/Group.png"),
    },
    Chatbot: {
      image: require("../icons/header/header_img/chatbot.png"),
      icon: require("../icons/header/cpass/chatbot (7) 1.png"),
    },
    Bulk: {
      image: require("../icons/header/header_img/sms.png"),
      icon: require("../icons/header/cpass/layer1.png"),
    },
    Email: {
      image: require("../icons/header/header_img/marketing.png"),
      icon: require("../icons/header/cpass/sms (5) 1.png"),
    },
    CRM: {
      image: require("../icons/header/header_img/crm.png"),
      icon: require("../icons/header/enterprise/crm (1) 1.png"),
    },
    CMS: {
      image: require("../icons/header/header_img/cms.png"),
      icon: require("../icons/header/enterprise/Group.png"),
    },
    e_comm: {
      image: require("../icons/header/header_img/marketing.png"),
      icon: require("../icons/header/enterprise/Vector.png"),
    },
    ERP: {
      image: require("../icons/header/header_img/erp.png"),
      icon: require("../icons/header/enterprise/Group (1).png"),
    },
    Service: {
      image: require("../icons/header/header_img/24_7.png"),
      icon: require("../icons/header/cpass/sms (5) 1.png"),
    },
  };

  return (
    <>
      <header className="header px-6 md:px-24 py-3 bg-white flex items-center justify-between">
        {/* <Grid container style={{ textAlign: 'center', position: 'fixed', top: '0%', left: 0, right: 0, background: '#D2E1FF', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
    <Grid item xs={12}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }} className='header_contain'>
                <img src={require("../icons/services/Stroke-1.png")} alt="WhatsApp header Icon" />

                <Link to='tel:8000305305' target='blank' style={{ textDecoration: 'none' }} >
                <p style={{ color: '#134CBC', fontSize: 'small', margin: '5px' }}>Call Now</p>
                </Link>
                <div style={{ border: '0.5px solid #00000040', height: '14px', margin: '15px 6px' }}></div>
                <img src={require("../icons/services/whatsapp (9) 2.png")} alt="WhatsApp second Icon" />
                <Link to='https://api.whatsapp.com/send?phone=917878277744' target='blank' style={{ textDecoration: 'none' }}>
                <p style={{ color: '#134CBC', fontSize: 'small', margin: '5px' }}>Whatsapp Message</p></Link>
                <div style={{ border: '0.5px solid #00000040', height: '14px', margin: '15px 6px' }}></div>
                <img src={whatsApp} alt="WhatsApp pay Icon" />
                <Link to='/payment'  style={{ textDecoration: 'none' }}
                     className={`header-link ${activeLink === '/payment' ? 'active' : ''}`}
                     onClick={() => handleSubLinkClick('/payment')}
                
                >
                <p style={{ color: '#134CBC', fontSize: 'small',marginLeft:'-20%' ,cursor: 'pointer' }}>Pay Now</p>

                </Link>
            </div>
        </div>
    </Grid>
</Grid> */}

        <img src={logo} alt="header logo" className="logo p-2.5" />
        <nav
          className={`nav ${
            isOpen ? "open" : ""
          } flex items-center justify-between space-x-6 w-full md:w-auto`}
        >
          <div className="nav-list">
            <Link
              to="/"
              className={`header-link ${activeLink === "/" ? "active" : ""}`}
              onClick={handleScrollToTop}
            >
              Home
              <span className="border_line"></span> {/* Line element */}
            </Link>
            <div
              ref={dropdownRef}
              onMouseEnter={() => handleMouseEnter("company")}
              onMouseLeave={() => handleMouseLeave("company")}
            >
              <Link
                className={`header-link ${
                  activeLink === "company" ? "active" : ""
                }`}
              >
                Company
                <span className="border_line"></span>
              </Link>
              {dropdownStates.company && (
                <div className="dropdown-menu company-menu absolute w-full sm:w-[600px] h-auto sm:h-[440px] bg-white shadow-lg rounded-md  flex flex-col sm:space-x-4 sm:right-0 sm:left-auto">
                  <div className="dropdown-options w-full sm:w-[47%] pr-4 sm:pr-0 ml-[20px] mt-2">
                    <div className="triangle"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 "
                      onMouseEnter={() => setHoveredLink("about")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../images/img/Group 1171275084.png")}
                        className="header_img w-6 h-6"
                        alt="About Us"
                      />
                      <Link
                        to="/about"
                        onClick={() => {
                          handleSubLinkClick("/about");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        About Us
                      </Link>
                    </div>
                    <div className="border-b-[2px] border-grey-700  mt-4"></div>
                    {/* Infrastructure */}
                    <div
                      className="header_tech_main flex items-center space-x-2 mt-2"
                      onMouseEnter={() => setHoveredLink("infrastructure")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../images/img/Group 1171275085.png")}
                        className="header_img w-6 h-6"
                        alt="Infrastructure"
                      />
                      <Link
                        to="/infrastructure"
                        onClick={() => {
                          handleSubLinkClick("/infrastructure");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        Infrastructure
                      </Link>
                    </div>
                    <div className="border-b-[2px] border-grey-700  mt-4"></div>
                    {/* Achievements */}
                    <div
                      className="header_tech_main flex items-center space-x-2 mt-2"
                      onMouseEnter={() => setHoveredLink("achievements")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../images/img/Group 1171275083.png")}
                        className="header_img w-6 h-6"
                        alt="Achievements"
                      />
                      <Link
                        to="/achievements"
                        onClick={() => {
                          handleSubLinkClick("/achievements");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        Achievements
                      </Link>
                    </div>
                    {/* <div className="border-b-[2px] border-grey-700  mt-4"></div> */}
                    {/* Our Client */}
                    {/* <div
                      className="header_tech_main flex items-center space-x-2 mt-2"
                      onMouseEnter={() => setHoveredLink("ourClient")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../images/img/Group 1171275087.png")}
                        className="header_img w-6 h-6"
                        alt="Our Client"
                      />
                      <Link
                        to="/our-client"
                        onClick={() => {
                          handleSubLinkClick("/our-client");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        Our Client
                      </Link>
                    </div> */}
                    <div className="border-b-[2px] border-grey-700  mt-4"></div>
                    {/* Media */}
                    <div
                      className="header_tech_main flex items-center space-x-2 mt-2"
                      onMouseEnter={() => setHoveredLink("media")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../images/img/event_icon.png")}
                        className="header_img w-6 h-6"
                        alt="Media"
                      />
                      <Link
                        to="/media"
                        onClick={() => {
                          handleSubLinkClick("/media");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        Event
                      </Link>
                    </div>
                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-2"
                      onMouseEnter={() => setHoveredLink("media")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../images/img/Group 1171275086.png")}
                        className="header_img w-6 h-6"
                        alt="Media"
                      />
                      <Link
                        to="/media-convergence"
                        onClick={() => {
                          handleSubLinkClick("/media-convergence");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        Media
                      </Link>
                    </div>
                    <div className="border-b-[2px] border-grey-700  mt-5"></div>
                    {/* life of leeway */}
                    <div
                      className="header_tech_main flex items-center space-x-2 mt-2"
                      onMouseEnter={() => setHoveredLink("leeway")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/Layer_x0020_1.png")}
                        className="header_img w-6 h-6"
                        alt="leeway"
                      />
                      <Link
                        to="/leeway"
                        onClick={() => {
                          handleSubLinkClick("/leeway");
                          window.scrollTo(0, 0);
                        }}
                        className="text-sm sm:text-base"
                      >
                        Life@Leeway
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown-card w-[270px]  sm:p-3 rounded-md  mt-4 mr-4 sm:mt-0 sm:absolute sm:right-0 sm:top-0 relative">
                    {hoveredLink == null ? (
                      <>
                        <img
                          src={require("../icons/header/header_img/about.png")}
                          className="w-full h-auto "
                          alt="drop-down about card"
                        />

                        <div className=" bg-white rounded h-10px h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex align-middle justify-center">
                          <img
                            src={require("../icons/header/Layer 2 (1).png")}
                            className="absolute top-2.5 w-5 h-5"
                            alt="drop-down about icon"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={mediaImages[hoveredLink]?.image} // Dynamically select the image based on hovered link
                          className="w-full h-auto"
                          alt="Dynamic Image"
                        />
                        <div className="bg-white rounded h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex items-center justify-center">
                          <img
                            src={mediaImages[hoveredLink]?.icon} // Dynamically select the icon based on hovered link
                            className="absolute top-2.5 w-5 h-5"
                            alt="Dynamic Icon"
                          />
                        </div>
                      </>
                    )}

                    <div className="bg-[#121212] rounded-b-md p-3">
                      <p className="text-sm sm:text-base text-white">
                        Get 10% off on all our services. Check out our offers!
                        Get 10% off on all our services.
                      </p>
                      <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                        Hire Tech Exeperts
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>

                  <div className="dropdown-sub-card mt-auto sm:mt-[3%] sm:ml-5 sm:mr-10">
                    <div className="bg-[#121212] rounded-md p-3 header_card_image">
                      <p className="text-sm sm:text-base text-white text-left">
                        Get 10% off on all our services. Check out our offers!
                      </p>

                      <button
                        className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                        onClick={() => navigate("/book-demo")}
                      >
                        Book a Live Demo
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              ref={dropdownRef}
              onMouseEnter={() => handleMouseEnter("platforms")}
              onMouseLeave={() => handleMouseLeave("platforms")}
            >
              <Link
                to="#"
                className={`header-link ${
                  activeLink === "platforms" ? "active" : ""
                }`}
              >
                Platforms
                <span className="border_line"></span>
              </Link>
              {dropdownStates.platforms && (
                <div className="dropdown-menu h-[451px] w-[1080px] absolute bg-white shadow-lg rounded-md menu-paltform ">
                  <div className="triangle"></div>
                  <Menu className="flex  flex-row">
                    <div className="flex justify-between">
                      <div className="flex flex-col mt-4 ml-[20px]">
                        <h4 className=" text-[#161011] cursor-pointer text-lg font-bold ">
                          Send2App
                        </h4>
                        {/* {dropdownStates.Channels && ( */}
                        <div className="droppdown_link">
                          {/* <MenuItem onClick={() => { handleSubLinkClick('digital-marketing', "/digital-marketing"); window.scrollTo(0, 0) }} >Digital Marketing</MenuItem> */}

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Mobile")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/mob.png")}
                              alt="mob app"
                              className="header_img"
                            ></img>
                            <MenuItem
                            // onClick={() => {
                            //   handleSubLinkClick(
                            //     "/mobile-app",
                            //     "/mobile-app"
                            //   );
                            //   window.scrollTo(0, 0);
                            // }}
                            >
                              Mobile App Notification
                            </MenuItem>
                          </div>

                          {/* <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Email")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/sms (5) 1.png")}
                              className="header_img"
                              alt="bulk mail"
                            ></img>
                            <MenuItem
                              // onClick={() => {
                              //   handleSubLinkClick("/bulk-mail", "/bulk-mail");
                              //   window.scrollTo(0, 0);
                              // }}
                            >
                              Push Notification
                            </MenuItem>
                          </div> */}

                          {/* <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Web")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/app-development 1.png")}
                              className="header_img"
                              alt="app devlopment"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick(
                                  "/web-devlopment",
                                  "/web-devlopment"
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              Send2Digital
                            </MenuItem>
                          </div> */}

                          {/* <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Software")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/software.png")}
                              className="header_img"
                              alt="software"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick(
                                  "/software-development",
                                  "/software-development"
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              Inbox mailer
                            </MenuItem>
                          </div> */}
                        </div>
                        {/* )} */}
                      </div>
                      <div className="self-stretch m-[15px] w-0.5 relative top-2 h-[286px] bg-[#ccc]"></div>
                      <div className="flex flex-col mt-4 ml-[10px]">
                        <h4 className=" text-[#161011] cursor-pointer text-lg font-bold">
                          CPaaS
                        </h4>

                        <div className="droppdown_link">
                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("RCS")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/cpass_icon.png")}
                              className="header_img"
                              alt="cpass"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/platform");
                                window.scrollTo(0, 0);
                              }}
                            >
                              CPaaS Services
                            </MenuItem>{" "}
                          </div>
                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("RCS")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/omni_channel_icon.png")}
                              className="header_img"
                              alt="cpass"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/omni-channel");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Omni Channel
                            </MenuItem>{" "}
                          </div>
                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Email")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/sms (5) 1.png")}
                              className="header_img"
                              alt="bulk mail"
                            ></img>
                            <MenuItem
                            // onClick={() => {
                            //   handleSubLinkClick("/bulk-mail", "/bulk-mail");
                            //   window.scrollTo(0, 0);
                            // }}
                            >
                              Push Notification
                            </MenuItem>
                          </div>
                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Email")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/hospitality_icon.png")}
                              className="header_img"
                              alt="bulk mail"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick(
                                  "/smart-hospital",
                                  "/smart-hospital"
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              Smart Hospital
                            </MenuItem>
                          </div>
                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Email")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/inbox_mailer_icon.png")}
                              className="header_img"
                              alt="bulk mail"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/bulk-mail");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Inbox Mailer
                            </MenuItem>
                          </div>
                          {/* <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("RCS")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/chat-box 1.png")}
                              className="header_img"
                              alt="cpass"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/rcs-msg", "/rcs-msg");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Google RCS
                            </MenuItem>{" "}
                          </div> */}

                          {/* <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("IPBX")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/Layer 2.png")}
                              className="header_img"
                              alt="ipbx"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick(
                                  "/pbx-service",
                                  "/pbx-service"
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              IPBX Service
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Whatsapp")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/Group.png")}
                              className="header_img"
                              alt="whatsapp img"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/whatsapp", "/whatsapp");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Whatsapp Bussiness
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Chatbot")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/chatbot (7) 1.png")}
                              className="header_img"
                              alt="chatbot"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/whatsapp", "/whatsapp");
                                window.scrollTo(0, 0);
                              }}
                            >
                              AI Chatbot
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Bulk")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/layer1.png")}
                              className="header_img"
                              alt="bulk sms"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/bulk-sms", "/bulk-sms");
                                window.scrollTo(0, 0);
                              }}
                            >
                              A2P SMS
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Email")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/sms (5) 1.png")}
                              className="header_img"
                              alt="bulk mail"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/bulk-mail", "/bulk-mail");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Push Notification
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Email")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={require("../icons/header/cpass/sms (5) 1.png")}
                              className="header_img"
                              alt="VOIP"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/bulk-mail", "/bulk-mail");
                                window.scrollTo(0, 0);
                              }}
                            >
                              VOIP
                            </MenuItem>
                          </div> */}
                        </div>
                      </div>

                      <div className="self-stretch m-[15px] w-0.5 relative top-2 h-[286px] bg-[#ccc]"></div>
                      {/* <div className='flex flex-col ml-[6%]'>
                                                <h4 style={{ color: '#161011', marginLeft: '8%' }}>Technologies</h4>
                                                <Link to="/java" onClick={() => {handleSubLinkClick('/java'); window.scrollTo(0, 0)}}  >Java</Link>
                                                <Link to="/database" onClick={() => {handleSubLinkClick('/database'); window.scrollTo(0, 0)}}  >Database</Link>
                                                <Link to="/front-end" onClick={() => {handleSubLinkClick('/front-end'); window.scrollTo(0, 0)}}  >Front-End</Link>
                                                <Link to="/internet-things" onClick={() => {handleSubLinkClick('/internet-things'); window.scrollTo(0, 0)}}  >IOT</Link>
                                                <Link to="/open-source" onClick={() => {handleSubLinkClick('/open-source'); window.scrollTo(0, 0)}}  >Open Source</Link>
                                                <Link to="/flutter" onClick={() => {handleSubLinkClick('/flutter'); window.scrollTo(0, 0)}}  >Flutter</Link>
                                                <Link to="/flutter" onClick={() => {handleSubLinkClick('/flutter'); window.scrollTo(0, 0)}}  >Paython</Link>
                                            </div> */}

                      <div className="flex flex-col mt-4 ml-[10px]">
                        <h4 className="header_enterprise  text-[#161011] cursor-pointer text-lg font-bold">
                          {" "}
                          Enterprise Solutions
                        </h4>
                        <div className="droppdown_link">
                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("CRM")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={
                                hoveredLink == "CRM"
                                  ? require("../icons/header/header_hover/Crm Icon.png") //hover img
                                  : require("../icons/header/enterprise/Crm Icon.png") // default img
                              }
                              className="header_img"
                              alt="crm"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/crm", "/crm");
                                window.scrollTo(0, 0);
                              }}
                            >
                              CRM
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("CMS")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={
                                hoveredLink == "CMS"
                                  ? require("../icons/header/header_hover/Cms Icon.png")
                                  : require("../icons/header/enterprise/Cms Icon.png")
                              }
                              className="header_img"
                              alt="cms"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/cms", "/cms");
                                window.scrollTo(0, 0);
                              }}
                            >
                              CMS
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("e_comm")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={
                                hoveredLink == "e_comm"
                                  ? require("../icons/header/header_hover/E- commerce Icon.png")
                                  : require("../icons/header/enterprise/E- commerce Icon.png")
                              }
                              className="header_img"
                              alt="e-comm header image "
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick(
                                  "/e-commerce",
                                  "/e-commerce"
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              E-Commerce
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("ERP")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={
                                hoveredLink == "ERP"
                                  ? require("../icons/header/header_hover/ERP.png")
                                  : require("../icons/header/enterprise/Erp Icon.png")
                              }
                              className="header_img"
                              alt="erp"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/erp", "/erp");
                                window.scrollTo(0, 0);
                              }}
                            >
                              ERP
                            </MenuItem>
                          </div>

                          <div
                            className="header_tech_main"
                            onMouseEnter={() => setHoveredLink("Service")}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            <img
                              src={
                                hoveredLink == "Service"
                                  ? require("../icons/header/header_hover/24-7 service Icon.png")
                                  : require("../icons/header/enterprise/24-7 service Icon.png")
                              }
                              className="header_img"
                              alt="header services"
                            ></img>
                            <MenuItem
                              onClick={() => {
                                handleSubLinkClick("/erp", "/erp");
                                window.scrollTo(0, 0);
                              }}
                            >
                              24/7 Service
                            </MenuItem>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Menu>

                  <div className="dropdown-card w-[270px]  sm:p-3 rounded-md mt-4 mr-4 sm:mt-0 sm:absolute sm:right-0 sm:top-0 relative">
                    {hoveredLink == null ? (
                      <>
                        <img
                          src={require("../icons/header/header_img/mobile.png")}
                          className="w-full h-auto "
                          alt="platform image"
                        />

                        <div className=" bg-white rounded h-10px h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex align-middle justify-center">
                          <img
                            src={require("../icons/header/mob.png")}
                            className="absolute top-2.5 w-5 h-5"
                            alt="platform icon"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={platformImage[hoveredLink]?.image} // Dynamically select the image based on hovered link
                          className="w-full h-auto"
                          alt="Dynamic platform Image"
                        />
                        <div className="bg-white rounded h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex items-center justify-center">
                          <img
                            src={platformImage[hoveredLink]?.icon} // Dynamically select the icon based on hovered link
                            className="absolute top-2.5 w-5 h-5"
                            alt="Dynamic platform Icon"
                          />
                        </div>
                      </>
                    )}

                    <div className="bg-[#121212] rounded-b-md p-3">
                      <p className="text-sm sm:text-base text-white">
                        Get 10% off on all our services. Check out our offers!
                        Get 10% off on all our services. Check out our
                        offers!Get 10% off on all our services. Check out our
                        offers!
                      </p>
                      <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                        Hire Tech Exeperts
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>

                  <div className="dropdown-sub-card  sm:mt-[4.5%] sm:ml-5 sm:mr-10 w-[71%]">
                    <div className="bg-[#121212] rounded-md p-3 header_card_image">
                      <p className="text-sm sm:text-base text-white text-left">
                        Get 10% off on all our services. Check out our offers!
                      </p>

                      <button
                        className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                        onClick={() => navigate("/book-demo")}
                      >
                        Book a Live Demo
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              ref={dropdownRef}
              onMouseEnter={() => handleMouseEnter("Technologies")}
              onMouseLeave={() => handleMouseLeave("Technologies")}
            >
              <Link
                to="/technology_page"
                className={`header-link ${
                  activeLink === "Technologies" ? "active" : ""
                }`}
                onClick={() => {
                  handleTechLinkClick("technology_page", "/technology_page");
                  window.scrollTo(0, 0);
                }}
              >
                Technologies
                <span className="border_line"></span>
              </Link>
              {dropdownStates.Technologies && (
                <div className="dropdown-menu header_menu h-[557px] w-[930px]">
                  <div className="absolute top-[-10px] left-[94%] -ml-[15px] w-0 h-0 border-l-[15px] border-r-[15px] border-b-[10px] border-solid border-transparent border-b-white technology_triangle"></div>
                  <Menu className="flex  flex-row">
                    <div className="flex justify-around ml-4">
                      <div className="flex text-left flex-col mt-2 ">
                        <h4
                          className="text-[#161011] cursor-pointer text-xl font-bold"
                          onClick={() => handleDropdownToggle("CPaaS")}
                        >
                          Frontend
                        </h4>
                        {techItems.map((item) => {
                          return (
                            <>
                              <div
                                key={item.id}
                                className="header_tech_main"
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(null)}
                              >
                                <img
                                  src={
                                    hoveredId === item.id
                                      ? item.hover
                                      : item.normal
                                  }
                                  className="header_img"
                                  alt={item.label}
                                />
                                <MenuItem
                                  onClick={() => {
                                    handleTechLinkClick(
                                      "frontend",
                                      "/technology_page"
                                    );
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              </div>
                            </>
                          );
                        })}
                        <p
                          className="tech_link"
                          onClick={() => {
                            handleTechLinkClick("frontend", "/technology_page");
                          }}
                        >
                          View All
                        </p>
                      </div>
                      <div className="vertical_line2"></div>

                      <div className="flex  text-left  flex-col mt-2">
                        <h4
                          className="text-[#161011] cursor-pointer text-xl font-bold"
                          onClick={() => handleDropdownToggle("CPaaS")}
                        >
                          Backend
                        </h4>
                        {backendItem.map((item) => {
                          return (
                            <>
                              <div
                                key={item.id}
                                className="header_tech_main "
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(null)}
                              >
                                <img
                                  src={
                                    hoveredId === item.id
                                      ? item.hover
                                      : item.normal
                                  }
                                  className="header_img"
                                  alt={item.label}
                                />
                                <MenuItem
                                  onClick={() => {
                                    handleTechLinkClick(
                                      "backend",
                                      "/technology_page"
                                    );
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              </div>
                            </>
                          );
                        })}
                        <p
                          className="tech_link"
                          onClick={() => {
                            handleTechLinkClick("backend", "/technology_page");
                          }}
                        >
                          View All
                        </p>
                      </div>

                      <div className="vertical_line"></div>

                      <div className="flex text-left flex-col mt-2">
                        <h4
                          className="text-[#161011] cursor-pointer text-xl font-bold"
                          onClick={() => handleDropdownToggle("CPaaS")}
                        >
                          AI
                        </h4>
                        {aiItem.map((item) => {
                          return (
                            <>
                              <div
                                key={item.id}
                                className="header_tech_main "
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(null)}
                              >
                                <img
                                  src={
                                    hoveredId === item.id
                                      ? item.hover
                                      : item.normal
                                  }
                                  className="header_img"
                                  alt={item.label}
                                />
                                <MenuItem
                                  onClick={() => {
                                    handleTechLinkClick(
                                      "artificial",
                                      "/technology_page"
                                    );
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              </div>
                            </>
                          );
                        })}
                        <p
                          className="tech_link"
                          onClick={() => {
                            handleTechLinkClick(
                              "artificial",
                              "/technology_page"
                            );
                          }}
                        >
                          View All
                        </p>
                      </div>
                    </div>
                    <div className="horizontal_line_container">
                      <div className="horizontal_line"></div>
                    </div>

                    <div className="flex justify-center ml-4">
                      <div className="flex flex-col text-left mt-2 ml-4">
                        <h4
                          className="text-[#161011] cursor-pointer text-xl font-bold"
                          onClick={() => handleDropdownToggle("Channels")}
                        >
                          Mobile
                        </h4>
                        {mobileItem.map((item) => {
                          return (
                            <>
                              <div
                                key={item.id}
                                className="header_tech_main "
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(null)}
                              >
                                <img
                                  src={
                                    hoveredId === item.id
                                      ? item.hover
                                      : item.normal
                                  }
                                  className="header_img"
                                  alt={item.label}
                                />
                                <MenuItem
                                  onClick={() => {
                                    handleTechLinkClick(
                                      "mobile",
                                      "/technology_page"
                                    );
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              </div>
                            </>
                          );
                        })}
                        <p
                          className="tech_link"
                          onClick={() => {
                            handleTechLinkClick("mobile", "/technology_page");
                          }}
                        >
                          View All
                        </p>
                      </div>

                      <div className="vertical_line_"></div>

                      <div className="flex flex-col text-left mt-2 ml-4">
                        <h4
                          className="text-[#161011] cursor-pointer text-xl font-bold"
                          onClick={() => handleDropdownToggle("CPaaS")}
                        >
                          DataBase
                        </h4>

                        {databaseItem.map((item) => {
                          return (
                            <>
                              <div
                                key={item.id}
                                className="header_tech_main "
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(null)}
                              >
                                <img
                                  src={
                                    hoveredId === item.id
                                      ? item.hover
                                      : item.normal
                                  }
                                  className="header_img"
                                  alt={item.label}
                                />
                                <MenuItem
                                  onClick={() => {
                                    handleTechLinkClick(
                                      "database",
                                      "/technology_page"
                                    );
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              </div>
                            </>
                          );
                        })}
                        <p
                          className="tech_link"
                          onClick={() => {
                            handleTechLinkClick("database", "/technology_page");
                          }}
                        >
                          View All
                        </p>
                      </div>
                      <div className="vertical_line_"></div>

                      <div className="flex flex-col text-left mt-2 ml-4">
                        <h4
                          className="text-[#161011] cursor-pointer text-xl font-bold"
                          onClick={() => handleDropdownToggle("Channels")}
                        >
                          E-commerce
                        </h4>
                        {e_commItem.map((item) => {
                          return (
                            <>
                              <div
                                key={item.id}
                                className="header_tech_main"
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(null)}
                              >
                                <img
                                  src={
                                    hoveredId === item.id
                                      ? item.hover
                                      : item.normal
                                  }
                                  className="header_img"
                                  alt={item.label}
                                />
                                <MenuItem
                                  onClick={() => {
                                    handleTechLinkClick(
                                      "database",
                                      "/technology_page"
                                    );
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              </div>
                            </>
                          );
                        })}
                        <p
                          className="tech_link"
                          onClick={() => {
                            handleTechLinkClick("database", "/technology_page");
                          }}
                        >
                          View All
                        </p>
                      </div>
                    </div>
                  </Menu>

                  <div className="dropdown-card w-[270px]  sm:p-3 rounded-md mt-4 mr-4 sm:mt-0 sm:absolute sm:right-0 sm:top-0 relative">
                    {hoveredId == null ? (
                      <>
                        <img
                          src={require("../icons/header/header_img/reactjs.png")}
                          className="w-full h-auto "
                          alt="technology image"
                        />

                        <div className=" bg-white rounded h-10px h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex align-middle justify-center">
                          <img
                            src={require("../icons/header/react_header.png")}
                            className="absolute top-2.5 w-5 h-5"
                            alt="technology icon"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            techItems.find((item) => item.id === hoveredId)
                              ?.image ||
                            databaseItem.find((item) => item.id === hoveredId)
                              ?.image ||
                            mobileItem.find((item) => item.id === hoveredId)
                              ?.image ||
                            aiItem.find((item) => item.id === hoveredId)
                              ?.image ||
                            backendItem.find((item) => item.id === hoveredId)
                              ?.image ||
                            e_commItem.find((item) => item.id === hoveredId)
                              ?.image
                          } // Dynamically select the image based on hovered link
                          className="w-full h-auto"
                          alt="Dynamic technology Image"
                        />
                        <div className="bg-white rounded h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex items-center justify-center">
                          <img
                            src={
                              techItems.find((item) => item.id === hoveredId)
                                ?.normal ||
                              databaseItem.find((item) => item.id === hoveredId)
                                ?.normal ||
                              mobileItem.find((item) => item.id === hoveredId)
                                ?.normal ||
                              aiItem.find((item) => item.id === hoveredId)
                                ?.normal ||
                              backendItem.find((item) => item.id === hoveredId)
                                ?.normal ||
                              e_commItem.find((item) => item.id === hoveredId)
                                ?.normal
                            } // Dynamically select the icon based on hovered link
                            className="absolute top-2.5 w-5 h-5"
                            alt="Dynamic technology Icon"
                          />
                        </div>
                      </>
                    )}

                    <div className="bg-[#121212] rounded-b-md p-3">
                      <p className="text-sm sm:text-base text-white">
                        Get 10% off on all our services. Check out our
                        offers!Get 10% off on all our services. Check out our
                        offers!Get 10% off on all our services. Check out our
                        offers Check out our offers Check out...
                      </p>
                      <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                        Hire Tech Exeperts
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>

                  <div className="dropdown-sub-card  sm:mt-[1%] sm:ml-5 sm:mr-10 w-[65%]">
                    <div className="bg-[#121212] rounded-md p-3 header_card_image">
                      <p className="text-sm sm:text-base text-white text-left">
                        Get 10% off on all our services. Check out our offers!
                      </p>
                      <button
                        className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                        onClick={() => navigate("/book-demo")}
                      >
                        Book a Live Demo
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div
                            ref={dropdownRef}
                            onMouseEnter={() => handleMouseEnter('Technologies')}
                        onMouseLeave={() => handleMouseLeave('Technologies')}
                        >
                            <Link
                                className={`header-link ${activeLink === 'Technologies' ? 'active' : ''}`}
                                aria-haspopup="true"
                            >Technologies
                                <span className="border_line"></span>
                            </Link>
                            {dropdownStates.Technologies && (
                                <div className="dropdown-menu tech-menu">
                                    <div className="triangle"></div>

                                    <div className='tech-dropdown'>
                                        <div>
                                            <Menu
                                                id="account-menu"
                                                open={dropdownVisible}
                                                onClose={() => setDropdownVisible(false)}
                                            >
                                                <MenuItem onClick={() => { handleSubLinkClick('/java'); window.scrollTo(0, 0); }}>Java</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/database'); window.scrollTo(0, 0); }}>Database</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/front-end'); window.scrollTo(0, 0); }}>Front-End</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/internet-of-things'); window.scrollTo(0, 0); }}>IOT</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/open-source'); window.scrollTo(0, 0); }}>Open Source</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/flutter'); window.scrollTo(0, 0); }}>Flutter</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/python'); window.scrollTo(0, 0); }}>Python</MenuItem>

                                            </Menu>
                                        </div>

                                        <div>
                                            <Menu
                                                id="account-menu"
                                                open={dropdownVisible}
                                                onClose={() => setDropdownVisible(false)}
                                            >
                                                <MenuItem onClick={() => { handleSubLinkClick('/java'); window.scrollTo(0, 0); }}>Java</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/database'); window.scrollTo(0, 0); }}>Database</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/front-end'); window.scrollTo(0, 0); }}>Front-End</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/internet-of-things'); window.scrollTo(0, 0); }}>IOT</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/open-source'); window.scrollTo(0, 0); }}>Open Source</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/flutter'); window.scrollTo(0, 0); }}>Flutter</MenuItem>
                                                <MenuItem onClick={() => { handleSubLinkClick('/python'); window.scrollTo(0, 0); }}>Python</MenuItem>

                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}

            <div
              ref={dropdownRef}
              onMouseEnter={() => handleMouseEnter("solutions")}
              onMouseLeave={() => handleMouseLeave("solutions")}
            >
              <Link
                to="#"
                className={`header-link ${
                  activeLink === "solutions" ? "active" : ""
                }`}
              >
                Solutions
                <span className="border_line"></span>
              </Link>
              {dropdownStates.solutions && (
                <div className="solution-menu dropdown-menu absolute w-full sm:w-[700px] h-auto sm:h-[548px] bg-white shadow-lg rounded-md flex flex-col sm:space-x-4 sm:right-0 sm:left-auto">
                  <div className="dropdown-options w-full sm:w-[45%] pr-4 sm:pr-0 ml-[20px] mt-2">
                    <div className="absolute top-[-10px] left-[94%] -ml-[15px] w-0 h-0 border-l-[15px] border-r-[15px] border-b-[10px] border-solid border-transparent border-b-white"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2"
                      onMouseEnter={() => setHoveredLink("bank")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={
                          hoveredLink === "bank"
                            ? require("../icons/header/solution/Vector.png") // Image when hovered
                            : require("../icons/header/solution/Vector.png") // Image by default
                        }
                        className="header_img"
                        alt="banking and financial"
                      />
                      <Link
                        to="/bankig-and-financial"
                        onClick={() => {
                          handleSubLinkClick("/bankig-and-financial");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Banking and Financial
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("government")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/solution/Group.png")}
                        className="header_img"
                        alt="government"
                      ></img>
                      <Link
                        to="/government"
                        onClick={() => {
                          handleSubLinkClick("/government");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Government
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("religious")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/solution/religion (1) 1.png")}
                        className="header_img"
                        alt="religious"
                      ></img>
                      <Link
                        to="/religious"
                        onClick={() => {
                          handleSubLinkClick("/religious");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Religious
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("education")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/solution/mortarboard (1) 1.png")}
                        className="header_img"
                        alt="education"
                      ></img>
                      <Link
                        to="/education"
                        onClick={() => {
                          handleSubLinkClick("/education");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Education
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("corporate")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/solution/corporation (1) 1.png")}
                        className="header_img"
                        alt="corporate"
                      ></img>
                      <Link
                        to="/corporate"
                        onClick={() => {
                          handleSubLinkClick("/corporate");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Corporate
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("politics")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/solution/politics (2) 1.png")}
                        className="header_img"
                        alt="politics"
                      ></img>
                      <Link
                        to="/politics"
                        onClick={() => {
                          handleSubLinkClick("/politics");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Politics
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("e_commerce")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/enterprise/Vector.png")}
                        className="header_img"
                        alt=" e commerce sector"
                      ></img>
                      <Link
                        to="/e-commerce-sector"
                        onClick={() => {
                          handleSubLinkClick("/e-commerce-sector");
                          window.scrollTo(0, 0);
                        }}
                      >
                        E-commerce Sector
                      </Link>
                    </div>

                    <div className="border-b-[2px] border-grey-700  mt-4"></div>

                    <div
                      className="header_tech_main flex items-center space-x-2 mt-1"
                      onMouseEnter={() => setHoveredLink("international")}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <img
                        src={require("../icons/header/solution/16-air delivery.png")}
                        className="header_img"
                        alt="international"
                      ></img>
                      <Link
                        to="/international"
                        onClick={() => {
                          handleSubLinkClick("/international");
                          window.scrollTo(0, 0);
                        }}
                      >
                        International
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown-card w-[270px] sm:p-3 rounded-md mt-4 mr-4 sm:mt-0 sm:absolute sm:right-0 sm:top-0 relative">
                    {hoveredLink == null ? (
                      <>
                        <img
                          src={require("../icons/header/header_img/bank.png")}
                          className="w-full h-auto "
                          alt="solutionImage"
                        />

                        <div className=" bg-white rounded h-10px h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex align-middle justify-center">
                          <img
                            src={require("../icons/header/solution/Vector.png")}
                            className="absolute top-2.5 w-5 h-5"
                            alt="solution icon"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={solutionImage[hoveredLink]?.image} // Dynamically select the image based on hovered link
                          className="w-full h-auto"
                          alt="Dynamic solution Image"
                        />
                        <div className="bg-white rounded h-[10px] w-[10px] absolute top-4 right-4 sm:w-10 sm:h-10 flex items-center justify-center">
                          <img
                            src={solutionImage[hoveredLink]?.icon} // Dynamically select the icon based on hovered link
                            className="absolute top-2.5 w-5 h-5"
                            alt="Dynamic solution Icon"
                          />
                        </div>
                      </>
                    )}

                    <div className="bg-[#121212] rounded-b-md p-3">
                      <p className="text-sm sm:text-base text-white">
                        Get 10% off on all our services. Check out our offers!
                        Get 10% off on all our services. Check out our offers!
                        Get 10% off on all our services. Check out our offers!
                        Get 10% off on all our services. Check out our offers!
                      </p>
                      <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                        Hire Tech Exeperts
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>

                  <div className="dropdown-sub-card mt-auto sm:mt-[3%] sm:ml-5 sm:mr-10">
                    <div className="bg-[#121212] rounded-md p-3 header_card_image">
                      <p className="text-sm sm:text-base text-white text-left">
                        Get 10% off on all our services. Check out our offers!
                      </p>
                      <button
                        className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                        onClick={() => navigate("/book-demo")}
                      >
                        Book a Live Demo
                        <ArrowForwardIcon className="relative ml-[8px] text-white" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <Link
                to="/blog"
                className={`header-link ${
                  activeLink === "/blog" ? "active" : ""
                } `}
                onClick={handleScrollToTop}
              >
                Blog<span className="border_line"></span>{" "}
              </Link>
            </div>

            <div>
              <Link
                to="/join_us"
                className={`header-link ${
                  activeLink === "/join_us" ? "active" : ""
                } w-joinus`}
                onClick={handleScrollToTop}
              >
                Join Us <span className="border_line"></span>{" "}
              </Link>
            </div>
            {/* <div className="contact_btn contact_container flex flex-col justify-center">
              <Link
                to="/contact-us"
                className={`header-link contact_link ${
                  activeLink === "/contact-us" ? "active" : ""
                }`}
                onClick={() => {
                  handleSubLinkClick("/contact-us");
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={require("../icons/Stroke-1.png")}
                  alt="contact us"
                ></img>
                <FormButton
                  label={"Contact Us"}
                  className="contact_label h-[17px] bg-[#139C32]"
                />
              </Link>
            </div> */}
          </div>
        </nav>

        <div>
          <nav className={`nav ${isSidebarOpen ? "hidden" : "visible"}`}>
            {/* Navbar content */}
          </nav>
          <Sidebar className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
            <Menu>
              <MenuItem
                onClick={() => {
                  handleSubLinkClick("/");
                  window.scrollTo(0, 0);
                }}
              >
                {" "}
                Home
              </MenuItem>
              <div style={{ borderBottom: "1px solid #ccc" }}></div>
              <div
                onMouseEnter={() => handleMouseEnter("company")}
                onMouseLeave={() => handleMouseLeave("company")}
              >
                <MenuItem
                  onClick={() => toggleDropdown("company")}
                  className="flex justify-between align-middle"
                >
                  Company
                  <ArrowDropDownIcon className="absolute right-[10%]" />
                </MenuItem>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>

                {openDropdown === "company" ? (
                  <div className="h-[900px]">
                    <div>
                      <MenuItem
                        className="menu_item ml-5"
                        onClick={() => {
                          handleSubLinkClick("about", "/about");
                          window.scrollTo(0, 0);
                        }}
                      >
                        About Us
                      </MenuItem>
                      <div style={{ borderBottom: "1px solid #ccc" }}></div>
                      <MenuItem
                        className="menu_item ml-5"
                        onClick={() => {
                          handleSubLinkClick(
                            "infrastructure",
                            "/infrastructure"
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        Infrastructure
                      </MenuItem>
                      <div
                        style={{
                          borderBottom: "1px solid #ccc",
                          margin: "5px 0",
                        }}
                      ></div>

                      <MenuItem
                        className="menu_item ml-5"
                        onClick={() => {
                          handleSubLinkClick("achievements", "/achievements");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Achievements
                      </MenuItem>
                      <div
                        style={{
                          borderBottom: "1px solid #ccc",
                          margin: "5px 0",
                        }}
                      ></div>

                      <MenuItem
                        className="menu_item ml-5"
                        onClick={() => {
                          handleSubLinkClick("our-client", "/our-client");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Our Client
                      </MenuItem>
                      <div
                        style={{
                          borderBottom: "1px solid #ccc",
                          margin: "5px 0",
                        }}
                      ></div>

                      <MenuItem
                        className="menu_item ml-5"
                        onClick={() => {
                          handleSubLinkClick("media", "/media");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Media
                      </MenuItem>
                      <div
                        style={{
                          borderBottom: "1px solid #ccc",
                          margin: "5px 0",
                        }}
                      ></div>

                      <MenuItem
                        className="menu_item ml-5"
                        onClick={() => {
                          handleSubLinkClick("leeway", "/leeway");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Life@Leeway
                      </MenuItem>
                      <div
                        style={{
                          borderBottom: "1px solid #ccc",
                          margin: "5px 0",
                        }}
                      ></div>

                      <MenuItem className="p-0 menu_card">
                        <div className="dropdown-card sm:w-[300px] mt-[20%] sm:p-3 rounded-md   ml-4 sm:mt-0 sm:absolute sm:left-0 sm:top-1 relative">
                          <>
                            <img
                              src={require("../icons/header/header_img/about.png")}
                              className="w-full h-auto "
                              alt="mobile company image"
                            />

                            <div className=" bg-white rounded h-40px h-[40px] w-[40px] absolute top-4 right-4 sm:w-10 sm:h-10 xs:w-10 xs:h-10 flex align-middle justify-center">
                              <img
                                src={require("../icons/header/Layer 2 (1).png")}
                                className="absolute top-2.5 w-5 h-5"
                                alt="mobile company icon"
                              />
                            </div>
                          </>

                          <div className="bg-[#121212] rounded-b-md p-3">
                            <p className="text-xs sm:text-base text-white">
                              Get 10% off on all our services
                            </p>
                            <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                              Hire Tech Exeperts
                              <ArrowForwardIcon className="relative ml-[8px] text-white" />
                            </button>
                          </div>

                          <div className="dropdown-sub-card mt-[10%] sm:mt-[3%] sm:ml-5 sm:mr-10 ">
                            <div className="bg-[#121212] rounded-md p-3 header_card_image flex flex-col">
                              <p className="text-sm sm:text-base text-white text-left">
                                Check out our offers!
                              </p>
                              <button
                                className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                                onClick={() => navigate("/book-demo")}
                              >
                                Book a Live Demo
                                <ArrowForwardIcon className="relative ml-[8px] text-white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                onMouseEnter={() => handleMouseEnter("platforms")}
                onMouseLeave={() => handleMouseLeave("platforms")}
              >
                <MenuItem
                  onClick={() => toggleDropdown("platforms")}
                  className="flex justify-between align-middle"
                >
                  Platforms
                  <ArrowDropDownIcon className="absolute right-[10%]" />
                </MenuItem>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
                {openDropdown === "platforms" ? (
                  <div className=" h-[100%]">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="dropdown_options"
                    >
                      <div className="flex flex-col ml-[6%]">
                        <h4>Send2App</h4>
                        {/* <MenuItem className='menu_item ml-5' onClick={() => { handleSubLinkClick('digital-marketing', "/digital-marketing"); window.scrollTo(0, 0) }}>Digital Marketing</MenuItem>
                                                <div style={{ borderBottom: '1px solid #ccc', margin: '5px 0' }}></div> */}
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("mobile-app", "/mobile-app");
                            window.scrollTo(0, 0);
                          }}
                        >
                          Mobile App Notification
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick(
                              "web-devlopment",
                              "/web-devlopment"
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          Send2Digital
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick(
                              "software-development",
                              "/software-development"
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          Inbox Mailer
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                      </div>

                      <div className="flex flex-col ml-[6%]">
                        <h4>CPaaS</h4>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("rcs-msg", "/rcs-msg");
                            window.scrollTo(0, 0);
                          }}
                        >
                          Google RCS
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("pbx-service", "/pbx-service");
                            window.scrollTo(0, 0);
                          }}
                        >
                          IPBX Service
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("whatsapp", "/whatsapp");
                            window.scrollTo(0, 0);
                          }}
                        >
                          Whatsapp Bussiness
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("whatsapp", "/whatsapp");
                            window.scrollTo(0, 0);
                          }}
                        >
                          AI Chatbot
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("bulk-sms", "/bulk-sms");
                            window.scrollTo(0, 0);
                          }}
                        >
                          A2P SMS
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("bulk-mail", "/bulk-mail");
                            window.scrollTo(0, 0);
                          }}
                        >
                          Push Notification
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("bulk-mail", "/bulk-mail");
                            window.scrollTo(0, 0);
                          }}
                        >
                          VOIP
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                      </div>

                      {/* <div className='flex flex-col ml-[6%]'>
                                                <h4 >Technologies</h4>
                                                <MenuItem className='menu_item ml-5' onClick={() => { handleSubLinkClick('java', "/java"); window.scrollTo(0, 0) }}>Java</MenuItem>
                                                <div style={{ borderBottom: '1px solid #ccc', margin: '5px 0' }}></div>
                                                <MenuItem className='menu_item ml-5' onClick={() => { handleSubLinkClick('database', "/database"); window.scrollTo(0, 0) }}>Database</MenuItem>
                                                <div style={{ borderBottom: '1px solid #ccc', margin: '5px 0' }}></div>
                                                <MenuItem className='menu_item ml-5' onClick={() => { handleSubLinkClick('front-end', "/front-end"); window.scrollTo(0, 0) }}>Front-End</MenuItem>
                                                <div style={{ borderBottom: '1px solid #ccc', margin: '5px 0' }}></div>
                                                <MenuItem className='menu_item ml-5' onClick={() => { handleSubLinkClick('internet-of-things', "/internet-of-things"); window.scrollTo(0, 0) }}>IOT</MenuItem>
                                                <div style={{ borderBottom: '1px solid #ccc', margin: '5px 0' }}></div>
                                                <MenuItem className='menu_item ml-5' onClick={() => { handleSubLinkClick('flutter', "/flutter"); window.scrollTo(0, 0) }}>Flutter</MenuItem>

                                            </div> */}

                      <div className="flex flex-col ml-[6%]">
                        <h4>Enterprise Solutions</h4>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("crm", "/crm");
                            window.scrollTo(0, 0);
                          }}
                        >
                          CRM
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("cms", "/cms");
                            window.scrollTo(0, 0);
                          }}
                        >
                          CMS
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("e-commerc", "/e-commerce");
                            window.scrollTo(0, 0);
                          }}
                        >
                          E-Commerce
                        </MenuItem>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <MenuItem
                          className="menu_item ml-5"
                          onClick={() => {
                            handleSubLinkClick("erp", "/erp");
                            window.scrollTo(0, 0);
                          }}
                        >
                          ERP
                        </MenuItem>
                      </div>
                      <MenuItem className="p-0 menu_card">
                        <div className="dropdown-card sm:w-[300px] sm:p-3 rounded-md   ml-4 sm:mt-0 sm:absolute sm:left-0 sm:top-1 relative">
                          <>
                            <img
                              src={require("../icons/header/header_img/mobile.png")}
                              className="w-full h-auto "
                              alt="mobile platform image"
                            />

                            <div className=" bg-white rounded h-40px h-[40px] w-[40px] absolute top-4 right-4 sm:w-10 sm:h-10 xs:w-10 xs:h-10 flex align-middle justify-center">
                              <img
                                src={require("../icons/header/mob.png")}
                                className="absolute top-2.5 w-5 h-5"
                                alt="mobile platform icon"
                              />
                            </div>
                          </>

                          <div className="bg-[#121212] rounded-b-md p-3">
                            <p className="text-xs sm:text-base text-white">
                              Get 10% off on all our services
                            </p>
                            <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                              Hire Tech Exeperts
                              <ArrowForwardIcon className="relative ml-[8px] text-white" />
                            </button>
                          </div>

                          <div className="dropdown-sub-card mt-[10%] sm:mt-[3%] sm:ml-5 sm:mr-10 ">
                            <div className="bg-[#121212] rounded-md p-3 header_card_image flex flex-col">
                              <p className="text-sm sm:text-base text-white text-left">
                                Check out our offers!
                              </p>
                              <button
                                className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                                onClick={() => navigate("/book-demo")}
                              >
                                Book a Live Demo
                                <ArrowForwardIcon className="relative ml-[8px] text-white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                onMouseEnter={() => handleMouseEnter("Technologies")}
                onMouseLeave={() => handleMouseLeave("Technologies")}
              >
                <MenuItem
                  onClick={() => toggleDropdown("Technologies")}
                  className="flex justify-between align-middle"
                >
                  Technologies
                  <ArrowDropDownIcon className="absolute right-[10%]" />
                </MenuItem>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
                {openDropdown === "Technologies" ? (
                  <div className="">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="dropdown_options"
                    >
                      <div className="flex flex-col ml-[6%]">
                        <h4>Frontend</h4>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Vector.png")}
                            className="header_img"
                            alt="frontent img"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "digital-marketing",
                                "/digital-marketing"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            React Js
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/javascript 1.png")}
                            className="header_img"
                            alt="Js"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/mobile-app", "/mobile-app");
                              window.scrollTo(0, 0);
                            }}
                          >
                            Js
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/html (2) 1.png")}
                            className="header_img"
                            alt="Html"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "/web-devlopment",
                                "/web-devlopment"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Html
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/css (1) 1.png")}
                            className="header_img"
                            alt="Css"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "/software-development",
                                "/software-development"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Css
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                      </div>

                      <div className="flex flex-col ml-[6%]">
                        <h4>Backend</h4>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/node-js (2) 1.png")}
                            className="header_img"
                            alt="Node Js"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/rcs-msg", "/rcs-msg");
                              window.scrollTo(0, 0);
                            }}
                          >
                            Node Js
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Vector (1).png")}
                            className="header_img"
                            alt="php"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "/pbx-service",
                                "/pbx-service"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Php
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/python 1.png")}
                            className="header_img"
                            alt="python"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/whatsapp", "/whatsapp");
                              window.scrollTo(0, 0);
                            }}
                          >
                            Python
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Group.png")}
                            className="header_img"
                            alt="laravel"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/chatbot", "/chatbot");
                              window.scrollTo(0, 0);
                            }}
                          >
                            Laravel
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                      </div>

                      <div className="flex flex-col ml-[6%]">
                        <h4>AI</h4>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/node-js (2) 1.png")}
                            className="header_img"
                            alt="chat GPT"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/rcs-msg", "/rcs-msg");
                              window.scrollTo(0, 0);
                            }}
                          >
                            chat GPT
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Vector (1).png")}
                            className="header_img"
                            alt="Pytorch"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "/pbx-service",
                                "/pbx-service"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Pytorch
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/python 1.png")}
                            className="header_img"
                            alt="Tensorflow"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/whatsapp", "/whatsapp");
                              window.scrollTo(0, 0);
                            }}
                          >
                            Tensorflow
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Group.png")}
                            className="header_img"
                            alt="IBM"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/chatbot", "/chatbot");
                              window.scrollTo(0, 0);
                            }}
                          >
                            IBM
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                      </div>

                      <div className="flex flex-col ml-[6%]">
                        <h4>Mobile</h4>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Vector (2).png")}
                            className="header_img"
                            alt="ios"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "digital-marketing",
                                "/digital-marketing"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            ios
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/Group (1).png")}
                            className="header_img"
                            alt="Android"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick("/mobile-app", "/mobile-app");
                              window.scrollTo(0, 0);
                            }}
                          >
                            Android
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/flutter (3) 1.png")}
                            className="header_img"
                            alt="Flutter"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "/web-devlopment",
                                "/web-devlopment"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Flutter
                          </MenuItem>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                        <div className="header_tech_main">
                          <img
                            src={require("../../src/icons/header/xamarin 1.png")}
                            className="header_img"
                            alt="Xamarin"
                          ></img>
                          <MenuItem
                            className="menu_item ml-5"
                            onClick={() => {
                              handleSubLinkClick(
                                "/software-development",
                                "/software-development"
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Xamarin
                          </MenuItem>
                        </div>
                      </div>

                      <MenuItem className="p-0 menu_card">
                        <div className="dropdown-card sm:w-[300px] sm:p-3 rounded-md   ml-4 sm:mt-0 sm:absolute sm:left-0 sm:top-1 relative">
                          <>
                            <img
                              src={require("../icons/header/header_img/react-mobile.png")}
                              className="w-full h-auto "
                              alt="technology image"
                            />

                            <div className=" bg-white rounded h-40px h-[40px] w-[40px] absolute top-4 right-4 sm:w-10 sm:h-10 xs:w-10 xs:h-10 flex align-middle justify-center">
                              <img
                                src={require("../icons/header/react_header.png")}
                                className="absolute top-2.5 w-5 h-5"
                                alt="mobile technology header icon"
                              />
                            </div>
                          </>

                          <div className="bg-[#121212] rounded-b-md p-3">
                            <p className="text-xs sm:text-base text-white">
                              Get 10% off on all our services
                            </p>
                            <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                              Hire Tech Exeperts
                              <ArrowForwardIcon className="relative ml-[8px] text-white" />
                            </button>
                          </div>

                          <div className="dropdown-sub-card mt-[10%] sm:mt-[3%] sm:ml-5 sm:mr-10 ">
                            <div className="bg-[#121212] rounded-md p-3 header_card_image flex flex-col">
                              <p className="text-sm sm:text-base text-white text-left">
                                Check out our offers!
                              </p>
                              <button
                                className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                                onClick={() => navigate("/book-demo")}
                              >
                                Book a Live Demo
                                <ArrowForwardIcon className="relative ml-[8px] text-white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                onMouseEnter={() => handleMouseEnter("solutions")}
                onMouseLeave={() => handleMouseLeave("solutions")}
              >
                <MenuItem
                  onClick={() => toggleDropdown("solutions")}
                  className="flex justify-between align-middle"
                >
                  solutions
                  <ArrowDropDownIcon className="absolute right-[10%]" />
                </MenuItem>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
                {openDropdown === "solutions" ? (
                  <div className="">
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick(
                          "bankig-and-financial",
                          "/bankig-and-financial"
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      Banking and Financial
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick("government", "/government");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Government
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick("religious", "/religious");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Religious
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick("education", "/education");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Education
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick("corporate", "/corporate");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Corporate
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick("politics", "/politics");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Politics
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick(
                          "e-commerce-sector",
                          "/e-commerce-sector"
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      E-commerce Sector
                    </MenuItem>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "5px 0",
                      }}
                    ></div>
                    <MenuItem
                      className="menu_item ml-5"
                      onClick={() => {
                        handleSubLinkClick("international", "/international");
                        window.scrollTo(0, 0);
                      }}
                    >
                      International
                    </MenuItem>

                    <MenuItem className="p-0 menu_card">
                      <div className="dropdown-card sm:w-[300px] sm:p-3 rounded-md   ml-4 sm:mt-0 sm:absolute sm:left-0 sm:top-1 relative">
                        <>
                          <img
                            src={require("../icons/header/header_img/bank.png")}
                            className="w-full h-auto "
                            alt="mobile sollution header"
                          />

                          <div className=" bg-white rounded h-40px h-[40px] w-[40px] absolute top-4 right-4 sm:w-10 sm:h-10 xs:w-10 xs:h-10 flex align-middle justify-center">
                            <img
                              src={require("../icons/header/solution/Vector.png")}
                              className="absolute top-2.5 w-5 h-5"
                              alt="mobile solution icon"
                            />
                          </div>
                        </>

                        <div className="bg-[#121212] rounded-b-md p-3">
                          <p className="text-xs sm:text-base text-white">
                            Get 10% off on all our services
                          </p>
                          <button className="mt-4 px-4 py-2 border border-white text-white text-xs rounded-lg  focus:outline-none focus:ring-0">
                            Hire Tech Exeperts
                            <ArrowForwardIcon className="relative ml-[8px] text-white" />
                          </button>
                        </div>

                        <div className="dropdown-sub-card mt-[10%] sm:mt-[3%] sm:ml-5 sm:mr-10 ">
                          <div className="bg-[#121212] rounded-md p-3 header_card_image flex flex-col">
                            <p className="text-sm sm:text-base text-white text-left">
                              Check out our offers!
                            </p>
                            <button
                              className=" px-2 ml-[10px] py-2 w-[165px] border border-[#134CBC] text-xs  text-white rounded-lg  focus:outline-none focus:ring-0"
                              onClick={() => navigate("/book-demo")}
                            >
                              Book a Live Demo
                              <ArrowForwardIcon className="relative ml-[8px] text-white" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  </div>
                ) : null}
              </div>

              <div>
                <MenuItem
                  className="menu_item ml-5"
                  onClick={() => {
                    handleSubLinkClick("join_us", "/join_us");
                    window.scrollTo(0, 0);
                  }}
                >
                  join Us
                </MenuItem>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
              </div>
              <div>
                <MenuItem
                  className="menu_item ml-5"
                  onClick={() => {
                    handleSubLinkClick("blog", "/blog");
                    window.scrollTo(0, 0);
                  }}
                >
                  Blog
                </MenuItem>
                <div style={{ borderBottom: "1px solid #ccc" }}></div>
              </div>

              <div className="flex justify-center mr-[-8%]">
                <MenuItem
                  className="menu_item ml-5"
                  onClick={() => {
                    handleSubLinkClick("contact_us", "/contact_us");
                    window.scrollTo(0, 0);
                  }}
                >
                  <FormButton
                    label={"Contact Us"}
                    style={{
                      height: "17px",
                      width: "100px",
                      backgroundColor: "#139C32",
                    }}
                  />
                </MenuItem>
              </div>
            </Menu>
          </Sidebar>
          <div
            className="menu-icon"
            onClick={() => setSidebarOpen(!isSidebarOpen)}
          >
            <MenuIcon />
          </div>
        </div>
      </header>
        < SocialMedia />
    </>
  );
};

export default Header;
