import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormInput from '../components/FormInput';
import FormTextArea from '../components/FormTextArea';
import FormButton from '../components/FormButton';

export default function RowAndColumnSpacing() {
    return (
        <Box sx={{ width: '100%'}} className='mb-5'>
        <h1  className='color-134CBC text-center font-bold text-[32px]'>LEAVE A COMMENT</h1>
        <Grid container spacing={2} className='d-flex justify-center'>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormInput
                    placeholder="Name Here"
                    variant="outlined"
                    margin="normal"
                    sx={{ marginBottom: '4%' }} 
                />
                
                <FormInput
                    placeholder="Email Here"
                    variant="outlined"
                    margin="normal"
                    sx={{ marginBottom: '24px' }} 
                />
             
            </Grid>
    
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormInput
                    placeholder="Website"
                    variant="outlined"
                    margin="normal"
                    
                    sx={{ marginBottom: '24px' }} 
                />
              
                <FormTextArea
                    placeholder="Write Comment*"
                    variant="outlined"
                    margin="normal"
                />

            </Grid>
            
        </Grid>
        <Grid container justifyContent="center" >
        <FormButton label={"Send"}/>
    </Grid>
    </Box>

    );
}
