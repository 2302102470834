import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useState } from "react";
import eve1 from "../event/IMC/Mask group (1).png";
import eve2 from "../event/IMC/Mask group (2).png";
import eve3 from "../event/IMC/Mask group (3).png";
import eve4 from "../event/IMC/Mask group (4).png";
import eve5 from "../event/IMC/Mask group (5).png";
import eve6 from "../event/IMC/Mask group (6).png";
import eve7 from "../event/IMC/Mask group (7).png";
import eve8 from "../event/IMC/Mask group (8).png";
import eve9 from "../event/IMC/Mask group (9).png";
import event from "../event/IMC/Mask group.png";
import SEO from "../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "300px",
  width: "400px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "12px",
  boxShadow: "0px 6.3px 30px 0px #3D3D3D0D",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // height:'73px',
  // width:'500px'
}));
export default function RowAndColumnSpacing() {
  const [active, setActive] = useState("2024");

  const onClickActive = (btnName) => {
    setActive(btnName);
  };
  return (
    <>
      <SEO
        title="IMC Event | Leeway Softech Pvt Ltd"
        description="Leeway Softech is participated in India Mobile Congress, the premier event showcasing the future of technology. At the 'India mobile congress' exhibition, they showcased their recent innovations in their domain."
      />
      <Box
        sx={{ width: "100%" }}
        className="it_infra main_content pt-[3%] pb-[3%]"
      >
        <h1
          style={{ color: "#161011", textAlign: "center" }}
          className="text-[#161011] text-center text-[32px] font-bold"
        >
          INDIA MOBILE CONGRESS
        </h1>

        {/* <div className="flex justify-center align-middle mb-5">
          <button
            className={`mt-4 px-4 py-2 ml-5 border  ${
              active === "2024"
                ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
                : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
            } rounded-lg  focus:ring-0 h-[45px] w-[175px] `}
            onClick={() => onClickActive("2024")}
          >
            2024
          </button>
          <button
            className={`mt-4 px-4 py-2 ml-5 border  ${
              active === "2023"
                ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
                : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
            } rounded-lg  focus:ring-0 h-[45px] w-[175px]`}
            onClick={() => onClickActive("2023")}
          >
            2023
          </button>
          <button
            className={`mt-4 px-4 py-2 ml-5  border  ${
              active === "2022"
                ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
                : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
            } rounded-lg  focus:ring-0 h-[45px] w-[175px]`}
            onClick={() => onClickActive("2022")}
          >
            2022
          </button>
          <button
            className={`mt-4 px-4 py-2 ml-5 border  ${
              active === "2021"
                ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
                : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
            } rounded-lg  focus:ring-0 h-[45px] w-[175px]`}
            onClick={() => onClickActive("2021")}
          >
            2021
          </button>
        </div> */}

        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve1}
                alt="imc event"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve2}
                alt="imc image"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve3}
                alt="imc image"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img src={eve4} alt="imc" className="event_img h-[282px]"></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve5}
                alt="media imc"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve6}
                alt="imc img of event"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve7}
                alt="media event imc"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve8}
                alt="imc image of event "
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve9}
                alt="main imc img"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={event}
                alt="sub img"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
