import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import icon from "../../icons/mob-app/Vector (2).png"




const Infrastructure = () => {
    return (
       
    <>
    <Box sx={{ width: '100%'}} className="">
    <h1 className='text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5'>Why Choose Front-End Technologies?</h1>
    <Grid 
            container 
            spacing={5}
         
            paddingTop={4}

           className='flex justify-center align-middle'
        >
            <Grid item xs={12} md={6} lg={6} className='component'>
                {/* Your icons and text here */}
                <div className='icons flex align-middle mb-5'>
                    <img src={icon} alt='coding icon' className='mr-2.5 h-5'  />
                    <div>
                        <p>Coding much faster</p>
                    </div>
                </div>
        
        <div className='icons flex align-middle mb-5'>
            
               <img src={icon} alt='testing icon' className='mr-2.5 h-5' ></img>
               <div>
                <p > 
                End-to-End testing
                </p></div>
        </div>
        </Grid>  

        <Grid item xs={12} md={6} lg={6} className='component'>
        <div className='icons flex align-middle mb-5'>
            
            <img src={icon} alt='Offers icon' className='mr-2.5 h-5' ></img>
            <div>
             <p > 
             Offers Advanced Caching System
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5'>
            
            <img src={icon} alt='support icon' className='mr-2.5 h-5' ></img>
            <div>
             <p > 
             Great support
             </p></div>
     </div>
     
        </Grid>  
      </Grid>
    </Box>
   
    
    </>
    );
};

export default Infrastructure;
