import { SendOutlined } from "@mui/icons-material";
import React from "react";

const FormButtonComponent = ({
  label,
  isDisableStyle,
  icon,
  outline,
  loading,
  ...rest
}) => {
  return (
    <div
      className={
        loading
          ? "form-button-container-disabled my-2"
          : isDisableStyle
          ? "form-button-container-disabled my-2"
          : outline
          ? "form-button-container-outline my-2"
          : "form-button-container my-2"
      }
      {...rest}
    >
      {icon}
      <p>{loading ? "Loading..." : label}</p>
    </div>
  );
};

export default FormButtonComponent;
