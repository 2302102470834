import React from 'react'
import Select from 'react-select';
import "../component.css"

function FormSelect({ options,  ...rest }) {

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '14px',
            height: '40px',
            marginBottom:'5%',
            borderColor: state.isFocused ? 'black' : provided.borderColor,
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'black' : provided.borderColor,
            },  
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Set your desired box shadow
            backgroundColor: 'white', // Set your desired background color
            fontSize: '14px'
        }),
        menuPortal: base => ({
            ...base,
            fontSize: '10px'
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '14px', // Change the font size here
          }),
    };

    return (
        <div className='d-flex w-100'>
        
            {/* <div className='w-100'> */}
                <Select
                    {...rest}
                    options={options}
                    styles={customStyles}
                />
            {/* </div> */}
        </div>
    )
}

export default FormSelect