import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import ami2 from "../../certificat_img/logo/ami2.png";
import BHEL_logo from "../../certificat_img/logo/BHEL_logo.png";
import BMRCL from "../../certificat_img/logo/BMRCL.png";
import Central_Warehousing from "../../certificat_img/logo/Central Warehousing.png";
import CGST from "../../certificat_img/logo/CGST-Rajkot.png";
import cotton from "../../certificat_img/logo/Cotton-Corporation.png";
import delhi from "../../certificat_img/logo/delhi.png";
import Forest from "../../certificat_img/logo/Forest_Department_LOGO.png";
import gujarat from "../../certificat_img/logo/Gujarat_Cancer.png";
import iiwm from "../../certificat_img/logo/iiwm.png";
import mtnl from "../../certificat_img/logo/MTNL Logo.png";
import nirrch from "../../certificat_img/logo/NIRRCH.png";
import ispat from "../../certificat_img/logo/Rashtriya Ispat Nigam Limited.png";
import sdcl from "../../certificat_img/logo/SDCL-Logo.png";
import railway from "../../certificat_img/logo/WESTERN-RAILWAY.png";
import SEO from "../../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "182px",
  width: "256px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));
export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Reliable Government IT Services by Leeway Softech Pvt. Ltd"
        description="Leeway Softech offers secure IT solutions, SMS, email services and user-friendly platforms for government projects."
      />
      <Grid container spacing={1} className="gove_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Government Sector</h1>
            <p className="text-lg font-normal">
              Empowering government agencies with digital tools that streamline
              services, enhance transparency, and improve public trust..
            </p>
          </div>
        </Grid>
      </Grid>

      {/* <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>A Trusted Partner</div>
                <Grid container spacing={4} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={ami2} alt='ami2'  className='w-[130px] h-[159px]'></img>
                            </div>

                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={BHEL_logo} alt='BHEL_logo' className='w-[159px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={BMRCL} alt='BMRCL' className='w-[230px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={Central_Warehousing} alt='Central_Warehousing' className='w-[130px] h-[159px]'></img>
                            </div>

                        </Item>
                    </Grid>


                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={CGST} alt='CGST' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={cotton} alt='cotton' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={delhi} alt='delhi' className='w-[130px] h-[159px]'></img>
                            </div>

                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={Forest} alt='Forest' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>


                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={gujarat} alt='gujarat img' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={iiwm} alt='iiwm' className='w-[130px] h-[159px]'></img>
                            </div>

                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={mtnl} alt='mtnl' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={nirrch} alt='nirrch' style={{ width: '214px', height: '159px' }}></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={ispat} alt='ispat' className='w-[130px] h-[159px]'></img>
                            </div>

                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={sdcl} alt='sdcl' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                <img src={railway} alt='railway' className='w-[130px] h-[159px]'></img>
                            </div>
                        </Item>
                    </Grid>

                </Grid>
            </Box> */}

      <div className="text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5">
        Diverse Range of Services to Fulfil Your Needs
      </div>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  SMS Communication Solutions
                </h3>
              </div>
              <p>
              Enhance your communication strategy with our robust SMS solutions. We ensure seamless connectivity, enabling fast and efficient dissemination of information to a wide audience, no matter where they are.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  Bulk E-mail Services
                </h3>
              </div>
              <p>
              Leverage the power of bulk email marketing with our reliable and secure email services. Reach a large number of recipients effortlessly, ensuring effective communication across your government network.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  User-Friendly Websites and Applications
                </h3>
              </div>
              <p>
              We specialize in designing and developing interactive, user-friendly websites and applications that are customized to meet the specific requirements of government agencies. These digital solutions improve user experience and operational efficiency.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  Secure Web Hosting and SSL Certificates
                </h3>
              </div>
              <p>
              Your data security is our priority. We provide secure web hosting services coupled with SSL certificates to ensure the highest level of protection for your online presence and sensitive information.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  Comprehensive IT audit services
                </h3>
              </div>
              <p>
              Our IT audit services help you stay compliant and optimize your digital infrastructure. By identifying potential vulnerabilities, we ensure that your systems are secure, efficient and capable of meeting evolving demands.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  Secure email IDs
                </h3>
              </div>
              <p>
              Maintain the confidentiality of sensitive information with our secure email IDs. Designed to safeguard communications within your organization, our service protects against unauthorized access and data breaches.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
    </>
  );
}
