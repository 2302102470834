import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import SEO from '../components/SEO';


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Card = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));



export default function     RowAndColumnSpacing() {
    return (
        <>
            <SEO title="PHP-OpenCart | Leeway Softech Pvt Ltd"
             description="Leeway offers e-commerce, educational, and banking services as a part of their web application development portfolio powered by PHP-OpenCart. They also offer services to revamp your website that speaks your brand"
            />
            <Grid container spacing={1} className="opencart_page main_content px-6 md:px-24" >
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
    <div className='text-white text-center'>
    <h1 className='text-3xl font-semibold'>Opencart</h1>
                        <p className='text-lg font-normal'>Opencart Development Services Get a feature-rich e-commerce store to achieve online success with our experts. Our opencart development services aid you to generate robust and highly dynamic e-commerce stores for the end users. Opencart is a platform that delivers easy-to-use and feature-rich web development services to the clients. With the help of our bespoke opencart solutions, businesses can expand their online businesses across all possible work platform and frameworks.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra pr-[2%] pl-[2%] pt-[2%]" >
            <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>Benefits of opencart</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Completely secure</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Functionality of multi-store</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center'>
                        <p  className='text[#161011] text-sm'>User-friendly management of multiple orders</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Easy setup</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>A simple system for user management</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Management of product catalog will be easier</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>The ability of online coupons</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Support for different payment gateways</p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Make use of extension plug-ins</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>


                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5'>Opencart development services</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5' >
                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart SEO</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart customization</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart extension development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart e-commerce solution</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Third party app integration</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart Migration solution</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart CMS development</p>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Opencart web development</p>
                            </div>
                        </Card>
                    </Grid>
                </Grid>


                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>Why Choose Us For the Opencart Web Development?</div>
                <Grid
                    container
                    spacing={2}
                    paddingTop={5}
                   
                    className='felx justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div  className='icons flex align-middle mb-5'>
                            <img src={icon} alt='Skilledicon' className='mr-2.5' />
                            <div>
                                <p>Skilled developers</p>
                            </div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='satisfactionicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                100% customer satisfaction
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='ISOicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                ISO certification
                                </p></div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Agileicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Agile approaches
                                </p></div>
                        </div>

                       
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Affordableicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Affordable pricing
                                </p></div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Serveicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Serve clients on a global platform
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Activeicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Active maintenance and support
                                </p></div>
                        </div>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
