import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import motivated from "../../icons/career/self-motivation 1.png";
import leadership from "../../icons/career/Group.png";
import positive from "../../icons/career/positive.png";
import goal from "../../icons/career/goal 1.png";
import ideas from "../../icons/career/idea-process 1.png";
import page from "../../icons/career/Page-1.png";
import Openings from "../../pages/Company/Openings";
import CareerForm from "../../pages/Company/CareerForm";
import SEO from "../../components/SEO";
import Header from "../Header";

const Item = styled(Box)(({ theme }) => ({
  // ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "100%",
  width: "207px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Careers at Leeway Softech Pvt Ltd"
        description="Explore rewarding career opportunities at our innovative IT services company. Be a part of Leeway Softech team for growth and development in your career. Apply now."
      />
      <Grid container spacing={1} className="career_page main_content">
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          className="max-w-[720px] items-center justify-center"
        >
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Career</h1>
            <p className="text-lg font-normal">
              We deliver quality standards. The entire setup is managed by
              Leeway Softech without any disturbance to the customers of the
              company.
            </p>
          </div>
        </Grid>
      </Grid>
      <Openings />

      <Box className="it_infra w-full p-5">
        <div className="text-[#134CBC] text-center text-2xl font-bold my-4">
          We are looking for
        </div>
        <Grid
          container
          spacing={2}
          // columns={16}
          justifyContent="center"
          padding={"10px"}
          className="gap-[3%] min-h-full"
        >
          <Grid items size={{ xs: 12, sm: 4, lg: 2 }}>
            <Item className="card">
              <div>
                <img src={motivated} alt="motivated" className="mx-auto"></img>
                <h3 className="my-4 text-lg font-bold">Self Motivated</h3>

                {/* <p className="text-[#1610118C] text-sm">
                  The candidate with self-motivational can increase the own
                  knowledge as well as company growth.We look for proactiveness
                  in candidate.
                </p> */}
              </div>
            </Item>
          </Grid>

          <Grid items size={{ xs: 12, sm: 4, lg: 2 }}>
            <Item className="card">
              <div className="text-center">
                <img
                  src={leadership}
                  alt="leadership"
                  className="mx-auto"
                ></img>
                <h3 className="my-4 text-lg font-bold">Leadership</h3>

                {/* <p className="text-[#1610118C] text-sm">
                  The candidate has the ability to take leadership in an
                  excellent way.The candidate can motivate the team member in
                  critical situation.{" "}
                </p> */}
              </div>
            </Item>
          </Grid>

          <Grid items size={{ xs: 12, sm: 4, lg: 2 }}>
            <Item className="card">
              <div className="text-center">
                <img src={positive} alt="positive" className="mx-auto"></img>
                <h3 className="my-4 text-lg font-bold">Positive Attitude</h3>

                {/* <p className="text-[#1610118C] text-sm">
                  The candidate has the ability to blend in our culture. The
                  candidate with positive attitude beneficial to ownself as well
                  as also to company growth.
                </p> */}
              </div>
            </Item>
          </Grid>
          <Grid items size={{ xs: 12, sm: 4, lg: 2 }}>
            <Item className="card">
              <div className="text-center">
                <img src={goal} alt="gsc" className="mx-auto" />
                <h3 className="my-4 text-lg font-bold">High Goals</h3>

                {/* <p className="text-[#1610118C] text-sm">
                  The candidate stays focused and keeps moving towards the goals
                  set. The candidate with high goals advantages to ownself.
                </p> */}
              </div>
            </Item>
          </Grid>
          <Grid items size={{ xs: 12, sm: 4, lg: 2 }}>
            <Item className="card">
              <div className="text-center">
                <img src={ideas} alt="gsc" className="mx-auto"></img>
                <h3 className="my-4 text-lg font-bold">Innovative Ideas</h3>

                {/* <p className="text-[#1610118C] text-sm">
                  Candidate can think in a different unique way. Has the ability
                  to work in critical situation with innovative way.
                </p> */}
              </div>
            </Item>
          </Grid>

          <Grid items size={{ xs: 12, sm: 4, lg: 2 }}>
            <Item className="card">
              <div className="text-center">
                <img src={page} alt="gsc" className="mx-auto"></img>
                <h3 className="my-4 text-lg font-bold">
                  Knowledge & Capabilities
                </h3>

                {/* <p className="text-[#1610118C] text-sm">
                  Stays abreast with latest technologies and also ready to work
                  with new technologies.
                </p> */}
              </div>
            </Item>
          </Grid>
        </Grid>

        {/* <Grid
          container
          spacing={2}
          justifyContent="center"
          padding={"10px"}
          className="gap-[3%]"
        >
          <Grid item xs={6}>
            <Item className="card">
              <div className="text-center">
                <img src={goal} alt="gsc" className="mx-auto" />
                <h3 className="my-4 text-lg font-bold">High Goals</h3>

                <p className="text-[#1610118C] text-sm">
                  The candidate stays focused and keeps moving towards the goals
                  set. The candidate with high goals advantages to ownself.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item className="card">
              <div className="text-center">
                <img src={ideas} alt="gsc" className="mx-auto"></img>
                <h3 className="my-4 text-lg font-bold">Innovative Ideas</h3>

                <p className="text-[#1610118C] text-sm">
                  Candidate can think in a different unique way. Has the ability
                  to work in critical situation with innovative way.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item className="card">
              <div className="text-center">
                <img src={page} alt="gsc" className="mx-auto"></img>
                <h3 className="my-4 text-lg font-bold">
                  Knowledge & Capabilities
                </h3>

                <p className="text-[#1610118C] text-sm">
                  Stays abreast with latest technologies and also ready to work
                  with new technologies.
                </p>
              </div>
            </Item>
          </Grid>
        </Grid> */}
      </Box>

      <CareerForm />
    </>
  );
}
