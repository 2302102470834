import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SEO from "../../components/SEO"



const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '10px',
  border: ' 1.5px solid #C4D2EE',
  // height: '280px',
  width: '30vw',
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));


const Infrastructure = () => {
    return (
       
    <>
      <SEO title='Digital Marketing Services | Leeway softech Pvt Ltd'
             description='Expand your business internationally with our all-encompassing digital marketing services. We provide SEO, SMM, SEM, SMO, PPC, and content marketing solutions.'
            />
        <Grid container spacing={1} className="digital_page main_content">
        <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                <h1 className='text-3xl font-semibold'>Digital Maketing</h1>
                <p className='text-lg font-normal'>Online digital marketing is the foundation of a successful online presence and can be used to drive customer participation, increase brand value, and build trust. It is important to create content that is relevant, engaging, and consistent. With a motive to provide the simplest solution for complex problems, Leeway Softech started way back with a mission to innovate, facilitate, and automate.</p>
            </div>
        </Grid>
    </Grid> 

    <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
    <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Services & Products</div>
    <Grid container spacing={3} columns={16} className='flex justify-center p-2.5 service_card' >

    <Grid item xs={6}>
    <Item className='sub_card'>
        <div className='icons flex align-middle mb-5'>
            
                <h3 className='text-xl font-bold'>BULK SMS</h3> 
                <p > 
                Intelligent, fine-tuned SMS gateway routing technology that increases sales and customer satisfaction with smarter SMS campaigns, instant OTPs, notifications, two-way interactions, etc. Type and send SMS in multiple Indian languages with our transliteration tool.
                </p>
            
        </div>
    </Item>
</Grid>

        <Grid item xs={6} >
          <Item className='sub_card'>
            <div className='icons flex align-middle mb-5' >
              
          
                <h3 className='text-xl font-bold'>BULK WHATSAPP MARKETING</h3> 
                <p> 
                The audience can be targeted in different ways in today’s era. Go beyond 160 characters and insert images, videos, PDFs, spreadsheets, and more as short links in your CPaaS campaigns to grab your customers’ attention more effectively.
                </p>
           
              
            </div>
          </Item>
        </Grid>

      </Grid>

      <Grid container spacing={3} columns={16} className='flex justify-center p-2.5' >
        <Grid item xs={6} >
          <Item className='sub_card'>
            <div className='icons flex align-middle mb-5' >
              
            
                <h3 className='text-xl font-bold'>BULK EMAIL</h3> 
                <p> 
                Bulk email marketing is a safe pick and the most impressive way to promote your services. You can now stay engaged with your potential customers via mass email services and, hence, turn your services into a brand name. Bulk email can be useful in sending newsletters, promotional and marketing emails, advertisements, bank statements, etc.
                </p>
            
              
            </div>
          </Item>
        </Grid>

        <Grid item xs={6} >
          <Item className='sub_card'>
            <div className='icons flex align-middle mb-5' >
              
        
                <h3 className='text-xl font-bold'>DIGITAL MARKETING</h3> 
                <p > 
                Digital marketing is a new concept that has changed the entire marketing system. It refers to advertising delivered through digital channels such as search engines, websites, social media, email, and mobile apps. In today’s era, consumers heavily rely on digital means to research products. Hence, Leeway Softech provides you with the best experience in digital marketing. 
                </p>
            </div>
          </Item>
        </Grid>

      </Grid>
    </Box>
   
    
    </>
    );
};

export default Infrastructure;
