import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import SEO from "../components/SEO"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Card = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));



export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Magento Framework | Leeway Softech Pvt Ltd"
             description="Leeway Softech has an extensive experience in providing exceptional Magento solutions, whether it's straightforward custom integration or complicated website development"
            />
            <Grid container spacing={1} className="magento_page main_content px-6 md:px-24">
                <Grid item xs={12} sm={12} lg={12}className='p-[5%]'>
    <div className='text-white text-center'>
    <h1 className='text-3xl font-semibold'>Magento</h1>
                        <p className='text-lg font-normal'>Magento Development Services Improve your e-commerce presence with Magento’s reliable solutions. We have proficiency in different forms of Magento development services that fulfill the unique needs of prospective clients. Experience, knowledge, budget, etc. are the elements that matter the most to choose a quality Magento development company. With the help of our dedicated and skilled Magento development team, we have created different e-commerce websites and efficiently delivered Magento Development Services for our significant customers.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra pr-[5%] pl-[5%] pt-[2%]">
                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>Empower your business through Magento</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Create a website as per the user’s specification</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Scalable platform</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>More than a thousand extensions</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Staging of contents</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>The help of Expert is provided</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Isolated sales</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Customer store credits</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Classy content management system</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>


                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5'>Different Areas we work</div>
                <Grid container spacing={2} columns={16} paddingTop={5}
                   
                   className='felx justify-center align-middle'>
                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Support & Maintenance</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Wide-ranging Magento services</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Magento website development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Custom Magento development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Customized E-commerce solutions</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Magento theme development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Magento module development</p>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Responsive Magento website</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Custom extensions development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Magento store development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Magento shopping-cart development</p>
                            </div>
                        </Card>
                    </Grid>
                   
                </Grid>

                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>Why Choose Us For the Magento Web Development?</div>
                <Grid
                    container
                    spacing={2}
                    paddingTop={5}
                   
                    className='felx justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        {/* Your icons and text here */}
                        <div className='icons flex align-middle mb-5'>
                            <img src={icon} alt='processicon' className='mr-2.5' />
                            <div>
                                <p>Speedy development process</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='panelicon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Enhanced admin panel
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='adminicon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Fast Upgrade
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='help systemicon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Integrated help system
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Mobile friendly' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Mobile-friendly
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt=' Smart search icon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Smart search
                                </p></div>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='fully compatible' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Easily upgradable & fully compatible plug-ins
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Multilingual' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Multilingual
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Improved collaboration icon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Improved collaboration with better user-account & schedule management
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Passionate Community icon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Passionate Community
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Assist tocreate icon' className='mr-2.5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Assist tocreate custom content types, starting from basic to advanced
                                </p></div>
                        </div>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
