import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import bank from "../../icons/icon_new_7.svg.png";
import international from "../../icons/international.png";
import politics from "../../icons/politics (1) 1.png";
import e_commerce from "../../icons/e-commerce.png";
import govet from "../../icons/political-science 1.png";
import edu from "../../icons/education 1.png";
import om from "../../icons/om (1) 1.png";
import corpo from "../../icons/corporation 1.png";
import health from "../../icons/health.png";
import hospital from "../../icons/hospital.png";
import "../../main.css";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  boxShadow: "0px 5px 24px 0px #0E1F3529",
  height: "150px",
  width: "350px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <Box className="w-full pt-[3%] px-6 md:px-24">
      <h1 className="text-[#134CBC] text-2xl font-bold my-4 text-center ">
        Our clients
      </h1>
      <Grid container spacing={4} className="justify-center mb-10 client_main_card">
        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client flex items-center justify-center flex-col client_card">
            <Link
              to={"/bankig-and-financial"}
              className="no-underline text-[#161011]"
            >
              <div>
                <img src={bank} alt="bank" className="mx-auto mb-3" />
                <h3>Banking & Finance</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client2 flex items-center justify-center flex-col client_card">
            <Link to="/international" className="no-underline text-[#161011]">
              <div>
                <img
                  src={international}
                  alt="international"
                  className="mx-auto mb-3"
                ></img>
                <h3>International</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client3 flex items-center justify-center flex-col client_card">
            <Link to="/politics" className="no-underline text-[#161011]">
              <div>
                <img
                  src={politics}
                  alt="politics"
                  className="mx-auto mb-3"
                ></img>
                <h3>Politics</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client4 flex items-center justify-center flex-col client_card">
            <Link
              to="/e-commerce-sector"
              className="no-underline text-[#161011]"
            >
              <div>
                <img
                  src={e_commerce}
                  alt="e_commerce"
                  className="mx-auto mb-3"
                ></img>
                <h3>E-Commerce & Retail</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client5 flex items-center justify-center flex-col client_card">
            <Link to="/government" className="no-underline text-[#161011]">
              <div>
                <img src={govet} alt="govet" className="mx-auto mb-3"></img>
                <h3>Government Sector</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client6 flex items-center justify-center flex-col client_card">
            <Link to="/education" className="no-underline text-[#161011]">
              <div>
                <img src={edu} alt="edu" className="mx-auto mb-3"></img>
                <h3>Education Sector</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client7 flex items-center justify-center flex-col client_card">
            <Link to="/religious" className="no-underline text-[#161011]">
              <div>
                <img src={om} alt="om" className="mx-auto mb-3"></img>
                <h3>Religious</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client8 flex items-center justify-center flex-col client_card">
            <Link to="/corporate" className="no-underline text-[#161011]">
              <div>
                <img src={corpo} alt="corpo" className="mx-auto mb-3"></img>
                <h3>Corporate Sector</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client9 flex items-center justify-center flex-col client_card">
            <Link to="/religious" className="no-underline text-[#161011]">
              <div>
                <img src={health} alt="health" className="mx-auto mb-3"></img>
                <h3>Healthcare</h3>
              </div>
            </Link>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 3 }}>
          <Item className="client10 flex items-center justify-center flex-col client_card">
            <Link to="/corporate" className="no-underline text-[#161011]">
              <div>
                <img
                  src={hospital}
                  alt="gshospitalc"
                  className="mx-auto mb-3"
                ></img>
                <h3>Hospital</h3>
              </div>
            </Link>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
