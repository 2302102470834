import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import SEO from "../components/SEO"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '90px',
    width: '224px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Card = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '85px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
        <SEO title="Wordpress Development | Leeway Softech Pvt Ltd"
             description="Leeway offers e-commerce, educational, and banking services as a part of their web application development portfolio powered by WordPress. They also offer services to revamp your website that speaks your brand."
            />
        <Grid container spacing={1} className="word_page main_content" paddingRight={'5%'} paddingLeft={'5%'}>
        <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                <h1 className='text-3xl font-semibold'>Wordpress Web Services</h1>
                <p className='text-lg font-normal'>Wordpress Web Development Services Experience smartness with our seamlessly optimized WordPress web solutions.  WordPress is the best and most user-friendly open-source innovation for developing a website. It is forced by the PHP and MySQL databases and is well-considered among all web developers. Basically, it is utilized to build blogs as well as business websites. Leeway Softech has a qualified and talented group of WordPress developers who are well familiar with the different features of development.</p>
            </div>
        </Grid>
    </Grid> 

        <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>OUR AREAS TO SERVE WORDPRESS WEB DEVELOPMENT SERVICES</div>
            <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
      <Grid item xs={6} >
        <Item className="card_spe">
         
            <div className='text-center p-[2%]'>
              <p className='text-[#161011] text-sm'>WordPress system setup and formation</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
       
            <div className='text-center p-[2%]'>
           
              <p className='text-[#161011] text-sm'>WordPress website Immigration & Upgrade</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
          
            <div className='text-center p-[2%]'>
              <p className='text-[#161011] text-sm'>JWordPress Backup and Safety Issues</p>
          </div>
        </Item>
      </Grid>
  
            <Grid item xs={6} >
        <Item className="card_spe">
         
            <div className='text-center p-[2%]'>
          
              <p className='text-[#161011] text-sm'>WordPress Bugs and Fault Fixing</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
         
            <div className='text-center p-[2%]'>
            
              <p className='text-[#161011] text-sm'>WordPress Theme Development, Setup, and Configuration</p>
          </div>
        </Item>
      </Grid>

      <Grid item xs={6} >
      <Item className="card_spe">
         
            <div className='text-center p-[2%]'>
            
              <p className='text-[#161011] text-sm'>WordPress Theme Development, Setup, and Configuration</p>
          </div>
        </Item>
      </Grid>

            <Grid item xs={6} >
        <Item className="card_spe">
         
            <div className='text-center p-[2%]'>
          
              <p className='text-[#161011] text-sm'>WordPress Bugs and Fault Fixing</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
         
            <div className='text-center p-[2%]'>
            
              <p className='text-[#161011] text-sm'>WordPress Theme Development, Setup, and Configuration</p>
          </div>
        </Item>
      </Grid>

        </Grid>

        <div className='text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5'>Why WordPress for Web Development?</div>
        <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
      <Grid item xs={6} >
        <Card className="card_spe">
         
            <div className='text-center p-[2%]'>
              <p className='text-[#161011] text-sm'>Choice for self-management</p>
          </div>
        </Card>
      </Grid>
  
      <Grid item xs={6} >
      <Card className="card_spe">
       
            <div className='text-center p-[2%]'>
           
              <p className='text-[#161011] text-sm'>WordPress conservation and maintenance PSD to WordPress Design and Development</p>
          </div>
        </Card>
      </Grid>
  
      <Grid item xs={6} >
      <Card className="card_spe">
          
            <div className='text-center p-[2%]'>
              <p className='text-[#161011] text-sm'>Cost-free support of customer communities</p>
          </div>
        </Card>
      </Grid>

      <Grid item xs={6} >
      <Card className="card_spe">
          
            <div className='text-center p-[2%]'>
              <p className='text-[#161011] text-sm'>The addition of new functionalities becomes very smooth.</p>
          </div>
        </Card>
      </Grid>
  
    </Grid>



    <div className='text-3xl font-semibold color-134CBC text-center mb-5 mt-5'>Why Choose Us For the Wordpress Web Development?</div>
    <Grid 
            container 
            spacing={2}
         
            paddingTop={4}

           className='flex justify-center align-middle'
        >
            <Grid item xs={12} md={6} lg={6} className='component'> 
                {/* Your icons and text here */}
                <div className='icons flex align-middle mb-5'>
                    <img src={icon} alt='filtrationicon' className='mr-2.5 h-5' />
                    <div>
                        <p>Spam filtration and delivery</p>
                    </div>
                </div>
        
                <div className='icons flex align-middle mb-5'>
                <img src={icon} alt='securityicon' className='mr-2.5 h-5' />
               <div>
                <p > {/* Removed top margin */}
                Source code security
                </p></div>
        </div>
        </Grid>  


        <Grid item xs={12} md={6} lg={6} className='component'>
        <div className='icons flex align-middle mb-5'>
            
               <img src={icon} alt='WordPressicon' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                WordPress blog design integration
                </p></div>
        </div>
       
        <div className='icons' style={{ display: 'flex', alignItems: 'center'}}>
            
               <img src={icon} alt='transparencyicon' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                Complete transparency with work
                </p></div>
        </div>
        </Grid>  
      </Grid>
        </Box>
        </>
    );
}
