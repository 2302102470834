// // src/components/CookieComponent.js
// import React, { useEffect, useState ,useRef } from 'react';
// import PopUpFromUi from '../components/CookiePopup';
// import CookieBox from "../components/CookieBox"




// const CookieComponent = () => {
//     const [cookieValue, setCookieValue] = useState('');
//     const [open, setOpen] = useState(true); 

//     const setCookie = (name, value, days) => {
//         let expires = '';
//         if (days) {
//             const date = new Date();
//             date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//             expires = '; expires=' + date.toUTCString();
//         }
//         document.cookie = name + '=' + (value || '') + expires + '; path=/';
//     };

//     const getCookie = (name) => {
//         const value = `; ${document.cookie}`;
//         const parts = value.split(`; ${name}=`);
//         if (parts.length === 2) return parts.pop().split(';').shift();
//     };

//     useEffect(() => {
//         const existingCookie = getCookie('myCookie');
//         if (existingCookie) {
//             setCookieValue(existingCookie);
//         } else {
//             setCookie('myCookie', 'Hello, World!', 7);
//             setCookieValue('Hello, World!');
//         }
//     }, []);

//     const handleClose = () => {
//         setOpen(false); // Close the modal
//     };

//     return (
//         <div className="cookie-box" tabIndex="0" >
//              <PopUpFromUi
//                         open={open}
//                         onClose={handleClose}
//                         setOpen={setOpen}
//                         header={<span className='d-flex justify-content-center'></span>}
//                         body={<CookieBox handleClose={handleClose}/>}
//                     />
//         </div>
//     );
// };

// export default CookieComponent;

// src/components/CookieComponent.js
import React, { useEffect, useState } from 'react';
import PopUpFromUi from '../components/CookiePopup';
import CookieBox from "../components/CookieBox";

const CookieComponent = () => {
    const [open, setOpen] = useState(false); // Start with popup closed by default

    // Use effect to handle cookie consent logic
    useEffect(() => {
        // Check if consent has already been made (either 'accepted' or 'declined')
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'accepted' || consent === 'declined') {
            setOpen(false); // Don't show popup if consent is accepted
        } else {
            setOpen(true); // Show the popup if no consent or declined
        }
    }, []);

    // Handle accepting cookies
    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted'); // Store consent as 'accepted'
        setOpen(false); // Close the popup
    };

    // Handle declining cookies
    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'declined'); // Store consent as 'declined'
        setOpen(false); // Ensure popup shows again
    };

    // Conditional rendering for the PopUp
    return (
        <div className="cookie-box" tabIndex="0">
            {open && (
                <PopUpFromUi
                    open={open}
                    onClose={() => setOpen(false)}
                    setOpen={setOpen}
                    header={<span className='d-flex justify-content-center'></span>}
                    body={<CookieBox handleClose={handleDecline} handleAccept={handleAccept} handleDecline={handleDecline} />}
                />
            )}
        </div>
    );
};

export default CookieComponent;
