import React from "react";
import Grid from "@mui/material/Grid2";
import bank from "../../icons/icon_new_7.svg.png";
import bank_img from "../../icons/bak_img.png";
import international from "../../icons/international.png";
import politics from "../../icons/politics (1) 1.png";
import e_commerce from "../../icons/e-commerce.png";
import govet from "../../icons/political-science 1.png";
import edu from "../../icons/education 1.png";
import om from "../../icons/om (1) 1.png";
import corpo from "../../icons/corporation 1.png";
import health from "../../icons/health.png";
import hospital from "../../icons/hospital.png";
import { Link } from "react-router-dom";

function Clients() {
  return (
    <>
      <div
        style={{
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          marginTop: 13,
          color: "#134CBC",
          fontWeight: 700,
        }}
      >
        {/* <h1 className="text-[#134CBC] text-2xl font-bold my-5 text-center ">
        Our clients
      </h1> */}
        Our clients
      </div>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        className="client_main_cards"
      >
        {/* Client 1 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link
            to={"/bankig-and-financial"}
            className="no-underline text-[#161011]"
          >
            <div className="client-card client">
              <img src={bank_img} alt="Client 1" className="client-image" />
              <div className="client-name">Banking & Finance</div>
            </div>
          </Link>
        </Grid>

        {/* Client 2 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link to={"/international"} className="no-underline text-[#161011]">
            <div className="client-card client2">
              <img
                src={international}
                alt="Client 2"
                className="client-image"
              />
              <div className="client-name">International</div>
            </div>
          </Link>
        </Grid>

        {/* Client 3 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link to={"/politics"} className="no-underline text-[#161011]">
            <div className="client-card client3">
              <img src={politics} alt="Client 3" className="client-image" />
              <div className="client-name">Politics</div>
            </div>
          </Link>
        </Grid>

        {/* Client 4 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link
            to={"/e-commerce-sector"}
            className="no-underline text-[#161011]"
          >
            <div className="client-card client4">
              <img src={e_commerce} alt="Client 4" className="client-image" />
              <div className="client-name">E-Commerce & Retail</div>
            </div>
          </Link>
        </Grid>

        {/* Client 5 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link to={"/government"} className="no-underline text-[#161011]">
            <div className="client-card client5">
              <img src={govet} alt="Client 5" className="client-image" />
              <div className="client-name">Government Sector</div>
            </div>
          </Link>
        </Grid>

        {/* Client 6 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link to={"/education"} className="no-underline text-[#161011]">
            <div className="client-card client6">
              <img src={edu} alt="Client 6" className="client-image" />
              <div className="client-name">Education Sector</div>
            </div>
          </Link>
        </Grid>

        {/* Client 7 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link to={"/religious"} className="no-underline text-[#161011]">
            <div className="client-card client7">
              <img src={om} alt="Client 7" className="client-image" />
              <div className="client-name">Religious</div>
            </div>
          </Link>
        </Grid>

        {/* Client 8 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          <Link to={"/corporate"} className="no-underline text-[#161011]">
            <div className="client-card client8">
              <img src={corpo} alt="Client 8" className="client-image" />
              <div className="client-name">Corporate Sector</div>
            </div>
          </Link>
        </Grid>

        {/* Client 9 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          {/* <Link to={"/religious"} className="no-underline text-[#161011]"> */}
          <div className="client-card client9">
            <img src={health} alt="Client 9" className="client-image" />
            <div className="client-name">Healthcare</div>
          </div>
          {/* </Link> */}
        </Grid>

        {/* Client 10 */}
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }} className="card_grid">
          {/* <Link to={"/corporate"} className="no-underline text-[#161011]"> */}
          <div className="client-card client10">
            <img src={hospital} alt="Client 10" className="client-image" />
            <div className="client-name">Hospitalities</div>
          </div>
          {/* </Link> */}
        </Grid>
      </Grid>
    </>
  );
}

export default Clients;
