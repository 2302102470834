import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import cyber from "../../icons/career/cybersecurity 1.png"
import customer from "../../icons/career/customer-care 1.png"
import group from "../../icons/career/Group (1).png"
import php from "../../icons/career/php (2) 1.png"
import professional from "../../icons/career/professional.png"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    height: '200px',
    width: '350px',
    boxShadow: '0px 5px 24px 0px #0E1F3529',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <Box className="pt-[3%]">
            <h1 className='text-3xl font-semibold color-134CBC text-center mb-5'>Types of Messaging Services</h1>
            <Grid container spacing={2} columns={16} className="add_card p-2.5 flex justify-center">
                <Grid item xs={6}>
                    <Item className="add_sub_card relative p-4" >
                        <div className='line_left absolute left-0 top-[23px] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]'  ></div>

                        <div className='line_left absolute left-0 top-0 translate-y-[-50%] h-[4px] w-[15%] bg-[#134CBC]'></div>

                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>2-WAY Messaging</h4>
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>
                                We understand the significance of every single message you send to your customers. We interact with prospective clients that have responded to our campaigns and engage with leads in real-time conversations. You can track the status of SMS delivery with our real-time delivery and click reports.
                            </p>
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={6} >
                    <Item className="add_sub_card relative p-4" >

                    <div className='line_top absolute right-0 bottom-[-10%] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]' ></div>

<div className=' absolute right-0 bottom-0  h-[5px] w-[15%] bg-[#134CBC]'></div>
                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>Picture Messaging</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>Using our Picture Messaging Service, you can link up a variety of attachments to your SMS, like images, files, videos, pdfs, and much more, with just a button click.</p>
                        </div>
                    </Item>
                </Grid>

            </Grid>


            <Grid container spacing={2} columns={16} className="add_card justify-center p-2.5">
                <Grid item xs={6} >
                    <Item className="add_sub_card relative p-4" >

                    <div className='line_left absolute left-0 top-[23px] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]'  ></div>

<div className='line_left absolute left-0 top-0 translate-y-[-50%] h-[4px] w-[15%] bg-[#134CBC]'></div>
                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>MAIL 2 SMS</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>With the help of our Mail 2 SMS service, we remould your email to SMS, which is the fastest way without altering your current system. Using this service, we directly send your mail to the client without any alteration.</p>
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={6} >
                    <Item className="add_sub_card relative p-4">

                        <div className='line_top absolute right-0 bottom-[-10%] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]' ></div>

                        <div className=' absolute right-0 bottom-0  h-[5px] w-[15%] bg-[#134CBC]'></div>
                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>A2P MESSAGING</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>Our A2P service is mainly transparent to our clients. Our IT services delivers a host of unique features, like enhanced data security and customization, to handle unique business requirements with round-the-clock support.</p>
                        </div>
                    </Item>
                </Grid>



            </Grid>

            <Grid container spacing={2} columns={16} className="add_card justify-center p-2.5">
                <Grid item xs={6} >
                    <Item className="add_sub_card relative p-4">
                    <div className='line_top absolute right-0 bottom-[-10%] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]' ></div>

<div className=' absolute right-0 bottom-0  h-[5px] w-[15%] bg-[#134CBC]'></div>
                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>Push Notification</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>We schedule your SMS for an upcoming time and date using push notifications. You can also use your campaign to send in different lots.</p>
                        </div>
                    </Item>
                </Grid>



            </Grid>
        </Box>

    );
}
