import React, { useEffect } from "react";
import "../App.css";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SEO from "../components/SEO";
import FormButton from "../components/FormButton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Element } from "react-scroll";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  // border: ' 1.5px solid #C4D2EE',
  height: "130px",
  width: "11vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Platform_Page = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash; // Get the current hash
    if (hash) {
      const section = document.getElementById(hash.substring(1)); // Remove the '#' and get the element
      if (section) {
        const offset = 100; // Adjust this value based on your header height
        const elementPosition =
          section.getBoundingClientRect().top + window.scrollY; // Get the position of the section
        const offsetPosition = elementPosition - offset; // Calculate the position with offset

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth", // Smooth scroll
        });

        // After scrolling, remove the hash from the URL without reloading the page
        navigate(location.pathname, { replace: true });
      }
    }
  }, [location, navigate]);

  return (
    <>
      <SEO
        title="CPaaS Services | Leeway Softech Pvt Ltd"
        // description="PBX services are an excellent way to enhance your business's communication and productivity. Discover the various types of PBX services available at Leeway Softech"
      />
      <Grid container spacing={1} className="platform_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div>
            <h1 className="text-white text-center text-[32px] d-flex m-auto">
              CPaaS
            </h1>
            <p
              className="text-white text-center m-auto"
              style={{ width: "70%" }}
            >
              At Leeway Softech Pvt. Ltd., we specialize in bridging the gap
              between technology and government entities, with a proven track
              record of successful collaborations. With our expertise and
              experience, we are committed to delivering top-notch
            </p>
          </div>
        </Grid>
      </Grid>

      <Box className="it_infra w-[100%]">
        {/* Rcs Message */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="tech_main_box p-2.5"
          id="Rcs Message"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="main_tech_content flex flex-col justify-center"
          >
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                RCS Messaging
              </h3>
              <p className="text-left text-sm mb-5">
                RCS Messaging (Rich Communication Services) is an advanced
                messaging protocol designed to enhance traditional SMS. It
                allows businesses to send multimedia-rich, interactive messages
                such as images, videos and buttons, offering a more engaging and
                personalized communication experience. RCS enables real-time
                messaging, seamless customer interactions and higher engagement
                rates, making it an effective tool for marketing, customer
                service and transactional messages. With secure, branded
                communication and seamless integration, RCS provides businesses
                with a powerful platform to connect with customers in a more
                dynamic and efficient way.
              </p>

              <div>
                <FormButton className="technology_btn" label="Hire Resources">
                  <ArrowForwardIcon className="arrow_icons" />
                </FormButton>
              </div>
            </div>

            {/* <img src={require("../../images/Mask group.png")} style={{ height: 'auto', width: '100%', maxHeight: '400px', }} alt="Phone" /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <img
              src={require("../icons/technology/platform1.png")}
              alt="tech react"
              className="inline"
            />
          </Grid>
        </Grid>

        {/* IPBX Service */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="technology_main_card_2 tech_main_box p-2.5"
          id="IPBX Service"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <img
              style={{ width: "60%" }}
              src={require("../icons/technology/ipbx_service.png")}
              alt="tech react"
              className="inline"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="tech_main_box flex flex-col"
            onClick={() => navigate("/pbx-service")}
            style={{ cursor: "pointer" }}
          >
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                IPBX Service
              </h3>
              <p className="text-left text-sm mb-5">
                IPBX Service (Internet Protocol Private Branch Exchange) is a
                modern phone system that uses the internet to manage internal
                and external communications within a business. Unlike
                traditional PBX systems, IPBX operates over VoIP technology,
                offering cost-effective, scalable and flexible communication
                solutions. It provides features like call routing, voicemail,
                conferencing and remote access, making it ideal for businesses
                of all sizes. With better audio quality and integration with
                other business tools, IPBX enhances communication efficiency and
                helps businesses stay connected with clients and teams globally.
              </p>
            </div>

            <div>
              <FormButton className="technology_btn" label="Hire Resources">
                <ArrowForwardIcon className="arrow_icons" />
              </FormButton>
            </div>
          </Grid>
        </Grid>

        {/* Whatsapp Business Chatbot */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="tech_main_box p-2.5"
          id="Whatsapp Business Chatbot"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="main_tech_content flex flex-col justify-center"
            onClick={() => navigate("/whatsapp")}
            style={{ cursor: "pointer" }}
          >
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                Whatsapp Business Chatbot
              </h3>
              <p className="text-left text-sm mb-5">
                The WhatsApp Business API enables medium to large businesses to
                connect with customers at scale through WhatsApp. It allows for
                automated messaging, real-time communication, and seamless
                integration with CRM systems. Businesses can send updates,
                notifications and support messages while offering rich media
                like images and videos. The API supports secure, two-way
                communication, is scalable for high message volumes and ensures
                better customer engagement with interactive features. It’s an
                efficient, cost-effective tool for improving customer service
                and marketing efforts.
              </p>

              <div>
                <FormButton className="technology_btn" label="Hire Resources">
                  <ArrowForwardIcon className="arrow_icons" />
                </FormButton>
              </div>
            </div>

            {/* <img src={require("../../images/Mask group.png")} style={{ height: 'auto', width: '100%', maxHeight: '400px', }} alt="Phone" /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60%" }}
                src={require("../icons/technology/platform2.png")}
                alt="tech react"
                className="inline"
              />
            </div>
          </Grid>
        </Grid>

        {/* AI Chatboat */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="technology_main_card_2 tech_main_box p-2.5"
          id="AI Chatboat"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <img
              style={{ width: "60%" }}
              src={require("../icons/technology/al_chatboat.png")}
              alt="tech react"
              className="inline"
            />
          </Grid>
          <Grid item xs={12} md={6} className="tech_main_box flex flex-col">
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                AI Chatboat
              </h3>
              <p className="text-left text-sm mb-5">
                An AI Chatbot is a smart, automated tool that uses artificial
                intelligence to engage in conversations with users. Through
                natural language processing (NLP), it understands and responds
                to queries, offering personalized interactions. AI chatbots work
                24/7, handling tasks like customer support, FAQs and even sales,
                which helps businesses provide faster, more efficient services
                while reducing operational costs.
              </p>
            </div>

            <div>
              <FormButton className="technology_btn" label="Hire Resources">
                <ArrowForwardIcon className="arrow_icons" />
              </FormButton>
            </div>
          </Grid>
        </Grid>

        {/* Bulk SMS */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="tech_main_box p-2.5"
          id="Bulk SMS"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="main_tech_content flex flex-col justify-center"
          >
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                Bulk SMS
              </h3>
              <p className="text-left text-sm mb-5">
                Bulk SMS is a service that allows businesses to send a large
                number of text messages to a wide audience quickly and
                cost-effectively. It is ideal for marketing campaigns,
                promotions, customer notifications and reminders. With high
                delivery rates and almost instant message delivery, Bulk SMS
                ensures that important information reaches customers in
                real-time. It offers features like audience segmentation,
                personalization and detailed analytics, making it a powerful
                tool for improving customer engagement and communication
                efficiency.
              </p>

              <div>
                <FormButton className="technology_btn" label="Hire Resources">
                  <ArrowForwardIcon className="arrow_icons" />
                </FormButton>
              </div>
            </div>

            {/* <img src={require("../../images/Mask group.png")} style={{ height: 'auto', width: '100%', maxHeight: '400px', }} alt="Phone" /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60%" }}
                src={require("../icons/technology/bulk_sms.png")}
                alt="tech react"
                className="inline"
              />
            </div>
          </Grid>
        </Grid>

        {/* Push Notification */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="technology_main_card_2 tech_main_box p-2.5"
          id="Push Notification"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <img
              style={{ width: "60%" }}
              src={require("../icons/technology/push_notification.png")}
              alt="tech react"
              className="inline"
            />
          </Grid>
          <Grid item xs={12} md={6} className="tech_main_box flex flex-col">
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                Push Notification
              </h3>
              <p className="text-left text-sm mb-5">
                Push notifications are real-time alerts sent directly to users’
                devices, even when they are not actively using an app or
                website. These messages can include text, images, or links and
                are designed to capture attention and encourage user engagement.
                Push notifications are highly effective for sending timely
                updates, reminders, promotions or new content, helping
                businesses increase customer interaction, retention and
                conversions. With the ability to target specific users based on
                behavior or preferences, they offer a personalized and
                cost-effective communication tool for businesses.
              </p>
            </div>

            <div>
              <FormButton className="technology_btn" label="Hire Resources">
                <ArrowForwardIcon className="arrow_icons" />
              </FormButton>
            </div>
          </Grid>
        </Grid>

        {/* VoIp */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 6, md: 6 }}
          className="tech_main_box p-2.5"
          id="VoIp"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="main_tech_content flex flex-col justify-center"
          >
            <div className="tech-content">
              <h3 className="color-134CBC text-left text-3xl mb-5 font-[500]">
                VoIp
              </h3>
              <p className="text-left text-sm mb-5">
                VoIP (Voice over Internet Protocol) is a technology that enables
                voice and multimedia communication over the internet instead of
                traditional phone lines. It converts voice into data packets and
                transmits them through the internet, allowing users to make
                calls, video chats and send messages with reduced costs. VoIP
                offers high-quality audio, flexibility and scalability, making
                it ideal for businesses and remote teams. It integrates various
                communication tools like voice calls, video conferencing and
                messaging into one platform, helping streamline communication
                and improve collaboration.
              </p>

              <div>
                <FormButton className="technology_btn" label="Hire Resources">
                  <ArrowForwardIcon className="arrow_icons" />
                </FormButton>
              </div>
            </div>

            {/* <img src={require("../../images/Mask group.png")} style={{ height: 'auto', width: '100%', maxHeight: '400px', }} alt="Phone" /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="technology-main-card-component"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "55%" }}
                src={require("../icons/technology/voip.png")}
                alt="tech react"
                className="inline"
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Platform_Page;
