import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import struts from "../../icons/mob-app/struts.png"
import spring from "../../icons/mob-app/spring.png"
import icon from "../../icons/mob-app/Vector (2).png"
import SEO from '../../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    // height: '350px',
    width: '400px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
        <SEO title="Java Development & Solutions Provider Company | Leeway Softech Pvt. Ltd."
             description="Leeway Softech offers reliable and cost-effective Java Services designed to help your business succeed. Their experienced team delivers top-notch solutions tailored to your specific requirements."
            />
        <Grid container spacing={1} className="java_page main_content">
        <Grid item xs={12} sm={12} lg={12}  className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Java Development</h1>
                <p className='text-lg font-normal'>Most Systematized Framework for synergistic Interface of Multi Tier Applications with Java Enterprise Solutions and widely used for IoT, Smart Apps and edge device development. Java is one of the most popular frameworks and the most reliable one as well. It has various frameworks that make web applications more powerful and scalable. We follow digital trends with advanced features coded with perfection that simplify the framework and develop dynamic web pages that cover the entire functionality of your website, leading to a secure, user-friendly experience.</p>
            </div>
        </Grid>
    </Grid> 

    <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
    <div className='text-3xl font-semibold color-134CBC text-center mb-5'>ENRICHING AND ENHANCING APPLICATION WITH JAVA FRAMEWORKS</div>
            <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
      <Grid item xs={6} >
        <Item className="big_card">
         
            <div className='text-center'>
                <img src={require("../../icons/mob-app/spring.png")} alt='spring img' className='inline mb-5'></img>
                <h3 className='mb-2  color-134CBC text-xl font-medium'>Spring</h3>
          
              <p  className='text-[#161011] text-sm'>Spring MVC assists you with implementation, configuration, and development of any kind. It is more stable, predictable, and enables cross-platform development that transforms your web applications into a complete solution. This framework outperforms and augments an elegant solution for using your MVC.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="big_card">
       
            <div style={{ textAlign:'center' }}>
                <img src={struts} alt='struts' className='inline mb-5'></img>
                <h3 className='mb-2  color-134CBC text-xl font-medium'>Struts</h3>
           
              <p className='text-[#161011] text-sm'>Apache Struts is the most advanced open-source MVC framework, making web application creation simple, unique, and seamless. Its dynamic framework allows developers to incarnate all your enterprise’s needs in an extensible architecture for plugins that supports scripts from all databases. The Java Web Application Framework allows developers to create easy-to-maintain enterprise-level Java applications.</p>
          </div>
        </Item>
      </Grid>
  </Grid>

  <div className='text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5'>Why Choose Java Framework?</div>
  <Grid 
            container 
            spacing={1}
            paddingTop={4}

           className='flex justify-center align-middle'
        >
            <Grid item xs={12} md={6} lg={6} className='component'>
                {/* Your icons and text here */}
                <div className='icons flex align-middle mb-5'>
                    <img src={icon} alt='java icon' className='mr-2.5 h-5' />
                    <div>
                        <p>Java is free, object-oriented</p>
                    </div>
                </div>
        
        <div className='icons flex align-middle mb-5'>
            
               <img src={icon} alt='multimedia icon' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                Supports multithreading, multimedia, and network support
                </p></div>
        </div>
        
        <div className='icons flex align-middle mb-5'>
            
            <img src={icon} alt='NetBeans icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Eclipse and NetBeans have debugging capabilities.
             </p></div>
     </div>
       
        
        </Grid>  


        <Grid item xs={12}  md={6} lg={6}  className='component'>
        <div className='icons flex align-middle mb-5'>
            
            <img src={icon} alt='stable icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             It is more stable and predictable
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5'>
            
            <img src={icon} alt='Library icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             The Java Class Library enables cross-platform development
             </p></div>
     </div>
       
   
     
        </Grid>  
      </Grid>
        </Box>

   
        </>
    );
}
