import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import { Link } from 'react-router-dom';

import blog1 from "../event/blog/emailmain.jpg"
import mail from "../event/blog/marketing/email.jpg"
import props from "../event/blog/marketing/props.jpg"
import graph from "../event/blog/marketing/graph.png"
import time from "../event/blog/marketing/timesaving.jpg"
import sales from "../event/blog/marketing/sales.jpg"
import data from "../event/blog/marketing/data.jpg"
import branding from "../event/blog/marketing/branding.jpg"
import customer from "../event/blog/marketing/customer.jpg"
import marke from "../event/blog/marketing/marke.jpg"
import spam from "../event/blog/marketing/spam.jpg"
import mail_list from "../event/blog/marketing/mail_list.jpg"
import unsubscribe from "../event/blog/marketing/unsubscribe.jpg"
import email_listing from "../event/blog/marketing/email_listing.jpg"

import BlogForm from "../pages/BlogForm"
import SEO from '../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '430px',
    width: '270px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Terms and Conditions | Leeway Softech Pvt Ltd"
             description="Click here to read our terms and conditions for important details about our IT services and privacy policy."
            />
            <Grid container spacing={1} className="terms_conditions main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
        <div className='text-white text-center'>
        <h1 className='text-3xl font-semibold'>Terms and Conditions</h1>
                        {/* <p style={{ color: 'white', textAlign: 'center' }}>Online digital marketing is the foundation of a successful online presence and can be used to drive customer participation, increase brand value, and build trust. It is important to create content that is relevant, engaging, and consistent. With a motive to provide the simplest solution for complex problems, Leeway Softech started way back with a mission to innovate, facilitate, and automate.</p> */}
                    </div>
                </Grid>
            </Grid>

            <Box className='w-[100%]'>
                <Grid container spacing={2} columns={16} className='blog_card flex justify-center p-2.5'>
                    <Grid item xs={6} className='flex'>
                        <div  className='text-center mr-[15%] ml-[15%]'> {/* Increased padding and added margin */}
                           
                            <p className='text-[#161011] text-[15px]  text-left align-left'>
                            Please read our Terms and Conditions carefully before using our website.
                            </p>

                            <p className='text-[#161011] text-[15px] align-left text-left opacity-[85%] mt-5'>The user must agree and accept all the Terms and Conditions before using our services and products.</p>

                            <p className='text-[#161011] text-[15px] align-left text-left opacity-[85%] mt-5'> Leeway Softech – An Information Technology company, reserves the right to modify the Terms and Conditions at any time without informing the user.</p>

                            <p className='text-[#161011] text-[15px] align-left  text-left opacity-[85%] mt-5'> This web site https://leewaysoftech.com/ is owned and operated by Leeway Softech Pvt Ltd. All content present on this site is the our property including all the graphics, images, logos, video, text, audio and animation used on this website.</p>



                            <p className='text-[#161011] text-[15px] align-left text-left opacity-[85%] mt-5'>You must not use any contact information and e-mail addresses which may be found on this web site to send any unsolicited commercial information.</p>

                            <p className='text-[#161011] text-[15px] align-left text-left opacity-[85%] mt-5'>The link of the third party web site address may found on this website. We are not responsible for the contents of such sites or whole web site (third party) at all. The third party web site is not under our control at all. You at your own risk may access to such other sites via the links contained on this web site.</p>
                            
                            <h2 className='text-[#161011]  align-left text-2xl mt-5 text-left font-semibold'>Contact Information</h2>
                            <p className='text-[#161011] text-[15px] align-left text-left opacity-[85%] mt-5'>For any queries about the terms and conditions or legal guidelines please Call us at 8000305305 or contact via customercare[at]leewaysoftech.com</p>
                            
                        </div>
                        <div>

                        </div>

                    </Grid>
                </Grid>
            </Box>

        </>
    );


}


