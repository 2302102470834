import React from "react";
import "../../main.css";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SEO from "../../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "150px",
  width: "400px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Infrastructure = () => {
  return (
    <>
      <SEO
        title="Our Infrastructure | Leeway Softech Pvt Ltd"
        description="We provide a dedicated Customer Service Desk to address customer queries and efficiently resolve any issues they encounter."
      />
      <Grid container spacing={1} className="infra_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Infrastructure</h1>
            <p className="text-lg font-normal">
              Experience the world's best class with full world class facilities
            </p>
          </div>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%" }} className="it_infra">
        <div className="text-[#134CBC] text-2xl font-bold my-4 text-center">
          Our IT infrastructure
        </div>
        <Grid
          container
          spacing={2}
          columns={16}
          className="justify-center p-[10px]"
        >
          <Grid item xs={6}>
            <Item className="infra_sub_cards">
              <div className="icons text-center p-[5%]">
                <p>
                  24/7 Customer Service Desk to address customer queries and
                  promptly resolve any issues they encounter.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item className="infra_sub_cards">
              <div className="icons text-center p-[5%]">
                {/* <h3>Software Development</h3> */}
                <p>
                  Leeway Softech clients eliminate the need to set up their own
                  data centers, significantly reducing start-up costs. Our
                  state-of-the-art data center offers all the functionalities
                  required to meet customer needs efficiently.
                </p>
              </div>
            </Item>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={16}
          className="justify-center p-[10px]"
        >
          <Grid item xs={6}>
            <Item className="infra_sub_cards">
              <div className="icons text-center p-[5%]">
                {/* <h3>Software Development</h3> */}
                <p>
                  All our products are rigorously tested and certified to meet
                  the highest security standards.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item className="infra_sub_cards">
              <div className="icons text-center p-[5%]">
                {/* <h3>Software Development</h3> */}
                <p>
                  Our expertise enables us to quickly resolve issues and help
                  businesses improve performance, cut costs, and enhance
                  security.
                </p>
              </div>
            </Item>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={16}
          className="justify-center p-[10px]"
        >
          <Grid item xs={6}>
            <Item className="infra_sub_cards">
              <div className="text-center p-[5%] icons">
                {/* <h3>Software Development</h3> */}
                <p>
                  We also help businesses craft and implement strategies to
                  enhance performance, reduce costs, and boost security.
                </p>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Infrastructure;
