import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import vaishnodevi from "../../icons/mob-app/bnsb_logo 1.png";
import baps from "../../icons/mob-app/baps 1.png";
import baps1 from "../../icons/mob-app/baps1.png";
import SEO from "../../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "126px",
  width: "188px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Our Clients in Religious Organization | Leeway Softech Pvt Ltd"
        description="SMS, CPaas, Bulk Email, WhatsApp Chatbot services allow religious organizations to communicate quickly and easily with their members."
      />
      <Grid container spacing={1} className="religious_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Religious</h1>
            <p className="text-lg font-normal">
              SMS, CPaas, Bulk Email, WhatsApp Chatbot services allow religious
              organizations to communicate quickly and easily with their
              members/devotees.
            </p>
          </div>
        </Grid>
      </Grid>

      <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={6}>
            <Item className="card_spe">
              <div className="flex justify-center align-middle">
                <img src={vaishnodevi} alt="vaishnodevi"></img>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item className="card_spe">
              <div className="flex justify-center align-middle">
                <img src={baps} alt="baps"></img>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item className="card_spe">
              <div className="flex justify-center align-middle">
                <img src={baps1} alt="baps"></img>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={3}
        className="flex justify-center p-2.5 mb-5 mt-5"
      >
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h5 className="mb-2 color-134CBC  text-xl font-medium">
                Embracing Technology in Religious Outreach
              </h5>

              <p>
                In today’s digital age, technology has transformed the way
                religious organizations connect with their communities. By
                leveraging modern communication tools, faith-based groups can
                enhance their outreach efforts, making it easier to engage with
                existing members and attract new ones. Among the most effective
                technological solutions are SMS, CPaaS, Bulk Email and WhatsApp
                Chatbot Services. These platforms provide religious
                organizations with efficient and meaningful ways to foster
                connection, disseminate information and nurture spiritual
                growth.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h5 className="mb-2 color-134CBC  text-xl font-medium">
                SMS: Instant Connection
              </h5>
              <p>
                SMS, commonly known as text messaging, offers a powerful means
                of communicating with members. Its simplicity, wide
                accessibility and immediate delivery make SMS a vital tool for
                religious organizations. Whether it's sending reminders about
                upcoming services, sharing inspirational messages or announcing
                special events, SMS ensures that your congregation stays
                informed in real-time.
              </p>
              <p>
                This service is especially valuable for members who may be
                unable to attend in person due to geographical distance or other
                barriers. SMS breaks down these challenges, ensuring that no one
                is left out of important communications.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center" padding={"10px"}>
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h5 className="mb-2 color-134CBC  text-xl font-medium">
                CPaaS & Bulk Email: Reaching Larger Audiences{" "}
              </h5>
              <p>
                Cloud Communication Platforms as a Service (CPaaS) and Bulk
                Email services provide religious organizations with the
                capability to send mass communications quickly and efficiently.
                Whether you need to share news about a special event, provide
                updates on community activities, or promote an outreach program,
                these tools allow you to reach a broad audience at once. CPaaS
                allows for both SMS and voice-based communications at scale,
                while Bulk Email makes it easy to send personalized or general
                newsletters, announcements and more. These services enhance your
                ability to communicate with a large group, ensuring that vital
                information reaches your entire congregation in a timely manner.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h5 className="mb-2 color-134CBC  text-xl font-medium">
                WhatsApp Chatbot Service: Engaging and Interactive Support{" "}
              </h5>
              <p>
                WhatsApp Chatbot Services offer an innovative way for religious
                organizations to engage with their community. Chatbots are
                artificial intelligence-driven tools that interact with users in
                a conversational manner. By implementing a WhatsApp Chatbot,
                religious groups can provide instant, round-the-clock support to
                members, answering frequently asked questions, offering event
                information and guiding individuals to relevant resources.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h5 className="mb-2 color-134CBC  text-xl font-medium">
                The Power of Technology in Faith-Based Communities{" "}
              </h5>
              <p>
                By utilizing SMS, CPaaS, Bulk Email and WhatsApp Chatbot
                Service, religious organizations can quickly and easily reach
                out to their members. Through these services, they can provide
                their members with timely and relevant information, as well as
                create a more interactive environment of understanding and
                engagement.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
    </>
  );
}
