import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import cyber from "../../icons/career/cybersecurity 1.png"
import customer from "../../icons/career/customer-care 1.png"
import group from "../../icons/career/Group (1).png"
import php from "../../icons/career/php (2) 1.png"
import professional from "../../icons/career/professional.png"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    height: '200px',
    width: '350px',
    boxShadow: '0px 5px 24px 0px #0E1F3529',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <Box >
            <h1 className='text-3xl font-semibold color-134CBC text-center mt-5 mb-5 '>Types of CMS Services</h1>
            <Grid container spacing={2} columns={16} className="add_card justify-center p-2.5 ">
                <Grid item xs={6}>
                    <Item className="add_sub_card relative p-4 text-center">
                    <div className='line_left absolute left-0 top-[23px] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]'  ></div>

<div className='line_left absolute left-0 top-0 translate-y-[-50%] h-[4px] w-[15%] bg-[#134CBC]'></div>

                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>Open source</h4>
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>
                            Open source CMSs are free to use and modify. They are often the most popular choice for businesses and organizations because they are highly customizable and can be adapted to meet specific needs.
                            </p>
                        </div>
                    </Item> 
                </Grid>

                <Grid item xs={6} >
                    <Item className="add_sub_card relative p-4 text-center">

                    <div className='line_top absolute right-0 bottom-[-10%] translate-y-[-50%] h-[50px] w-1 bg-[#134CBC]' ></div>

<div className=' absolute right-0 bottom-0  h-[5px] w-[15%] bg-[#134CBC]'></div>
                        <div>
                            <h4 className='mb-2 text-center text-xl font-medium'>Commercial</h4> {/* Add margin to the paragraph */}
                        </div>
                        <div>
                            <p className='text-[#161011] text-[13px] text-center'>Commercial CMSs are paid for licenses. They often offer more features and support than open-source CMSs.</p>
                        </div>
                    </Item>
                </Grid>

            </Grid>

        </Box>

    );
}
