import React from "react";
import "../main.css";
import { Grid2 } from "@mui/material";
import FormButton from "../components/FormButton";
import img from "../images/Layer_1.png";
import phone from "../icons/Group 35637.png";
import mail from "../icons/Group.png";
import location from "../icons/location (1) 1.png";
import Instagram from "../media/socialmedia/instagram_icon.png";
import facebook from "../media/socialmedia/facebook_icon.png";
import twitter from "../media/socialmedia/twitter_icon.png";
import linkedin from "../media/socialmedia/linkedin_icon.png";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "81px",
  width: "81px",
  backgroundColor: "#fff",
}));

const WhyChooseUs = () => {
  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 className="bg-[#0C0C0C] px-6 md:px-24 py-7">
          <Grid2
            items
            size={{ xs: 12, sm: 12, lg: 12 }}
            className="footer_container flex"
          >
            <Grid2 items size={{ xs: 12, sm: 6, lg: 6 }}>
              <div className="footer_card">
                <div>
                  <img
                    src={img}
                    alt="footer logo"
                    className="footer_logo mb-[5%] p-2.5"
                  ></img>
                  <p className="text-white opacity-[0.9]">
                    Leeway Soft-Tech Private Limited was founded in 2010. And
                    has since grown to become a leading IT services provider in
                    India. They have been ISO 9001:2015, ISO-IEC 27001: 2013,
                    ISO-IEC-IEEE 23026:2015, ISO-IEC 27017:2015, and ISO-IEC
                    27018:2019 certified, and have a CMMI-Maturity Level 3
                    rating.
                  </p>
                </div>
              </div>
            </Grid2>

            <Grid2 items size={{ xs: 12, sm: 6, lg: 6 }} style={{marginTop: "25px"}}>
              <div className="footer_links ml-[30%]">
                <h3 className="text-white text-lg mb-3 font-semibold">
                  Company
                </h3>
                <a href="/about" className="text-base font-light">
                  About US
                </a>
                <a className="text-base font-light" href="/contact-us">
                  Contact US
                </a>
                <a className="text-base font-light" href="/achievements">
                  Achievements
                </a>
                <a className="text-base font-light" href="/join_us">
                  Careers
                </a>
                <a className="text-base font-light" href="/our-client">
                  Clients
                </a>
                {/* <a className="text-base font-light" href="/policy">
                  Privacy Policy
                </a>
                <a className="text-base font-light" href="/terms-conditions">
                  Terms & Conditions
                </a> */}
              </div>
            </Grid2>

            <Grid2 items size={{ xs: 12, sm: 6, lg: 6 }} style={{marginTop: "25px"}}>
              <div className="footer_links ml-[10%] mb-5">
                <h3 className="text-white text-lg mb-3 font-semibold">Important Links</h3>
                <a className="text-base font-light">Company</a>
                <a className="text-base font-light">Platforms</a>
                <a className="text-base font-light" href="/technology_page">
                  Technology
                </a>
                <a className="text-base font-light">Chatbot</a>
                <a className="text-base font-light">Solutions</a>
              </div>
            </Grid2>

            <Grid2 items size={{ xs: 12, sm: 6, lg: 6 }} style={{marginTop: "25px"}}>
              <div className="footer_address">
                <h3 className="text-white text-left text-lg mb-5 font-semibold">
                  Reach Us
                </h3>
                <div className="contact-item">
                  <Link
                    to="tel:8000305305"
                    target="blank"
                    className="decoration-none flex"
                  >
                    <img
                      src={phone}
                      alt="footer Phone"
                      className="phone-icon"
                    />
                    <p className="text-white opacity-[0.9]">8000305305</p>{" "}
                  </Link>
                </div>
                <div className="contact-item">
                  <Link
                    to="mailto:sales@leewaysoftech.com"
                    target="blank"
                    className="decoration-none flex"
                  >
                    <img src={mail} alt="mail Phone" className="phone-icon" />
                    <p className="text-white opacity-[0.9]">
                      sales@leewaysoftech.com
                    </p>{" "}
                  </Link>
                </div>
                {/* <div className='contact-item'>
                                    <img src={location} alt="Phone" className='phone-icon' />
                                    <p style={{ color: 'white',opacity:'0.9' }}>A 502, Rustomjee Central Park, Opp.Kanakia Wall Street, Andheri, Kurla Road, Andheri (E), Mumbai, - 400059.</p>
                                </div> */}
                {/* <Grid2 container spacing={2} className='flex justify-start relative left[-10px]'>
                                    <Grid2 item xs={6} >
                                        <div className='h-[55px] w-[51px] bg-white place-content-center rounded-md'>
                                            <div className='icons text-center'>
                                                <img src={require("../images/payment/Group.png")} className='block mx-auto' alt='payment footer'></img>
                                                <p className='text-[9px] color-134CBC font-semibold'>Google partner</p>
                                            </div>
                                        </div>
                                    </Grid2>

                                    <Grid2 item xs={6} >
                                        <div className='h-[55px] w-[51px] bg-white place-content-center rounded-md'>
                                            <Link to='https://www.facebook.com/business/partner-directory/search?solution_type=messaging&id=25054628074122494&section=overview' style={{textDecoration:'none'}}>
                                            <div className='icons text-center p-[5%]' >
                                                <img src={require("../images/payment/meta 1.png")} className='block mx-auto' alt='meta payment'></img>
                                                <p className='text-[9px]  color-134CBC font-semibold'>Bussiness partner</p>
                                            </div>
                                            </Link>
                                           
                                        </div>
                                    </Grid2>
                                    <Grid2 item xs={6} >
                                        <div className='h-[55px] w-[51px] bg-white place-content-center rounded-md'>
                                            
                                            <div className='icons text-center p-[5%]' >
                                                <img src={require("../images/payment/image 64.png")} className='block mx-auto' alt='microsoft'></img>
                                                <p className='text-[9px]  color-134CBC font-semibold'>Microsoft</p>
                                            </div>
                                           
                                        </div>
                                    </Grid2>
                                </Grid2> */}
              </div>
            </Grid2>
          </Grid2>
          <div className="border_footer"></div>

          <Grid2 container spacing={2} className="pr-[5%] pl-[1%]">
            <Grid2 item size={{ xs: 12, sm: 12, lg: 12 }}>
              <div className="flex flex-col align-middle text-center">
                <div className="flex flex-row align-middle justify-center w-[100%] flex-wrap ml-auto pt-8">
                  <p className="text-white text-sm m-1.5">
                    2024@ Leeway Softech
                  </p>
                  <div className="footer_line"></div>
                  {/* <p className='text-white text-sm m-1.5'>All rights reserved</p> */}
                  <a href="/policy" className="no-underline">
                    <p className="text-white text-sm m-1.5 cursor-pointer">
                      Privacy Policy
                    </p>
                  </a>
                  <div className="footer_line"></div>
                  <a href="/policy" className="no-underline">
                    <p className="text-white text-sm m-1.5 cursor-pointer">
                      Refund & Cancellation Policy
                    </p>
                  </a>
                  <div className="footer_line"></div>
                  <a href="/terms-conditions" className="no-underline">
                    <p className="text-white text-sm m-1.5 cursor-pointer">
                      Terms & Conditions
                    </p>
                  </a>

                  <div className="social_links flex align-middle ml-auto mt-1.5">
                    <Link
                      to="https://www.instagram.com/leewaysoftech_pvt_ltd/"
                      target="blank"
                      className="social_media"
                    >
                      <img
                        src={Instagram}
                        alt="Instagram"
                        className="ml-2.5 mr-2.5 w-10 h-10"
                      />
                    </Link>
                    <Link
                      to="https://www.facebook.com/LEEWAY2372012/"
                      target="blank"
                    >
                      <img
                        src={facebook}
                        alt="Facebook"
                        className="ml-2.5 mr-2.5 w-10 h-10"
                      />
                    </Link>
                    <Link to="https://twitter.com/leewaypvt" target="blank">
                      <img
                        src={twitter}
                        alt="Twitter"
                        className="ml-2.5 mr-2.5 w-10 h-10"
                      />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/leeway-softech-pvt-ltd"
                      target="blank"
                    >
                      <img
                        src={linkedin}
                        alt="LinkedIn"
                        className="ml-2.5 mr-2.5 w-10 h-10"
                      />
                    </Link>
                  </div>

                  <head>
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:url"
                      content="https://www.yoursite.com"
                    />
                    <meta
                      property="og:title"
                      content="Leeway Softech Pvt Ltd | IT Services & Solutions"
                    />
                    <meta
                      property="og:description"
                      content="Leeway Softech offers comprehensive digital solutions including Bulk Email, SMS Service, Web Development, Mobile App Development, WhatsApp Integration, and more."
                    />
                    <meta
                      property="og:image"
                      content="https://www.yoursite.com/path-to-your-image.jpg"
                    />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@leewaysoftech" />
                    <meta
                      name="twitter:title"
                      content="Leeway Softech Pvt Ltd | IT Services & Solutions"
                    />
                    <meta
                      name="twitter:description"
                      content="Leeway Softech offers comprehensive digital solutions including Bulk Email, SMS Service, Web Development, Mobile App Development, WhatsApp Integration, and more."
                    />
                    <meta
                      name="twitter:image"
                      content="https://www.yoursite.com/path-to-your-image.jpg"
                    />
                    <meta
                      name="twitter:url"
                      content="https://www.yoursite.com"
                    />

                    <title>Leeway Softech Pvt Ltd</title>
                  </head>
                </div>
              </div>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default WhyChooseUs;
