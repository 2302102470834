import React, { useState, useRef } from 'react'
import "../component.css"
import { Button } from '@mui/material';

function FormTextArea({  ...rest }) {
    const textAreaRef = useRef(null)

    return (
        <>
            <div className='flex relative'>
                <textarea type="text" ref={textAreaRef} className="input-chat pt-[10px] mxa-h-[85px]" id="input-placeholder" {...rest} />
                <div  className='absolute bottom-[1px] right-[6px] flex flex-col items-end'></div>
                </div>

</>
    )
}

export default FormTextArea