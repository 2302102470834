import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useState } from "react";
import eve1 from "../event/gitex.png";
import Event_year from "../components/Event_year";

import SEO from "../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "300px",
  width: "400px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "12px",
  boxShadow: "0px 6.3px 30px 0px #3D3D3D0D",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // height:'73px',
  // width:'500px'
}));
export default function RowAndColumnSpacing() {
  const [active, setActive] = useState("2023");

  const onClickActive = (btnName) => {
    setActive(btnName);
  };
  return (
    <>
      <SEO
        title="IMC Event | Leeway Softech Pvt Ltd"
        description="Leeway Softech is participated in India Mobile Congress, the premier event showcasing the future of technology. At the 'India mobile congress' exhibition, they showcased their recent innovations in their domain."
      />
      <Box
        sx={{ width: "100%" }}
        className="it_infra main_content pt-[3%] pb-[3%]"
      >
        <h1
          style={{ color: "#161011", textAlign: "center" }}
          className="text-[#161011] text-center text-[32px] font-bold"
        >
          Gitex Event
        </h1>
        {/* <Event_year/> */}
        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve1}
                alt="gitex event"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <div className="text-center">
              <img
                src={eve1}
                alt="image of gitex"
                className="event_img h-[282px]"
              ></img>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
