import React from "react";
import "../../main.css";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Client from "../Company/Client";
import Clients from "../Company/Clients";
import SEO from "../../components/SEO";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "12px",
  boxShadow: "0px 6.3px 30px 0px #3D3D3D0D",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  height: "73px",
  // width:'500px'
}));

const Infrastructure = () => {
  return (
    <>
      <SEO
        title="Our Clients Across the Various Sectors | Leeway Softech"
        description="Leeway Softech serves various market sectors, including clients from the banking and finance sector, government sector, corporate sector, education sector, and more"
      />
      <Grid container spacing={1} className="client_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Our Clients</h1>
            <p className="text-lg font-normal">
              Leeway Softech Serves Various Sectors in Market, Our Clients like,
              Banking and Finance Sector, Government Sector, Corporate Sector,
              Education Sector, etc.
            </p>
          </div>
        </Grid>
      </Grid>

      <Clients />
      {/* <Client /> */}
    </>
  );
};

export default Infrastructure;
