import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import mail from "../../images/bulk_mail.png"
import icon from "../../icons/mob-app/Vector (2).png"
import SEO from '../../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="Bulk Email Service Provider | Leeway Softech Pvt Ltd"
             description="Leeway Softech is a bulk email service provider, offering promotional email services, mass email solutions, and a variety of email services to meet your needs."
            />
            <Grid container spacing={1} className="mail_page main_content">
                <Grid item xs={12} sm={12} lg={12}  className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Bulk Email</h1>
                        <p className='text-lg font-normal'>Boost your sales by doing email marketing right with a result-driven mass mailing campaign.</p>
                    </div>
                </Grid>
            </Grid>



            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">

            <Grid container className='bg-[#F2F5FB] mt-5' spacing={{ xs: 2, md: 3, lg:3 }} >
                    <Grid size={{ xs: 12, md: 4, lg:4}}>
                        <div className=' w-full'>
                        <img src={require("../../images/bulk_mail.png")}  alt="Phone" /></div>
                    </Grid>

                    <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex flex-col justify-center px-12'>   
                        <h3 className='mb-2 text-xl font-medium'>Bulk Mail</h3>
                        <p>
                        We are a global IT services and enterprise solutions provider. We are catering the different technology platforms, i.e., domain services worldwide, to the various industries. We lead as a bulk email service provider in Mumbai, Maharashtra; that does not end here, and we are at the prime point for the mass email service provider in Ahmedabad, Gujarat. Our business also includes a bulk email marketing solution provider in Ahmedabad, Gujarat. The sky is not our limit. We serve at a global level as a bulk email service provider in Bhavnagar-Rajkot, Gujarat, India. Our interactive behaviour and transparent business policy with customers make us famous as one of the industry's best IT services providers.

                        </p>
                    </Grid>
                </Grid>


                <div  className='text-3xl font-semibold  text-[#139C32] text-center mb-5 mt-5'>Awesome Features </div>
                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2 color-134CBC text-xl font-medium'>Email Design</h3>
                                    </div>
                                    <p >
                                    We offer an easy-to-use interface with a drag-and-drop email editor. You can utilize our free templates as well, which makes us the most demanding bulk email service provider for any kind of business.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>API</h3>
                                    </div>
                                    <p >
                                    Use our API to make custom integrations with your website and mobile application, add and manage contact lists, reports, the number of users, campaigns, and much more.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Advanced Reporting and Tracking</h3>
                                    </div>
                                    <p >
                                    Track real-time performance and evaluate the success of your mass email campaigns. You will come to know precisely what contacts opened, clicked, or communicated with your emails.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Send Time Customization</h3>
                                    </div>
                                    <p >
                                    Our algorithms figure out the best time to send your email campaigns. It will help you get 5 to 15% in open rates.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Contact List Management</h3>
                                    </div>
                                    <p >
                                    Turn your unlimited contacts into permanent customers with our bulk mail software. Manage, separate, and increase your audience with our contact list management tool.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Verified Deliverability</h3>
                                    </div>
                                    <p >
                                    bulk emailing platform with IP options, email authentication, and spam management for better deliverability of email campaigns
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Landing Pages</h3>
                                    </div>
                                    <p >
                                    The drag-and-drop landing page editor linked to your account will help you set up particular marketing operations and increase your list of contacts within minutes.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Personalized And Responsive Emails</h3>
                                    </div>
                                    <p >
                                    Create customized campaigns like newsletters, greetings, transactional emails, trigger-based emails, and much more with our responsive mail designs that look perfect on any smart device.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>Generate Campaigns</h3>
                                    </div>
                                    <p >
                                    Create customized campaigns like newsletters, greetings, transactional emails, trigger-based emails, and much more with our responsive mail designs that look perfect on any smart device.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                <div>
                                        <h3  className='mb-2 color-134CBC text-xl font-medium'>A/B Split Test</h3>
                                    </div>
                                    <p >
                                    Learn about the things that attract your customers with A/B or split testing. Gather the data to take your decision and enhance one email campaign at a time.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
                


                <div className='text-3xl font-semibold color-134CBC text-center mb-5 mt-5'>Why Choose Us For The Bulk Email Services?</div>
                <Grid container spacing={5} 
                    paddingTop={4}

                 className='flex justify-center align-middle'>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='component'>
                        {/* Your icons and text here */}
                        <div className='icons flex mb-5'>
                            <img src={icon} alt='icons' className='mr-2.5 h-5' />
                            <div>
                                <p>Robust Options</p>
                            </div>
                        </div>

                        <div className='icons flex mb-5'>
                            <img src={icon} alt='bulk' className='mr-2.5 h-5' />
                            <div>
                                <p > {/* Removed top margin */}
                                    Hosted systems are cloud-based
                                </p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} className='component'>
                        <div className='icons flex mb-5'>
                            <img src={icon} alt='bulk_icon' className='mr-2.5 h-5' />
                            <div>
                                <p > {/* Removed top margin */}
                                    Offering simpler setup and management
                                </p>
                            </div>
                        </div>

                        <div className='icons flex mb-5'>
                            <img src={icon} alt='mail' className='mr-2.5 h-5' />
                            <div>
                                <p > {/* Removed top margin */}
                                    Business to make an informed decision.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>


        </>
    );
};

export default Infrastructure;
