import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import vscode from "../icons/career/vs_code.png"
import share from "../icons/career/share_point.png"
import icon from "../icons/mob-app/Vector (2).png"
import SEO from "../components/SEO"

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '350px',
    width: '300px',
    backgroundColor: '#fff',
}));

export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title='Leading Provider of ASP.NET Services | Leeway Softech Pvt Ltd'
                description="Leeway\'s ASP.NET solutions are tailored to help businesses of all sizes in reaching their objectives. They provide a diverse array of services, including custom development."
            />
            <Grid container spacing={1} className="front_page main_content bg-color">
                <Grid item xs={12} sm={12} lg={12} className='p-10'>
                    <div>
                        <h1 className='text-center text-white font-bold text-[32px] p-2 pt-5'>ASP.NET</h1>
                        <p className='text-center text-white  text-[16px] p-3'>Deploy a rich and highly custom enterprise solutions using .NET Technologies. LEEWAY SOFTECH, provides world-class services. Our expert team offers opportunities for striving and improvising quality of the work along with giving versatile support. We deliver a top solution for growing your business. We have a team of skilled Microsoft Application developers that deliver high-end solutions for the complex business structures. Our expert prepares a plan for the growth and provides the offshore development center for dedicated .NET MVC developer’s team. Get high-performance business excellence at reduced cost and optimize the business process for your organizational growth.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra w-100-perc pt-2-perc">
                <div className='text-center  text-3xl font-semibold color-134CBC d-flex p-10'>Build strong technical expertise to deliver a staggering number of projects</div>
                <Grid container spacing={2} columns={16} className='flex justify-center  mb-10'  >
                    <Grid item xs={6} >
                        <Item className="add_sub_card">

                            <div className='text-center p-3'>
                                <img src={vscode} alt='gsc' className='inline'></img>
                                <h3 className='text-[16.38px] font-bold p-5'>Microsoft Visual Studio</h3>

                                <p className='text-xs text-[#161011]'>Microsoft Visual Studio is an integrated development environment (IDE) from Microsoft. It is used to develop computer programs, as well as websites, web apps, web services and mobile apps. Visual Studio uses Microsoft software development platforms such as Windows API, Windows Forms, Windows Presentation Foundation, Windows Store and Microsoft Silverlight. It can produce both native code and managed code.</p>

                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="add_sub_card">

                            <div className='text-center p-3'>
                                <img src={share} alt='share' className='inline'></img>
                                <h3 className='text-[16.38px] font-bold p-5'>SharePoint</h3>

                                <p className='text-xs text-[#161011]'>SharePoint is a web-based collaborative platform that integrates with Microsoft Office. Launched in 2001, SharePoint is primarily sold as a document management and storage system, but the product is highly configurable and usage varies substantially among organizations.We provide end-to-end SharePoint Development to create a simple and easy starting business activity.</p>

                            </div>
                        </Item>
                    </Grid>
                </Grid>


                <div className='text-center  text-3xl font-semibold text-[#139C32] d-flex p-10'>Why Choose Us For the ASP.Net Development?</div>
                <Grid
                    container
                    spacing={5}
                    className='d-flex justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        
                        <div className='icons flex align-middle p-2'>
                            <img src={icon} alt='iconfaster' className='mr-2.5' />
                            <div>
                                <p>Coding much faster</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle p-2'>

                            <img src={icon} alt='End-to-Endicon' className='mr-2.5'></img>
                            <div>
                                <p> 
                                    End-to-End testing
                                </p></div>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle p-2'>

                            <img src={icon} alt='Cachingicon' className='mr-2.5'></img>
                            <div>
                                <p> 
                                    Offers Advanced Caching System
                                </p></div>
                        </div>

                        <div className='icons flex align-middle p-2'>

                            <img src={icon} alt='Systemicon' className='mr-2.5'></img>
                            <div>
                                <p> 
                                    Great support
                                </p></div>
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </>
    );
}
