import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import asp from "../../icons/mob-app/asp.png"
import php from "../../icons/career/php (2) 1.png"
import java from "../../icons/mob-app/java 1.png"
import wordpress from "../../icons/mob-app/wordpress_270832 1.png"
import front_end from "../../icons/mob-app/css-3_732190 1.png"
import Web_company from "./Web_company"
import Web_app_development from "./Web_app_development"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SEO from "../../components/SEO"
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '300px',
    width: '300px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
        <SEO title='About LWeb Development Services | Leeway Softech Pvt Ltd'
             description='Leeway Softech provides web design and development services. From concept to completion, they craft stunning websites that deliver impactful results.'
            />
        <Grid container spacing={1} className="web_page main_content">
        <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
            <div className='text-white text-center'>
                <h1 className='text-3xl font-semibold'>Web Development</h1>
                <p className='text-lg font-normal'>Leeway Softech offers web design and development services, From design to development, we create stunning websites that drive results.
                </p>
            </div>
        </Grid>
    </Grid> 

        <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center'>We Uses Technologies</div>
            <Grid container spacing={3} columns={16} className='flex justify-center p-2.5 pt-5 mb-[1%]'>
      <Grid item xs={6} >
        <Item className={'card card_spe'}>
         
            <div className='text-center'>
                <img src={asp} alt='asp' className='inline mb-2'></img>
                <h3 className=' text-[#161011] text-xl font-medium p-[12px]'>ASP.NET</h3>
          
              <p className='text-[#161011] text-sm'>ASP.NET is an open-source server-side web application framework designed for web development to produce dynamic web pages. It was developed by Microsoft to allow programmers to build dynamic web sites, applications, and services. ASP.NET has evolved...</p>
              <a href="/asp-net" className='no-underline color-134CBC text-sm' >Read More</a> <ArrowForwardIcon className='arrow_icons'/>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className={'card card_spe'}>
       
            <div className='text-center'>
                <img src={php} alt='php' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Php</h3>
           
              <p className='text-[#161011] text-sm'>Build websites and web applications of all sizes and complexity, ranging from small static websites to large-scale complex enterprises. CMS enables faster development with reusable and maintainable code. PHP Frameworks...</p>
              <a href="/php" className='no-underline color-134CBC text-sm'>Read More</a><ArrowForwardIcon className='arrow_icons'/>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className={'card card_spe'}>
          
            <div className='text-center'>
                <img src={java} alt='java' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Java</h3>
           
              <p className='text-[#161011] text-sm'>Enriching and Enhancing Application Performances with Java Frameworks. It has various frameworks that make web applications more powerful and scalable. We follow digital...</p>
              <a href="/java" className='no-underline color-134CBC text-sm'>Read More</a><ArrowForwardIcon className='arrow_icons'/>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
        <Item className={'card card_spe'}>
         
            <div className='text-center'>
                <img src={wordpress} alt='wordpress' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Wordpress</h3>
          
              <p className='text-[#161011] text-sm'>WordPress: Beautiful designs, powerful features, and the freedom to build anything you want. WordPress allows you to build a website that meets your unique needs. Start a blog, business site, portfolio, online store, or anything else you can imagine....</p>
              <a href="/wordpress" className='no-underline color-134CBC text-sm'>Read More</a><ArrowForwardIcon className='arrow_icons'/>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className={'card card_spe'}>
         
            <div className='text-center'>
                <img src={front_end} alt='front_end' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Front-End</h3>
            
              <p className='text-[#161011] text-sm'>FRONT-END TECHNOLOGIES shapes structure, design, behaviour, and liveliness of everything you see on the screen. The core technologies we use are HTML5, CSS3, JQuery, AJAX, XML, etc. Our expert web development team will create..</p>
              <a href="/front-end" className='no-underline color-134CBC text-sm'>Read More</a><ArrowForwardIcon className='arrow_icons'/>
          </div>
        </Item>
      </Grid>
    </Grid>


        </Box>

    <Web_company/>
    <Web_app_development/>
        </>
    );
}
