import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import sco from "../../icons/sco.png"
import development from "../../icons/Web Design.png"
import analytics from "../../icons/analytic-chart 1.png"

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '20vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
        

        <Box  >
            <h1 className='text-3xl color-134CBC font-semibold text-center mb-5 mt-5'>Additional Information</h1>
            <Grid container spacing={2} columns={16}   className="add_card flex justify-center p-2.5">
      <Grid item xs={6} >
        <Item className="add_sub_card">
         
            <div className='text-center'>
                <img src={sco} alt='sco' className='inline mb-5'></img>
                <h3 className='mb-2  text-[#161011] text-xl font-medium'>SEO</h3>
          
              <p classNmae='text[#161011] text-sm'>E-commerce companies can help businesses to improve their search engine ranking. This means that their website will be more likely to appear in search results when customers search for products or services like theirs.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="add_sub_card">
       
            <div className='text-center'>
                <img src={development} alt='development' className='inline mb-5'></img>
                <h3 className='mb-2  text-[#161011] text-xl font-medium'>Web design and development</h3> 
           
              <p classNmae='text[#161011] text-sm'>E-commerce companies can help businesses to design and develop their online stores. This includes everything from creating the website to integrating payment processing and shipping.</p>
          </div>
        </Item>
      </Grid>

      <Grid item xs={6} >
      <Item className="add_sub_card">
       
            <div className='text-center'>
                <img src={analytics} alt='analytics' className='inline mb-5'></img>
                <h3 className='mb-2  text-[#161011] text-xl font-medium'>Analytics</h3>
           
              <p classNmae='text[#161011] text-sm'>E-commerce companies can provide businesses with analytics data about their website traffic and sales. This data can help businesses to understand how customers are using their website and make improvements to their online store.</p>
          </div>
        </Item>
      </Grid>
  </Grid>

        </Box>

        </>
    );
}
