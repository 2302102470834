import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';


const Item = styled(Paper)(({ theme, bgColor, bgImage }) => ({
    backgroundColor: bgColor || '#fff',
    width: '300px',
    height: '100px',
    borderRadius:"20px",
    backgroundImage: bgImage ? `url(${bgImage})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

export default function ResponsiveGrid() {
    const cardData = [
        { backgroundColor: '#FAEFFF', image: require('../certificat_img/it-solution_1.png') },
        { backgroundColor: '#C9E5FF', image: require('../certificat_img/Group 2.png') },
        { backgroundColor: '#FFF1F0', image: require('../certificat_img/Group 1.png') },
        { backgroundColor: '#FFF7E1', image: require('../certificat_img/Frame 162694.png') },
        { backgroundColor: '#DEE5FD', image: require('../certificat_img/Group 2.png') },
        { backgroundColor: '#F2FFE7', image: require('../certificat_img/iso-certificate.png') },
    ];

    return (
        <Grid container className='BackgroundContainer w-full mt-[3%]' >
    <Box sx={{ flexGrow: 1 }}>
    <h2 className='text-center text-xl font-semibold text-[#134CBC]' >Certificates</h2>
    <Grid container spacing={2} columns={{ xs: 3, sm: 6, md: 12 }} className='flex justify-center mb-[2%] w-full'>
        {cardData.map((item, index) => (
            <Grid className="certificate_card" key={index} item xs={2} sm={4} md={4}>
                <Item bgColor={item.backgroundColor} className='item'>
                    <img 
                        src={item.image} 
                        alt={`Card image ${index + 1}`} 
                        style={{ objectFit: 'cover' }} 
                    />
                </Item>
            </Grid>
        ))}
    </Grid>
</Box>
</Grid>
    );
}
