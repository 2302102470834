import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SEO from '../../components/SEO';



const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="Database | Leeway Softech Pvt Ltd"
             description="Leeway Softech provides database management services tailored to meet your business needs. Their team of professionals delivers reliable, secure, and cost-effective solutions."
            />
            <Grid container spacing={1} className="database_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                <h1 className='text-3xl font-semibold'>Database</h1>
                        <p className='text-lg font-normal'>Database Management Services tailored to fit your business needs. Get reliable, secure, and cost-effective solutions with our experienced team of professionals.</p>
                    </div>
                </Grid>
            </Grid>


            <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Database</div>
                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons flex align-middle mb-5'>
                                    <p >
                                        A database management service provider offers a variety of services designed to help businesses manage their data in a secure and effective manner. A database is a collection of information, usually stored on a computer, which helps in maintaining the organization and retrieval of data for various purposes. The primary goal of a database management service provider is to provide businesses with a secure and reliable system for managing their data. This includes designing and creating secure databases, ensuring the integrity of the data stored, and providing access to data for authorized users.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    The use of database management services is essential for businesses of all sizes. It helps businesses store and manage their data in an effective and secure manner. The data stored in the database can be used for multiple purposes, such as providing data to customers, creating reports, and providing data for analytics.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    A database management service provider is responsible for creating the physical structure of the database and setting up the database security, as well as designing the, application programs and queries to access the data stored in the database. They will also provide maintenance, support, and help keep the databases secure.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    Database management services provide a variety of advantages for businesses. Secure, reliable databases that are easily managed provide businesses with peace of mind, storing data in a secure and reliable manner. Database management services can improve the efficiency and accuracy of a business’s data, while allowing them to access data quickly. Additionally, well-managed databases also help businesses protect their data from unauthorized access, data theft, or data corruption.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    When choosing a database management service provider, businesses should look for one that is experienced and certified. This will ensure that their data is secure and protected from malicious activity. It is also important to choose a provider that is familiar with the type of database software being used and has the expertise to provide reliable support, help with designing and creating secure databases, and maintain the database in an optimized and secure manner.
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    Businesses should also consider the reliability of the database management service provider and the level of customer support provided. Database management services should provide services to help businesses set up and manage their databases, as well as provide support for questions and queries. Any database management service provider should be able to provide businesses with a clear and easy to follow guide on how to properly manage and secure their databases, as well as providing help and support in the event of an issue with the databases.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    It is also important to consider the cost of database management services, as some providers may offer a subscription-based pricing model or charge an additional fee for extra support and maintenance. Businesses should look for a database management service provider that offers reasonable and competitive rates for their services.
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons flex align-middle mb-5'>
                                    <p >
                                    Overall, database management services are essential for businesses of all sizes. A reliable and experienced provider can provide businesses with secure and well-managed databases to store and access their data in a secure and efficient manner. When choosing a provider, businesses should look for one that is experienced and certified, as well as provides reliable customer support and reasonable fees. Doing so will ensure that businesses benefit from secure, well-managed databases and reliable data access.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>
            </Box>


        </>
    );
};

export default Infrastructure;
