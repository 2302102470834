import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import strategy from "../../icons/Frame 390.png";
import technology from "../../icons/Frame 390 (1).png";
import design from "../../icons/design.png";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  height: "230px",
  width: "300px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <Box className="component_bgimg w-full mt-12 py-10">
      <h1 className="text-[#139C32] text-2xl font-semibold text-center">
        We specialise In
      </h1>
      <Grid
        container
        spacing={4}
        columns={16}
        className="flex justify-center mt-10 px-4 min-[1200px]:px-24"
      >
        <Grid item xs={6}>
          <Item className="card_spe place-content-center">
            <div className="p-[5%]">
              <div className="flex items-center justify-start mb-3">
                <img src={strategy} alt="strategy"></img>
                <h3 className="ml-[10%] text-base font-bold">Strategy</h3>
              </div>
              <p className="text-[#1610118C]">
                Our Main aim is to deliver the best of IT services and also in
                an affordable way to Our customer base.
              </p>
            </div>
          </Item>
        </Grid>

        <Grid item xs={6}>
          <Item className="card_spe place-content-center">
            <div className="p-[5%] ">
              <div className="flex items-center justify-start mb-3">
                <img src={design} alt="design"></img>
                <h3 className="ml-[10%] text-base font-bold">Design</h3>
              </div>
              <p className="text-[#1610118C]">
                We Offer Corporate Identity Design Services, Affordable Offshore
                Website Design, E-commerce Web Design.
              </p>
            </div>
          </Item>
        </Grid>

        <Grid item xs={6}>
          <Item className="card_spe place-content-center">
            <div className="p-[5%]">
              <div className="flex items-center justify-start mb-3">
                <img src={technology} alt="technology"></img>
                <h3 className="ml-[10%] text-base font-bold">Technology</h3>
              </div>
              <p className="text-[#1610118C]">
                Technology is the application of knowledge and skills to make
                products or to offer cutting-edge services.
              </p>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
