import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import whatsapp from "../../images/whatsapp_grp.png"
import whatsapp_api from "../../images/whatsapp_api.png"
import icon from "../../icons/mob-app/Vector (2).png"
import SEO from '../../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="Business Whatsapp Chatbot Integration | Leeway Softech"
             description="Boost your business revenue with WhatsApp Chatbot integration. By obtaining the WhatsApp Green Tick, you can establish trust and credibility with your customers and much more. Contact Leeway Softech now."
            />
            <Grid container spacing={1} className="whatsapp_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                    <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Whatsapp Business Chatbot Integration</h1>
                        <p className='text-lg font-normal'>Convert peoples to potential customer of your brand. Grow Your ROI by WhatsApp Chatbot Integration. Make easier to discover new product/service for your customer.  WhatsApp has become a popular platform for businesses to connect with customers, and business WhatsApp Chatbot integration is a great way to contact this platform.</p>
                    </div>
                </Grid>
            </Grid>



            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">


                <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Whatsapp Business Integration</div>
                <Grid container className='bg-[#F2F5FB]' spacing={{ xs: 2, md: 3, lg:3 }} >
                    <Grid size={{ xs: 12, md: 4, lg:4}}>
                        <div className=' w-full'>
                        <img src={require("../../images/whatsapp_grp.png")} className=' object-contain' alt="whatsapp img" /></div>
                    </Grid>

                    <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex flex-col justify-center px-12'>   
                    <h3 className='mb-2 text-xl font-medium'>Whatsapp Integration</h3>
                        <p>
                        WhatsApp has become a popular platform for businesses to connect with customers, and business WhatsApp Chatbot integration is a great way to contact this platform.

                            <div className='icons flex ' >
                                <img src={icon} alt='connecticon' className='mr-2.5 h-5' />

                                <p>Easily connect with customers.</p>

                            </div>
                            <div className='icons flex '>
                                <img src={icon} alt='serviceicon' className='mr-2.5 h-5' />

                                <p>Providing them with real-time customer service support.</p>

                            </div>
                            <div className='icons flex '>
                                <img src={icon} alt='chatbotsicon' className='mr-2.5 h-5' />

                                <p>Build robotic chatbots that can answer customer questions and inquiries.</p>

                            </div><div className='icons flex '>
                                <img src={icon} alt='informationicon' className='mr-2.5 h-5' />

                                <p>As well as provide customers with product and service information.</p>

                            </div>

                        </p>



                    </Grid>
                </Grid>

 
                <Grid container  className='bg-[#F2F5FB] mt-10' spacing={{ xs: 2, md: 3, lg:3 }}>

                <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex flex-col justify-center px-12'>  
                        <h3  className='mb-2 text-left leading-[1.5] text-xl font-medium'>
                            Make the campaign personal and engage with the user.<br />
                            HERE 2 TYPE OF WHATSAPP BUSINESS PROFILE:
                        </h3>
                        <div className='icons flex '>
                            <img src={icon} alt='Greenicon' className='mr-2.5 h-5' />

                            <p>Official Business Account (well known as “Green Tick”)</p>

                        </div><div className='icons flex '>
                            <img src={icon} alt='Accounticon' className='mr-2.5 h-5' />

                            <p>Business Account</p>

                        </div>

                    </Grid>

                    <Grid size={{ xs: 12, md: 4, lg:4}}>
                    <div className=' w-full'>
                        <img src={require("../../images/whatsapp_api.png")} className=' object-contain' alt="whatsapp_api" /></div>
                    </Grid>


                </Grid>

                <div  className='text-3xl font-semibold  text-[#139C32] text-center mt-5 mb-5'>Whatsapp Features</div>
                <Grid
                    container
                    spacing={5}
                    paddingTop={4}

                 className='flex justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        {/* Your icons and text here */}
                        <div className='icons flex align-middle mb-5'>
                            <img src={icon} alt='Communicationsicon' className='mr-2.5 h-5' />
                            <div>
                                <p>2-way Communications</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Automationicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                    Chat Automation
                                </p></div>
                        </div>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='billionicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    More than 2 billion users
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Countriesicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Network in 180 Countries
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='languagesicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Support 60+ languages
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Availableicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Available for any size of business
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Experienceicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Better User Experience
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Personalizedicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Personalized campaigns
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Establishicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Establish trust through Green Tick.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Catalogueicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Product/Service Catalogue
                                </p></div>
                        </div>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Securedicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Secured by End-to-End Encryption
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='supporticon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                    Sales and support in one place
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Quickicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                    Quick Setup
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Easyicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                    Easy to Re-Market
                                </p></div>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} md={6} lg={6} className='component'>
                    <div className='icons flex align-middle mb-5'>
                            <img src={icon} alt='connectedicon' className='mr-2.5 h-5' />
                            <div>
                                <p>24/7 connected with customers</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='repliesicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Set custom replies for FAQs.
                                </p></div>
                        </div>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Schedulingicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Campaign Scheduling
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='notificationicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Automate store notification
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Analysisicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Monitor Chat Analysis
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Facebookicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Integrate with Facebook Ads
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='actionsicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Set up call-to-actions.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='multimediaicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Share multimedia messages.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Reduceicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Reduce calls and emails.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Paymenticon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Payment Update
                                </p></div>
                        </div>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Financeicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Personal Finance Update
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Reservationicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Reservation update
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Shippingicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Shipping Update
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Ticketicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Ticket Update
                                </p></div>
                        </div>

                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className='component'>
                    <div className='icons flex align-middle mb-5'>
                            <img src={icon} alt='Updateicon' className='mr-2.5 h-5' />
                            <div>
                                <p>24/7 connected with customers</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='repliesicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Set custom replies for FAQs.
                                </p></div>
                        </div>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Campaignicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Campaign Scheduling
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Automateicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Automate store notification
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Monitoricon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Monitor Chat Analysis
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Integrateicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Integrate with Facebook Ads
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='call-to-actions' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Set up call-to-actions.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='messages' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Share multimedia messages.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='calls' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Reduce calls and emails.
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='emails' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Payment Update
                                </p></div>
                        </div>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Personalicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Personal Finance Update
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Reservationicon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Reservation update
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Shipping whatsapp' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Shipping Update
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='green tick icon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > {/* Removed top margin */}
                                Ticket Update
                                </p></div>
                        </div>

                    </Grid>


                    
                </Grid>

                <div className='text-3xl font-semibold color-134CBC text-center mt-5 mb-5'>Lorem Ipsum </div>
                <Grid container spacing={3} columns={16}  className='flex justify-center p-2.5'>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>

                                    <p >
                                        WhatsApp Business chatbots provide customers with additional features, such as product suggestions, order tracking, and loyalty. This can also be used to send marketing messages to customers, helping businesses increase their customer base and improve customer engagement.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>

                                    <p >
                                        This allows businesses to provide their customers with a more humane experience, as it allows them to respond to customer queries in seconds.This can also be used to send marketing messages to customers, helping businesses increase their customer base and improve customer engagement.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>


                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>

                                    <p >
                                    WhatsApp chatbot integration is a great way for businesses to leverage the power of WhatsApp Business, improve customer service and support, provide customers with a more personalized experience, and help them better understand their products and services.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>

                                    <p >
                                    Basically, WhatsApp Chatbot is a set of automated replies that simulates a human conversation on WhatsApp. From May 22nd, WhatsApp will provide its business API to any size of business. In the past, it was only available for large or medium-sized businesses.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>

                                    <p >
                                    The WhatsApp bot is the easiest way to communicate with customers. Leeway Softech provides WhatsApp business API integration services as a Business Solution Provider (BSP). In this era, people should prefer WhatsApp messages over email and text messages. Through WhatsApp, it is easy to chat with customers in 180 countries and 60+ languages
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                
            </Box>


        </>
    );
};

export default Infrastructure;
