import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from '../icons/Group 34.png';
import icon1 from '../icons/Group 34 (1).png';
import icon2 from '../icons/Group 34 (2).png';
import icon3 from '../icons/Group 34 (3).png';
import icon4 from '../icons/Group 34 (4).png';
import icon5 from '../icons/Group 34 (5).png';
import { useNavigate } from 'react-router-dom';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '12px',
  cursor: 'pointer',
  height: '200px',
  // width:'42vw',
  borderColor: '#1375BC', // Border color
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'left',
  borderWidth: '2px', // Border width
  borderStyle: 'solid', // Border style
}));

export default function RowAndColumnSpacing() {

  const navigate = useNavigate();


  const handleLinkClick = (path) => {
    navigate(path); // Navigate to the new page
  };

  return (
    <Box  className='w-full pt-[1%]'>
      <h1  className='text-3xl font-semibold text-[#139C32]'>Our Innovative Service</h1>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 12 }} className='services p-[5%] mt-[-3%]'>
        <Grid items size={6}>
          <Item onClick={() => handleLinkClick('/software_development')}  className='flex justify-center align-middle'>
            <div className='icons flex p-2.5' >
              <img src={icon} alt="Service Custom Icon" className='icon-img mr-10' />
              <div>
                <h3 className='text-xl font-semibol'>Custom software solutions</h3>
                <p >
                  Use our missed call solutions to start missed call campaigns for your business and upsurge the number of leads by up to 90%.
                </p>
              </div>
            </div>
          </Item>
        </Grid>

        <Grid items size={6}>
          <Item onClick={() => handleLinkClick('/bulk_sms')} className='flex justify-center align-middle'>
            <div className='icons flex p-2.5' >
              <img src={icon1} alt=" smart Service Icon" className='icon-img mr-10' />
              <div>
                <h3 className='text-xl font-semibol'>Bulk SMS</h3>
                <p >
                Reach out to millions quickly with smart marketing, OTPs, notifications and other bulk SMS services offered by Leeway Softech.
                </p>
              </div>
            </div>
          </Item>
        </Grid>

        <Grid items size={6}>
          <Item onClick={() => handleLinkClick('/pbx_service')} className='flex justify-center align-middle'>
            <div className='icons flex p-2.5' >
              <img src={icon2} alt="Voice Service Icon" className='icon-img mr-10' />
              <div>
                <h3 className='text-xl font-semibol'>Voice Call</h3>
                <p >
                Place your brand right in the mind of your audiences by interacting with them in real-time with our voice call solutions.   
                </p>
              </div>
            </div>
          </Item>
        </Grid>

        <Grid items size={6}>
          <Item onClick={() => handleLinkClick('/mobile_app')} className='flex justify-center align-middle'>
            <div className='icons flex p-2.5' >
              <img src={icon3} alt="Mobile Service Icon" className='icon-img mr-10' />
              <div>
                <h3 className='text-xl font-semibol'>Mobile app development</h3>
                <p >
                  Experience the standard mobile app development services to modernize your business in the cyberspace. We develop a best demanding app with effective coding!

                </p>
              </div>
            </div>
          </Item>
        </Grid>

        <Grid items size={6}>
          <Item onClick={() => handleLinkClick('/web_devlopment')} className='flex justify-center align-middle'>
            <div className='icons flex p-2.5' >
              <img src={icon4} alt="best website design Service Icon" className='icon-img mr-10' />
              <div>
                <h3 className='text-xl font-semibol'>Web Development</h3>
                <p >
                Get your business best website design to increase visibility, sales and revenue. Our responsive web development services ensure your significant online presence.
                </p>
              </div>
            </div>
          </Item>
        </Grid>

        <Grid items size={6}>
          <Item onClick={() => handleLinkClick('/bulk_mail')} className='flex justify-center align-middle'>
            <div className='icons flex p-2.5' >
              <img src={icon5} alt="Do-It-Yourself Service Icon" className='icon-img mr-10' />
              <div>
                <h3 className='text-xl font-semibol'>Bulk Email</h3>
                <p >
                Our reliable and Do-It-Yourself bulk email service aid you to send marketing emails to your customer base without configuring any mail servers.
                </p>
              </div>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
