import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import blog3 from "../event/blog/chandrayaan.jpg"
import blog2 from "../event/blog/Flutter app development.png"
import { Link } from 'react-router-dom';

import BlogForm from "../pages/BlogForm"
import SEO from '../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '430px',
    width: '270px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {

    const schemaMarkup = {
        "@context": "https://schema.org/",
  "@type": "Article",
  "name": "Chandrayaan-3: How AI Is Shaping India s Moon Mission",
  "description": "Tech and AI in Chandrayaan-3",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://new.leewaysoftech.com/chandrayaan-blog"
   },
    }


    return (
        <>  
            <SEO title="Chandrayaan Blog | Leeway Softech Pvt Ltd"
             description="Explore how AI is transforming India's Chandrayaan-3 mission to the moon in our latest article. Learn about ISRO's ambitious goals, the innovative technology behind the AI-powered rover Pragyan."
             schema={schemaMarkup}
            />
            <div className='px-6 md:px-24'>
            <Grid container spacing={1} className="blog_page">
                <Grid items xs={12} sm={12} lg={12} className='pt-[5%]'>
                    <h1 className='color-134CBC text-center font-bold text-[32px] d-flex'>Blogs</h1>
                    <div className='main_content align-middle flex flex-col justify-center'>
                        <div className='text-center font-bold text-[32px] d-flex p-10 '>Chandrayaan-3: How AI Is Shaping India s Moon Mission</div>
                        <div className='flex align-middle justify-center'>
                            <p className='color-134CBC'>Chandrayaan-3</p>
                            <div className='blog_line'></div> {/* Add margin to the line */}
                            <p className='color-134CBC ml-0.5'>Posted on 15th July 2023</p>
                        </div>

                        <div>
                            <img src={blog3} alt="Blog Chandrayaan Visual" className='image_component' />

                        </div>
                    </div>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%', }} >
                <Grid container spacing={2} columns={16} className='blog_card'>
                    <Grid item xs={6} className='flex justify-center'>
                        <div className=' pt-2'> {/* Increased padding and added margin */}
                            <div  className='flex flex-row'>
                                <Link to='https://www.instagram.com/reel/CurXw1cuIts/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='>
                                    <h2 className='text-[#161011]  align-left text-2xl  font-semibold'>Chandrayaan-3:</h2></Link>
                                <h2 className='text-[#161011]  align-left text-2xl  font-semibold'> How AI Is Shaping India s Moon Mission</h2>
                            </div>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mt-5'>
                                India s space agency, the Indian Space Research Organisation (ISRO), has launched Chandrayaan-3, the country s third mission to the moon. The mission is a important milestone for India in its ambition to become a major space power. Chandrayaan-3 is the successor to Chandrayaan-2, which didn t achieve a soft landing on the moon. The new mission is designed to learn from the mistakes of its predecessor and make a successful landing.
                            </p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'><strong>Chandrayaan-3</strong> is a landmark mission that will help India to demonstrate its ability in space exploration. The mission includes a rover that is powered by AI, which is expected to play a significant role in the success of the mission.</p>

                            <img src={blog3} alt='launched Chandrayaan-3' className='blog_sub_img m-auto'></img>

                            <div  className='flex flex-row'>
                                <Link to='https://www.instagram.com/reel/CurXw1cuIts/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='>
                                    <h2 className='text-[#161011]  align-left text-2xl pt-5 font-bold'>Chandrayaan-3</h2></Link>
                                <h2 className='text-[#161011]  align-left text-2xl pt-5 font-bold ml-1'> Mission Overview</h2>
                            </div>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'>Chandrayaan-3 is an <strong>ambitious mission that is designed to demonstrate</strong> the <strong>end-to-end capability</strong> for a safe landing and roving on the lunar surface. The mission is a <strong>follow-on to Chandrayaan-2</strong>, which was launched in 2019. Chandrayaan-2 was a successful mission, but the lander failed to land safely on the moon.  <strong>Chandrayaan-3 has been developed to address</strong> the <strong>weaknesses of Chandrayaan-2</strong> and to achieve a soft landing on the moon</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'>The mission includes a lander and a rover, which will be deployed near the moon s south pole. The lander and the rover will conduct scientific experiments to study the lunar surface and its composition. The mission aims to study the lunar surface and its evolution and to search for water ice in the lunar polar regions. Chandrayaan-3 is an important mission for India, as it will help to boost the country s capabilities in space exploration and create commercial opportunities in the space sector.</p>


                            <h2 className='text-[#161011]  align-left text-2xl pt-5 font-bold'> Launch of Chandrayaan-3</h2>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'>The Chandrayaan-3 mission was launched on <strong>July 14, 2023,</strong> using the <strong>GSLV Mark 3 heavy lift launch vehicle.</strong> The launch took place from the <strong>Satish Dhawan Space Center in Sriharikota, India.</strong> The mission was launched by the <strong>Indian Space Research Organisation (ISRO)</strong> and is a significant milestone for India s space program. The launch was watched by over <strong>1.4 million people on ISRO s YouTube channel.</strong></p>

                            <h2 className='text-[#161011]  align-left text-2xl pt-5 font-bold'>Role of AI in Chandrayaan-3</h2>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'>Chandrayaan-3 includes a <strong>solar-powered robotic vehicle, </strong> known as  <strong>Pragyan, which is powered by artificial intelligence (AI).</strong> The rover is equipped with a piece of  <strong>motion technology developed by researchers at IIT-Kanpur</strong>, which will help the rover manoeuvre on the surface of the moon and aid in landing. <strong>The AI algorithm will help the rover trace water and other minerals on the lunar surface and send pictures for research and examination.</strong>The rover s<strong>AI algorithm</strong>  will also map out<strong>the terrain and identify obstacles</strong>
                                to avoid them. AI will be used to analyse the scientific data that is collected by the lander and the rover. This will help scientists to better understand the moon s composition and its history.
                            </p>

                            <h2 className='text-[#161011]  align-left text-2xl pt-5 font-bold'>Scientific Payloads of Chandrayaan-3</h2>
                            <p className='text-[#161011] text-[15px] align-left'>The lander and the rover of Chandrayaan-3 are equipped with a range of scientific payloads that will contribute to photo-chemical scientific observations and experiments on the lunar surface. The lander payloads include the following:</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] ml-10 mt-5'>Chandra s Surface Thermophysical Experiment (ChaSTE): This payload is designed to measure the thermal conductivity and temperature on the lunar surface.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] ml-10 mt-5'>Instrument for Lunar Seismic Activity (ILSA): ILSA is intended for measuring the seismic activity around the landing site on the moon.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] ml-10 mt-5'>Langmuir Probe (LP): The LP payload aims to estimate the plasma density and its variations.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] ml-10 mt-5'>LASER Retroreflector Array (LRA): It is a passive experiment to understand the dynamics of the Moon system.</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mt-3'>In addition, a passive Laser Retroreflector Array from NASA will be accommodated for lunar laser ranging studies.</p>

                            <p className='text-[#161011] text-[15px] align-left'>The rover payloads of Chandrayaan-3 include the following:</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'>Alpha Particle X-ray Spectrometer (APXS): APXS is used to derive the elemental composition in the vicinity of the landing site on the lunar surface.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%]'>Laser Induced Breakdown Spectroscope (LIBS): LIBS is another payload that helps in deriving the elemental composition of the lunar surface.</p>

                            <h2 className='text-[#161011]  align-left text-2xl pt-5 font-bold'>Benefits of Chandrayaan-3</h2>


                            <p className='text-[#161011] text-[15px] align-left mt-5'>Chandrayaan-3 is an important mission for India, as it will help to demonstrate the country s capabilities in space exploration. The mission is also expected to boost the Indian economy, as it will create jobs and open up commercial opportunities in the space sector. The mission will help India to become the fourth country to make a soft landing on the moon, after the former Soviet Union, the U.S., and China. India will also become the first country in the world to land at the lunar south pole, an area of special interest for space agencies and private space companies because of the presence of water ice that could support a future space station</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mt-5'>The use of AI in space exploration is becoming increasingly important. AI can help to make space missions safer, more efficient, and more productive. AI s ability to sift through humungous amounts of data and find correlations helps in intelligently analyzing that data. AI is also being used for trajectory and payload optimization. An AI known as AEGIS is already on the red planet onboard NASA s current rovers. The system can handle autonomous targeting of cameras and choose what to investigate. However, the next generation of AIs will be able to control vehicles, autonomously assist with study selection, and dynamically schedule and perform scientific tasks.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mt-5'>NASA is planning to launch the James Webb Space Telescope into an orbit of around 1.5 million kilometers from Earth in 2020. Part of the mission will involve AI-empowered autonomous systems overseeing the full deployment of the telescope s 705-kilo mirror. AI s role in space exploration is set to increase in the coming years, helping to make space exploration safer, more efficient, and more productive.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mt-5'>Chandrayaan-3 is a landmark mission for India, as it will help to demonstrate the country s capabilities in space exploration and create commercial opportunities in the space sector. The mission includes a solar-powered robotic vehicle, known as Pragyan, which is powered by artificial intelligence (AI). The rover is equipped.</p>


                        </div>
                        <div>

                        </div>
                    </Grid>
                </Grid>

            </Box>
            </div>
            <BlogForm />

        </>
    );


}


