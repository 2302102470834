import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./PagesStyle.css";
import Header from "./pages/Header";
import Home from "./pages/Home";
import WhyChooseUs from "./pages/WhyChooseUs";
import Services_for_industry from "./pages/Services_for_industry";
import Certificates from "./pages/Certificates";
import Services from "./pages/Services";
import Event from "./pages/Event";
import Testimonial from "./pages/Testimonial";
import Review from "./pages/Review";
import Footer from "./pages/Footer";
import About_us from "./pages/Company/About_us";
import Infrastructure from "./pages/Company/Infrastructure";
import Achievements from "./pages/Company/Achievements";
import Our_Client from "./pages/Company/Our_Client";
import Career from "./pages/Company/Career";
import Digital_marketing from "./pages/Platform/Digital_marketing";
import MobileApp from "./pages/Platform/MobileApp";
import Web_devlopment from "./pages/Platform/Web_devlopment";
import Software_development from "./pages/Platform/Software_development";
import Java_page from "./pages/Platform/Java_page";
import Database_page from "./pages/Platform/Database_page";
import Front_end from "./pages/Platform/Front_end";
import Internet_things from "./pages/Platform/Internet_things";
import Flutter_page from "./pages/Platform/Flutter_page";
import RCS_msg from "./pages/Platform/RCS_msg";
import PBX_services from "./pages/Platform/PBX_services";
import Whatsapp_page from "./pages/Platform/Whatsapp_page";
import Bulk_sms from "./pages/Platform/Bulk_sms";
import Bulk_mail from "./pages/Platform/Bulk_mail";
import Crm_page from "./pages/Platform/Crm_page";
import Cms_page from "./pages/Platform/Cms_page";
import E_commerce from "./pages/Platform/E_commerce";
import Erp_page from "./pages/Platform/Erp_page";

import Bank from "./pages/Solutions/Bank";
import Government from "./pages/Solutions/Government";
import Religious from "./pages/Solutions/Religious";
import Education from "./pages/Solutions/Education";
import Corporate from "./pages/Solutions/Corporate";
import Politics from "./pages/Solutions/Politics";
import E_commerce_sector from "./pages/Solutions/E_commerce_sector";
import International from "./pages/Solutions/International";
import Media from "./pages/Media";
import Imc_event from "./pages/Imc_event";
import Blog from "./pages/Blog";
import Flutter_blog from "./pages/Flutter_blog";
import Open_source from "./pages/Platform/Open_Source";
import ContactUs from "./pages/ContactUs";

import Asp_net from "./pages/Asp_net";
import Php from "./pages/Php";
import Wordpress from "./pages/Wordpress";
import Php_codeigniter from "./pages/Php_codeigniter";
import Php_drupal from "./pages/Php_drupal";
import Php_joomla from "./pages/Php_joomla";
import Php_magento from "./pages/Php.magento";
import Php_opencart from "./pages/Php_opencart";
import Marketing_blog from "./pages/Marketing_blog";
import Chandrayaan_blog from "./pages/Chandrayaan_blog";
import It_technology from "./pages/It_technology";
import Bluk_sms_blog from "./pages/Bulk_sms_blog";
import Ethiopia_event from "./pages/Ethiopia_event";
import Gesia_event from "./pages/Gesia_event";
import Policy from "./pages/Policy";
import Terms_conditions from "./pages/Terms&condition";
import Payment from "./pages/Payment";
import Chatbox from "./pages/Chatbox";
import PageNotFound from "./pages/PageNotFound";
import { HelmetProvider } from "react-helmet-async";
import Technology_page from "../src/pages/Technology_page";
import Platform_Page from "../src/pages/Platform_Page";
import OmniChannel from "../src/pages/OmniChannel";
import Life_of_leeway from "../src/pages/LifeOfLeeway";
import Communication_event from "../src/pages/CommunicationAsia_event";
import Convergence_event from "./pages/Convergence_event";
import Gitex_event from "./pages/Gitex";
import Ibex_event from "./pages/Ibex";
import BookADemoForm from "../src/pages/Company/BookADemoForm";
import GFAEvent from "./pages/GFAEvent";
import SocialMedia from "./pages/SocialMedia";
import SmartHospital from "./pages/SmartHospital";
import MediaConvergence from "./pages/MediaConvergence ";
function App() {
  return (
    <HelmetProvider>
      <Router>
        <Main />
      </Router>
    </HelmetProvider>
  );
}

function Main() {
  const validPaths = [
    "/",
    "/about",
    "/Services_for_industry",
    "/WhyChooseUs",
    "/Certificates",
    "/Services",
    "/Event",
    "/Testimonial",
    "/Review",
    "/infrastructure",
    "/achievements",
    "/our-client",
    "/join_us",
    "/digital-marketing",
    "/government",
    "/web-devlopment",
    "/asp-net",
    "/php",
    "/wordpress",
    "/php-codeigniter",
    "/php-drupal",
    "/php-joomla",
    "/php-magento",
    "/php-opencart",
    "/software-development",
    "/java",
    "/database",
    "/front-end",
    "/internet-of-things",
    "/open-source",
    "/flutter",
    "/rcs-msg",
    "/pbx-service",
    "/whatsapp",
    "/bulk-sms",
    "/bulk-mail",
    "/crm",
    "/cms",
    "/e-commerce",
    "/erp",
    "/bankig-and-financial",
    "/mobile-app",
    "/religious",
    "/education",
    "/corporate",
    "/politics",
    "/e-commerce-sector",
    "/international",
    "/media",
    "/imc-event",
    "/blog",
    "/flutter-blog",
    "/marketing-blog",
    "/chandrayaan-blog",
    "/it-technology",
    "/ethiopia-event",
    "/gesia-event",
    "/leeway",
    "/technology_page",
    "/bulk-sms-blog",
    "/policy",
    "/terms-conditions",
    "/payment",
    "/contact-us",
    "/communicationAsia-event",
    "/convergence-event",
    "/gitex",
    "/ibex",
    "/book-demo",
    "/platform",
    "/omni-channel",
    "/gff",
    "/smart-hospital",
    "/media-convergence",
  ];

  const location = useLocation();
  const is404Page = !validPaths.includes(location.pathname);
  return (
    <>
      {!is404Page && <Header />}
      {/* <Router> */}
      <nav>{/* Add navigation links here if needed */}</nav>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
            </>
          }
        >
          <Route
            path="/Services_for_industry"
            element={<Services_for_industry />}
          />
          <Route path="WhyChooseUs" element={<WhyChooseUs />} />
          <Route path="Certificates" element={<Certificates />} />
          <Route path="Services" element={<Services />} />
          <Route path="Event" element={<Event />} />
          <Route path="Testimonial" element={<Testimonial />} />
          <Route path="Review" element={<Review />} />
        </Route>

        <Route path="/">
          <Route path="/about" element={<About_us />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/our-client" element={<Our_Client />} />
          <Route path="/join_us" element={<Career />} />
        </Route>

        <Route path="/">
          <Route path="/digital-marketing" element={<Digital_marketing />} />
          <Route path="/government" element={<Government />} />
          <Route path="/web-devlopment" element={<Web_devlopment />} />
          <Route path="/asp-net" element={<Asp_net />} />
          <Route path="/php" element={<Php />} />
          <Route path="/wordpress" element={<Wordpress />} />
          <Route path="/php-codeigniter" element={<Php_codeigniter />} />
          <Route path="/php-drupal" element={<Php_drupal />} />
          <Route path="/php-joomla" element={<Php_joomla />} />
          <Route path="/php-magento" element={<Php_magento />} />
          <Route path="/php-opencart" element={<Php_opencart />} />
          <Route path="/technology_page" element={<Technology_page />} />
          <Route path="/platform" element={<Platform_Page />} />
          <Route path="/omni-channel" element={<OmniChannel />} />
          <Route path="/smart-hospital" element={<SmartHospital />} />
          <Route path="/media-convergence" element={<MediaConvergence />} />

          <Route
            path="/software-development"
            element={<Software_development />}
          />
          <Route path="/java" element={<Java_page />} />
          <Route path="/database" element={<Database_page />} />
          <Route path="/front-end" element={<Front_end />} />
          <Route path="/internet-of-things" element={<Internet_things />} />
          <Route path="/open-source" element={<Open_source />} />
          <Route path="/flutter" element={<Flutter_page />} />
          <Route path="/rcs-msg" element={<RCS_msg />} />
          <Route path="/pbx-service" element={<PBX_services />} />
          <Route path="/whatsapp" element={<Whatsapp_page />} />
          <Route path="/bulk-sms" element={<Bulk_sms />} />
          <Route path="/bulk-mail" element={<Bulk_mail />} />
          <Route path="/crm" element={<Crm_page />} />
          <Route path="/cms" element={<Cms_page />} />
          <Route path="/e-commerce" element={<E_commerce />} />
          <Route path="/erp" element={<Erp_page />} />
        </Route>

        <Route path="/">
          <Route path="/bankig-and-financial" element={<Bank />} />
          <Route path="/mobile-app" element={<MobileApp />} />
          <Route path="/religious" element={<Religious />} />
          <Route path="/education" element={<Education />} />
          <Route path="/corporate" element={<Corporate />} />
          <Route path="/politics" element={<Politics />} />
          <Route path="/e-commerce-sector" element={<E_commerce_sector />} />
          <Route path="/international" element={<International />} />
          <Route path="/media" element={<Media />} />
          <Route path="/imc-event" element={<Imc_event />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/flutter-blog" element={<Flutter_blog />} />
          <Route path="/marketing-blog" element={<Marketing_blog />} />
          <Route path="/chandrayaan-blog" element={<Chandrayaan_blog />} />
          <Route path="/it-technology" element={<It_technology />} />
          <Route path="/ethiopia-event" element={<Ethiopia_event />} />
          <Route path="/gesia-event" element={<Gesia_event />} />
          <Route
            path="/communicationAsia-event"
            element={<Communication_event />}
          />
          <Route path="/convergence-event" element={<Convergence_event />} />
          <Route path="/gitex" element={<Gitex_event />} />
          <Route path="/ibex" element={<Ibex_event />} />
          <Route path="/gff" element={<GFAEvent />} />

          <Route path="/leeway" element={<Life_of_leeway />} />

          <Route path="/bulk-sms-blog" element={<Bluk_sms_blog />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms-conditions" element={<Terms_conditions />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/book-demo" element={<BookADemoForm />} />
        </Route>
        <Route path="/">
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>
        {/* <Route Component={<Chatbox />} />
        <Route Component={<Footer />} /> */}
        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="*" element={<Navigate to="/page404" replace />} /> */}
      </Routes>

      {!is404Page && <Chatbox />}
      {!is404Page && <SocialMedia />}
      {!is404Page && <Footer />}
    </>
  );
}

export default App;
