import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useState } from "react";

export default function RowAndColumnSpacing() {
  const [active, setActive] = useState("2023");
  console.log(active, "actives");

  const onClickActive = (btnName) => {
    setActive(btnName);
  };
  return (
    <>
      <div className="flex justify-center align-middle mb-5">
        <button
          className={`mt-4 px-4 py-2 ml-5 border  ${
            active === "2023"
              ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
              : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
          } rounded-lg  focus:ring-0 h-[45px] w-[175px]`}
          onClick={() => onClickActive("2023")}
        >
          2023
        </button>
        <button
          className={`mt-4 px-4 py-2 ml-5  border  ${
            active === "2022"
              ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
              : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
          } rounded-lg  focus:ring-0 h-[45px] w-[175px]`}
          onClick={() => onClickActive("2022")}
        >
          2022
        </button>
        <button
          className={`mt-4 px-4 py-2 ml-5 border  ${
            active === "2021"
              ? "bg-white text-[#134CBC] font-semibold shadow-[inset_4px_4px_10px_0px_#134CBC]"
              : "bg-white text-[#161011] shadow-[4.73px_4.73px_14.2px_0px_#134CBC40]"
          } rounded-lg  focus:ring-0 h-[45px] w-[175px]`}
          onClick={() => onClickActive("2021")}
        >
          2021
        </button>
      </div>
    </>
  );
}
