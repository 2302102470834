import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import cyber from "../../icons/career/cybersecurity 1.png";
import customer from "../../icons/career/customer-care 1.png";
import group from "../../icons/career/Group (1).png";
import php from "../../icons/career/php (2) 1.png";
import professional from "../../icons/career/professional.png";
import FormInput from "../../components/FormInput";
import FormTextArea from "../../components/FormTextArea";
import FormFileUpload from "../../components/FormFileUpload";
import SelectData from "../../components/SelectData";
import FormSelect from "../../components/FormSelect";
import { Card, CardContent } from "@mui/material";
import FormButton from "../../components/FormButton";

export default function RowAndColumnSpacing() {
  return (
    <Box sx={{ width: "100%" }}>
      <Card className="max-w-[904px] mx-auto p-[20px] shadow-md">
        <CardContent>
          <h1 className="text-[#134CBC] text-center text-3xl font-semibold mb-5">
            Make Your Future
          </h1>
          <Grid container spacing={3} className="flex justify-center">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <label htmlFor="name" className="career_form_input">
                Name
              </label>
              <FormInput
                placeholder="Name Here"
                variant="outlined"
                margin="normal"
              />

              <label htmlFor="name" className="career_form_input">
                Email
              </label>
              <FormInput
                placeholder="Email Here"
                variant="outlined"
                margin="normal"
              />

              <label htmlFor="name" className="career_form_input">
                Resume
              </label>
              <FormFileUpload
                placeholder="File upload"
                variant="outlined"
                margin="normal"
              />
              <p className="text-red relative text-sm mt-5">
                *Please complete all fields correctly
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <label htmlFor="name" className="career_form_input">
                Contact No
              </label>
              <FormInput
                placeholder="Ex: 9898952413"
                variant="outlined"
                margin="normal"
              />

              <label htmlFor="name" className="career_form_input">
                Position
              </label>
              <FormSelect
                placeholder="Select Your Position"
                variant="outlined"
                margin="normal"
                options={SelectData.position}
              />

              <label htmlFor="name" className="career_form_input">
                Message
              </label>
              <FormTextArea
                placeholder="Your Message"
                variant="outlined"
                margin="normal"
                className="join_us_textarea input-chat"
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <FormButton label="Submit" />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
