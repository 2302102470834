import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import icon from "../../icons/mob-app/Vector (2).png"
import SEO from '../../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="RCS SMS System | Leeway Softech Pvt Ltd"
             description="Upgrade your messaging experience with our RCS SMS system, offering rich media, read receipts and real-time interaction."
            />
            <Grid container spacing={1} className="rsc_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                    <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>RCS Messaging</h1>
                        <p className='text-lg font-normal'>Provides a protocol between both next-generation SMS and offers a huge leap in functionality for both businesses and customers. By Rich Communication Service (RCS).</p>
                    </div>
                </Grid>
            </Grid>



            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">

            <div className='text-3xl font-semibold text-[#139C32]  text-center mb-5 mt-5'>RCS Messaging </div>
            <Grid container className='bg-[#F2F5FB]  min-[1140px]:rounded-l-[55px] rounded-t-[55px] min-[1140px]:rounded-tr-[0px]' spacing={{ xs: 2, md: 3, lg:3 }} >
                    <Grid size={{ xs: 12, md: 4, lg:4}} className='rcs_content rounded-[55px] rounded-t-[55px] max-[1140px]:mx-auto'>
                        <img src={require("../../images/iPhone 14 Pro.png")}  alt="iPhone images" className='h-auto w-[100%] max-h-[350px] object-contain'/>
                    </Grid>

                    <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex max-[1140px]:mx-auto max-[1140px]:text-center flex-col justify-center px-12'>
                        <h3 className='mb-2 text-xl font-medium'>Rich Communication Services</h3>
                        <p>
                        RCS stands for Rich Communication Service. RCS Messaging is the next generation of SMS, providing a mobile app's rich functionality in messages. No installing, no downloading. Just tapping. The overall aim of RCS is for it to eventually replace SMS and MMS messaging, the formats we're all familiar with as they have been around for years. RCS messaging is very much like WhatsApp, where live chat can take place, including multi-media support, with everything handled via the data network. The flow of conversation between your agent and a user is similar to a conversation between two people, with each party taking turns speaking, listening, and responding to the other. Your RBM agent initiates conversations with users.
                        </p>
                    </Grid>
                </Grid>
               
                <div className='text-3xl font-semibold color-134CBC text-center mt-5'>Why Choose Us For The RCS MESSAGING?</div>
    <Grid 
            container 
            spacing={2}
            paddingTop={4}
           
            className='flex justify-center align-middle'
        >
            <Grid item xs={12} md={6} lg={6} className='component'>
                {/* Your icons and text here */}
                <div className='icons flex align-middle mb-5' >
                    <img src={icon} alt='images and videos icon' className='mr-2.5 h-5' />
                    <div>
                        <p>You can send more than six images and videos to the customer at that time.</p>
                    </div>
                </div>
        
        <div className='icons flex align-middle mb-5' >
            
               <img src={icon} alt=' transactions icon' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                Analyse campaigns by measuring read receipts, transactions, and other suggested replies and actions.
                </p></div>
        </div>

        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='security icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Enjoy greater security than is found with apps.
             </p></div>
     </div>

     <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='inspirationicon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             RCS takes inspiration from the world’s most popular over-the-top (OTT) messaging channels to ‘beautify’ business messaging.
             </p></div>
     </div>
        
       
    
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt=' High-resolution icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             High-resolution photo and file sharing
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='functionality icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             RCS brings additional functionality by providing read receipts, typing indicators, and response and action notifications in real time.
             </p></div>
     </div>

     <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='customericon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             RCS drives higher customer engagement
             </p></div>
     </div>
       
        </Grid>  
      </Grid>


                <div className='text-3xl font-semibold text-[#139C32] text-center'>Awesome Features </div>
                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5 pt-5'>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Branded Business Messaging </h3>
                                    <p >
                                    Your customers are used to SMS text messages, so why not take advantage of it? Add your personal touch and make them aware they are texting with you in their messaging inbox.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Delivered Rich Media</h3>
                                    <p >
                                    Boost your brand and communication with your customers by delivering enriched messages they can trust. Add your logo, brand name, images, and carousels to your offers.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} className='flex justify-center align-middle'>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Handset Capability Check and SMS Fallback</h3>
                                    <p >
                                    When sending an RCS message, our system performs a capability check to determine whether the end user's phone can receive and properly display an RCS message.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card' >
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Handset Capability Check and SMS Fallback</h3>
                                    <p >
                                    When sending an RCS message, our system performs a capability check to determine whether the end user's phone can receive and properly display an RCS message.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Auto-response to MO Messages</h3>
                                    <p >
                                    When an end user replies to a message that you've sent, our system can respond automatically with a predefined message. For example, if your MT message gives the user the option to contact your support team, you might provide a 'Support' button for the user to tap.the user the option to contact your support team.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Custom Branding, Colors, And Rich Media Content</h3>
                                    <p >
                                    With RCS messaging, you have the ability to custom-brand the message with your company's logo and colours and include rich media content such as images,etc.the user to tap or let the user text "support" back for more help.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Blacklist Management</h3>
                                    <p >
                                    Just like SMS and MMS, RCS messaging always needs to provide the end user with a way to opt out. With RCS, the user experience is the same as SMS in that an end user can text STOP or tap a "Stop" button if one is provided basic information about the message's status.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Delivery And Read Receipts</h3>
                                    <p >
                                    When you send an RCS message, you can receive a read receipt, which simply tells you whether the end user opened the message. Delivery receipts, which are sent from the mobile operator, provide basic information about the message's status.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Content Management</h3>
                                    <p >
                                    RCS messages contain rich media such as images, videos, and interactive elements like rich cards and action buttons. You'll need to store the media files for these elements in a content management system (CMS).
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <h3 className='mb-2 color-134CBC text-xl font-medium'>Message localization</h3>
                                    <p >
                                    Use this feature in conjunction with the auto-responder to deliver localized responses to each country. You'll need to store the media files for these elements in a content management system (CMS)
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>


             
            </Box>


        </>
    );
};

export default Infrastructure;
