const SelectData={
    "position" :[
        {value: 'DATA SCIENCE DEVELOPER' ,label: 'DATA SCIENCE DEVELOPER'},
        {value: 'LINUX SERVER ADMINISTRATOR' ,label: 'LINUX SERVER ADMINISTRATOR'},
        {value: 'CYBER SECURITY ENGINEER' ,label: 'CYBER SECURITY ENGINEER'},
        {value: 'SR. DATABASE ADMINISTRATOR' ,label: 'SR. DATABASE ADMINISTRATOR'},
        {value: 'BUSINESS DEVELOPMENT MANAGER' ,label: 'BUSINESS DEVELOPMENT MANAGER'},
        {value: 'CUSTOMER CARE EXECUTIVE' ,label: 'CUSTOMER CARE EXECUTIVE'},
        {value: 'PHP DEVELOPER' ,label: 'PHP DEVELOPER'},

    ],
    "category":[
        {value:"Channels", label:'Channels'},
        {value:'CPaaS',label:'CPaaS'},
        {value:'Technologies',label:'Technologies'},
        {value:'Enterprise Solutions',label:'Enterprise Solutions'},
    
    ],

    "cpaas_services":[
        {value:"RCS", label:'RCS'},
        {value:'PBX Services',label:'PBX Services'},
        {value:'Whatsapp Bussiness',label:'Whatsapp Bussiness'},
        {value:'Chatbot',label:'Chatbot'},
        {value:'Bulk SMS',label:'Bulk SMS'},
        {value:'Bulk Mail',label:'Bulk Mail'},
    ],
    "channels_services":[
        {value:"Digital Marketing", label:'Digital Marketing'},
        {value:'Mobile App',label:'Mobile App'},
        {value:'Web Development',label:'Web Development'},
        {value:'Software Development',label:'Software Development'},
    ],
    "techno_services":[
        {value:"Java", label:'Java'},
        {value:'Database',label:'Database'},
        {value:'Front-End',label:'Front-End'},
        {value:'IOT',label:'IOT'},
        {value:'Open Source',label:'Open Source'},
        {value:'Flutter',label:'Flutter'},
    ],
    "ep_solution_services":[
        {value:"CRM", label:'CRM'},
        {value:'CMS',label:'CMS'},
        {value:'E-Commerce',label:'E-Commerce'},
        {value:'ERP',label:'ERP'},
    ],

    "city":[
        {value:"Mumbai", label:'Mumbai'},
        {value:'Delhi',label:'Delhi'},
        {value:'Bangalore',label:'Bangalore'},
        {value:'Hyderabad',label:'Hyderabad'},
        {value:'Ahmedabad',label:'Ahmedabad'},
        {value:'Chennai',label:'Chennai'},
        {value:'Kolkata',label:'Kolkata'},
        {value:'Amritsar',label:'Amritsar'},
        {value:'Kochi',label:'Kochi'},
        {value:'Visakhapatnam',label:'Visakhapatnam'},
        {value:'Srinagar',label:'Srinagar'},

    ],

"state":[
        {value:"Andhra Pradesh", label:'Andhra Pradesh'},
        {value:'Arunachal Pradesh',label:'Arunachal Pradesh'},
        {value:'Assam',label:'Assam'},
        {value:'Bihar',label:'Bihar'},
        {value:'Chhattisgarh ',label:'Chhattisgarh '},
        {value:'Goa ',label:'Goa '},
        {value:'Gujarat',label:'Gujarat'},
        {value:'Haryana',label:'Haryana'},
        {value:'Himachal Pradesh',label:'Himachal Pradesh'},
        {value:'Maharashtra',label:'Maharashtra'},
        {value:'Meghalaya',label:'Meghalaya'},

    ],    
}

export default SelectData