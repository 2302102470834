import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import eve1 from "../event/IMC/Mask group (1).png"
import eve2 from "../event/IMC/Mask group (2).png"
import eve3 from "../event/IMC/Mask group (3).png"
import eve4 from "../event/IMC/Mask group (4).png"
import eve5 from "../event/IMC/Mask group (5).png"
import eve6 from "../event/IMC/Mask group (6).png"
import eve7 from "../event/IMC/Mask group (7).png"
import eve8 from "../event/IMC/Mask group (8).png"
import eve9 from "../event/IMC/Mask group (9).png"
import event from "../event/IMC/Mask group.png"
import SEO from '../components/SEO';
import Event_year from "../components/Event_year"

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    // height:'300px',
    width:'400px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Content = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '12px',
    boxShadow: '0px 6.3px 30px 0px #3D3D3D0D',
    backgroundColor: '#fff', 
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    // height:'73px',
    // width:'500px'
  }));
export default function RowAndColumnSpacing() {
    return (
        <>    
              <SEO title="Gesia Event | Leeway Softech Pvt Ltd"
             description="Leeway Softech participated in Gesia event. At the event the company showcasd their innovation in ICT field and how it can portentially revolutionize industries."
            />
              <Box sx={{ width: '100%'}} className="it_infra main_content pt-[3%] pb-[3%]" >
    <h1 className='text-[#161011] text-center font-bold text-[32px]'>Ethiopia International Trade Exhibition</h1>

    <Event_year/>
    
    <Grid container spacing={2} columns={16}  className='flex justify-center p-2.5'>
      <Grid item xs={6}>
        <Item className="event">
          
            <div style={{ textAlign:'center' }}>
                <img src={require("../event/Mask group (1).png")} alt='gesia event' className='event_img  w-[400px]'></img>
          </div>
        </Item>
      </Grid>
  
     
    </Grid>

  </Box>
        </>
    );
}
