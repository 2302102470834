import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import bharat from "../../icons/BaranNagrik_logo.png";
import bharat_co from "../../icons/Bharat_Co_operative_bank-removebg-preview 1.png";
import logo from "../../icons/logo2 1.png";
import gsc from "../../icons/image 20.png";
import integral from "../../icons/Integral_bank_logo.png";
import SEO from "../../components/SEO";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "80px",
  width: "200px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));
export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Banking and Financial Services | Leeway softech Pvt Ltd"
        description="Discover innovative services that prioritize customer convenience and security. From SMS alerts to advanced security measures, we've got you covered."
      />
      <Grid container spacing={1} className="bank_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Bank And Financing</h1>
            <p className="text-lg font-normal">
              Explore innovative banking services and customized financing
              options to help you achieve your financial goals.
            </p>
          </div>
        </Grid>
      </Grid>

      {/* <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
                <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Our Trust</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">
                            <div className='flex justify-center align-middle'>
                                    <img src={bharat} alt='bharat' className='mt-2.5'></img>
                                </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                            <div className='flex justify-center align-middle'>
                                    <img src={bharat_co} alt='bharat_co' className='mt-2.5'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                            <div className='flex justify-center align-middle'>
                                    <img src={logo} alt='logo' className='mt-2.5'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                            <div className='flex justify-center align-middle'>
                                    <img src={gsc} alt='gsc'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                            <div className='flex justify-center align-middle'>
                                    <img src={integral} alt='integral' className='mt-2.5'></img>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

            </Box> */}

      <div className="text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5">
        Bank And Financing
      </div>
      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2 color-134CBC  text-xl font-medium">
                  SMS Alerts: Stay Informed Anytime, Anywhere
                </h3>
              </div>
              <p>
                Our SMS alert service ensures you’re always connected to your
                account. Receive real-time notifications for transactions,
                balance updates and more. Whether you’re making a payment or
                receiving a deposit, our SMS alerts keep you informed no matter
                where you are.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                  Missed Call Service: Banking at Your Fingertips{" "}
                </h3>
              </div>
              <p>
                Your convenience is our priority. Dial our dedicated number to
                access account balances and mini-statements instantly. No
                internet connection required banking is as easy as a missed
                call.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                Interactive Voice Response (IVR) Service: Your Virtual Banking Assistant{" "}
                </h3>
              </div>
              <p>
              Our IVR service acts as your personal banking assistant. Easily access your account details, make fund transfers, or get updates with just a simple phone call. Experience the convenience of effortless banking with this advanced service.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                WhatsApp Chatbot: Instant Banking, Just a Message Away                </h3>
              </div>
              <p>
              With our WhatsApp Chatbot, banking is now at your fingertips anytime, anywhere. From checking your balance to paying bills, our chatbot is available 24/7 to assist you with a quick and easy chat. Welcome to the future of conversational banking.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                E-mail Statements: Green Banking, Paperless Convenience                </h3>
              </div>
              <p>
              Go paperless and embrace eco-friendly banking with our e-mail statements. Receive your account statements directly in your inbox efficient, secure and environmentally responsible.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                Vulnerability Assessment and Penetration Testing (VAPT) Audit: Banking on Security
                </h3>
              </div>
              <p>
              Security is a top priority. Our VAPT audit ensures your data is protected at all costs. We identify vulnerabilities and fortify our defences to keep your information safe.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2 color-134CBC  text-xl font-medium">
                Virtual Mobile Number (VMN) Long Code: Your Dedicated Banking Line
                </h3>
              </div>
              <p>
              Have a query? Use our VMN long code to get in touch. Simply send a text and we’ll provide quick responses to your banking queries. It’s your direct line to seamless banking assistance.
              </p>
            </div>
          </Content>
        </Grid>
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2 color-134CBC  text-xl font-medium">
                RCS SMS: Next-Level Messaging with Rich Communication{" "}
                </h3>
              </div>
              <p>
              Upgrade your messaging experience with RCS SMS a rich, interactive way to communicate with your bank. Engage with visual content and interactive features, taking your banking conversations to the next level.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2 color-134CBC  text-xl font-medium">
                Domain Hosting with SSL: Securing Your Online Presence
                </h3>
              </div>
              <p>
              We extend the same high-level security to your online presence. Our SSL-encrypted domain hosting ensures that your website and online transactions remain fully protected, offering peace of mind for your digital interactions.
              </p>
            </div>
          </Content>
        </Grid>
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                ATM Card Block: Instant Lockdown, Total Control
                </h3>
              </div>
              <p>
              Lost your ATM card? With our ATM card block service, you can instantly lock your card to prevent unauthorized use. Take control of your security at any moment.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2  color-134CBC text-xl font-medium">
                Secure E-mail ID: Privacy in Every Communication
                </h3>
              </div>
              <p>
              Sensitive communications deserve the utmost privacy. Our secure e-mail ID ensures that your banking conversations remain confidential and safe from unauthorized access.
              </p>
            </div>
          </Content>
        </Grid>
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2 color-134CBC  text-xl font-medium">
                CERT-IN Audit: Compliant and Cyber-Resilient                </h3>
              </div>
              <p>
              Our banking operations are fully compliant with the highest cybersecurity standards. With a CERT-IN audit, we reinforce our commitment to safeguarding your digital assets against cyber threats.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <div>
                <h3 className="mb-2 color-134CBC  text-xl font-medium">
                Migration Audit: Seamless Transition for Your Banking Needs                </h3>
              </div>
              <p>
              Change can be seamless with our migration audit service. When we upgrade our systems, we ensure a smooth transition so your banking experience remains uninterrupted.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
    </>
  );
}
