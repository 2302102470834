// import React from 'react'

// function BookADemoForm() {
//   return (
//     <div>BookADemoForm</div>
//   )
// }

// export default BookADemoForm

import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import cyber from "../../icons/career/cybersecurity 1.png";
import customer from "../../icons/career/customer-care 1.png";
import group from "../../icons/career/Group (1).png";
import php from "../../icons/career/php (2) 1.png";
import professional from "../../icons/career/professional.png";
import FormInput from "../../components/FormInput";
import FormTextArea from "../../components/FormTextArea";
import FormFileUpload from "../../components/FormFileUpload";
import SelectData from "../../components/SelectData";
import FormSelect from "../../components/FormSelect";
import { Card, CardContent } from "@mui/material";
import FormButton from "../../components/FormButton";
import SEO from "../../components/SEO";

export default function BookADemoForm() {
  //   const [data, setData] = React.useState("");
  // const handleChange = (event) => {
  //   setData(event.target.value);
  // };

  const [selectedButtons, setSelectedButtons] = React.useState([]);

  const handleButtonClick = (name) => {
    setSelectedButtons((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SEO
        title="Request a Demo | Leeway Softech Pvt Ltd"
        // description="PBX services are an excellent way to enhance your business's communication and productivity. Discover the various types of PBX services available at Leeway Softech"
      />
      <Card className="max-w-[904px] mx-auto p-[20px] shadow-md">
        <CardContent>
          <h1 className="text-[#134CBC] text-center text-3xl font-semibold mb-5">
            Request a Demo
          </h1>
          <h1
            style={{
              textAlign: "center",
              maxWidth: "600px", // Set a max-width for the paragraph
              margin: "0 auto", // Center the paragraph
            }}
          >
            Share a few details and our team will reach out to learn more about
            your event goals and guide you through a personalized demo.
          </h1>
          <Grid container spacing={3} className="flex justify-content-start">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <label htmlFor="name" className="career_form_input">
                First Name
              </label>
              <FormInput
                placeholder="Name"
                variant="outlined"
                margin="normal"
              />

              <label htmlFor="name" className="career_form_input">
                Company Name
              </label>
              <FormInput
                placeholder="Enter your company name"
                variant="outlined"
                margin="normal"
              />

              <p className="text-red relative text-sm">
                Features Interested in (Choose All That Apply):
              </p>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <div className="mt-3" style={{display: "flex", justifyContent: "start", gap: 5}}>
                <div className="feature_button">
                  <div className="feature_text">All In One!</div>
                </div>
                <div className="feature_button">
                  <div className="feature_text">WhatsApp</div>
                </div>
                <div className="feature_button">
                  <div className="feature_text">RCS</div>
                </div>
                <div className="feature_button">
                  <div className="feature_text">Push Notification</div>
                </div>
                <div className="feature_button">
                  <div className="feature_text">Missed Call</div>
                </div>
              </div> */}
              </Grid>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <label htmlFor="name" className="career_form_input">
                Business Email
              </label>
              <FormInput
                placeholder="john@leewaysoftech.com"
                variant="outlined"
                margin="normal"
              />
              <label htmlFor="name" className="career_form_input">
                Phone/Whatsapp Number
              </label>
              <FormInput
                placeholder="9876543210"
                variant="outlined"
                margin="normal"
              />
            </Grid>
            {/* <div
              className=""
              style={{ display: "flex", justifyContent: "start", gap: 5 }}
            >
              <div className="feature_button">
                <div className="feature_text">All In One!</div>
              </div>
              <div className="feature_button">
                <div className="feature_text">WhatsApp</div>
              </div>
              <div className="feature_button">
                <div className="feature_text">RCS</div>
              </div>
              <div className="feature_button">
                <div className="feature_text">Push Notification</div>
              </div>
              <div className="feature_button">
                <div className="feature_text">Missed Call</div>
              </div>
              <div className="feature_button">
                <div className="feature_text">ChatBoat</div>
              </div>
            </div> */}

            <div
              className=""
              style={{ display: "flex", justifyContent: "start", gap: 5 }}
            >
              {[
                "All In One!",
                "WhatsApp",
                "RCS",
                "Push Notification",
                "Missed Call",
                "ChatBot",
              ].map((label, index) => (
                <div
                  key={index}
                  className="feature_button"
                  onClick={() => handleButtonClick(label)}
                  style={{
                    borderColor: selectedButtons.includes(label)
                      ? "blue"
                      : "#12133140",
                  }}
                >
                  <div className="feature_text">{label}</div>
                </div>
              ))}
            </div>
          </Grid>
          <div
            className="mt-2"
            style={{ display: "flex", justifyContent: "start", gap: 5 }}
          >
            {/* <div className="feature_button">
              <div className="feature_text">Bulk Sms</div>
            </div>
            <div className="feature_button">
              <div className="feature_text">Inbox Mailer</div>
            </div> */}
            {[
              "Bulk Sms",
              "Inbox Mailer",
              // "RCS",
              // "Push Notification",
              // "Missed Call",
              // "ChatBot",
            ].map((label, index) => (
              <div
                key={index}
                className="feature_button"
                onClick={() => handleButtonClick(label)}
                style={{
                  borderColor: selectedButtons.includes(label)
                    ? "blue"
                    : "#12133140",
                }}
              >
                <div className="feature_text">{label}</div>
              </div>
            ))}
          </div>
          <Grid container justifyContent="center">
            <FormButton label="Book Demo" />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
