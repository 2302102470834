import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import bjp from "../../certificat_img/BJP-symbol.png";
import SEO from "../../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "114px",
  width: "152px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Our Client in Political Sector | Leeway Softech Pvt Ltd"
        description="Leeway Softech provide all kind of IT Solutions, politics sector work has seen a revolution in the way campaigns and activities."
      />
      <Grid container spacing={1} className="politics_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Politics</h1>
            <p className="text-lg font-normal">
            Digital Solutions for Political Success: Revolutionizing Campaigns with Technology
            </p>
          </div>
        </Grid>
      </Grid>

      <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
        {/* <div className="text-3xl font-semibold color-134CBC text-center mb-5">
          Lorem Ipsum
        </div> */}
        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={6}>
            <Item>
              <div className="flex justify-center align-middle">
                <img src={bjp} alt="bjp"></img>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <div className="text-3xl font-semibold text-[#139C39] text-center mb-5">
        Comprehensive Digital Solutions for Political Campaigns.
      </div>
      <div className=" pb-5 ">
        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={6}>
            <Content className="sub_card">
              <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Transforming Political Communication with Advanced Solutions{" "}
              </h4>
                <p>
                In today’s fast-paced political landscape, staying connected with constituents is crucial. Our suite of communication tools including 1-2 Way SMS, CPaaS, bulk email services and WhatsApp chatbots enables politicians and campaign managers to deliver targeted, real-time information to their audience with ease. These solutions allow for mass communication, quick updates and personalized interactions, helping political teams save time, reduce costs and maximize impact.
                </p>
              </div>
            </Content>
          </Grid>

          <Grid item xs={6}>
            <Content className="sub_card">
              <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                1-2 Way SMS: Effortless and Instant Communication{" "}
              </h4>
                <p>
                1-2 Way SMS services offer an efficient way for politicians and political teams to send automated messages directly to their constituents. Whether sharing campaign news, issuing reminders about voting deadlines, SMS provides immediate delivery and high engagement. The ability to send one-way or two-way messages allows for dynamic communication, where constituents can also reply and engage with the campaign, fostering a deeper connection.
                </p>
              </div>
            </Content>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={6}>
            <Content className="sub_card">
              <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                CPaaS & Bulk Email: Mass Communication Made Easy{" "}
              </h4>
                <p>
                Communication is key in any campaign and our CPaaS (Communication Platform as a Service) and bulk email solutions provide the perfect tools for reaching a large audience in a cost-effective and scalable manner. Whether sending out campaign updates, newsletters or important announcements, these solutions allow political teams to send messages to thousands of people simultaneously, ensuring that the right message reaches the right audience at the right time. With easy customization and scheduling options, these tools make large-scale communication efficient and impactful.
                </p>
              </div>
            </Content>
          </Grid>

          <Grid item xs={6}>
            <Content className="sub_card">
              <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                WhatsApp Chatbot Service: Real-Time Voter Engagement{" "}
              </h4>
                <p>
                WhatsApp Chatbot services offer a powerful tool for real-time communication with constituents. Through automated conversations, chatbots can provide voters with information about campaign policies, upcoming events or important updates, 24/7. Chatbots can also collect data from constituents, analyze feedback and provide valuable insights into public opinion, helping political teams understand the issues that matter most to their audience. This interactive service not only improves engagement but also offers personalized experiences for each voter.
                </p>
              </div>
            </Content>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={6}>
            <Content className="sub_card">
              <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Why Choose Leeway Softech for Your Political Campaign?{" "}
              </h4>
                <p>
                At Leeway Softech, we understand the unique challenges of running a successful political campaign in the digital age. Our suite of solutions ranging from SMS and CPaaS to WhatsApp chatbots tools helps political organizations connect with voters, engage in meaningful conversations and make data-driven decisions. With the right technology in place, political campaigns can become more organized, efficient and impactful.
                </p>
              </div>
            </Content>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
