// src/components/PageNotFound.js

import React from 'react';

const PageNotFound = () => {
  return (
    <div  className='text-center mt-[20%]'>
      <h1  className='color-134CBC'>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <a href="/" >Go back to Home</a>
    </div>
  );
};

export default PageNotFound;