import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Creating our own theme
const theme = {
  background: "#EFF2FF",
  headerBgColor: "#134CBC",
  headerFontSize: "20px",
  botBubbleColor: "#134CBC",
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "white",
  userFontColor: "black",
};

// Set some properties of the bot
const config = {
  floating: true,
};

const CustomHeader = () => {
  return (
    <>
      <div className="flex align-middle p-2.5">
        <h5 className="text-xl text-white mt-2">Welcome</h5>
        <div className="header_contain">
          <img
            src={require("../icons/services/Stroke-1.png")}
            alt="WhatsApp Icon"
            className="call_img"
          />

          <Link to="tel:8000305305" target="blank" className="no-underline">
            <p className="text-[#FFFFFF] text-sm m-1.5">Call Now</p>
          </Link>
          <div className="chatbot_header"></div>
          <img
            src={require("../icons/services/whatsapp (9) 2.png")}
            alt="WhatsApp services Icon"
          />
          <Link
            to="https://api.whatsapp.com/send?phone=917878277744"
            target="blank"
            className="no-underline"
          >
            <p className="text-[#FFFFFF] text-sm m-1.5">Whatsapp Message</p>
          </Link>
        </div>
      </div>
    </>
  );
};

function Chatbox() {
  const [steps, setSteps] = useState([
    {
      id: "1",
      message: "Welcome! What would you like to talk about?",
      trigger: "user-input",
    },
    {
      id: "user-input",
      user: true,
      trigger: "dynamic-response",
    },
    {
      id: "dynamic-response",
      message: "You said: {previousValue}. What else would you like to say?",
      trigger: "user-input", // Loop back to user input
    },
  ]);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ChatBot
          className="chatbox"
          headerTitle={<CustomHeader />}
          steps={steps}
          {...config}
          // onEnd={(input) => handleUserInput(input)}
        />
      </ThemeProvider>
    </div>
  );
}

export default Chatbox;
