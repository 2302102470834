import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import flutter from "../../icons/mob-app/flutter 1.png"
import react from "../../icons/mob-app/Group.png"
import apple from "../../icons/mob-app/ios-apple 1.png"
import android from "../../icons/mob-app/Vector.png"
import windows from "../../icons/mob-app/Vector (1).png"
import Mobile_app_company from "./Mobile_app_company"
import Mobile_app_devlopment from "./Mobile_app_devlopment"
import SEO from '../../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '300px',
    width: '300px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
        <SEO title="Mobile App Development Company | Leeway Softech Pvt Ltd"
             description="Leeway Softech is a mobile app development company specializing in creating high-quality, user-friendly applications for businesses of all sizes."
            />
            
        <Grid container spacing={1} className="mob_page main_content">
        <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
            <div className='text-white text-center'>
                <h1 className='text-3xl font-semibold'>Mobile App Development</h1>
                <p className='text-lg font-normal'>Scalable & engaging mobile app development to excel your business in each corner of the worldweb</p>
            </div>
        </Grid>
    </Grid> 

        <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
            <div  className='text-3xl font-semibold color-134CBC text-center'>We Uses Technologies</div>
            <Grid container spacing={4} columns={16}  className='flex justify-center p-2.5 pt-5'>
      <Grid item xs={6} >
        <Item className="card_spe">
         
            <div  className='text-center'>
                <img src={flutter} alt='flutter' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Flutter</h3>
          
              <p  className='text-[#161011] text-sm'>We are able to develop such hybrid applications with faster development and increased time-to-market speed. Our app developers are ready to serve flexible, materialistic, suitable for all target platforms, compatible with Android, iPhone, and web platforms, and UX and UI technology-based apps.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
       
            <div className='text-center'>
                <img src={android} alt='android' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Android</h3>
           
              <p className='text-[#161011] text-sm'>We intend to develop cross-platform applications that naturally interact between the Android and iOS platforms. our team is dedicated to develop reliable apps with lack complexity of apps, Apply Agile methodologies, lot of attention to designing the app UI and easy migration of apps.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
          
            <div className='text-center'>
                <img src={react} alt='react' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>ReactJs</h3>
           
              <p className='text-[#161011] text-sm'>We intend to develop cross-platform applications that naturally interact between the Android and iOS platforms. Our team are dedicated to develop reliable apps with lack complexity of apps.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
        <Item className="card_spe">
         
            <div className='text-center'>
                <img src={apple} alt='apple' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>iOS</h3>
          
              <p className='text-[#161011] text-sm'>We are skilled to deliver next-generation iPhone apps that rank at the top of the App Store. Our iOS developers are dedicated to developing extremely scalable, better customer experiences, faster, more assured and the most engaging iPhone apps.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe">
         
            <div className='text-center'>
                <img src={windows} alt='windows' className='inline mb-2'></img>
                <h3 className='mb-2 text-[#161011] text-xl font-medium'>Windows</h3>
            
              <p className='text-[#161011] text-sm'>We intend to offer such Android applications that call for plenty of downloads. Our Android developers always think creatively to provide something exceeding, better UI support, and splendid with each client project.</p>
          </div>
        </Item>
      </Grid>
    </Grid>

        </Box>

    <Mobile_app_company/>
    <Mobile_app_devlopment/>
        </>
    );
}
