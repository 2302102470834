import React, { useState, useEffect } from "react";
import "../main.css";
import WhyChooseUs from "../pages/WhyChooseUs";
import Event from "../pages/Event";
import Testimonial from "../pages/Testimonial";
import Services_for_industry from "../pages/Services_for_industry";
import { Link } from "react-router-dom";
import Cookies from "../components/Cookies";
import SEO from "../components/SEO";
import { Button } from "@mui/material";
import image1 from "../images/home_page.png";
import image2 from "../images/Frame 1984077225.webp";
import image3 from "../images/Frame 1984077227.webp";
import img5 from "../images/Frame 1984077237.webp";
import img6 from "../images/Frame 1984077241.webp";
import image4 from "../images/iPhone 13 mini - 1.webp";
const Dots = ({ currentIndex, setCurrentIndex, images }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px", // Adjust as needed
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        zIndex: 10, // Ensure dots are above the image
      }}
    >
      {images.map((_, index) => (
        <div
          key={index}
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            margin: "0 5px",
            cursor: "pointer",
            backgroundColor: currentIndex === index ? "blue" : "gray", // Active color vs default color
          }}
          onClick={() => setCurrentIndex(index)}
        />
      ))}
    </div>
  );
};

const Home = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Bhavnagar",
      postalCode: "364002",
      streetAddress: "306, 3rd Floor, Swara Parklane",
    },
    email: "sales[at]leewaysoftech.com",
    member: [
      {
        "@type": "Organization",
      },
      {
        "@type": "Organization",
      },
    ],

    name: "LEEWAY SOFTECH",
    telephone: "1800-121-5355",
  };

  const schemaMarkup2 = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "LEEWAY SOFTECH",
    image:
      "https://new.leewaysoftech.com/static/media/Layer_1.ddb0d5f90f00c2f664c5.png",
    "@id": "http://www.leewaysoftech.com",
    url: "http://www.leewaysoftech.com",
    telephone: "1800-121-5355",
    priceRange: "$",
    address: {
      "@type": "PostalAddress",
      streetAddress: "306,3rd Floor,Swara Parklane",
      addressLocality: "Bhavnagar",
      postalCode: "364002",
      addressCountry: "IN",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 21.7571483,
      longitude: 72.14893840000002,
    },
  };

  const images = [image1, image2, image3];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const handleSize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleSize();
    window.addEventListener("resize", handleSize);

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    const pageLoadTimeout = setTimeout(() => {
      setIsPageLoaded(true);
    }, 3000);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleSize);
      clearTimeout(pageLoadTimeout);
    };
  }, []);

  const content = [
    { text: "Transforming Industries with Technology" },
    { text: "Shaping the Future with AI & IT Services" },
    { text: "Innovative Solutions for Various Sectors" },
  ];

  const textPositions = [
    "top-[10%] left-1/2 transform -translate-x-1/2",
    "top-[23%] left-1/2 transform -translate-x-1/2 text-white",
    "left-[30%] top[-10px] align-middle left-1/2 transform -translate-x-1/2 flex justify-center flex-row",
  ];

  const imageStyles = [
    "w-full h-auto object-cover ",
    "w-full  h-auto object-cover",
    "w-full h-auto object-cover",
  ];

  return (
    <>
      <SEO schema={schemaMarkup} schema2={schemaMarkup2} />

      <div className="relative w-full ">
        {currentIndex < 2 ? (
          <div className="relative w-full">
            <div className="flex justify-center items-center w-full h-full">
              <img
                src={
                  isMobile && currentIndex === 0
                    ? image4
                    : isMobile && currentIndex === 1
                    ? img6
                    : images[currentIndex]
                }
                alt="Slide"
                className={
                  isMobile && currentIndex === 0
                    ? "w-full h-auto"
                    : imageStyles[currentIndex]
                }
              />
            </div>
            <div
              className={`absolute ${textPositions[currentIndex]} left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#134CBC] font-bold p-1 flex flex-row justify-center`}
            >
              <h1 className="sm:text-xl md:text-xl lg:text-3xl xs:text-[10px] font-semibold home_text">
                {content[currentIndex].text}
              </h1>
            </div>
            <Dots
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              images={images}
            />{" "}
            {/* Include Dots here */}
          </div>
        ) : (
          <div className="relative flex items-center w-full h-full">
            <div className="w-full h-full">
              <img
                src={
                  isMobile && currentIndex === 0
                    ? image3
                    : isMobile && currentIndex === 2
                    ? img5
                    : images[currentIndex]
                }
                alt="Last Slide"
                className={`${imageStyles[currentIndex]} w-full h-full object-cover`}
              />
            </div>
            <div
              className={`absolute left-[30%] top-1/2 transform -translate-y-1/2 text-[#134CBC] font-bold p-[10%] ${
                textPositions[currentIndex]
              } ${currentIndex === 2 ? "break-words" : ""}`}
            >
              <div className="text-[50px] font-semibold">
                {content[currentIndex].text}
              </div>
            </div>
            <Dots
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              images={images}
            />{" "}
            {/* Include Dots here */}
          </div>
        )}
      </div>

      {isPageLoaded && <Cookies />}
      <Services_for_industry />
      <WhyChooseUs />
      <Testimonial />
    </>
  );

  // return (

  //     <>
  //         <SEO
  //             schema={schemaMarkup} schema2={schemaMarkup2}
  //         />

  //    <div className="relative w-full ">

  // {currentIndex < 2 ? (
  //   <div className="relative w-full">

  //     <div className="flex justify-center items-center w-full h-full">
  //       <img
  //         src={isMobile && currentIndex == 0 ? image4 : isMobile && currentIndex == 1 ? img6  : images[currentIndex]}
  //         alt="Slide"
  //         className={isMobile && currentIndex == 0 ? "w-full h-auto" : imageStyles[currentIndex]} // Dynamically apply styles here

  //       />
  //     </div>

  //     <div className={`absolute ${textPositions[currentIndex]}  left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#134CBC]  font-bold  p-1 flex flex-row justify-center `}>

  //     <h1 className=" sm:text-xl md:text-xl lg:text-3xl xs:text-[10px] font-semibold home_text">{content[currentIndex].text}</h1>
  //   </div>
  //   </div>
  // ) : (
  //   <div className="relative flex items-center w-full h-full">
  //   {/* Image */}
  //   <div className="w-full h-full">
  //     <img
  //       src={isMobile && currentIndex === 0 ? image3 : isMobile && currentIndex === 2 ? img5 : images[currentIndex]}
  //       alt="Last Slide"
  //       className={`${imageStyles[currentIndex]} w-full h-full object-cover`}
  //     />
  //   </div>

  //   {/* Text on the left */}
  //   <div className={`absolute left-[30%] top-1/2 transform -translate-y-1/2 text-[#134CBC] font-bold p-[10%] ${textPositions[currentIndex]} ${currentIndex === 2 ? 'break-words' : ''}`}>
  //     <div className="text-[50px] font-semibold">{content[currentIndex].text}</div>
  // </div>
  // </div>

  //       )}

  //     </div>

  //     {isPageLoaded && <Cookies />}
  //         <Services_for_industry />
  //         <WhyChooseUs />
  //         {/* <Certificates /> */}
  //         {/* <Services /> */}
  //         {/* <Event /> */}
  //         <Testimonial />
  //         {/* <Review /> */}
  //     </>
  // );
};

export default Home;
