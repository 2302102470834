import React, { useEffect } from 'react';
import "../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SEO from '../components/SEO';
import FormButton from "../components/FormButton"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Element } from 'react-scroll';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    // border: ' 1.5px solid #C4D2EE',
    height: '130px',
    width: '11vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));


const Infrastructure = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const hash = location.hash; // Get the current hash
        if (hash) {
            const section = document.getElementById(hash.substring(1)); // Remove the '#' and get the element
            if (section) {
                const offset = 100; // Adjust this value based on your header height
                const elementPosition = section.getBoundingClientRect().top + window.scrollY; // Get the position of the section
                const offsetPosition = elementPosition - offset; // Calculate the position with offset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth' // Smooth scroll
                });

                // After scrolling, remove the hash from the URL without reloading the page
                navigate(location.pathname, { replace: true });
            }
        }
    }, [location, navigate]);

    return (

        <>
            <SEO title="Technology Solutions | Leeway softech Pvt Ltd"
                description="Discover innovative technology solutions that drive business growth, improve efficiency and transform operations for a competitive edge in digital world"
            />
            <Grid container spacing={1} className="technology_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                    <div >
                        <h1 className='text-white text-center text-[32px] d-flex m-auto'>Technology</h1>
                        <p className='text-white text-center m-auto'>Discover how our technology stack can drive your business forward, providing smarter, faster and more efficient solutions for today's digital landscape.</p>
                    </div>
                </Grid>
            </Grid>



            <Box  className="it_infra w-[100%]">
                {/* Frontend */}
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }}  className="tech_main_box p-2.5" id='frontend'>
                    <Grid item xs={12} md={6} lg={6} className="main_tech_content flex flex-col justify-center"  >
                        <div className='tech-content'>
                            <h3  className='color-134CBC text-left text-3xl mb-5 font-[500]'>Frontend Technology</h3>
                            <p  className='text-left text-sm mb-5'>Frontend technology is essential for creating engaging and user-friendly web experiences. It involves the use of HTML, CSS and JavaScript to structure, style and add interactivity to websites and applications. Frameworks like React JS, Angular and Vue JS empower developers to build dynamic, scalable and efficient user interfaces. By focusing on responsive design and mobile-first principles, frontend technologies ensure that applications work seamlessly across all devices and screen sizes. With an emphasis on performance optimization and seamless user interaction, frontend technology plays a key role in delivering modern, high-quality digital experiences.</p>


                            <div>
                                <FormButton
                                    className='technology_btn'
                                    label="Hire Resources"
                                >
                                <ArrowForwardIcon className='arrow_icons'/>
                                </FormButton>
                            </div>
                        </div>


                        {/* <img src={require("../../images/Mask group.png")} style={{ height: 'auto', width: '100%', maxHeight: '400px', }} alt="Phone" /> */}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className='technology-main-card-component'>

                        <Grid container spacing={4}  className='p-2.5 flex justify-center'>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Item className="technology_cards felx align-middle justify-center flex-col">
                                    {/* <Link to={'/bankig-and-financial'}  className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-react'>
                                                <img src={require("../icons/technology/react (3) 2.png")} alt='tech react' className='inline' />

                                            </div>
                                            <h4 className='mt-3'>React js</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/international" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div  className='tech_img_main bg-js text-center'>
                                                <img src={require("../icons/technology/javascript 1.png")} alt='tech javascript' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>javascript</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/politics" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-js text-center'>
                                                <img src={require("../icons/technology/html (2) 1.png")} alt='tech html' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Html</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>


                            <Grid xs={12} sm={6} md={4} lg={4} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/css (1) 1.png")} alt='tech css' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Css</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-jquery'>
                                                <img src={require("../icons/mob-app/jquery.png")} alt='tech jQuery' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>jQuery</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-jquery'>
                                                <img src={require("../icons/technology/tailwind-css.256x154.png")} alt='tech Tailwind' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Tailwind</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                {/* Backend */}
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }}  className='technology_main_card_2 tech_main_box p-2.5' id='backend'>


                    <Grid item xs={12} md={6} lg={6}  className='technology-main-card-component_2 flex flex-col text-left w-[40vw]'>

                        <Grid container spacing={4}  className='flex justify-center p-2.5'>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to={'/bankig-and-financial'} className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-njs'>
                                                <img src={require("../icons/technology/Vector (1).png")} alt='tech Node js' className='inline' />
                                            </div>
                                            <h4 className='mt-3'>Node js</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/international" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-php'>
                                                <img src={require("../icons/technology/Vector (2).png")} alt='tech php' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Php</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/politics" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-py'>
                                                <img src={require("../icons/technology/python 1.png")} alt='tech python' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Python</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>


                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main text-center bg-laravel'>
                                                <img src={require("../icons/technology/laravel (2) 1.png")} alt='tech Laravel' className='mb-3 inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Laravel</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-asp'>
                                                <img src={require("../icons/mob-app/asp.png")} alt='tech asp' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>ASP.NET</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-asp'>
                                                <img src={require("../icons/mob-app/java 1.png")} alt='tech java' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Java</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={6}  className='tech_main_box flex flex-col'>
                        <div className='tech-content'>
                            <h3 className='color-134CBC text-left text-3xl mb-5 font-[500]' >Backend Technology</h3>
                            <p className='text-left text-sm mb-5'>Backend technology powers the server-side operations of web applications, handling everything that happens behind the scenes. It involves programming languages like Node.js, Python, Laravel and PHP, along with frameworks such as Django and Express.js to process requests, manage databases and execute business logic. Backend systems work with databases like MySQL, MongoDB and PostgreSQL to store and retrieve data, while APIs allow seamless communication between the frontend and backend. Key functions like user authentication, data security and scalability are managed on the backend, ensuring smooth, efficient and secure operations for your applications.</p>
                        </div>

                        <div>
                        <FormButton
                                    className='technology_btn'
                                    label="Hire Resources"
                                >
                                <ArrowForwardIcon className='arrow_icons'/>
                                </FormButton>
                        </div>
                    </Grid>
                </Grid>

                {/* Artificial */}
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }}  className="tech_main_box p-2.5" id='artificial'>
                    <Grid item xs={12} md={6}  className='main_tech_content flex flex-col justify-center'>
                        <div className='tech-content'>
                            <h3 className='color-134CBC text-left text-3xl mb-5 font-[500]'>Artificial Intelligence</h3>
                            <p className='text-left text-sm mb-5'>Artificial Intelligence (AI) technology enables machines to simulate human intelligence and perform tasks such as learning, problem-solving and decision-making. It includes key components like Machine Learning, which helps systems learn from data, Natural Language Processing (NLP) for language understanding and Computer Vision for interpreting images and videos. AI is used across industries such as healthcare, finance, retail and autonomous vehicles to improve efficiency, personalize user experiences and automate tasks. Overall, AI is revolutionizing how businesses operate and interact with customers, driving innovation and creating new opportunities.</p>
                        </div>

                        <div>
                        <FormButton
                                    className='technology_btn'
                                    label="Hire Resources"
                                >
                                <ArrowForwardIcon className='arrow_icons'/>
                                </FormButton>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className='technology-main-card-component'>

                        <Grid container spacing={4} className='flex justify-center p-2.5' >
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/Frame 1984077220.png")} alt='tech Pytorch' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Pytorch</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/tensorflow.238x256.png")} alt='tech Tensorflow' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Tensorflow</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/IBM-Watson.png")} alt='tech IBM' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>IBM Watson</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>


                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/OpenAI-GPT-green.png")} alt='tech GPT' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Chat GPT</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/Microsoft-Cognitive-Toolkit.png")} alt='Microsoft Cognitive Toolkit' className='inline'></img>
                                            </div>
                                            <h4 className='mt-2 text-center'>Microsoft Cognitive Toolkit</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/technology/H2O-ai.png")} alt='H2o' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>H2O.ai</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                {/* mobile */}

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }}  className='technology_main_card_2 tech_main_box p-2.5' id='mobile'>


                    <Grid item xs={12} md={6} lg={6}  className='technology-main-card-component_2 flex felx-col text-left w-[40vw]'>

                        <Grid container spacing={4} className='flex justify-center p-2.5' >
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to={'/bankig-and-financial'} className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main'>
                                                <img src={require("../icons/technology/apple (1) 2.png")} alt='iso tech' className='inline' />
                                            </div>
                                            <h4 className='mt-3'>IOS</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/international" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-flutter'>
                                                <img src={require("../icons/technology/flutter (3) 2.png")} alt='tech Flutter' className='inline'></img>
                                            </div>
                                            <h4>Flutter</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/politics" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main'>
                                                <img src={require("../icons/technology/react (3) 2.png")} alt='tech React Native' className='inline' />
                                            </div>
                                            <h4 className='mt-3'>React Native</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>


                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-xamarin'>
                                                <img src={require("../icons/technology/xamarin 2.png")} alt='tech Xamarin' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Xamarin</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-android'>
                                                <img src={require("../icons/technology/android (6) 2.png")} alt='tech Android' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Android</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-css'>
                                                <img src={require("../icons/mob-app/Vector (1).png")} alt='tech Windows' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Windows</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={6}  className='tech_main_box flex flex-col'>
                        <div className='tech-content'>
                            <h3 className='color-134CBC text-left text-3xl mb-5 font-[500]'>Mobile</h3>
                            <p className='text-left text-sm mb-5'>Mobile technology powers the modern world, allowing businesses and individuals to stay connected, informed, and productive from virtually anywhere. With the rise of powerful mobile operating systems and frameworks like iOS, Android, Flutter, React Native, and Xamarin, developing responsive and high-performing mobile applications has never been easier. These technologies enable the creation of apps that deliver seamless user experiences across multiple platforms, enhancing engagement and driving business growth.</p>
                        </div>

                        <div>
                        <FormButton
                                    className='technology_btn'
                                    label="Hire Resources"
                                >
                                <ArrowForwardIcon className='arrow_icons'/>
                                </FormButton>
                        </div>
                    </Grid>
                </Grid>

                {/* Database */}

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }}  className="tech_main_box p-2.5" id='database'>
                    <Grid item xs={12} md={6}  className='main_tech_content flex flex-col justify-center'>
                        <div className='tech-content'>
                            <h3 className='color-134CBC text-left text-3xl mb-5 font-[500]'>Database</h3>
                            <p className='text-left text-sm mb-5'>Databases are crucial for efficiently managing and storing data in modern applications. They allow businesses to securely store, access, and manipulate data in structured (SQL) or flexible (NoSQL) formats. Popular database technologies like MySQL, MongoDB, FireBase and DynamoDB offer unique advantages for different use cases whether you need relational data management, real-time data access or scalable storage. With features like data security, backup/recovery and performance optimization, databases support business operations, help with decision-making and drive innovation across industries.</p>
                        </div>

                        <div>
                        <FormButton
                                    className='technology_btn'
                                    label="Hire Resources"
                                >
                                <ArrowForwardIcon className='arrow_icons'/>
                                </FormButton>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className='technology-main-card-component'>

                        <Grid container spacing={4} className='flex justify-center p-2.5' >
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to={'/bankig-and-financial'} className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-sql'>
                                                <img src={require("../icons/technology/mysql 1.png")} alt='tech sql' className='inline' />
                                            </div>
                                            <h4 className='mt-3'>my sql</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/international" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-firebase'>
                                                <img src={require("../icons/technology/firebase 1.png")} alt='tech Firebase' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Firebase</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={4} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/politics" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-mongo'>
                                                <img src={require("../icons/technology/mongodb 2.png")} alt='tech MongoDB' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>MongoDB</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>


                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-dyna'>
                                                <img src={require("../icons/technology/20241202150015aws 2.png")} alt='tech DynamoDB' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>DynamoDB</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                    <div className='tech_img_container'>
                                            <div className='tech_img_main bg-android'>
                                                <img src={require("../icons/technology/sql-server.png")} alt='tech server' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Sql-server</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                    <div className='tech_img_container'>
                                            <div className='tech_img_main bg-android'>
                                                <img src={require("../icons/technology/oracle.png")} alt='tech Oracle' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Oracle</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                {/* e-commerce */}

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }} className='technology_main_card_2 tech_main_box p-2.5' id='e-comm'>


                    <Grid item xs={12} md={6} lg={6}  className='technology-main-card-component_2 flex flex-col text-left w-[40vw]'>

                        <Grid container spacing={4} className='flex justify-center p-2.5' >
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to={'/bankig-and-financial'} className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-dyna'>
                                                <img src={require("../icons/technology/codeigniter-svgrepo-com 1.png")} alt='tech Codeigniter' className='inline' />
                                            </div>
                                            <h4 className='mt-3'>Codeigniter</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3}>
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/international" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-dyna'>
                                                <img src={require("../icons/mob-app/joomla.png")} alt='tech Joomal' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Joomal</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/politics" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-firebase'>
                                                <img src={require("../icons/mob-app/magento.png")} alt='tech Magento' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Magento</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>


                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/e-commerce-sector" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-dyna'>
                                                <img src={require("../icons/mob-app/drupal.png")} alt='tech Drupal' className='inline'></img>
                                            </div>
                                            <h4 className='mt-3'>Drupal</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                        <div className='tech_img_container'>
                                            <div className='tech_img_main bg-dyna'>
                                                <img src={require("../icons/mob-app/wordpress_270832 1.png")} alt='tech Wordpress' className='inline'></img>
                                            </div>
                                                <h4 className='mt-3'>Wordpress</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <Item className="technology_cards felx align-middle justify-center flex-col" >
                                    {/* <Link to="/government" className='no-underline text-[#161011]'> */}
                                    <div className='tech_img_container'>
                                            <div className='tech_img_main bg-android'>
                                                <img src={require("../icons/technology/shopify.png")} alt='tech Shopify' className='inline'></img>

                                            </div>
                                            <h4 className='mt-3'>Shopify</h4>
                                        </div>
                                    {/* </Link> */}
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={6}  className='tech_main_box flex flex-col'>
                        <div className='tech-content'>
                            <h3 className='color-134CBC text-left text-3xl mb-5 font-[500]'>E-commerce technology</h3>
                            <p className='text-left text-sm mb-5'>E-commerce technology encompasses a wide range of tools and platforms that help businesses build, manage and scale their online stores. Platforms like Shopify, WordPress and Magento offer comprehensive solutions for setting up and running e-commerce websites, with robust features for product management, secure payments and customer engagement. For businesses looking for more flexibility, Drupal and CodeIgniter provide customizable options to develop tailored online solutions, while Joomla offers a user-friendly interface with e-commerce extensions. These technologies enable seamless integration, efficient order processing and optimized shopping experiences, ensuring businesses stay competitive in the ever-evolving digital landscape.</p>
                        </div>

                        <div>
                        <FormButton
                                    className='technology_btn'
                                    label="Hire Resources"
                                >
                                <ArrowForwardIcon className='arrow_icons'/>
                                </FormButton>
                        </div>
                    </Grid>
                </Grid>
            </Box>


        </>
    );
};

export default Infrastructure;
