import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';



const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '10px',
  border: ' 1.5px solid #C4D2EE',
  width: '30vw',
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Infrastructure = () => {
  return (

    <>

      <Box sx={{ width: '100%' }} className="it_infra">
        <h1 className='text-3xl font-semibold text-[#139C32] text-center mt-5 mb-5'>More about Mobile App Development Company</h1>
        <Grid container spacing={3} columns={16}  className='flex justify-center p-2.5 pt-5'>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]'>
                

                  <p > {/* Removed top margin */}
                  As a top-notch mobile app development company, we strategize your plan and utilize the needed approach to develop the perfect app for your business. Right from concept to development, we leverage the best of the latest tools, SDKs, and frameworks to ensure our mobile app development services cater to Windows, iOS, and Android.
                  </p>
                
              </div>
            </Item>
          </Grid>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]' >

                  <p > {/* Removed top margin */}
                  To cater to your different business demands, we develop mobile apps for several operating systems and mobile platforms. Android, iOS, or Windows, whatever it may be, we are here to aid you with our proficient mobile solutions. Our experts are our most valuable asset, who work dedicatedly 24/7 to keep us among the best mobile app development companies globally.
                  </p>

              </div>
            </Item>
          </Grid>

        </Grid>
        <Grid container spacing={3} columns={16}  className='flex justify-center p-2.5'>
          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]'>

                  <p > {/* Removed top margin */}
                  With our years of experience, strategies, and development process, we offer top-notch mobile app solutions that comprise advanced technologies. If you want to be quite unique among the crowd of other app development companies, you may leverage our sophisticated and extremely functional mobile app development.
                  </p>

              </div>
            </Item>
          </Grid>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]'>
                  <p > {/* Removed top margin */}
                  Leeway Softech stands out when it comes to high-quality solutions. With the abilities of on-board business analysts, designers, and app usability analysis skills, we deliver unparalleled mobile application development solutions that address real-life problems. We always discover the proper balance among cost, quality, and we can provide a superior solution.
                  </p>

              </div>
            </Item>
          </Grid>

        </Grid>
      </Box>


    </>
  );
};

export default Infrastructure;
