import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import blog1 from "../event/blog/emailmain.jpg"
import blog2 from "../event/blog/Flutter app development.png"
import blog3 from "../event/blog/chandrayaan.jpg"
import blog4 from "../event/blog/ground.png"
import blog5 from "../event/blog/Internal Communication Website.png"
import blog6 from "../event/blog/kartini.jpg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SEO from '../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '430px',
    width: '270px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Blog | Leeway Softech Pvt Ltd"
             description="Leeway Softech covers IT solutions in its entirity. It is leading in different Business Sectors, Government & Corporate Sectors that includes Banking, Educational, Hospitality and Politics with more than 1500 clients."
            />
            <div className='px-6 md:px-24'>
            <Grid container spacing={1} className="blog_page main_content " >
                <Grid items xs={12} sm={12} lg={12} className='pt-[2%] '>
                    <h1  className='color-134CBC text-center font-bold text-[32px] d-flex'>Blogs</h1>
                    <div  className='p-2.5 align-middle flex flex-col justify-center'>
                        <div className='text-center font-bold text-[32px] d-flex p-3'>Best Email Marketing Strategy in 2023</div>
                        <div className='flex align-middle justify-center  mb-2'>
                            <p className='ml-2.5 color-134CBC text-center '>Email Marketing</p>
                            <div className='blog_line'></div> {/* Add margin to the line */}
                            <p className='ml-1 color-134CBC'>Posted on 2nd December 2021 </p>
                        </div>

                        <div>
                            <img src={blog1} alt="Blog Visual" className='image_component'/>
                            
                            <div className='text-center p-[3%] ml-[6%] mr-[6%]'>
                            <p className='text-center'>
                            Email Marketing is a process to the sending electronics messages, specifically to certain group of peoples with the help of email. Eventually every email sent to the specific type of person or existing customer of particular company it could be considered as email marketing ...
                            <a href="/marketing-blog" className='blog_contain'>Read More<ArrowForwardIcon className='arrow_icons'/></a>
                            </p>
                            </div>
                        </div>
                           
                    </div>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%',marginTop:'-5%' }} >
                <Grid container spacing={3} columns={16} className='blog_card'>
                   
                    <Grid item xs={6}>
                        <Item >

                        <div className='text-center p-[2%]'>
                                <img src={blog2} alt='blog bulk'  className=''></img>
                                <p className='p-2 text-left'>25 Jane 2022</p>

                                <h5 className='text-left text-[#161011] p-2 font-semibold text-[14px]'>Biggest Advantage to Choose Flutter as Development compare to Other Languages?</h5>
                                <p className='text-left text-[#161011] text-sm p-1'>Flutter have hot reload feature make the app development much quicker. With Flutter...</p>
                                <a href="/flutter-blog" className='blog_arrow'>Read More<ArrowForwardIcon className='arrow_icons'/></a>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item >

                        <div className='text-center p-[2%]'>
                                <img src={blog3} alt='blog Chandrayaan' className=''></img>
                                <p className='p-2 text-left'>15 July 2023</p>

                                <h4 className='text-left text-[#161011] p-2 font-semibold text-[14px]'>Chandrayaan-3: How AI Is Shaping India s Moon Mission</h4>
                                <p className='text-left text-[#161011] text-sm p-1'>India s space agency, the Indian Space Research Organisation (ISRO), has launched Chandrayaan-3, the country....</p>
                                <a href="/chandrayaan-blog" className='blog_arrow'>Read More<ArrowForwardIcon className='arrow_icons'/></a>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item>

                            <div className='text-center p-[2%]'>
                                <img src={blog4} alt='IT Technologies' className=''></img>
                                <p className='p-2 text-left'>13 July 2023</p>

                                <h4 className='text-left text-[#161011] p-2 font-semibold text-[14px]'>10 Groundbreaking IT Technologies Shaping Ethiopia Future</h4>
                                <p className='text-left text-[#161011] text-sm p-1'>Are you ready to embark on a journey into the technological realm that is shaping Ethiopia...</p>
                                <a href="/it-technology" className='blog_arrow'>Read More<ArrowForwardIcon className='arrow_icons'/></a>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'} className='blog_card'>
                 

                    <Grid item xs={6} >
                        <Item >

                        <div className='text-center p-[2%]'>
                                <img src={blog5} alt='Way/Bulk SMS' className=''></img>
                                <p className='p-2 text-left'>18 July 2023</p>

                                <h4 className='text-left text-[#161011] p-2 font-semibold text-[14px]'>How 1-2 Way/Bulk SMS & Email, WhatsApp Chatbot System Helps Ethiopia Country to Improve Their Technology?</h4>
                                <p className='text-left text-[#161011] text-sm p-1'>Ethiopia, a country located in the North-Eastern...</p>
                                <a href="/bulk-sms-blog" className='blog_arrow'>Read More<ArrowForwardIcon className='arrow_icons'/></a>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item >

                        <div className='text-center p-[2%]'>
                                <img src={blog6} alt='Flutter as Development' className=''></img>
                                <p className='p-2 text-left'>27 Jan 2021</p>

                                <h4 className='text-left text-[#161011] p-2 font-semibold text-[14px]'>Biggest Advantage to Choose Flutter as Development compare to Other Languages?</h4>
                                <p className='text-left text-[#161011] text-sm p-1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <a href="/read-more" className='blog_arrow'>Read More<ArrowForwardIcon className='arrow_icons'/></a>
                            </div>
                        </Item>
                    </Grid>

                </Grid>
            </Box>
            </div>
        </>
    );
}
