import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import audience from "../../icons/target-audience (1) 1.png"
import line from "../../icons/Line-black2.png"
import icon from "../../icons/Icon.png"
import improve from "../../icons/efficiency 1.png"
import customer from "../../icons/customer.png"
import Additonal_info from "./Additional_info"
import SEO from '../../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '50px',
    width: '250px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="E-Commerce | Leeway Softech Pvt Ltd"
             description="E-commerce is the buying and selling of goods and services over the internet. It is a rapidly growing industry. Read on to know how you can leverage the expertise of Leeway Softech in e-commerce domain"
            />
            <Grid container spacing={1} className="e-commerce main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>E-Commerce</h1>
                        <p className='text-lg font-normal'>E-commerce is the buying and selling of goods and services over the internet. It is a rapidly growing industry, with global sales expected to reach $4.9 trillion in 2021.</p>
                    </div>
                </Grid>
            </Grid>
        <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
        <div className='text-3xl font-semibold color-134CBC text-center mb-5'>E-Commerce</div>
                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Online stores</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies provide a platform for businesses to sell their products or services online. This includes everything from setting up the store to managing inventory and shipping.
                                    </p>
                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Payment processing</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies offer a variety of payment processing options, such as credit cards, debit cards, and PayPal. This makes it easy for customers to pay for their purchases online.
                                    </p>

                            </div>
                        </Content>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>
                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Marketing and advertising</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies help businesses to reach their target audience through online marketing and advertising. This includes search engine optimization (SEO), pay-per-click (PPC) advertising, and social media marketing.
                                    </p>

                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Customer support</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies provide customer support to help customers with their orders, returns, and other issues. This ensures that customers have a positive experience shopping online.
                                    </p>
                            </div>
                        </Content>
                    </Grid>
                </Grid>
        </Box>
         


                <Box  className='pt-[2%]'>
                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5 mb-5'>Benefits of E-Commerce</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                            <div className="flex justify-center align-middle" >
                                <div>

                                    <img src={audience} alt='audience' ></img>
                                </div>
                                <p className='mr-5'>Reach a wider audience</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className="flex justify-center align-middle">
                                <div>
                                    <img src={line} alt='line' ></img>
                                </div>
                                <p className='mr-5'>Reduce costs</p>


                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className="flex justify-center align-middle">
                                <div>

                                    <img src={icon} alt='icon of Increase sales' ></img>
                                </div>
                                <p className='mr-5'>Increase sales</p>


                            </div>
                        </Item>
                    </Grid>

                </Grid>


                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                            <div className="flex justify-center align-middle">
                                <div >

                                    <img src={improve} alt='improve'></img>
                                </div>
                                <p className='mr-5'>Improve efficiency</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className="flex justify-center align-middle">
                                <div>

                                    <img src={customer} alt='customer'></img>
                                </div>
                                <p className='mr-5'>Lorem</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

            </Box>
        <Additonal_info/>
        </>
    );
}
