// // import React from 'react'

// // function OmniChannel() {
// //   return (
// //     <div>OmniChannel</div>
// //   )
// // }

// // export default OmniChannel

import React, { useEffect } from "react";
import "../App.css";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SEO from "../components/SEO";
import FormButton from "../components/FormButton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Element } from "react-scroll";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  // border: ' 1.5px solid #C4D2EE',
  height: "130px",
  width: "11vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const OmniChannel = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash; // Get the current hash
    if (hash) {
      const section = document.getElementById(hash.substring(1)); // Remove the '#' and get the element
      if (section) {
        const offset = 100; // Adjust this value based on your header height
        const elementPosition =
          section.getBoundingClientRect().top + window.scrollY; // Get the position of the section
        const offsetPosition = elementPosition - offset; // Calculate the position with offset

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth", // Smooth scroll
        });

        // After scrolling, remove the hash from the URL without reloading the page
        navigate(location.pathname, { replace: true });
      }
    }
  }, [location, navigate]);

  return (
    <>
      <SEO
        title="Omni Channel | Leeway Softech Pvt Ltd"
        // description="PBX services are an excellent way to enhance your business's communication and productivity. Discover the various types of PBX services available at Leeway Softech"
      />
      <Grid container spacing={1} className="platform_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div>
            <h1 className="text-white text-center text-[32px] d-flex m-auto">
              Omni Channel Platform
            </h1>
            <p
              className="text-white text-center m-auto"
              style={{ width: "70%" }}
            >
              At Leeway Softech Pvt. Ltd., we specialize in bridging the gap
              between technology and government entities, with a proven track
              record of successful collaborations. With our expertise and
              experience, we are committed to delivering top-notch
            </p>
          </div>
        </Grid>
      </Grid>

      <Box className="omni_channel w-[100%]">

      <img  style={{maxHeight: "80vh"}} src={require("../icons/header/omni_channel.png")} />

      </Box>
    </>
  );
};

export default OmniChannel;

// import React from "react";
// import campaigns from "../media/Omni Channel/campaigns.png";
// import engagment from "../media/Omni Channel/engagment.png";
// import message from "../media/Omni Channel/message.png";

// import twowayssms from "../media/Omni Channel/2wayssms.png";
// import analytics from "../media/Omni Channel/analytics.png";
// import apis from "../media/Omni Channel/apis.png";
// import chatboat from "../media/Omni Channel/chatboat.png";
// import email from "../media/Omni Channel/email.png";
// import notification from "../media/Omni Channel/notification.png";
// import rcs from "../media/Omni Channel/rcs.png";
// import s2 from "../media/Omni Channel/s2.png";
// import virtuals_numbers from "../media/Omni Channel/virtuals_numbers.png";
// import voice from "../media/Omni Channel/voice.png";
// import whatsapp from "../media/Omni Channel/whatsapp.png";

// function OmniChannel() {
//   return (
//     <section class="main-container">
//       <div class="main">
//         <div class="big-circle">
//           <div class="icon-block">
//             <img src={message} alt="game dev icon" />
//           </div>
//           <div class="icon-block">
//             <img src={campaigns} alt="web design icon" />
//           </div>
//           <div class="icon-block">
//             <img src={engagment} alt="game design icon" />
//           </div>
//           <div class="icon-block">
//             <img
//               src={notification}
//               alt="notification"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//                 src={analytics}
//               alt="analytics"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//                 src={analytics}
//               alt="analytics"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//                 src={analytics}
//               alt="analytics"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//                 src={analytics}
//               alt="analytics"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//                 src={analytics}
//               alt="analytics"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//                 src={analytics}
//               alt="analytics"
//             />
//           </div>
//         </div>

//         <div class="circle">
//           <div class="icon-block">
//             <img
//               src={email}
//               alt="email"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//               src={rcs}
//               alt="rcs"
//             />
//           </div>
//           <div class="icon-block">
//             <img
//               src={chatboat}
//               alt="chatboat"
//             />
//           </div>
//         </div>
//         <div class="center-logo">
//           <img
//             src={s2}
//             alt="s2"
//           />
//         </div>
//       </div>
//     </section>
//   );
// }

// export default OmniChannel;

// import React, { useEffect } from "react";
// import "../App.css";
// import Grid from "@mui/material/Grid2";
// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import SEO from "../components/SEO";
// import FormButton from "../components/FormButton";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import { Element } from "react-scroll";

// const Item = styled(Box)(({ theme }) => ({
//   ...theme.typography.body2,
//   borderRadius: "10px",
//   height: "130px",
//   width: "11vw",
//   backgroundColor: "#fff",
//   padding: theme.spacing(1),
// }));

// const OmniChannel = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const hash = location.hash; // Get the current hash
//     if (hash) {
//       const section = document.getElementById(hash.substring(1)); // Remove the '#' and get the element
//       if (section) {
//         const offset = 100; // Adjust this value based on your header height
//         const elementPosition =
//           section.getBoundingClientRect().top + window.scrollY; // Get the position of the section
//         const offsetPosition = elementPosition - offset; // Calculate the position with offset

//         window.scrollTo({
//           top: offsetPosition,
//           behavior: "smooth", // Smooth scroll
//         });

//         // After scrolling, remove the hash from the URL without reloading the page
//         navigate(location.pathname, { replace: true });
//       }
//     }
//   }, [location, navigate]);

//   return (
//     <>
//       <SEO
//         title="Omni Channel | Leeway Softech Pvt Ltd"
//       />
//       <Grid container spacing={1} className="platform_page main_content">
//         <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
//           <div>
//             <h1 className="text-white text-center text-[32px] d-flex m-auto">
//               Omni Channel Platform
//             </h1>
//             <p
//               className="text-white text-center m-auto"
//               style={{ width: "70%" }}
//             >
//               At Leeway Softech Pvt. Ltd., we specialize in bridging the gap
//               between technology and government entities, with a proven track
//               record of successful collaborations. With our expertise and
//               experience, we are committed to delivering top-notch
//             </p>
//           </div>
//         </Grid>
//       </Grid>

//       <Box className="omni_channel w-[100%]" style={{ position: "relative" }}>
//         {/* First Image */}
//         <img
//           style={{ maxHeight: "80vh", width: "100%", objectFit: "contain" }}
//           src={
//             "https://portal.leewaysofttech.com/image/20250408125554omni-channel-circle1.png"
//           }
//           alt="Omni Channel Circle 1"
//         />
        
//         {/* Overlay Image */}
//         <img
//           style={{
//             position: "absolute",
//             top: "50%",  // You can adjust this for vertical positioning
//             left: "50%", // You can adjust this for horizontal positioning
//             transform: "translate(-50%, -50%)",  // Center the overlay image
//             maxHeight: "60vh",  // Control the size of the overlay
//             width: "auto",  // Maintain aspect ratio
//             objectFit: "contain",  // Ensure the image fits without distortion
//             zIndex: 1,  // Ensure this image is on top of the first one
//           }}
//           src={
//             "https://portal.leewaysofttech.com/image/20250408125603omni-channel-circle2.png"
//           }
//           alt="Omni Channel Circle 2"
//         />
//       </Box>
//     </>
//   );
// };

// export default OmniChannel;

