import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '12px',
  boxShadow:'16px solid #0E1F3529',
  padding:'17px',

  height:'150px',
  width:'213px',
  backgroundColor: '#fff', 
  // padding: theme.spacing(1),
//   textAlign: 'left',
  color: theme.palette.text.secondary,
}));


export default function RowAndColumnSpacing() {
  return (
    <>
    <Grid container className='Background pt-[3%] px-6 md:px-24'>
    {/* <BackgroundContainer > */}
 <Box sx={{flexGrow: 1 }}>
  
    <div className='color-134CBC text-center text-[32px] font-semibold mt-3'>Testimonial</div>
    <Grid container spacing={2} columns={16}  className='flex justify-center mt-10 mb-10'>
      <Grid item xs={3}>
        <Item>
          <div  className='flex align-middle justify-center text-center'>
            <div>
              <div  className='text-[#139C32] text-[32px] font-semibold'>400+</div>
              <p  className='text-[#161011] font-medium text-[20px] mt-2 break-words w-full'>Projects Completed</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
              <div  className='color-134CBC text-[32px] font-semibold'>250+</div>
              <p className='text-[#161011] font-medium text-[20px] mt-2 break-words w-[166px]'>Apps Developed</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
              <div  className='text-[#139C32] text-[32px] font-semibold' >300+</div>
              <p className='text-[#161011] font-medium text-[20px] mt-2 break-words w-[166px]'>Websites Designed</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div style={{ display: 'flex',alignItems: 'center',justifyContent:'center',textAlign:'center' }}>
            <div>
              <div  className='color-134CBC text-[32px] font-semibold'>600+</div>
              <p className='text-[#161011] font-medium text-[20px] mt-2 break-words w-[166px]'>Happy Customers</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
              <div  className='text-[#139C32] text-[32px] font-semibold'>1200+</div>
              <p className='text-[#161011] font-medium text-[20px] mt-2 break-words w-[166px]'>CPaaS Customers</p>
            </div>
          </div>
        </Item>
      </Grid>
    </Grid>
  </Box>
  {/* </BackgroundContainer> */}
  </Grid>
    </>
   
  
  );
}
