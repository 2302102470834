import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import account from "../../icons/mob-app/accounting-analyst 1.png"
import marketing from "../../icons/mob-app/marketing.png"
import hr from "../../icons/mob-app/hr-manager 1.png"
import menufacture from "../../icons/mob-app/manufacturing 1.png"
import design from "../../icons/Web Design.png"
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    // height: '350px',
    width: '300px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
        

        <Box >
            <h1 className='text-3xl font-semibold text-[#139C32] text-center mb-5'>Features of ERP Solutions</h1>
            <Grid container spacing={2} columns={16}  className="add_card justify-center p-2.5">
      <Grid item xs={6} >
        <Item className="add_sub_card">
         
            <div className='text-center'>
                <img src={account} alt='account' className='inline mb-5'></img>
                <h3 className='mb-2   text-xl font-medium'>Accounting</h3>
          
              <p  className='text-[#161011] text-sm'>ERP systems can automate accounting tasks such as billing, accounts payable, and accounts receivable and create marketing campaigns.</p>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="add_sub_card">
       
            <div className='text-center'>
                <img src={marketing} alt='marketing' className='inline mb-5'></img>
                <h3 className='mb-2   text-xl font-medium'>Sales and marketing</h3>
           
              <p className='text-[#161011] text-sm'>ERP systems can help businesses manage customer relationships, track sales leads, and create marketing campaigns.</p>
          </div>
        </Item>
      </Grid>

      <Grid item xs={6} >
      <Item className="add_sub_card">
       
            <div className='text-center'>
                <img src={hr} alt='hr' className='inline mb-5'></img>
                <h3 className='mb-2   text-xl font-medium'>Human resources</h3>
           
              <p className='text-[#161011] text-sm'>ERP systems can help businesses manage employee records, track time and attendance, and process payroll  and create marketing campaigns. </p>
          </div>
        </Item>
      </Grid>
  </Grid>

  <Grid container spacing={2} columns={16} justifyContent="center" padding={'10px'}>
      <Grid item xs={6} >
      <Item className="add_sub_card">
       
            <div className='text-center'>
                <img src={menufacture} alt='gsc' className='inline mb-5'></img>
                <h3 className='mb-2   text-xl font-medium'>Manufacturing</h3>
           
              <p className='text-[#161011] text-sm'>ERP systems can help businesses manage the manufacturing process from product design to production to distribution.</p>
          </div>
        </Item>
      </Grid>

      <Grid item xs={6} >
      <Item className="add_sub_card">
       
            <div className='text-center'>
                <img src={design} alt='gsc' className='inline mb-5'></img>
                <h3 className='mb-2   text-xl font-medium'>Lorem Ipsum</h3>
           
              <p className='text-[#161011] text-sm'>This includes everything from creating the website to integrating payment processing and shipping. and create marketing campaigns.</p>
          </div>
        </Item>
      </Grid>
  </Grid>

        </Box>

        </>
    );
}
