// import React from 'react'

// function MediaConvergence () {
//   return (
//     <div>MediaConvergence </div>
//   )
// }

// export default MediaConvergence

import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import SEO from "../components/SEO";
import Event_All_year from "../components/Event_All_year";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

function MediaConvergence() {
  const [active, setActive] = useState("2023");

  const onClickActive = (btnName) => {
    setActive(btnName);
  };
  return (
    <div className="it_infra">
      <SEO
        title="IMC Event | Leeway Softech Pvt Ltd"
        description="Leeway Softech is participated in India Mobile Congress, the premier event showcasing the future of technology. At the 'India mobile congress' exhibition, they showcased their recent innovations in their domain."
      />
      <h1
        style={{ color: "#161011", textAlign: "center" }}
        className="text-[#161011] text-center text-[32px] font-bold mt-5"
      >
        Media
      </h1>
      {/* <Event_All_year onClickActive={onClickActive} active={active} /> */}

      <Grid
        container
        spacing={3}
        columns={16}
        className="flex justify-center p-2.5"
      >
        <Grid items xs={12} sm={12} lg={6}>
          {/* <Content className="sub_card"> */}
          <iframe
            width="400"
            height="225"
            src="https://www.youtube.com/embed/_3d5XAHrang"
            title="Event of Ibex"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          {/* </Content> */}
        </Grid>

        <Grid items xs={12} sm={12} lg={6}>
          {/* <Content className="sub_card"> */}
          <iframe
            width="400"
            height="225"
            src="https://www.youtube.com/embed/obzodrlrViM"
            title="Event of Ibex"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          {/* </Content> */}
        </Grid>
        <Grid items xs={12} sm={12} lg={6}>
          {/* <Content className="sub_card"> */}
          <iframe
            width="400"
            height="225"
            src="https://www.youtube.com/embed/0GHS93yDM8I"
            title="Event of Ibex"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          {/* </Content> */}
        </Grid>

        <Grid items xs={12} sm={12} lg={6}>
          {/* <Content className="sub_card"> */}
          <iframe
            width="400"
            height="225"
            src="https://www.youtube.com/embed/G8ERIEbuuSI"
            title="Event of Ibex"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          {/* </Content> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default MediaConvergence;
