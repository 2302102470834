import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import SEO from "../components/SEO"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Card = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '85px',
    width: '192px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));



export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Joomla Framework | Leeway Softech Pvt Ltd"
             description="Leeway offers e-commerce, educational, and banking services as a part of their web application development portfolio using Joomla CMS. They also offer services to revamp your website that speaks your brand"
            />
            <Grid container spacing={1} className="joomla_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
    <div className='text-white text-center'>
    <h1 className='text-3xl font-semibold'>Joomla</h1>
                        <p className='text-lg font-normal'>Joomla Development Services Get the best out of responsive, resistant and safe open source CMS. Joomla is an open source Content Management System (CMS) to guide customers to develop websites and numerous other dominant online apps. It is available to everyone very easily and free of cost. Joomla enables everyone to redesign and deal with their own website practically. It contains simple to-utilize features and composed as a part of PHP to handle the content on the website.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra pr-[2%] pl-[2%] pt-[2%]">
                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>OUR AREAS TO SERVE Joomla WEBSITE DEVELOPMENT SERVICES</div>
                <Grid container spacing={5} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Website Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla eCommerce Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla CMS Solution</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Portal Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Design Integration</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Custom Template and Theme Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className=""><div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla site Maintenance and Care</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Custom Modules Development</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>


                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5'>What makes us the ideal service provider?</div>
                <Grid container spacing={5} columns={16} paddingTop={5}
                   
                   className='felx justify-center align-middle' >
                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Maintenance of Joomla extension</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla template installations and customization</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Custom Template and Theme Development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Custom Template and Theme Development</p>
                            </div>
                        </Card>
                    </Grid>
                    
                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Portal Development</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Design Integration</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Custom Template and Theme Development</p>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={6} >
                        <Card className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Joomla Custom Template and Theme Development</p>
                            </div>
                        </Card>
                    </Grid>
                </Grid>

                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>Why Choose Us For the Joomla Web Development?</div>
                <Grid
                    container
                    spacing={2}
                    paddingTop={5}
                   
                    className='felx justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                       
                        <div className='icons flex align-middle mb-5'>
                            <img src={icon} alt='Speedyicon' className='mr-2.5' />
                            <div>
                                <p>Speedy development process</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Enhancedicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Enhanced admin panel
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Upgradeicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Fast Upgrade
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='helpicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Integrated help system
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='friendlyicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Mobile-friendly
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='searchicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Smart search
                                </p></div>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='fullyicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Easily upgradable & fully compatible plug-ins
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Multilingualicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Multilingual
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='collaborationicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Improved collaboration with better user-account & schedule management
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Passionateicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Passionate Community
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='tocreateicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Assist tocreate custom content types, starting from basic to advanced
                                </p></div>
                        </div>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
