import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import SEO from "../components/SEO";

const Item = styled(Paper)(({ theme, bgColor, bgImage }) => ({
  backgroundColor: bgColor || "#fff",
  width: "360px",
  height: "150px",
  borderRadius: "20px",
  backgroundImage: bgImage ? `url(${bgImage})` : "none",
  backgroundSize: "cover",
  backgroundPosition: "center",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

export default function ResponsiveGrid() {
  const cardData = [
    {
      backgroundColor: "#FAEFFF",
      image: require("../media/GITEX_Global_logo.png"),
    },
    {
      backgroundColor: "#C9E5FF",
      image: require("../media/Gesia-Logo-2015.png"),
    },
    { backgroundColor: "#FFF1F0", image: require("../media/image 38.png") },
    { backgroundColor: "#EFF2FF", image: require("../media/Group 56.png") },
    { backgroundColor: "#EFFBFF", image: require("../media/Group.png") },
    {
      backgroundColor: "#FFF9EF",
      image: require("../media/Vibrant_Gujarat_2022_Logo 1.png"),
    },
    { backgroundColor: "#FFF1F0", image: require("../media/image 37.png") },
    {
      backgroundColor: "#EFF3FF",
      image: require("../media/india-africa-ict-expo-removebg-preview 1.png"),
    },
    {
      backgroundColor: "#FFF1F0",
      image: require("../media/logo-cio-2023-color 1.png"),
    },
    {
      backgroundColor: "#EDEDED",
      image: require("../media/56a9db14e520c-ibex 1.png"),
    },
    { backgroundColor: "#FFF1F0", image: require("../media/image 39.png") },
    {
      backgroundColor: "#E7FFFA",
      image: require("../media/singapore-logo-removebg-preview 1.png"),
    },
    {
      backgroundColor: "#B9AFFF",
      image: require("../media/gff_event.png"),
    },
  ];

  const navigate = useNavigate();

  const handleImageClick = (index) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (index === 4) {
      navigate("/imc-event");
    } else if (index === 6) {
      navigate("/ethiopia-event");
    } else if (index == 1) {
      navigate("/gesia-event");
    } else if (index == 11) {
      navigate("/communicationAsia-event");
    } else if (index == 2) {
      navigate("/convergence-event");
    } else if (index == 0) {
      navigate("/gitex");
    } else if (index == 9) {
      navigate("/ibex");
    } else if (index == 12) {
      navigate("/gff");
    }
  };
  return (
    <>
      <SEO
        title="Our Media | Leeway Softech Pvt Ltd"
        description="Leeway Softech develops high-quality websites for government organizations, following best practices and delivering their best in every project they take up."
      />
      <Grid
        container
        className="BackgroundContainer main_content"
        style={{ width: "100%", paddingTop: "3%" }}
      >
        <Box sx={{ flexGrow: 1 }} className=" mb-10 px-6 md:px-24">
          <h2 className="color-134CBC text-center text-3xl font-semibold">
            Showcasing Our Presence at Prestigious Corporate Events
          </h2>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 3, sm: 6, md: 12 }}
            className="flex justify-center pt-[2%]"
          >
            {cardData.map((item, index) => {
              console.log(index); // Log the index to the console
              return (
                <Grid
                  className="certificate_card"
                  key={index}
                  item
                  xs={2}
                  sm={4}
                  md={4}
                >
                  <Item bgColor={item.backgroundColor} className="item">
                    <img
                      src={item.image}
                      onClick={() => handleImageClick(index)}
                      alt={`Card image ${index + 1}`}
                      className="inline"
                      style={{
                        objectFit: "cover",
                        alignItems: "center",
                        cursor: "pointer",
                        padding:
                          index === 6
                            ? "10%"
                            : index === 0
                            ? "3%"
                            : index === 8
                            ? "7%"
                            : null,
                        height:
                          index === 1
                            ? "100px"
                            : index === 2
                            ? "100px"
                            : index === 5
                            ? "100px"
                            : index === 6
                            ? "auto"
                            : index === 7
                            ? "100px"
                            : index === 8
                            ? "auto"
                            : null,
                      }}
                    />
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {/* </BackgroundContainer> */}
      </Grid>
    </>
  );
}
