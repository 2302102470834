import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Software_app_development from "./Software_app_development"
import SEO from '../../components/SEO';


const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '10px',
  border: ' 1.5px solid #C4D2EE',
  width: '30vw',
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Infrastructure = () => {
  return (

    <>
      <SEO title="Software Development Company | Leeway softech Pvt Ltd"
             description="Leeway Softect offers Software as a Service (SaaS) and provides a wide range of software development services in Mumbai, Ahmedabad, Delhi, and Bhavnagar."
            />
      <Grid container spacing={1} className="soft_page main_content">
        <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
          <div className='text-white text-center'>
            <h1 className='text-3xl font-semibold'>Software Development</h1>
            <p className='text-lg font-normal'>Empower your business growth and creativity with our custom software development service. Leeway Softech is offering end-to-end software development solutions to leading enterprises worldwide. Our custom software development services exploit the technical expertise of our developers, who are empowered with leading-edge tools and technology, to provide customized software of different flavours, such as business intelligence solutions, e-business solutions, and CRM solutions. With our precise designs, our services can significantly save the yearly budget of any enterprise by streamlining the systems.</p>
          </div>
        </Grid>
      </Grid>

      <Box  className="it_infra pt-[2%] w-full  px-6 md:px-24">
        <div className='text-3xl font-semibold color-134CBC text-center'>Domains We Serves</div>
        <Grid container spacing={3} columns={16} className='flex justify-center p-2.5 pt-5'>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]'>
                <p > 
                  Customized software solution
                </p>
              </div>
            </Item>
          </Grid>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]' >

                <p > 
                  Web-based applications
                </p>

              </div>
            </Item>
          </Grid>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]' >
                <p > 
                  Mobile App Development (Android, iOS, and Windows)
                </p>

              </div>
            </Item>
          </Grid>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]' >
                <p > 
                  Website Design, Development, and Maintenance
                </p>
              </div>
            </Item>
          </Grid>

          <Grid items xs={6}>
            <Item className='sub_card'>
              <div className='icons text-center p-[5%]' >
                <p > 
                  We have in-depth know-how in client and server-side development in XML, Android, Java,etc..
                </p>

              </div>
            </Item>
          </Grid>

        </Grid>
      </Box>

      <Software_app_development />
    </>
  );
};

export default Infrastructure;
