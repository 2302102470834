import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SEO from '../../components/SEO';


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="Open Source | Leeway Softech Pvt Ltd"
             description="Leeway Softech provides a diverse array of Open Source solutions, including software development, hosting, and support. Reach out to us today to learn more."
            />
            <Grid container spacing={1} className="open_source main_content">
                <Grid item xs={12} sm={12} lg={12} style={{ padding: '5%'}}>
                    <div style={{ }}>
                        <h1 style={{ color: 'white', textAlign: 'center' }}>Open Source</h1>
                        <p style={{ color: 'white', textAlign: 'center' }}>Our expertise and passion lie in providing innovative IoT solutions that empower businesses to harness the true potential of a connected world.</p>
                    </div>
                </Grid>
            </Grid>


            <Box sx={{ width: '100%' }} className="it_infra">
                <div style={{ color: '#134CBC', textAlign: 'center' }}>Lorem Ipsum Is Dummy Text Here Only </div>
                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>

                <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Cost savings</h3>
                                    <p style={{}}>
                                    Open Source software is often free or very low-cost. This can save you a significant amount of money on software development and licensing costs.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Quality</h3>
                                    <p style={{}}>
                                    Open Source software is developed by a large community of developers, so it is often very high-quality
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Flexibility</h3>
                                    <p style={{}}>
                                    Open Source software is highly customizable, so you can tailor it to your specific needs.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Security</h3>
                                    <p style={{}}>
                                    Open Source software is often very secure, as it is constantly being reviewed and updated by the community.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Choose the right service</h3>
                                    <p style={{}}>
                                    There are many different OpenSource services available, so it is important to choose one that fits your specific needs.
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Make sure the service is well-maintained</h3>
                                    <p style={{}}>
                                    The OpenSource software you choose should be well-maintained by the community. This will ensure that it is up-to-date and secure.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Get support</h3>
                                    <p style={{}}>
                                    If you need help with your OpenSource software, make sure the service you choose offers support.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                  
                </Grid>

            </Box>


        </>
    );
};

export default Infrastructure;
