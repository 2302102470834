import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import icon from "../../icons/mob-app/Vector (2).png"




const Infrastructure = () => {
    return (
       
    <>

    <Box className="">
    <h1 className='text-3xl font-semibold text-center color-134CBC mt-5'>Why Choose Us for Development?</h1>
    <Grid 
            container 
            spacing={5}
            paddingTop={4}
            className='flex justify-center align-middle'
        >
            <Grid item xs={12} md={6} lg={6} className='component'>
                {/* Your icons and text here */}
                <div className='icons flex align-middle mb-5' >
                    <img src={icon} alt='Customicon' className='mr-2.5 h-5' />
                    <div>
                        <p>Custom Web Development</p>
                    </div>
                </div>
        
        <div className='icons flex align-middle mb-5' >
            
               <img src={icon} alt='Animationsicon' className='mr-2.5 h-5'></img>
               <div>
                <p > 
                Customized HTML 5 Animations and Gaming
                </p></div>
        </div>
        
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='Gamingicon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             E-commerce development
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='informationalicon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             Complex informational sites
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='Membershipicon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             Membership/Subscription sites
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='sitesicon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             Complex e-commerce
             </p></div>
     </div>
        
        </Grid>  


        <Grid item xs={12} md={6} lg={6} className='component'>
        <div className='icons flex align-middle mb-5' >
            
               <img src={icon} alt='Deveicon' className='mr-2.5 h-5'></img>
               <div>
                <p > 
                Front-End Development
                </p></div>
        </div>
       
        <div className='icons flex align-middle mb-5' >
            
               <img src={icon} alt='drivenicon' className='mr-2.5 h-5'></img>
               <div>
                <p > 
                Community-driven sites
                </p></div>
        </div>
     
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='browsericon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             Cross-browser, cross-platform, and cross-device functionality
             </p></div>
     </div>

        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='deviceicon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             Multi-functional web portals
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='platformsicon' className='mr-2.5 h-5'></img>
            <div>
             <p > 
             Easy migration to multiple mobile platforms
             </p></div>
     </div>
       
        </Grid>  
      </Grid>
    </Box>
   
    
    </>
    );
};

export default Infrastructure;
