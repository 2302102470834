import React from 'react';
import "../../App.css"
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import sms from "../../images/sms.png"
import whatsapp_api from "../../images/whatsapp_api.png"
import icon from "../../icons/mob-app/Vector (2).png"
import Subtract from "../../icons/mob-app/Subtract.png" 
import Bulk_services from "./Bulk_services"
import Bulk_card from "./Bulk_card"
import SEO from '../../components/SEO';


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="Bulk sms Provider | Leeway Softech Pvt Ltd"
             description="Leeway is a Bulk SMS service provider, offering a range of solutions including messaging services, text messaging with OTP, two-way SMS, bulk SMS, and more."
            />
            <Grid container spacing={1} className="bulk_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Bulk SMS</h1>
                        <p className='text-lg font-normal'>Engage your audience promptly with our smart and unified bulk messaging solutions. We are crafting each feature to suit your unique business needs. Your need and our services for the same made us the best CPaaS solution provider globally. Suppose you want to promote your brand smartly. In that case, we are the best CPaaS, OTP, text, SMS online, OTP SMS service, text messaging, SMS, text marketing, and gateway login provider. Ultimate solutions for boosting a business are our prime motto.</p>
                    </div>
                </Grid>
            </Grid>


            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24" >


                <div className='text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5'>We are Devivering all that you Need for betters SMS Communication</div>
                <Grid container className='bg-[#F2F5FB]' spacing={{ xs: 2, md: 3, lg:3 }} >
                    <Grid size={{ xs: 12, md: 4, lg:4}}>
                        <div className=' w-full'>
                        <img src={require("../../images/sms.png")} className=' object-contain' alt="Phone" /></div>
                    </Grid>

                    <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex flex-col justify-center px-12'>   

                    <h3 className='mb-2 text-left leading-[1.5] text-xl font-medium'>
                            Make the campaign personal and engage with the user.<br />
                            HERE 2 TYPE OF WHATSAPP BUSINESS PROFILE:
                        </h3>
                            <div className='icons flex '>
                                <img src={icon} alt='icon' className='mr-2.5 h-5' />

                                <p>Effortlessly add and remove opt-outs straight from the portal.</p>

                            </div>
                            <div className='icons flex '>
                                <img src={icon} alt='icon' className='mr-2.5 h-5' />

                                <p>Type and send SMS in multiple languages with our transliteration tool.</p>

                            </div>
                            <div className='icons flex '>
                                <img src={icon} alt='icon' className='mr-2.5 h-5' />

                                <p>Enable a specific touch to your SMS by adding names, IDs, and other required details without any plug-ins.</p>

                            </div><div className='icons flex '>
                                <img src={icon} alt='icon' className='mr-2.5 h-5' />

                                <p>Our SMS solution is a one-stop shop that takes care of all bulk business SMS needs for an enterprise.</p>

                            </div>
                            <div className='icons flex '>
                                <img src={icon} alt='icon' className='mr-2.5 h-5' />

                                <p>Transparency is enhanced with the billing platform charging solely on the basis of messages delivered to handsets.</p>

                            </div>
                    </Grid>
                </Grid>


                <Grid container className='bg-[#F2F5FB] mt-5' spacing={{ xs: 2, md: 3, lg:3 }} >
                <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex flex-col justify-center px-12'>   
                        <h3 className='mb-2 text-left leading-[1.5] text-xl font-medium'>
                        Business Advantages
                        </h3>
                        <div className='icons flex '>
                            <img src={icon} alt='icon' className='mr-2.5 h-5' />

                            <p>Highest level of security</p>

                        </div><div className='icons flex '>
                            <img src={icon} alt='icon' className='mr-2.5 h-5' />

                            <p>One-stop shop for both applications/API integration and simple connectivity needs.</p>

                        </div>

                        <div className='icons flex '>
                            <img src={icon} alt='icon' className='mr-2.5 h-5' />

                            <p>Customization as per customer business requirements</p>

                        </div>

                        <div className='icons flex '>
                            <img src={icon} alt='icon' className='mr-2.5 h-5' />

                            <p>Faster delivery of the message</p>

                        </div>

                        <div className='icons flex '>
                            <img src={icon} alt='icon' className='mr-2.5 h-5' />

                            <p>Control costs with a flexible pricing model and volume-based discounting options.</p>

                        </div>

                    </Grid>

                    <Grid size={{ xs: 12, md: 4, lg:4}}  className='flex justify-center'>
                    <TableContainer className='w-[100%] overflow-x-auto bg-[#fff]'>
                <Table  className='border-collapse'>
                    <TableHead>
                        <TableRow className='table' >
                            <TableCell className='font-bold text-sm'>Features</TableCell>
                            <TableCell className='font-bold text-sm'>Bulk SMS</TableCell>
                            <TableCell className='font-bold text-sm'>Interactive SMS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className=' text-sm'>Communication</TableCell>
                            <TableCell className=' text-sm'>One Way</TableCell>
                            <TableCell className=' text-sm'>Two Way</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className=' text-sm'>Cross Operator</TableCell>
                            <TableCell>
                            <div className='icons flex text-center' >
                                <img src={icon} alt='icon table' className='mr-2.5 h-5' />
                            </div>
                            </TableCell >
                            <TableCell className=' text-sm'>
                            <div className='icons flex text-center'>
                                <img src={icon} alt='table cell' className='mr-2.5 h-5' />
                            </div>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className=' text-sm'>Storage</TableCell>
                            <TableCell>
                            Store & Forward
                            </TableCell >
                            <TableCell className=' text-sm'>
                            <div className='icons flex text-center'>
                                <img src={Subtract} alt='subtract icon'/>
                            </div>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className=' text-sm'>Customization</TableCell>
                            <TableCell>
                            <div className='icons flex text-center'>
                                <img src={icon} alt='tablerow icon' className='mr-2.5 h-5' />
                            </div>
                            </TableCell >
                            <TableCell className=' text-sm'>
                            <div className='icons flex text-center'>
                                <img src={Subtract} alt='icon of subtract' className='mr-2.5 h-5' />
                            </div>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className=' text-sm'>API Integration</TableCell>
                            <TableCell>
                            <div className='icons flex text-center'>
                                <img src={icon} alt='API' className='mr-2.5 h-5' />
                            </div>
                            </TableCell >
                            <TableCell className=' text-sm'>
                            <div className='icons flex text-center'>
                                <img src={Subtract} alt='icon using into subtract' className='mr-2.5 h-5' />
                            </div>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className=' text-sm'>Analytics</TableCell>
                            <TableCell>
                            <div className='icons flex text-center'>
                                <img src={icon} alt='Analytics' className='mr-2.5 h-5' />
                            </div>
                            </TableCell >
                            <TableCell className=' text-sm'>
                            <div className='icons flex text-center'>
                                <img src={Subtract} alt='Analytics subtract icon ' className='mr-2.5 h-5' />
                            </div>
                            </TableCell>
                        </TableRow>
                        {/* Add more rows as needed */}
                    </TableBody>
                </Table>
            </TableContainer>
                    </Grid>


                </Grid>


                <Bulk_services/>

                <div className='text-3xl font-semibold color-134CBC text-center mt-5'>CPaaS SERVICE AND INTERACTIVE SMS SERVICE</div>
                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <p >
                                    Our SMS solution comes in two variations:CPaas and interactive SMS. CPaaS services are offered as a simple web-based application or as an API interface, as the case may be, and only require a PC with internet access to get started.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <p >
                                    Utilizing our APIs, integration is fast, simple, and reliable. The APIs allow integration with any front-end or legacy system over standard protocols, with a direct connection to our gateway.and only require a PC with internet access to get started.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <p >
                                    Interactive SMS, or Unstructured Supplementary Service Data (USSD), is a GSM technology used to interact between a mobile device and the business service network. The interactive SMS can be either one-way or interactive, depending on the customer's requirements.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5'>Why Choose Us For The Messaging Services?</div>
                <Grid
                    container
                    spacing={5}
                    paddingTop={4}
                 className='flex justify-center align-middle mb-5'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        {/* Your icons and text here */}
                        <div className='icons flex mb-5'>
                            <img src={icon} alt='icon sms' className='mr-2.5 h-5' />
                            <div>
                                <p>SLAs based on scenarios</p>
                            </div>
                        </div>

                        <div className='icons flex mb-5'>

                            <img src={icon} alt='icon mail' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                CPaaS service Provides dedicated capacity available, 24x7
                                </p></div>
                        </div>
                        <div className='icons flex mb-5'>

                            <img src={icon} alt='bulk sms' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                #1 SMS service Trustworthy and transparent billing
                                </p></div>
                        </div>

                       
                    </Grid>
                    
                    <Grid item xs={12} md={6} lg={6} className='component'>
                    <div className='icons flex mb-5'>
                            <img src={icon} alt='b icon' className='mr-2.5 h-5' />
                            <div>
                                <p>Database and sender ID security</p>
                            </div>
                        </div>

                        <div className='icons flex mb-5'>

                            <img src={icon} alt='tick icon' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                Flexible pricing model based on volumes of SMS
                                </p></div>
                        </div>
                        <div className='icons flex mb-5'>

                            <img src={icon} alt='bulk icon sms' className='mr-2.5 h-5'></img>
                            <div>
                                <p > 
                                SMS service needs only Charging basis delivery to mobile handset
                                </p></div>
                        </div>
                    </Grid>
                </Grid>
                <Bulk_card/>


                
                
            </Box>

        
        </>
    );
};

export default Infrastructure;
