import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import event from '../images/Ellipse 16.webp';
import event1 from '../images/Ellipse 15.webp';
import event3 from '../images/Ellipse 15 (1).webp';
import FormButton from '../components/FormButton';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Events = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
}));

const BackgroundContainer = styled(Box)({
  // backgroundImage: `url(${backgroundimg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh', // Set height to cover the viewport
  width: '100%', // Ensure it takes full width
  padding: '20px',
});


export default function RowAndColumnSpacing() {

  const navigate = useNavigate();


  const handleViewClick = () => {
    navigate('/media'); // Replace '/event' with the actual path to your event page
  };
  return (
    <>
      <div className='event_main px-6 md:px-24' >


        <div className='event_highlights'>


          <div className='text-[#139C32] text-center font-bold text-[32px] d-flex mt-[5%]'>Event Highlights</div>

          <div className='container flex justify-center' >

            <div className='card_event'>
              <div className='relative truncate'>
                <h3 className="text-[#134CBC] relative opacity-[50%] text-xl font-semibold  w-4/12 whitespace-normal">
                  GFF Event
                </h3>

                <img src={event} alt="Event Icon" className="event-img relative  bottom-0 left-4 border-l-4 border-b-7 border-solid border-[#134CBC] box-border object-cover rounded-full rounded-bl-full" />

                <div className='color-134CBC text-center relative left-[30%] opacity-50 text-3xl font-semibold'>2018</div>
              </div>

              <div className='line h-auto border-solid border-[#134CBC] ' ></div>
              <div className='relative truncate'>

              <img src={event1} alt="Event imgs" className="event-img relative  bottom-0 right-[15px] border-r-4 border-b-7 border-solid border-[#134CBC] box-border object-cover rounded-full rounded-br-full" />


                <h3 className="text-[#134CBC] relative opacity-[50%] text-xl font-semibold  w-4/12 whitespace-normal">Design event</h3>
                <div className='color-134CBC text-center relative  opacity-50 text-3xl font-semibold sm:left-[27%]  xs:left-[5%]'>2019</div>
              </div>
              <div className='line h-auto border-solid border-[#134CBC] ml-[-15px]' ></div>
            </div>


            <div className='card_event flex align-middle' >


              <div className='flex flex-col align-middle relative overflow-hidden'>
                <h3 className="text-[#134CBC] relative opacity-[50%] text-xl font-semibold  w-4/12 whitespace-normal">Lorem Ipsum</h3>
                <img src={event} alt="Event Design" className="event-img relative bottom-0 left-[15px] border-l-4 border-b-7 border-solid border-[#134CBC] box-border object-cover rounded-full rounded-bl-full"></img>
                <div className='color-134CBC text-center relative left-[30%] opacity-50 text-3xl font-semibold'>2020</div>
              </div>


              <div className='line h-auto border-solid border-[#134CBC]' ></div>
              <div className='relative truncate' >

                <img src={event3} alt="Icon of event" className='event-img' style={{ position: 'relative', bottom: '0', right: '15px', borderRight: '4px solid #134CBC', borderBottom: '7px solid #134CBC', boxSizing: 'border-box', objectFit: 'cover', borderRadius: '50%', borderBottomRightRadius: '50%' }} />

                <h3 className="text-[#134CBC] relative opacity-[50%] text-xl font-semibold  w-4/12 whitespace-normal">Design event</h3>
                <div className='color-134CBC text-center relative  opacity-50 text-3xl font-semibold sm:left-[27%]  xs:left-[5%]'>2021</div>
              </div>
              <div className='line h-auto border-solid border-[#134CBC] ml-[-15px]' ></div>
            </div>
          </div>
          <p  className='m-0 top-[10%] text-center'>
            Vestibulum tempus imperdiet sem ac porttitor. Vivamus pulvinar commodo orci, suscipit porttitor velit elementum non. Fusce nec pellentesque erat, id lobortis nunc.
          </p>
          <div  className='flex justify-center mt-[5px] mb-5'>
            <Link to={'/media'} >
              <FormButton label={"View"} style={{ marginBottom: '20px', textDecoration: 'none' }} /></Link>
          </div>
        </div>


      </div>
    </>
  );
}
