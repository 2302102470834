import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormInput from '../components/FormInput';
import FormTextArea from '../components/FormTextArea';
import FormFileUpload from "../components/FormFileUpload"
import FormButton from '../components/FormButton';
import { Card, CardContent, } from '@mui/material';
import location from "../icons/mob-app/location1.png"
import location2 from "../icons/mob-app/location2.png"
import SEO from '../components/SEO';
import { Link } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    height: '200px',
    width: '250px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Bhavnagar",
            "postalCode": "364002",
            "streetAddress": "306, 3rd Floor, Swara Parklane",
        },
        "email": "sales[at]leewaysoftech.com",
        "member": [
            {
                "@type": "Organization"
            },
            {
                "@type": "Organization"
            }
        ],

        "name": "LEEWAY SOFTECH",
        "telephone": "1800-121-5355",
    }

    const schemaMarkup2 = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "LEEWAY SOFTECH",
        "image": "https://new.leewaysoftech.com/static/media/Layer_1.ddb0d5f90f00c2f664c5.png",
        "@id": "http://www.leewaysoftech.com",
        "url": "http://www.leewaysoftech.com",
        "telephone": "1800-121-5355",
        "priceRange": "$",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "306,3rd Floor,Swara Parklane",
            "addressLocality": "Bhavnagar",
            "postalCode": "364002",
            "addressCountry": "IN"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 21.7571483,
            "longitude": 72.14893840000002
        }
    }
    return (
        <>
            <SEO title="Contact US | Mumbai, Ahmedabad, Delhi-NCR, Bhavnagar Branch"
                description="Contact Us through Call and WhatsApp on +91 8000305305 for sales and inquiry drop a mail on sales[at]leewaysoftech.com"
                schema={schemaMarkup} schema2={schemaMarkup2}
            />
            <Box sx={{ width: '100%' }} className='px-6 md:px-24'>
                <div className='text-center mt-10'>
                    <h1 className='text-[#134CBC] text-center text-3xl font-bold'>Contact Us</h1>
                    <p className='text-xl font-medium mt-2'>Get response within 48 hours</p>
                    <p className='text-base'>Thank you for your interest in Leeway Softech’s services.</p>

                    <p>Please provide the following information and get connected with us.</p>
                    <Link to='mailto:sales@leewaysoftech.com' target='blank'  className='decoration-none '>
                    <p className='text-[#139C32] font-semibold'>sales@leewaysoftech.com</p></Link>

                    <div className='contactus'>
                        <div className='contact-info'>
                         
                            <div className='contact-item'>
                                <p><span className='font-medium'>Contact: </span> +91 8000305305</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='contact_card mt-8 '>
                    <div>
                        <Grid container spacing={3} className='flex justify-center p-5' >
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label htmlFor="name" className="career_form_input">
                                    Name
                                </label>
                                <FormInput
                                    placeholder="Name Here"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{
                                        marginBottom: '4%',
                                        maxWidth: '100%'
                                    }}
                                />
                                <label htmlFor="name" className="career_form_input">
                                    Email
                                </label>
                                <FormInput
                                    placeholder="Email Here"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{
                                        marginBottom: '24px',
                                        maxWidth: '100%'
                                    }}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label htmlFor="name" className="career_form_input">
                                    Mobile No.
                                </label>
                                <FormInput
                                    placeholder="Ex: 9898952413"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{
                                        marginBottom: '24px',
                                        maxWidth: '100%'
                                    }}
                                />
                                <label htmlFor="name" className="career_form_input">
                                    Subject
                                </label>
                                <FormInput
                                    placeholder="Subject Here"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{
                                        marginBottom: '24px',
                                        maxWidth: '100%'
                                    }}
                                />
                            </Grid>

                            {/* Full Width Textarea */}
                            <Grid item xs={12}>
                                <label htmlFor="name" className="mb-[5%] block font-thin opacity-[80%]">
                                    Message
                                </label>
                                <FormTextArea
                                    className='textarea'
                                    placeholder="Your Message"
                                    variant="outlined"
                                    margin="normal"
                                  
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}  className='flex justify-center align-middle text-center'>
                            <input type="checkbox" id="subscribe" />
                            <label htmlFor="subscribe">I authorize Leeway Softech to call/email/SMS/WhatsApp me for plans and ongoing promotions and offers.</label>
                        </Grid>
                        {/* Centering the button */}
                        <Grid container className='mt-5 justify-center'>
                            <FormButton
                                label="Send"
                                style={{ padding: "10px" }}
                            />
                        </Grid>
                    </div>
                </div>

                <h1 className='text-[#139C32] text-center pt-[2%] text-[32px] font-bold'>Our Location</h1>
                <Grid container spacing={2} columns={16} className='justify-center p-5'>
                    <Grid item xs={6} >
                        <Item className="">
                            <div>
                                <div className='flex align-middle justify-start'>
                                    <img src={location} alt='location'></img>
                                    <h3 className='ml-[4%] font-semibold'>INDIA</h3>

                                </div>
                                <p className='text-[#139C32] ml-[20%] leading-1 relative bottom-4'>MUMBAI - Corporate Office</p>
                                <p className='ml-[19%] relative bottom-2'>A 502, Rustomjee Central Park, Opp.Kanakia Wall Street, Andheri,Kurla Road, Andheri (E), Mumbai, - 400059.</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                            <div>
                                <div className='flex align-middle justify-start'>
                                    <img src={location2} alt='location india'></img>
                                    <h3 className='ml-[4%] font-semibold'>INDIA</h3>

                                </div>
                                <p className='text-[#139C32] ml-[20%] leading-4.5 relative bottom-4'>BHAVNAGAR - Registered Office</p>
                                <p className='ml-[19%] relative bottom-2'>306,3rd Floor,Swara Parklane,Opp.Jogger's Park,Aatabhai Chowk,Bhavnagar - 364002</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                            <div>
                                <div className='flex align-middle justify-start'>
                                    <img src={location} alt='location delhi'></img>
                                    <h3 className='ml-[4%] font-semibold'>INDIA</h3>

                                </div>
                                <p className='text-[#139C32] ml-[20%] leading-4.5 relative bottom-4'>DELHI/NCR - Branch Office</p>
                                <p className='ml-[19%] relative bottom-2'>Logix City Centre,7th Floor Plot No. BW-58,Sector 32, Noida,Uttar Pradesh-201301 India</p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>
            </Box>


        </>
    );
}
