import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormInput from '../components/FormInput';
import FormSelectPopup from "../components/FormSelectPopup"
import FormTextArea from '../components/FormTextArea';
import FormFileUpload from "../components/FormFileUpload"
import FormButton from '../components/FormButton';
import { Card, CardContent, } from '@mui/material';
import SelectData from '../components/SelectData';
import { useState } from 'react';


export default function RowAndColumnSpacing() {
    const [category, setCategory] = useState()

    const onchangeCategory = (value) => {
        if (value.value == "Channels") {
            setCategory(value.value)
        }
        else if (value.value == "CPaaS") {
            setCategory(value.value)
        }
        else if (value.value == "Technologies") {
            setCategory(value.value)
        }
        else if (value.value == "Enterprise Solutions") {
            setCategory(value.value)
        }

    }

    return (
        <Grid className='popup_card max-w-[904px] rounded-md overflow-y-auto overflow-hidden max-h-[45vh]'>
            <Grid container spacing={1} className='flex justify-center'>
                {/* Left Side Inputs */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <label htmlFor="name" className='blok mb-1'>
                        Name
                    </label>
                    <FormInput
                        className='popup_input input-chat mb-[4%]'
                        placeholder="Name Here"
                        variant="outlined"
                        margin="normal"
                    />

                    <label htmlFor="name" className='blok mb-1'>
                        Mobile No.
                    </label>
                    <FormInput
                        className='popup_input input-chat mb-6'
                        placeholder="Ex: 9898952413"
                        variant="outlined"
                        margin="normal"
                    />

                    <label htmlFor="name" className='blok mb-1'>
                        City
                    </label>
                    <FormSelectPopup
                        className='popup_select mb-[10%]'
                        placeholder="Select City"
                        variant="outlined"
                        margin="normal"
                        options={SelectData.city}
                    />


                    <label htmlFor="name" className='blok mb-1'>
                        Category
                    </label>
                    <FormSelectPopup
                        className='popup_select mb-[10%]'
                        placeholder="Select Category"
                        variant="outlined"
                        margin="normal"
                        onChange={onchangeCategory}
                        options={SelectData.category}
                    />
                </Grid>

                {/* Right Side Selects */}
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <label htmlFor="name" className='blok mb-1'>
                        Email
                    </label>
                    <FormInput
                        className='popup_input input-chat mb-6'
                        placeholder="Email Here"
                        variant="outlined"
                        margin="normal"
                    />
                    <label htmlFor="name" className='blok mb-1'>
                        Subject
                    </label>
                    <FormInput
                        className='popup_input input-chat mb-6'
                        placeholder="Subject Here"
                        variant="outlined"
                        margin="normal"
                    />


                    <label htmlFor="name" className='blok mb-1'>
                        State
                    </label>
                    <FormSelectPopup
                        className='popup_select mb-[10%]'
                        placeholder="Select State"
                        variant="outlined"
                        margin="normal"
                        options={SelectData.state}
                    />



                    <label htmlFor="name" className='blok mb-1'>
                        Services
                    </label>
                    {
                        !category ? (
                            <FormSelectPopup
                                className='popup_select mb-[10%]'
                                placeholder="Select Services "
                                variant="outlined"
                                margin="normal"
                                options={[]}
                            />
                        ) : category === "Channels" ? (
                            <FormSelectPopup
                                className='popup_select mb-[10%]'
                                placeholder="Select Services"
                                variant="outlined"
                                margin="normal"
                                options={SelectData.channels_services}
                            />
                        ) : category === "CPaaS" ? (
                            <FormSelectPopup
                                className='popup_select mb-[10%]'
                                placeholder="Select Services"
                                variant="outlined"
                                margin="normal"
                                options={SelectData.cpaas_services}
                            />
                        ) : category === "Technologies" ? (
                            <FormSelectPopup
                                className='popup_select mb-[10%]'
                                placeholder="Select Services"
                                variant="outlined"
                                margin="normal"
                                options={SelectData.techno_services}
                            />
                        ) : category === "Enterprise Solutions" ? (
                            <FormSelectPopup
                                className='popup_select mb-[10%]'
                                placeholder="Select Services"
                                variant="outlined"
                                margin="normal"
                                options={SelectData.ep_solution_services}
                            />
                        ) : null
                    }

                </Grid>
                {/* Full Width Textarea */}
                <Grid item xs={12}>
                    <label htmlFor="name" className='block mb-6'>
                        Message
                    </label>
                    <FormTextArea
                        className='popup_textarea textarea mb-6 mt-[-3%]'
                        placeholder="Your Message"
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>

                {/* Checkbox for Subscription */}
                <Grid item xs={12} className='flex flex-col align-middle justify-center items-center'>
                    <div className='flex align-middle'>
                        <input type="checkbox" id="subscribe" />
                        <label htmlFor="subscribe" className='ml-2 max-w-[35vw]'>
                            I authorize Leeway Softech to call/email/SMS/WhatsApp me for plans and ongoing promotions and offers.
                        </label>
                    </div>

                       
                        <div  className='max-w-[136px]'>
                    <FormButton
                        label="Send"
                        style={{ padding: "10px", marginTop: '20px' }} // Add margin to separate the button from the checkbox
                        />
                    </div>
                 
                </Grid>
            </Grid>
        </Grid>


    );
}