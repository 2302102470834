import React from 'react';
import "../main.css";
import Grid from '@mui/material/Grid2';
import FormButton from '../components/FormButton';
import img from "../images/Rectangle 37.png";
import icon from "../images/Vector.png";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    // height: '345px',
    width: '500px',
    backgroundColor: '#fff',
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
  }));


const WhyChooseUs = () => {
    return (

        <>
            <div className='flex justify-center'>
                <div  className='text-[#134CBC] pb-[1%] text-3xl font-semibold mt-5'>Why Choose Us ?</div>
            </div>
            
            {/* <Grid2 container spacing={1} className='main_container px-6 md:px-24' >

                <Grid2 items size={{ sm: 6, lg: 6, md: 6 }} className='flex '>

                    <img src={img} alt='img' className='img-container'/>

                </Grid2>


                <Grid2 items size={{ xs: 12, sm: 6, lg: 6, md: 12 }} className="card-container">
                    <Grid2 container spacing={1}>
                        <Grid2 items size={{ xs: 12, sm: 6, lg: 6,}} className='card-main'>
                            <div className="card">
                                <img src={icon} alt="Hire Developers" className="card_image inline" />
                                <h4 className="card_title">HIRE DEVELOPERS</h4>
                                <p className="card_description">You can hire Web Development Services and Mobile App Developers on a full time or on an hourly basis to satisfy your business requisites. Get benefited by hiring experts from Leeway Softech and we take you to the next level.</p>

                            </div>
                        </Grid2>
                        <Grid2 items size={{ xs: 12, sm: 6, lg: 6,}} className='card-main'>
                            <div className="card">

                                <img src={icon} alt="Another Service" className="card_image inline" />
                                <h4 className="card_title">EXCEPTIONAL QUALITY WORK</h4>
                                <p className="card_description">Every time we are achieving an advanced standard of excellence by performing the right things in the right way. Our Developers continue learning, improving to stay updated with the latest Technologies.</p>
                            </div>
                        </Grid2>
                        <Grid2 items size={{ xs: 12, sm: 6, lg: 6,}} className='card-main'>
                            <div className="card">

                                <img src={icon} alt="Third Service" className="card_image inline" />
                                <h4 className="card_title">SUPERIOR PROCESS</h4>
                                <p className="card_description">Get to the market with quickness and confidence Experience the Power of our highly qualitative procedure for Software Development. Our professional Developers take great care in the Management of Bugs and Errors.</p>
                            </div>
                        </Grid2>
                        <Grid2 items size={{ xs: 12, sm: 6, lg: 6,}} className='card-main'>
                            <div className="card">

                                <img src={icon} alt="Fourth Service" className="card_image inline" />
                                <h4 className="card_title">COST EFFICIENCY WEBSITE DESIGN</h4>
                                <p className="card_description">We give unbelievable quality custom software solutions at moderate costs. We are constantly one stage ahead, making arrangements for the future with the assistance of the latest technology trends.</p>
                            </div>
                        </Grid2>
                    </Grid2>


                </Grid2>
            </Grid2> */}


<Grid container spacing={4} columns={16} className='flex justify-center mt-5 px-4 min-[1200px]:px-24' >
      <Grid item xs={6} >
        <Item className="card_spe services_card" >
          <div className=''>
            <div className='flex p-[2%] flex-col mb-3' >
                <h2 className='text-left text-[32px] font-semibold text-[#134CBC] ml-[10%]'>We Provide CPaaS Services</h2>
                <p className='text-[12px] text-[#134CBC] text-left ml-[10%] cursor-pointer'>Learn More <ArrowForwardIcon className='arrow_icons'/></p>
            </div>
                <img src={require('../images/img/Frame 1984077228.png')} alt='why choose us image' className='mt-[5.2%]'></img>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card_spe services_card" >
          <div className=''>
            <div className='flex p-[2%] flex-col mb-3' >
                <h2 className='text-left text-[32px] font-semibold text-[#134CBC] ml-[10%]'>We Provide IT Services</h2>
                <p className='text-[12px] text-[#134CBC] text-left ml-[10%] cursor-pointer'>Learn More <ArrowForwardIcon className='arrow_icons'/></p>
            </div>
                <img src={require('../images/img/Frame 1984077228 (2).png')} alt='second image of why choose us' className='mt-[14.5%] it_services_card'></img>
          </div>
        </Item>
      </Grid>
  
    
  
    </Grid>

        </>

    );
};

export default WhyChooseUs;
