import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import { Link } from 'react-router-dom';

import blog1 from "../event/blog/emailmain.jpg"
import mail from "../event/blog/marketing/email.jpg"
import props from "../event/blog/marketing/props.jpg"
import graph from "../event/blog/marketing/graph.png"
import time from "../event/blog/marketing/timesaving.jpg"
import sales from "../event/blog/marketing/sales.jpg"
import data from "../event/blog/marketing/data.jpg"
import branding from "../event/blog/marketing/branding.jpg"
import customer from "../event/blog/marketing/customer.jpg"
import marke from "../event/blog/marketing/marke.jpg"
import spam from "../event/blog/marketing/spam.jpg"
import mail_list from "../event/blog/marketing/mail_list.jpg"
import unsubscribe from "../event/blog/marketing/unsubscribe.jpg"
import email_listing from "../event/blog/marketing/email_listing.jpg"

import BlogForm from "../pages/BlogForm"
import SEO from '../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '430px',
    width: '270px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Refund and Cancellation Policy | Leeway softech Pvt Ltd"
             description="Click here to read refund and cancellation policy as well as important details about our IT Services. In case of any queries, reach out to us."
            />
            <Grid container spacing={1} className="digital_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]' >
                    <div style={{}}>
                        <h1 className='text-white text-center text-[32px] d-flex m-auto'>Refund-and-cancellation-policy</h1>
                        {/* <p style={{ color: 'white', textAlign: 'center' }}>Online digital marketing is the foundation of a successful online presence and can be used to drive customer participation, increase brand value, and build trust. It is important to create content that is relevant, engaging, and consistent. With a motive to provide the simplest solution for complex problems, Leeway Softech started way back with a mission to innovate, facilitate, and automate.</p> */}
                    </div>
                </Grid>
            </Grid>

            <Box className='w-[100%]'>
                <Grid container spacing={2} columns={16}  className='blog_card flex justify-center p-2.5'>
                    <Grid item xs={6} className=' flex justify-center'>
                        <div  className='text-center mr-[15%] ml-[15%]'> {/* Increased padding and added margin */}
                            <h2  className='text-left mb-5 font-[400]'>Eligibility for Refund
                            </h2>
                            <p  className='text[#161011] text-sm text-left opacity-[80%] mb-5'>
                                Leeway Soft-Tech Pvt. Ltd., an IT Service Provider Company in India every project is important to us and we make sure that we handle each project with utmost care and professionalism. Our aim is to provide the results as per the terms and conditions of the proposal. However, despite every measure if the client is not satisfied with the results and chooses to go for the dispute resolution process, we consider refund requests as per the following program.
                            </p>


                            <h2  className='text-left mb-5 font-[400] text-[#161011]'>Website Design & Development</h2>
                            <p  className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'><strong>Full refund:</strong> In cases where the project has not been started or if the initial design style has not been approved. The Full Refund policy will initiate after receive the upfront payment date and will take 60 days to refund the amount.</p>

                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>Partial refund:</strong>If there is a failure to deliver as per our delivery policy after the approval of the initial design style. The partial refund will be in proportion to the work completed.</p>

                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>No refunds:</strong> If the project has been completed and uploaded on the server.</p>

                            <h2 className='text-left mb-5 font-[400] text-[#161011]'>C-PaaS Service</h2>


                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>Full refund:</strong> In cases where Account is not created. The Full Refund policy will initiate after receive the upfront payment date and will take 60 days to refund the amount.</p>

                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>Partial refund::</strong> If there is account is created and credits added to account, if there is any issue with credits, credits will refund, not the amount with the approval and analysis of the issue.</p>
                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>Scalability: </strong>You can use to send bulk email for large scale of audience as well as short targeted list of peoples. </p>
                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>No refunds:</strong> If the Account is created and handover to client with credits and Client find the issue and not raise the issue within 5 days.</p>

                            <h2 className='text-left mb-5 font-[400] text-[#161011]'>Digital Marketing</h2>

                            <p className='text-left mb-5 font-[400] text-[#161011] text-[15px] opacity-85'> <strong>No refunds:</strong>There is no refund in Digital Marketing Service.</p>

                        </div>
                        <div>

                        </div>

                    </Grid>
                </Grid>
            </Box>

        </>
    );


}


