import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import { Link } from 'react-router-dom';

import blog1 from "../event/blog/emailmain.jpg"
import mail from "../event/blog/marketing/email.jpg"
import props from "../event/blog/marketing/props.jpg"
import graph from "../event/blog/marketing/graph.png"
import time from "../event/blog/marketing/timesaving.jpg"
import sales from "../event/blog/marketing/sales.jpg"
import data from "../event/blog/marketing/data.jpg"
import branding from "../event/blog/marketing/branding.jpg"
import customer from "../event/blog/marketing/customer.jpg"
import marke from "../event/blog/marketing/marke.jpg"
import spam from "../event/blog/marketing/spam.jpg"
import mail_list from "../event/blog/marketing/mail_list.jpg"
import unsubscribe from "../event/blog/marketing/unsubscribe.jpg"
import email_listing from "../event/blog/marketing/email_listing.jpg"

import BlogForm from "../pages/BlogForm"
import SEO from '../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '430px',
    width: '270px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Email Marketing Blog | Leeway Softech Pvt Ltd"
             description="Emails are lifeline for  B2B and B2C relations. If used strategically, they can drive numbers for your company. Read about best email marketing strategies in our latest blog article."
            />
            <Grid container spacing={1} className="blog_page">
                <Grid items xs={12} sm={12} lg={12}className='pt-[2%]'>
                <h1  className='color-134CBC text-center font-bold text-[32px] d-flex'>Blogs</h1>
                    <div  className='p-2.5 align-middle flex flex-col justify-center'>
                        <div className='text-center font-bold text-[32px] d-flex p-13'>Best Email Marketing Strategy in 2023</div>
                        <div className='flex align-middle justify-center  mb-2'>
                            <p className='ml-2.5 color-134CBC text-center '>Email Marketing</p>
                            <div className='blog_line'></div> {/* Add margin to the line */}
                            <p className='ml-1 color-134CBC'>Posted on 2nd December 2021 </p>
                        </div>

                        <div>
                            <img src={blog1} alt="Blog Marketing Visual" className='image_component' />

                        </div>
                    </div>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%', }} >
                <Grid container spacing={2} columns={16}  className='blog_card'>
                    <Grid item xs={6} className='flex justify-center'>
                        <div className='blog_content '> {/* Increased padding and added margin */}
                            <h2 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Introduction: (What is Email Marketing?)</h2>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>
                                Email Marketing is a process to the sending electronics messages, specifically to certain group of peoples with the help of email. Eventually every email sent to the specific type of person or existing customer of particular company it could be considered as email marketing. It includes promotional email (advertisement), informative email, Update Email and so on. Email marketing is part of direct marketing and most effective digital marketing tactic.
                            </p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>
                                It can play very important role in digital marketing strategy for generate the leads, awareness of brand, create loyalty & trust, and establish relationship between consumer and Business to engage customer for purchase through various marketing emails.
                            </p>
                            <h2 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Why Email Marketing? </h2>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Importance of Email marketing we understood with an example. In 1978, Gary Thuerk used Email Marketing as new method of direct communication to send initial commercial email to 400 high potential people and the email generates 13 million dollar sale through Bulk Email Marketing.</p>
                            <img src={mail} alt='mail' className='blog_sub_img m-auto mb-5'></img>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Bulk Email marketing is a popular marketing tool for businesses causes it influencing the person to take some action either unread email shows then until the read, delete or archived. In 2015 study by the U.K based Marketing Association found that in a spent of every 1 dollar, email marketing average return on investment (ROI) of 38 dollar. It means email marketing ROI is 3800% which is more than other medium of marketing.</p>

                            <h2 style={{ color: '#161011', textAlign: 'left', }}>Pros and Corns of Email Marketing:</h2>
                            <img src={props} alt='props' className='blog_sub_img m-auto mb-5'></img>

                            <h3 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Pros:</h3>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Costing:</strong> Pricing of Email Marketing is cheaper than other marketing techniques. there is no any extra fees like advertising, printing etc. If you have skill to write and design a creative mail and having a effective strategy for it then the cost of email marketing can very low. And the Return of Investment (ROI) is higher.</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>User base:</strong> The User base of Email is increase 1 billion every year. In 2019 email users are 3930 Million, in 2020 their 4037 million and in 2021 4147 million. And according to statista in 2020 the number can touch to 4258 million. The statistic is below.</p>

                            <img src={graph} alt='graph' className='blog_sub_img m-auto mb-5'></img>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Flexible and very customizable Design:</strong> Email has flexible and very customizable design. You can use only plain text, graphics design, attachment, HTML Design and various other options in there. Using these factors possible to design personalized customer experience, also spent time to learn about your target audience.</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Permission:</strong> The user data of Email Marketing are mostly provided by user. It means user gives permission sent email to them. And customer can really interest in your service/product to engage with your Brand.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Scalability: </strong>You can use to send bulk email for large scale of audience as well as short targeted list of peoples. </p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Time Saving:</strong>With the use of automation trigger emails to send user base on their actions. like user sign up then sent Welcome mail, sign in then sent sign in information email for security purpose, discount offer mail, and so on. You just need to design email template once and set automation for it.</p>

                            <img src={time} alt='time' className='blog_sub_img m-auto mb-5'></img>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Conversion and Increase the Sales:</strong>If you have a list of potential people and you can use click on links or follow its call-to-action in this case email marketing is very effective at all stage of selling process. May be customer cannot make purchase today but its high chance to made purchase in future.</p>

                            <img src={sales} alt='sales' className='blog_sub_img m-auto mb-5' ></img>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Measurability (Data Tracking):</strong>The Analysis and evaluate of email marketing campaign result is easy through Web Analytics which is provide by Bulk Email Marketing service companies. You can also easily test various copy, design theme subject with most effectively. And using these data you can optimize your future campaigns.</p>

                            <img src={data} alt='data' className='blog_sub_img m-auto mb-5'></img>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Marketing as well as branding:</strong> Email Marketing is also technique for marketing as well as branding. If you are performing campaign with fresh or new data of users then it is first step of your marketing. And if you want to convince your existing customer for your new product or service then trustworthiness of your brand good possibility to made conversion from Branding through Email Marketing.</p>

                            <img src={branding} alt='branding' className='blog_sub_img m-auto mb-5'></img>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Build customer relationship:</strong>Email is best way to made personalized Email contact with your Existing Customer. e.g. to send email when customer can sign in for security enhancement, using AI/ML send Interest base email and build trust and user experience with company which is rise the revenue of Firm.</p>

                            <img src={customer} alt='customer' className='blog_sub_img m-auto mb-5'></img>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Benchmark:</strong>You can do comparison between your niche industries. On internet many free marketing benchmarking reports are available. You can find it from Google. The data of benchmarking is helping to evaluation of priorities improvement.</p>

                            <img src={marke} alt='marke' className='blog_sub_img m-auto mb-5'></img>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Test before you send:</strong>Before the starting the campaign you can test the emails on your test mails list. You can check A/B testing of Subject or content, effectiveness, design and attachments before the sending the mass emails. If any error found on testing then you also correct it.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Less intrusive:</strong>User has a freedom if they dont want to get emails from your side.  User can unsubscribe them self from getting future emails.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Easy to share:</strong>Customers can easily share email by just tapping on forward button.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Real time marketing:</strong>Email Marketing cans real-time marketing using the automation triggering of customer. For an example use of website activity and send mail according to last activity like recent purchases, adding product in cart, etc. This is right time to target customer for offer and influence for porches.</p>


                            <h3 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Cons: </h3>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Not Delivered:</strong>Worst content, bad design of email and spammy keyword or characters in subject heading or content of email like $s, Free, Sale, click here, etc are filtered by email software and ISP (internet service provider). Any correction in actual email address or incorrect list of emails cannot react to target audience.</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Sent in Spam:</strong>Due to spammy keywords or phrases in subject or spammy link contains in main content of email then these types of email place in "SPAM" or "JUNK" instead of inbox. Consumers can irritate from spam or junk mail. Many time the delete all spam mails once and clean spam section in the mail. For Email Marketing you need to understood privacy and data protection rules.</p>
                            <img src={spam} alt='spam' className='blog_sub_img m-auto mb-5'></img>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Designing Issues:</strong> To make Multiple Device responsive design is very difficult. May be some time trade-off between the designing and functionality.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Email Open Rate:</strong> Most of Emails can delivered successfully in Inbox of user. But some time user just read subject and delete the mail without open it. Writing best subject heading can made chance to open email.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Competition:</strong> We are pursuing from the era of high competition. In this time people ignore or delete without reading the promotional mails. So monitoring of Click-through rates (CTR) and traffic. And Continuous Measurement of Email Campaign.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Categories by Email Service Providers:</strong> Specifically in Gmail are Some Categories like "Promotional", "Social", and "Update". When your email in promotional category then most of user can delete all mail or archived mails. Thats why CTR (Click through Rate) goes down.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Unsubscribe:</strong> User can free to "Unsubscribe" from getting emails from your side. Hence improve content, graphics design and overall quality of Email.</p>

                            <img src={unsubscribe} alt='unsubscribe' className='blog_sub_img m-auto mb-5'></img>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'> <strong>Resource utilization and Skills Enhancement:</strong> Best practice of Email Marketing is must ensure that you have right content, Appropriate Design of email, right user data and time of analysis of email campaigns which are done. If you dont have enough resource and skills, you must consider Outsourcing your email marketing to expert person or team or company.</p>

                            <div className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Types of Email:</div>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>If anyone Hard Working to build engaging email list of High potential peoples and also worked on quality content of email with good and responsive design. Then these types of emails can important for your marketing strategy, if you dont know about then it is hard to achieve the goal which you set through Email Marketing.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>One Major problem with email marketing is stuff many ineffective email to users. You need to Mix up "value" base emails and "Promotional" emails.</p>

                            <h2 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Here is 15 Types of Emails:</h2>
                            <ol  className='text-[#161011] flex flex-col justify-center align-middle'>
                                <li>Newsletter emails</li>
                                <li>Lead nurturing emails</li>
                                <li>Promotional emails</li>
                                <li>Transactional emails</li>
                                <li>Milestone emails</li>
                                <li>Survey emails</li>
                                <li>Review request emails</li>
                                <li>Welcome emails</li>
                                <li>Curated content emails</li>
                                <li>Abandoned cart emails</li>
                                <li>Progress emails</li>
                                <li>Confirmation emails</li>
                                <li>News Updates emails</li>
                                <li>Announcement Email</li>
                                <li>Personalize emails</li>

                            </ol>

                            <h2 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>How to Write Email?</h2>
                            <img src={mail_list} alt='mail list' className='blog_sub_img m-auto mb-5'></img>
 
                            <div className='icons_blog flex align-middle'>
                                <img src={icon} alt='appropriate icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Use appropriate format for email. If your email is newsletter then chose proper theme for it. And select right color combination as per your brand logo or brand theme.</p>
                                </div>
                            </div>

                            <div className='icons_blog flex align-middle' >
                                <img src={icon} alt='Default icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Use Default or Custom Template for your Email Marketing.</p>
                                </div>
                            </div>

                            <div className='icons_blog flex align-middle' >
                                <img src={icon} alt='template icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Design or chose Email template base on your target audience, brand theme, product/service theme because you sent all Email in Bulk and also the email shown like it is personalize mail.</p>
                                </div>
                            </div>

                            <div className='icons_blog flex align-middle' >
                                <img src={icon} alt='optimize icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Properly optimize your Subject line. Cause the initial impression of your mail depends on your subject line. In much case blank subject line consider as a spam, so avoid in your email campaign to leave blank subject line.  So make sure your subject line properly optimized by appropriate words.</p>
                                </div>
                            </div>

                            <div className='icons_blog flex align-middle' >
                                <img src={icon} alt='Email Brief icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Write Email Brief and point to point. Highlight most important things or content.</p>
                                </div>
                            </div>

                            <div className='icons_blog flex align-middle' >
                                <img src={icon} alt='Email template icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Design of Email template is device responsive.</p>
                                </div>
                            </div>

                            <div className='icons_blog flex align-middle' >
                                <img src={icon} alt='send the email icon' style={{ marginRight: '10px', filter: 'grayscale(100%) brightness(0)' }} className='mb-5'/>
                                <div>
                                    <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Before send the email check proofreading. Check Grammar, spelling, and punctuation mistakes in email. </p>
                                </div>
                            </div>

                            <h2 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>How to Build Email List?</h2>
                            <img src={email_listing} alt='email listing' className='blog_sub_img m-auto mb-5'></img>


                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Using Sign up Form:</strong> Easy way to make a user email list of potential person is to embed sign up form in High traffic landing pages on a header, footer or sidebar. It is good way to collect interested people list and emails.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Using Pop-ups:</strong> According to studies average rate of conversion is 3.9 %, and 9.8%/10%. But pop-ups should be design as per the goggles guideline either you getting manual penalty on your rank as well as on Google Ad Sense.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Using Display Overlays:</strong> Embed sign up page in Display/Screen overlay after scrolling or spent some time on then display signup form on screen overlay. But again follow Google guideline for it.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Introduce Gamification:</strong> In a gamification using interactive element of game playing to encourage user for engagement with lead form. e.g. quizzes, tests, survey, spinner pop-ups, scratch cards etc.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Create Squeeze pages:</strong> Squeeze page can get leads from landing page design to "squeeze" information which is one type of lead form.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Include option fields at checkout:</strong> By providing option check box for regular email update at a time of signup. Which is allows you to send regular email or newsletter to end-user.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Utilize loyalty and start referral program:</strong> Best way to build email list is and made repeat purchase to create loyalty with visitors. Cause People are more inclined to shop with their shop that shows loyalty or some reward to them.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Paid Promotion:</strong> Everyone knows that Paid Marketing on social media or search engine are good engagement rate with customer targeted by interest and geographic. and the ROI of Paid Campaign is much better if you have a skill to run ad on various medium else you outsource these work. Because you dont have enough knowledge to targeting people then ROI goes very low.
                                <strong>Just run proper ad on various medium and drive visitor on your Landing page and setup pop-ups, screen overlay and etc. and get data of user through form.</strong>
                            </p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Through Contact us page:</strong>By adding form on contact us page only instead of static contact information on contact us page. Through contact form user easily interact with you and solve their doubts from you.</p>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'><strong>Chat-bots:</strong>In nowadays when user drive on landing page the after some seconds chat-bots can active and try to interact with user by pop-up on screen. Then some bots asking for contact information like email, mobile number. And give notification on back side. Then collecting and arranging this data you can create a potential list of users.</p>

                        <h2 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Email Marketing Service Providers:</h2>
                        
                        <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5'>Here we provide 4 email marketing service provider. On internet many email marketing service provide by free and paid also.</p>

                        <Link to='https://mailchimp.com/'>
                        <h3 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>MailChimp:</h3></Link>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5 ml-10' >MailChimp is leading email marketing provider. Their free plan provide with some basic email marketing functionality like creating email template, scheduling campaign, and good interface for track data of email campaign.</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5 ml-10'>Their also provide contact manager, easy interface to design email, easy access of images and files. The automation system of MailChimp is quite good. MailChimp have their own paid plans as per customer requirement.</p>

                            <h3 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Sendinblue:</h3>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5 ml-10'>Sendinblue is a platform for marketing that provides email marketing as well as CRM and landing page creating functionality. Sendinblue having some awesome feature like A/B Testing and segment recipient list to take the manual effort to optimizing campaign for targeting potential audience.</p>

                            <h3 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>Leeway Softech:</h3>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5 ml-10'>Leeway Softech is best Bulk Email Marketing provider. They also provide SMS Marketing and Digital marketing services. Leeway Softech provide their own panel to manage and track data of Email Marketing.</p>

                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5 ml-10'>Leeway Softech has give free trial and then paid plans for continuing Email Marketing service.</p>

                            <h3 className='text-[#161011]  align-left text-2xl  font-semibold mb-5'>SendPulse:</h3>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mb-5 ml-10'>SendPulse is Multi-channel marketing platform. SendPulse provide drag-drop editor for customize template creation.</p>

                        </div>
                        <div>

                        </div>

                    </Grid>
                </Grid>
            </Box>
                        <BlogForm/>

        </>
    );


}


