import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import SEO from "../components/SEO"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));
export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="PHP-Drupal Development | Leeway Softech Pvt Ltd"
             description="Leeway offers e-commerce, educational, and banking services as a part of their web application development portfolio powered by PHP-Drupal. They also offer services to revamp your website that speaks your brand"
            />
            <Grid container spacing={1} className="drupal_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
    <div className='text-white text-center'>
    <h1 className='text-3xl font-semibold'>Drupal</h1>
                        <p className='text-lg font-normal'>Drupal Web Development Services Shape your business successfully with our enterprise-grade Drupal solutions. Starting from offering customized Drupal solutions to advanced look and feel, Leeway Softech is completely focused on your business objective. We use an agile methodology to provide Drupal web development services with a proven, highly-flexible engagement model. As a reliable Drupal development company, we render 100% client satisfaction while handling any sized projects wisely. We will guide you with your project from a basic idea to a big achievement.</p>
                    </div>
                </Grid>
            </Grid>

            <Box  className="it_infra pr-[2%] pl-[2%] pt-[2%]">
                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>Drupal Web Development services</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal Module Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'> Drupal Template Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal CMS Solution and Customization</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal E-Commerce</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal Portal Development</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal Cross-Browser Compatibility</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Easy to understand</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal Design Integration</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal Design and Theming</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Drupal Website Support and Maintenance</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Active database support</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Easily manageable features</p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>


                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5'>Why Choose Us For the Drupal Web Development?</div>
                <Grid
                    container
                    spacing={2}
                    paddingTop={5}
                   
                    className='felx justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div  className='icons flex align-middle mb-5'>
                            <img src={icon} alt='module icon' className='mr-2.5' />
                            <div>
                                <p>Drupal core module development</p>
                            </div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='third-party icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Integration of third-party modules
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Integration icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                E-commerce development
                                </p></div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='design icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Design integration
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='modules icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Membership/Subscription sites
                                </p></div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='com icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Complex e-commerce
                                </p></div>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Customized icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Customized Development
                                </p></div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Web developmenticon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Web development and maintenance
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Custom templates icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Custom templates Development and themes development
                                </p></div>
                        </div>

                        <div  className='icons flex align-middle mb-5'>

                            <img src={icon} alt='themes developmenticon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Multi-functional web portals
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='Assist tocreateicon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Assist tocreate custom content types, starting from basic to advanced
                                </p></div>
                        </div>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
