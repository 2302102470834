import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';



const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '10px',
  border: ' 1.5px solid #C4D2EE',
  width: '40vw',
  // height:'30vh',
  backgroundColor: '#fff',
  padding: theme.spacing(1),
}));


const Infrastructure = () => {
    return (
       
    <>

    <Box sx={{ width: '100%'}} className="it_infra">
    <h1 className='text-3xl font-semibold text-center text-[#139C32] mb-5 mt-5'>More about Mobile Web App Development Company</h1>
    <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

    <Grid items xs={6}>
    <Item className='sub_card'>
        <div className='icons text-center p-[5%]'>
                <p > 
                Leeway Softech is a web design and development company with a talented team of experts with superior domain knowledge across different development platforms. Our world-wide customers range from start-ups to large endeavours. We are focused on driving customer satisfaction and place our ability and knowledge in this direction.
                </p>
        </div>
    </Item>
</Grid>

        <Grid items xs={6}>
          <Item className=' sub_card'>
            <div className='icons text-center p-[5%]' >
                <p > 
                Our wide-ranging customer base from different dominant industries is a mark of the good compatibility we have with our customers globally. We make sure that with each project, we offer better ROI to our customers. If you want to build your own website but are not sure how to start it, Leeway Softech is the best place to begin with your web design or web development project.
                </p>
              
            </div>
          </Item>
        </Grid>

        <Grid items xs={6}>
          <Item className='sub_card'>
            <div className='icons text-center p-[5%]' >
                <p > 
                In addition to expecting a complete set of technical conditions that we could simply write code for, we get involved actively in defining needs, proposing solution architectures, designing prototypes, expanding specifications, and going ahead with the development itself. With us, every step of the development process ensures that your website looks extraordinary, functions seamlessly, and grows your business.
                </p>
              
            </div>
          </Item>
        </Grid>

      </Grid>
    </Box>
   
    
    </>
    );
};

export default Infrastructure;
