import React from 'react';

function FormButton({ label, disabled, children, ...rest }) {
    return (
        <div 
            className='home-button w-100 d-flex align-items-center justify-content-center' 
            {...rest} 
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} // Optional: handle disabled state
        >
            <p className='text-white text-center m-0 font-Poppins'>
                {label || "Submit"}
            </p>
            {children}
        </div>
    );
}

export default FormButton;