import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import cyber from "../../icons/career/cybersecurity 1.png";
import customer from "../../icons/career/customer-care 1.png";
import group from "../../icons/career/Group (1).png";
import php from "../../icons/career/php (2) 1.png";
import professional from "../../icons/career/professional.png";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "225px",
  width: "255px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <Box className="w-full pt-[3%] bg-[#FAFBFD]">
      <h1 className="text-[#139C32] text-2xl text-center font-bold">
        Current Openings
      </h1>
      <Grid
        container
        spacing={4}
        // columns={16}
        // className="justify-center p-[10px] gap-[3%]"
        // className="justify-center p-[10px] gap-[3%]"
        style={{ padding: "30px" }}
      >
        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }}>
          <Item className=" flex flex-col justify-start">
            <div className="justify-start items-center flex mt-7 px-4">
              <img src={cyber} alt="cyber" className="mr-[10px]" />{" "}
              {/* Add margin to the image */}
              <div className="border border-[#DDDDDD] h-5 mx-[10px]"></div>{" "}
              {/* Add margin to the line */}
              <h4 className="font-bold text-[#134CBC] ml-[10px]">
                Full Time
              </h4>{" "}
              {/* Add margin to the paragraph */}
            </div>
            <div className="flex flex-col items-start justify-start px-4">
              <h2 className="text-base font-bold my-3 text-left">
                Cyber Security Engineer(Cisa Certified)
              </h2>
              <p className="text-[#1610118C] text-sm">Experience: 1-2 Years</p>
            </div>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }}>
          <Item className="card flex flex-col justify-start">
            <div className="justify-start items-center flex mt-7 px-4">
              <img src={customer} alt="customer" className="mr-[10px]" />{" "}
              {/* Add margin to the image */}
              <div className="border border-[#DDDDDD] h-5 mx-[10px]"></div>{" "}
              {/* Add margin to the line */}
              <h4 className="font-bold text-[#134CBC] ml-[10px]">
                Full Time
              </h4>{" "}
              {/* Add margin to the paragraph */}
            </div>
            <div className="flex flex-col items-start justify-start px-4">
              <h2 className="text-base font-bold my-3 text-left">
                Customer Care Executive
              </h2>
              <p className="text-[#1610118C] text-sm">Experience: Min 1 Year</p>
            </div>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }}>
          <Item className="card flex flex-col justify-start">
            <div className="justify-start items-center flex mt-7 px-4">
              <img src={group} alt="group" className="mr-[10px]" />{" "}
              {/* Add margin to the image */}
              <div className="border border-[#DDDDDD] h-5 mx-[10px]"></div>{" "}
              {/* Add margin to the line */}
              <h4 className="font-bold text-[#134CBC] ml-[10px]">
                Full Time
              </h4>{" "}
              {/* Add margin to the paragraph */}
            </div>
            <div className="flex flex-col items-start justify-start px-4">
              <h2 className="text-base font-bold my-3 text-left">
                Sr. Database Administrator(My SQL)
              </h2>
              <p className="text-[#1610118C] text-sm">Experience: 4+ Years</p>
            </div>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }}>
          <Item className="card flex flex-col justify-start">
            <div className="justify-start items-center flex mt-7 px-4">
              <img src={php} alt="php" className="mr-[10px]" />{" "}
              {/* Add margin to the image */}
              <div className="border border-[#DDDDDD] h-5 mx-[10px]"></div>{" "}
              {/* Add margin to the line */}
              <h4 className="font-bold text-[#134CBC] ml-[10px]">
                Full Time
              </h4>{" "}
              {/* Add margin to the paragraph */}
            </div>
            <div className="flex flex-col items-start justify-start pl-4 pr-9">
              <h2 className="text-base font-bold my-3 text-left">
                PHP Laravel Developer
              </h2>
              <p className="text-[#1610118C] text-sm">
                Experience: Min 2 Years
              </p>
            </div>
          </Item>
        </Grid>

        <Grid items size={{ xs: 12, sm: 4, lg: 2.4 }}>
          <Item className="card flex flex-col justify-start">
            <div className="justify-start items-center flex mt-7 px-4">
              <img
                src={professional}
                alt="professional"
                className="mr-[10px]"
              />{" "}
              {/* Add margin to the image */}
              <div className="border border-[#DDDDDD] h-5 mx-[10px]"></div>{" "}
              {/* Add margin to the line */}
              <h4 className="font-bold text-[#134CBC] ml-[10px]">
                Full Time
              </h4>{" "}
              {/* Add margin to the paragraph */}
            </div>
            <div className="flex flex-col items-start justify-start px-4">
              <h2 className="text-base font-bold my-3 text-left">
                Business Development Manager
              </h2>
              <p className="text-[#1610118C] text-sm">Experience: 3+ Years</p>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
