import React from "react";
import "../../main.css";
import Grid from "@mui/material/Grid2";

import Specialise from "../../pages/Company/Specialise";
import Client from "../../pages/Company/Client";
import SEO from "../../components/SEO";

const Company = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Bhavnagar",
      postalCode: "364002",
      streetAddress: "306, 3rd Floor, Swara Parklane",
    },
    email: "sales[at]leewaysoftech.com",
    member: [
      {
        "@type": "Organization",
      },
      {
        "@type": "Organization",
      },
    ],

    name: "LEEWAY SOFTECH",
    telephone: "1800-121-5355",
  };

  const schemaMarkup2 = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "LEEWAY SOFTECH",
    image:
      "https://new.leewaysoftech.com/static/media/Layer_1.ddb0d5f90f00c2f664c5.png",
    "@id": "http://www.leewaysoftech.com",
    url: "http://www.leewaysoftech.com",
    telephone: "1800-121-5355",
    priceRange: "$",
    address: {
      "@type": "PostalAddress",
      streetAddress: "306,3rd Floor,Swara Parklane",
      addressLocality: "Bhavnagar",
      postalCode: "364002",
      addressCountry: "IN",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 21.7571483,
      longitude: 72.14893840000002,
    },
  };

  return (
    <>
      <SEO
        title="About Leeway softech Pvt Ltd | IT Services Provider"
        description="Leeway Softech Base in Mumbai and we provide Bulk Email and SMS, Website and Mobile App Development, Promotional and Transactional SMS and so on."
        schema={schemaMarkup}
        schema2={schemaMarkup2}
      />
      <Grid container className="content_page main_content">
        <Grid item xs={12} sm={12} lg={12}>
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">About Us</h1>
            <p className="text-lg font-normal">
              Design, Create and Innovate with us
            </p>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={12}>
          <div className="mt-12 px-4 min-[1200px]:px-24">
            <div className="text-[#134CBC] text-center text-2xl font-semibold">
              Who We Are
            </div>
            <p className="text-black text-center text-base mt-7">
              Headquartered in Mumbai, Leeway Softech was established in 2010 to
              provide Information Technology Solutions and is leading in
              different Business Sectors, Government & Corporate Sectors that
              includes Banking, Educational, Hospitality and Politics with more
              than 1500+ clients.
            </p>
            <p className="text-black text-center text-base mt-7">
              Leeway Softech is also accomplished ISO 9001:2015 & ISO/ IEC
              27001: 2013 Certification with CMMI-Maturity Level 3 & much more.
            </p>
            <p className="text-black text-center text-base mt-7">
              We offer services such as Web development services & Mobile app
              development, search engine optimization (SEO), Database
              Administration, Web Hosting Services, UI/UX design services, IT
              consulting, Custom software solutions, Mobile app development,
              Client satisfaction in IT services, E-commerce solutions, Media
              Solution, Data Centre, Enterprise Cloud. We also offer different
              value added services reliable campaign services (CPaaS, RCS , Bulk
              Email), Cloud Telecom etc.
            </p>
          </div>
          <div className="mt-12 px-4 min-[1200px]:px-24">
            <div className="text-[#134CBC] text-center text-2xl font-semibold">
              What We Do
            </div>
            <p className="text-black text-center text-base mt-7">
              We are a global IT services provider and enterprise solutions and
              catering different technology platforms across various industry
              domains worldwide. The interactive behavior & transparent business
              policy with the customers make us famous as one of the best
              service providers in the industry!
            </p>
          </div>
        </Grid>
      </Grid>

      <Specialise />
      {/* <Client/> */}
    </>
  );
};

export default Company;
