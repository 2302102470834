import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import strategy from "../icons/Frame 390.png";
import technology from "../icons/Frame 390 (1).png";
import design from "../icons/design.png";
import attencan_module from "../../src/images/attencan_module.png";
import radiology from "../../src/images/radiology.png";
import pharmacy from "../../src/images/pharmacy.png";
import canteen from "../../src/images/canteen.png";
import Pathology from "../../src/images/microscope.png";
import SEO from "../components/SEO";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  height: "230px",
  width: "300px",
  backgroundColor: "#fff",
  //   padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Items = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "150px",
  width: "400px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="CPaaS Services | Leeway Softech Pvt Ltd"
        // description="PBX services are an excellent way to enhance your business's communication and productivity. Discover the various types of PBX services available at Leeway Softech"
      />
      <Grid container spacing={1} className="platform_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div>
            <h1 className="text-white text-center text-[32px] d-flex m-auto">
              Smart Hospital
            </h1>
            <p
              className="text-white text-center m-auto"
              style={{ width: "70%" }}
            >
        Our smart hospital system integrates technology to create a more efficient and patient-centric environment. Unlock the future of healthcare management with seamless, intelligent solutions.
            </p>
          </div>
        </Grid>
      </Grid>
      <Box className="mt-5 it_infra">
        <h1 className="text-[#134CBC] text-2xl font-semibold text-center">
        Comprehensive Hospital Management System Modules
        </h1>
        <Grid
          container
          spacing={4}
          columns={16}
          className="flex justify-center mt-10 px-4 min-[1200px]:px-24"
        >
          <Grid item xs={6}>
            <Item className="card_spe place-content-center">
              <div className="p-[5%]">
                <div className="flex items-center justify-center mb-3">
                  <img
                    src={attencan_module}
                    alt="attencan_module"
                    style={{ width: "50px" }}
                  ></img>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className=" text-base font-bold">Attendance Module</h3>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className="ml-[10%] text-base">
                    Staff aendance view with seamless API integraon.
                  </h3>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="card_spe place-content-center">
              <div className="p-[5%]">
                <div className="flex items-center justify-center mb-3">
                  <img
                    src={radiology}
                    alt="radiology"
                    style={{ width: "50px" }}
                  ></img>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className=" text-base font-bold">Radiology</h3>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className="ml-[10%] text-base">
                    Comprehensive radiology test records and paent imaging
                    history.
                  </h3>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="card_spe place-content-center">
              <div className="p-[5%]">
                <div className="flex items-center justify-center mb-3">
                  <img
                    src={pharmacy}
                    alt="pharmacy"
                    style={{ width: "50px" }}
                  ></img>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className=" text-base font-bold">Pharmacy</h3>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className="ml-[10%] text-base">
                    Complete list of medicines, real-me stock monitoring, and
                    pharmacy billing system.
                  </h3>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="card_spe place-content-center">
              <div className="p-[5%]">
                <div className="flex items-center justify-center mb-3">
                  <img
                    src={canteen}
                    alt="canteen"
                    style={{ width: "50px" }}
                  ></img>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className=" text-base font-bold">Canteen Module</h3>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className="ml-[10%] text-base">
                    Add menu, manage paent orders, and view transacon history.
                  </h3>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="card_spe place-content-center">
              <div className="p-[5%]">
                <div className="flex items-center justify-center mb-3">
                  <img
                    src={Pathology}
                    alt="Pathology"
                    style={{ width: "50px" }}
                  ></img>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className=" text-base font-bold">Pathology</h3>
                </div>
                <div className="flex items-center justify-center mb-3">
                  <h3 className="ml-[10%] text-base">
                    Detailed pathology test records and paent test history.
                  </h3>
                </div>
              </div>
            </Item>
          </Grid>
        </Grid>

        {/* ======================================= */}

        {/* <h1 className="text-[#139C32] text-2xl font-semibold text-center mt-5">
          Lorem Ipsum
        </h1> */}
        <Grid
          container
          spacing={2}
          columns={16}
          className="justify-center p-[10px]"
        >
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Operaon Theater Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Efficient management of paent operaon acvies and operaon
                  records.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Blood Bank Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Real-me tracking of available blood stock, donor details, and
                  blood issue records.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  TPA Management
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Third Party Administrator records and insurance/mediclaim
                  management.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Finance Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Management of general income and expenses within the hospital.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Ambulance Module{" "}
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Vehicle management and detailed records of ambulance call
                  acvies.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Calendar Module{" "}
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  View public events and appointments created by hospital staff
                  users.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Messaging Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Internal messaging system for seamless communicaon between
                  paents and staff.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Inventory Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>Comprehensive asset management and stock control system.</p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Reports Module{" "}
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Various reports including Transacon, OPD, IPD, etc.,
                  consolidated in one place.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Setup Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Configuraon opons for hospital sengs, admin password, charges,
                  bed details, and bill formats.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  User Login (Paent Login)
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Personalized paent portal for managing appointments, OPD
                  details, and IPD records
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Security Menu
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  IP tracking, blocked IP addresses, temporary blocked IPs, and
                  user noficaons.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Login System
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Secure login for Admin, Paent, Doctor, Pharmacy, Pathology,
                  Radiology, Canteen, Accountant, etc.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Human Resource Module
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Staff management, including search, profiles, aendance,
                  payroll, leaves, and user creaon.
                </p>
              </div>
            </Items>
          </Grid>
          <Grid item xs={6}>
            <Items className="infra_sub_cards">
              <div className="flex items-center justify-center mt-4">
                <h3 className="text-[#139C32] ml-[10%] text-base font-bold">
                  Payment Gateway Integraon
                </h3>
              </div>
              <div className="icons text-center p-[5%]">
                <p>
                  Seamless integraon of payment gateways like Rozarpay, Paypal,
                  Easy Pay, Payumoney, etc.
                </p>
              </div>
            </Items>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
