import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import gdc from "../../certificat_img/gdc.png";
import volt from "../../certificat_img/voltfitnessicon.png";
import sallebulls from "../../certificat_img/sallebulls.png";
import SEO from "../../components/SEO";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "140px",
  width: "230px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Our Clients in international Sector | Leeway Softech Pvt Ltd"
        description="Leeway Softech done Many Large Scale E-Commerce Website and Mobile Application development with customer satisfaction."
      />
      <Grid container spacing={1} className="international_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">International</h1>
            <p className="text-lg font-normal">
              Businesses today are no longer limited to geographical boundaries.
              The global economy has led to businesses of all sizes working in
              multiple countries and trading internationally. To make sure that
              businesses are able to communicate with their global customer
              base, technology innovations in communication have become
              necessary. Two way SMS, CPaaS,Bulk Email, WhatsApp Chatbot
              Services and Voice Calling are four services that businesses can
              use in order to effectively communicate with international
              customers and potential clients.
            </p>
          </div>
        </Grid>
      </Grid>

      <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
        <div className="text-3xl font-semibold color-134CBC text-center mb-5">
          A Trusted Corporate Partner
        </div>
        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          <Grid item xs={6}>
            <Item>
              <div className="flex justify-center align-middle">
                <img src={gdc} alt="gdc"></img>
              </div>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <div className="flex justify-center align-middle">
                <img src={volt} alt="volt" className="mt-4"></img>
              </div>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div className="flex justify-center align-middle">
                <img src={sallebulls} alt="saalebulls" className="mt-4"></img>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <div className="text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5">
        Diverse Range of Services to Fulfil Your Needs
      </div>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Global Communication Solutions for Modern Businesses{" "}
              </h4>

              <p>
                In today’s global marketplace, businesses are no longer confined
                by borders. The interconnected nature of the world economy has
                made it essential for companies of all sizes to engage with
                customers and partners across the globe. To stay competitive and
                deliver exceptional customer service internationally, businesses
                need innovative communication tools that break down geographical
                barriers. 
                We offer a range of advanced communication services
                designed to help businesses communicate seamlessly with their
                global customer base. From Two-Way SMS to WhatsApp Chatbots,
                CPaaS/Bulk Email and Voice Calling, these tools are crafted to
                ensure that your business can connect, engage and build
                relationships with international clients efficiently and
                cost-effectively.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Two-Way SMS: Engaging Your Global Audience, One Message at a Time{" "}
              </h4>
              <p>
              With Two-Way SMS, businesses can reach their international customer base directly via text, while also receiving instant replies. This interactive service is perfect for keeping customers informed whether it’s for product launches, special offers or service updates. Two-Way SMS makes communication straightforward, immediate and cost-effective, giving businesses the ability to maintain personalized connections with their international clientele at any stage of the customer journey.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                CPaaS & Bulk Email: Reach Your Customers Worldwide with Ease{" "}
              </h4>
              <p>
              For businesses that need to communicate with large numbers of customers across borders, CPaaS (Communication Platform as a Service) and Bulk Email are indispensable tools. These solutions allow companies to send tailored messages to diverse global audiences, ensuring your communications are not only delivered, but are also relevant and timely. Whether you’re delivering newsletters, updates or promotions, CPaaS and Bulk Email allow for seamless, scalable communication helping you engage customers on a global scale without the complexity.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                WhatsApp Chatbots: Personalized, Instant Support Around the Clock{" "}
              </h4>
              <p>
              WhatsApp is a powerful platform for businesses seeking to provide instant, personalized customer service to international audiences. With WhatsApp Chatbot Services, businesses can offer 24/7 automated customer support that answers inquiries instantly and efficiently. From handling product queries to processing orders, our WhatsApp Chatbots can be tailored to meet the specific needs of your customers helping you provide fast, accurate and engaging support no matter where your clients are located.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Voice Calling: Real-Time Global Conversations Made Simple{" "}
              </h4>
              <p>
              When direct communication is needed, Voice Calling is the most effective way to connect with international customers. Whether you're conducting meetings, providing customer service or collaborating with partners, our Voice Calling service allows businesses to make international calls at affordable rates. This service ensures crystal-clear communication, enabling you to maintain personal connections and conduct business without the barriers of time zones or geographic distance.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Enhance Your Global Presence{" "}
              </h4>
              <p>
              No matter where your business operates, our communication services will help you build stronger connections with international customers and create a seamless, professional communication experience. Whether it's through direct messages, automated support or voice calls, we offer the tools necessary to support your global expansion and enhance your customer service efforts worldwide.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
    </>
  );
}
