import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import PopUpFromUi from '../components/PopupForm';
import PaymentForm from "./PaymentForm"
import SEO from '../components/SEO';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '196px',
    width: '290px',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
    const [open, setOpen] = useState(false);

    const handleTextClick = () => {
        setOpen(true); // Open the popup when the text is clicked
    };
    return (
        <>
            <SEO title="Payment Methods | Leeway Softech Pvt Ltd"
             description="Payment through Call and WhatsApp on +91 8000305305 for sales and inquiry drop a mail on sales[at]leewaysoftech.com"
            />
            <Grid container spacing={1} className="payment_page main_content " >
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                    <div style={{}}>
                        <h1  className='text-white text-center text-[32px]'>Payment Methods</h1>
                        <p className='text-white text-center'>PayU Payment Gateway QR Code</p>
                    </div>
                </Grid>
            </Grid>


            <Box sx={{ width: '100%',  }} className="it_infra pt-[3%]">
                <Grid container spacing={2} columns={16}  className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="card">

                            <div  className='flex justify-center align-middle'>
                                <img src={require("../images/payment/image 45.png")} alt='payment page'  className='w-[187px]'/> {/* Add margin to the image */}

                            </div>

                        </Item>
                        <h4 style={{ color: '#134CBC', textAlign: 'center' }}>QR CODE</h4>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card">

                            <div className='flex align-middle justify-center relative top-[33%] cursor-pointer 'onClick={handleTextClick}>
                                <img src={require("../images/payment/image 44.png")} alt='payment image' className='w-[187px]' />

                            </div>

                            {/* <PopUpFromUi
                                open={open}
                                setOpen={setOpen}
                                header={<span className='d-flex justify-content-center'></span>}
                                body={<PaymentForm />}
                            /> */}

                        </Item>
                        <h4 className='color-134CBC text-center'>RAZORPAY PAYMENT GATEWAY</h4>

                    </Grid>



                </Grid>
            </Box>
        </>
    );
}
