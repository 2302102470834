import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SEO from '../../components/SEO';


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));

const Infrastructure = () => {
    return (

        <>
            <SEO title="Flutter development services | Leeway Softech Pvt Ltd"
             description="Flutter is a cross-platform framework that lets you build native apps for both iOS and Android using a single codebase. Leeway's Flutter development services can assist you in creating stunning, high-performance apps."
            />
            <Grid container spacing={1} className="flutter_page main_content">
                <Grid item xs={12} sm={12} lg={12} style={{  padding: '5%' }}>
                    <div style={{ }}>
                        <h1 style={{ color: 'white', textAlign: 'center' }}>Flutter</h1>
                        <p style={{ color: 'white', textAlign: 'center' }}>Our Flutter development services can help you create beautiful, high-performance apps that are responsive and engaging. Flutter is a modern cross-platform mobile development framework that is quickly gaining popularity. It is used by some of the biggest tech companies in the world.</p>
                    </div>
                </Grid>
            </Grid>


            <Box sx={{ width: '100%' }} className="it_infra">
                <h1 style={{ color: '#134CBC', textAlign: 'center' }}>Flutter Development</h1>
                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Cost savings</h3>
                                    <p style={{}}>
                                    Flutter is a modern cross-platform mobile development framework that is quickly gaining popularity. It is used by some of the biggest tech companies in the world.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Quality</h3>
                                    <p style={{}}>
                                    Our Flutter development services can help you build beautiful, high-performance apps that are responsive and engaging. We have a team of experienced Flutter developers who can help you with every aspect of your project, from ideation to deployment.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Flexibility</h3>
                                    <p style={{}}>
                                    App design and development
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Security</h3>
                                    <p style={{}}>
                                    App testing and debugging
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Choose the right service</h3>
                                    <p style={{}}>
                                    App deployment and maintenance
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Make sure the service is well-maintained</h3>
                                    <p style={{}}>
                                    Flutter consulting
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Get support</h3>
                                    <p style={{}}>
                                    We are committed to providing our clients with the highest quality Flutter development services. We use the latest Flutter tools and technologies to ensure that your app is built to the highest standards.
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>App design and development</h3>
                                    <p style={{}}>
                                    Transform your ideas into reality with our expert app development services. From concept to launch, we deliver innovative, user-centric apps that drive business growth. 

                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>High performance</h3>
                                    <p style={{}}>
                                    Flutter apps are known for their high performance. They are often as fast as native apps, even on older devices.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Beautiful UI</h3>
                                    <p style={{}}>
                                    Flutter has a rich set of widgets that can be used to create beautiful and engaging user interfaces.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>App testing and debugging</h3>
                                    <p style={{}}>
                                    Ensure the quality and reliability of your software with our comprehensive testing and debugging services.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>App deployment and maintenance
                                    </h3>
                                    <p style={{}}>
                                    Our app deployment and maintenance services provide a comprehensive solution to keep your app running smoothly.

                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Flutter consulting
                                    </h3>
                                    <p style={{}}>
                                    The future of app development is cross-platform, and Flutter is leading the way. Our consulting services will help you harness the power of Flutter to build cutting-edge apps that deliver exceptional user experiences.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Cross-platform</h3>
                                    <p style={{}}>
                                    Flutter apps can be built for both iOS and Android from a single codebase. This saves time and money in development and maintenance.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Easy to learn</h3>
                                    <p style={{}}>
                                    Flutter is a relatively easy framework to learn. This makes it a good choice for developers who are new to cross-platform development.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Easy to learn</h3>
                                    <p style={{}}>
                                    If you are looking for a modern, high-performance, and cross-platform mobile development framework, then Flutter is a great option. Our Flutter development services can help you build beautiful, high-performance apps that are sure to impress your users.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={3}  justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{  textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Easy to learn</h3>
                                    <p style={{}}>
                                    Contact us today to learn more about our Flutter development services.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>
            </Box>


        </>
    );
};

export default Infrastructure;
