import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import icon from "../icons/mob-app/Vector (2).png"
import SEO from "../components/SEO"


const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '70px',
    width: '200px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));



export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Codeigniter Framework | Leeway Softech Pvt Ltd"
             description="Leeway has a team of software engineers that crafts optimized and high quality web applications built with CodeIgniter that align with your brand's theme."
            />
            <Grid container spacing={1} className="code_page main_content" >
                <Grid item xs={12} sm={12} lg={12}  className='p-[5%]'>
    <div className='text-white text-center'>
    <h1 className='text-3xl font-semibold'>Codeigniter</h1>
                        <p className='text-lg font-normal'>CodeIgniter is an open-source software rapid development web framework, for use in building dynamic web sites with PHP.Codeigniter is a widely used PHP based web application framework that is suitable for creating high-quality web applications, websites, CMS, and e-commerce stores. The development friendly platform bundles up a highly interactive interface with a set of easy-to-use tools. Additional features like simplicity, flexibility, and security make Codeigniter a popular choice. We, at Codeigniter India, deliver a trusted CodeIgniter web development services and have a brilliant track record in this technology domain. We use this prolific framework to create exceptional web applications within a short time span and also within the client's budget.</p>
                    </div>
                </Grid>
            </Grid>

            <Box  className="it_infra pr-[2%] pl-[2%] pt-[2%]">
                <div className='text-[#134CBC] text-center text-3xl font-bold my-4'>BENEFITS OF CODEIGNITE</div>
                <Grid container spacing={5} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                            <div  className='text-center p-[2%]'>
                                <p  className='text[#161011] text-sm'>MVC-based systems</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Zero configuration needed</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>Free coupling</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Impressive performance</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>Powerful functionalities</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>Editable PHP programs</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>Easy to understand</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>Easy bug resolution</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>XSS filtering</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm mt-3'>Simple coding norms</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Active database support</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">
                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Easily manageable features</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                        <div  className='text-center p-[2%]'>
                        <p  className='text[#161011] text-sm'>Agile development process</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div  className='text-center p-[2%]'>

                                <p className='text[#161011] text-sm mt-3'>Easy to customize</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div  className='text-center p-[2%]'>

                                <p className='text[#161011] text-sm'>Compatibility with most hosting solutions</p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>


                <div  className='text-3xl font-semibold text-[#139C32] text-center mt-5'>Why Choose Us For the Wordpress Web Development?</div>
                <Grid
                    container
                    spacing={4}
                    paddingTop={5}
                   
                    className='felx justify-center align-middle'
                >
                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle mb-5' >
                            <img src={icon} alt='Simple icon'  className='mr-2.5'/>
                            <div>
                                <p>Simple yet robust solutions</p>
                            </div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='web icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                    Robust Website
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='e-com dev icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                    E-commerce development
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='info icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                    Complex informational sites
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='sites icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Membership/Subscription sites
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='complex icon of page' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Complex e-commerce
                                </p></div>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='component'>
                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='fronend dev icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Front-End Development
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='driven sites icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Community-driven sites
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='cross device icon' className='mr-2.5'></img>
                            <div>
                                <p> 
                                Cross-browser, cross-platform, and cross-device functionality
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='web portals icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Multi-functional web portals
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='icon Multi-functional' className='mr-2.5'></img>
                            <div>
                                <p > 
                                    E-commerce development
                                </p></div>
                        </div>

                        <div className='icons flex align-middle mb-5'>

                            <img src={icon} alt='migration icon' className='mr-2.5'></img>
                            <div>
                                <p > 
                                Easy migration to multiple mobile platforms
                                </p></div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
