import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import sales from "../../icons/sales 1.png"
import productivity from "../../icons/Vector.png"
import decision from "../../icons/decision-making 1.png"
import costs from "../../icons/Line-black.png"
import customer from "../../icons/Rating.png"
import Mobile_app_company from "./Mobile_app_company"
import Mobile_app_devlopment from "./Mobile_app_devlopment"
import SEO from '../../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '50px',
    width: '250px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));
export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Customer Relationship Management | Leeway Softech Pvt Ltd"
             description="Elevate your business with our powerful CRM software by Leeway Softech. Some of its benefits include streamlining sales, manageability of customer relationships, and increased growth using advanced features of CRM."
            />
            <Grid container spacing={1} className="crm_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Customer Relationship Management</h1>
                        <p className='text-lg font-normal'>Looking for CRM software that can help you grow your business? Our CRM software is easy to use and affordable, and it can help you track leads, manage sales, and provide excellent customer service. Customer relationship management (CRM) is the process of managing all interactions with customers, from lead generation to customer support. CRM software can help businesses track leads, manage sales, and provide excellent customer service.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
                <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Benefits of CRM</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={sales} alt='sales'></img>
                                </div>
                                <p  className='ml-[10%] mt-2'>Increased sales</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>
                                    <img src={productivity} alt='productivity'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Improved productivity</p>


                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={decision} alt='decision'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Better decision-making</p>


                            </div>
                        </Item>
                    </Grid>

                </Grid>


                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5' >
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div >

                                    <img src={costs} alt='costs'></img>
                                </div>
                                <p className='ml-[10%] mt-2'>Reduced costs</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={customer} alt='customer'></img>
                                </div>
                                <p className='ml-[10%]'>Improved customer satisfaction</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>



            </Box>


            <div className='text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5'>Customer Relations Management Services</div>
            <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

            <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>

                                    <p > 
                                    Our customer relationship management (CRM) services can help you improve your customer relationships, increase sales, and boost customer satisfaction.
                                    </p>
                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <p > 
                                    If you are looking for ways to improve your business, CRM services should be a top consideration. By implementing a CRM system, you can reap the many benefits.
                                    </p>
                            </div>
                        </Content>
                    </Grid>
                </Grid>
        </>
    );
}
