import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import gujarati from "../../certificat_img/corporate/bnsb_logo 1.png";
import mahindra from "../../certificat_img/corporate/clubmahindraa-removebg-preview.png";
import carda from "../../certificat_img/corporate/karda-removebg-preview.png";
import palava from "../../certificat_img/corporate/palava_icon-removebg-preview.png";
import dbs from "../../certificat_img/corporate/dbs-removebg-preview.png";
import neemax from "../../certificat_img/corporate/neemax-removebg-preview.png";
import uni from "../../certificat_img/corporate/11uni-removebg-preview.png";
import bhel from "../../certificat_img/corporate/bhel-removebg-preview.png";
import sports from "../../certificat_img/corporate/amd_sports-removebg-preview.png";
import gnfc from "../../certificat_img/corporate/gnfc1-removebg-preview.png";
import hgs from "../../certificat_img/corporate/hgs-removebg-preview.png";
import sarovar from "../../certificat_img/corporate/sarovar1-removebg-preview.png";
import poly from "../../certificat_img/image 20.png";
import mahi from "../../certificat_img/corporate/image 23.png";
import hungama from "../../certificat_img/corporate/hungama1-removebg-preview.png";
import ipro from "../../certificat_img/corporate/iprospect-removebg-preview.png";
import lodha from "../../certificat_img/corporate/lodha2-removebg-preview.png";
import SEO from "../../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "116px",
  width: "200px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Our Clients in Corporate Sector | Leeway Softech Pvt Ltd"
        description="Leeway Softech provides innovative IT solutions to transform Corporate Sector which helps to outcome it’s problems."
      />
      <Grid container spacing={1} className="corporate_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Corporate Sector</h1>
            <p className="text-lg font-normal">
              Transforming Corporate Operations with Smart Technology
            </p>
          </div>
        </Grid>
      </Grid>

      {/* <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>A Trusted Corporate Partner</div>
                <Grid container spacing={3}  className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={gujarati} alt='gujarati'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={mahindra} alt='mahindra'></img>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={carda} alt='carda'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={palava} alt='palava'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={dbs} alt='dbs'></img>
                            </div>
                        </Item>
                    </Grid>
               

               
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={neemax} alt='neemax' className='mt-[5%]'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={uni} alt='uni'></img>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={bhel} alt='bhel' className='mt-[3%]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={sports} alt='sports'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={gnfc} alt='gnfc'></img>
                            </div>
                        </Item>
                    </Grid>

                
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={hgs} alt='hgs' className='mt-[5%] mr-[8%]'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={sarovar} alt='sarovar'></img>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={poly} alt='poly' className='mt-[14%]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={mahi} alt='mahi'  className='mt-[-3%]'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={hungama} alt='hungama'  className='mt-[14%]'></img>
                            </div>
                        </Item>
                    </Grid>

               
                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={ipro} alt='ipro' className='mt-[11%]'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="card_spe">

                            <div className='flex justify-center align-middle'>
                                    <img src={lodha} alt='lodha' className='mt-[11%]'></img>
                            </div>
                        </Item>
                    </Grid>
      
                </Grid>
            </Box> */}

      <div className="text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5">
        Diverse Range of Services to Fulfil Your Needs
      </div>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Two-Way SMS: Instant Communication, Anytime, Anywhere
              </h4>

              <p>
                Two-way SMS services enable seamless communication between your
                business and its customers or employees. Whether it’s for quick
                support, feedback collection or important updates, this service
                allows for bidirectional communication, ensuring that your
                messages are not only received but responded to in a timely
                manner. With instant delivery, businesses can quickly address
                customer queries and enhance overall satisfaction.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                CPaaS: Scalable and Flexible Solutions{" "}
              </h4>
              <p>
                CPaaS provides businesses with the flexibility to integrate
                communication features such as SMS, voice and video into their
                applications or platforms. This scalable service allows your
                company to build tailored communication workflows, offering more
                personalized and efficient interactions with both customers and
                employees. By using CPaaS, businesses can create a seamless
                experience for their clients and employees, driving better
                engagement and operational efficiency.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Bulk Email: Effective Mass Communication at Scale{" "}
              </h4>
              <p>
                Bulk email services are an essential tool for businesses to
                communicate efficiently with a large audience. Whether you need
                to send newsletters, promotional campaigns, or important
                business updates, bulk email services ensure that your messages
                reach a wide audience with minimal effort. Customize your
                emails, schedule them in advance and track their performance to
                ensure your communications are impactful and relevant.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                WhatsApp API Services: Automated Support with a Personal Touch{" "}
              </h4>
              <p>
                WhatsApp API services offer an innovative way for businesses to
                engage with their customers in real time. These intelligent bots
                are capable of answering frequently asked questions, providing
                information about products and services and even helping with
                bookings or transactions, all while simulating a human
                conversation. By integrating WhatsApp API, companies can provide
                24/7 support and improve customer satisfaction with immediate
                responses.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
              <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Stay Competitive with Modern Communication Technology{" "}
              </h4>
              <p>
              By incorporating these advanced communication solutions, businesses can not only improve customer support but also enhance internal communication and streamline processes. Staying up-to-date with the latest technology ensures that your company remains competitive and agile in the global economy. Leeway Softech’s diverse range of services empowers your organization to communicate more effectively, stay informed and adapt to the ever-changing business landscape.
              </p>
            </div>
          </Content>
        </Grid>


      </Grid>
    </>
  );
}
