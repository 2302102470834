import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import health from "../icons/services/healthcare (1) 2.png"
import chatbot from "../icons/services/chatbot (4) 1.png"
import cpu from "../icons/services/cpu (1) 1.png"
import whatsapp from "../icons/services/Group (1).png"
import phone from "../icons/services/Group (2).png"
import message from "../icons/services/Group.png"
import media from "../images/img/Layer_2.png"
import menufacture from "../icons/services/manufacture 1.png"
import wallet2 from "../icons/services/wallet 1.png"
import mortar from "../icons/services/mortarboard 1.png"
import services from "../icons/services/professional-services 1.png"
import telecom from "../icons/services/telecommunications 1.png"
import callback from "../icons/services/callback 1.png"
import PopUpFromUi from '../components/PopupForm';
import ServicesForm from "./ServicesForm"

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '26px',
  boxShadow:'16px solid #0E1F3529',
  width:'130px',
  padding: theme.spacing(1),
//   textAlign: 'left',
  color: theme.palette.text.secondary,
}));



export default function RowAndColumnSpacing() {

    const [open, setOpen] = useState(false);

    const handleTextClick = () => {
        setOpen(true); // Open the popup when the text is clicked
    };
  return (
    <>
    <Grid container   className='main_content pt-[2%] bg-[#134CBC] px-6 md:px-24'>
 <Box  className='w-[100%]'>
  
    <div  className='text-center text-[#FFFFFF] text-[32px]'>Leeway Business Services offers best solutions customized for every Industry</div>
    <Grid container spacing={1} columns={16} className='flex justify-center p-2.5'>
      <Grid item xs={3}>
        <Item>
          <div  className='flex align-middle justify-center text-center'>
            <div>
              <img src={wallet2} alt='wallet2' className='inline'></img>
              <p className='text-[#FFFFFF]'>BFSI</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
            <img src={cpu} className='inline' alt='cpu'></img>
              <p className='text-[#FFB546]'>IT/Tes</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
            <img src={menufacture} className='inline' alt='menufacture'></img>
              <p style={{color:'#FFFFFF'}}>Manufacturing</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div style={{ display: 'flex',alignItems: 'center',justifyContent:'center',textAlign:'center' }}>
            <div>
            <img src={services} className='inline' alt='services'></img>
              <p className='text-[#FFB546]'>Services</p>
            </div>
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
            <img src={health} className='inline' alt='health'></img>
              <p style={{color:'#FFFFFF'}}>Healthcare</p>
            </div>
          </div>
        </Item>
      </Grid>

      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
            <img src={mortar} className='inline' alt='mortar'></img>
              <p className='text-[#FFB546]'>Education</p>
            </div>
          </div>
        </Item>
      </Grid>



      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
              <img src={telecom} className='inline' alt='telecom'></img>
              <p className='text-[#FFFFFF]'>Telecom</p>
            </div>
          </div>
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item>
          <div className='flex align-middle justify-center text-center'>
            <div>
              <img src={media} className='inline' alt='telecom'></img>
              <p className='text-[#FFB546]'>Media</p>
            </div>
          </div>
        </Item>
      </Grid>
    </Grid>

    <Grid container  className='flex justify-center p-2.5 mb-[3%]'>
            <Grid item xs={3}>
                <Grid  className='services_popup'>
                    
                        <div onClick={handleTextClick} className='services_sub_card justify-center'>
                            <p  className='text-[#FFFFFF]'>
                                We are very talented team.....<strong className='text-[#FFB546]'> Request a callback today!</strong><img src={callback}  className='w-[11px] h-[11px]  inline ml-2'alt="callback icon" />
                            </p>
                            
                        </div>
                  
                    <PopUpFromUi 
                        open={open}
                        setOpen={setOpen}
                        header={
                        <h3 className='main_header'>
                        <span className='d-flex justify-content-center popup_header'>Do you want us to call you back?</span>
                        <span className='d-flex justify-content-center text-white'>We will call you back in 90 minutes!</span>

                        </h3>
                        }
                        body={<ServicesForm/>}
                    />
                </Grid>
            </Grid>
        </Grid>
  </Box>

  

  </Grid>
    </>
   
  
  );
}
