import React from "react";
import "../component.css"

function FormInput({ newClassName,  note, ...rest }) {
  return (
    <>
    <div className={newClassName || "d-flex mb-2"}>
     
      <div className="">
        <input
          type="text"
          className="input-chat"
          id="input-placeholder"
          {...rest}
        />
       
      </div>
    </div>
    </>

  );
}

export default FormInput;
