import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import sales from "../../icons/mob-app/compliance (1) 1.png"
import productivity from "../../icons/efficiency.png"
import decision from "../../icons/decision-making 1.png"
import costs from "../../icons/Line-black.png"
import customer from "../../icons/Rating.png"
import Features_of_erp from "./Features_of_erp"
import SEO from '../../components/SEO';
const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '50px',
    width: '250px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));
export default function RowAndColumnSpacing() {
    return (
        <>
            <SEO title="Enterprise Resource Planning | Leeway Softech Pvt Ltd"
             description="Read about Leeway Softech's tailored solutions for your organization's enterprise resource planning. Their team follows the industry best practices to craft ERP product"
            />
            <Grid container spacing={1} className="erp_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                <div className='text-white text-center'>
                        <h1 className='text-3xl font-semibold'>Enterprise Resource Planning</h1>
                        <p className='text-lg font-normal'>Enterprise resource planning (ERP) is a software system that integrates all aspects of a business, including accounting, manufacturing, sales, and human resources. ERP systems can help businesses improve efficiency, reduce costs, and make better decisions.</p>
                    </div>
                </Grid>
            </Grid>

            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
                <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Benefits of ERP</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={sales} alt='sales of Increased compliance'></img>
                                </div>
                                <p className='ml-[10%]'>Increased compliance</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                <div>
                                    <img src={costs} alt='costs'></img>
                                </div>
                                <p className='ml-[10%]'>Reduce costs</p>


                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={decision} alt='decision'></img>
                                </div>
                                <p className='ml-[10%]'>Better decision-making</p>


                            </div>
                        </Item>
                    </Grid>

                </Grid>


                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                <div >

                                    <img src={productivity} alt='Improve efficiency' ></img>
                                </div>
                                <p className='ml-[10%]'>Improve efficiency</p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                <div>

                                    <img src={customer} alt='customer'></img>
                                </div>
                                <p className='ml-[10%]'>Improved customer satisfaction</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>



            </Box>
            <Features_of_erp/>

            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>Conclusion</div>
            <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>

                <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Use clear and concise language.</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies provide a platform for businesses to sell their products or services online. This includes everything from setting up the store to managing inventory and shipping.
                                    </p>
                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Avoid jargon and technical terms.</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies offer a variety of payment processing options, such as credit cards, debit cards, and PayPal. This makes it easy for customers to pay for their purchases online.
                                    </p>

                            </div>
                        </Content>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Use keywords throughout the article.</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies help businesses to reach their target audience through online marketing and advertising. This includes search engine optimization (SEO), pay-per-click (PPC) advertising, and social media marketing.
                                    </p>

                            </div>
                        </Content>
                    </Grid>

                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Keep the article informative and engaging.</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies provide customer support to help customers with their orders, returns, and other issues. This ensures that customers have a positive experience shopping online.
                                    </p>

                            </div>
                        </Content>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} className='flex justify-center p-2.5'>
                    <Grid items xs={6}>
                        <Content className='sub_card'>
                            <div className='icons text-center p-[5%]'>
                                    <div>
                                        <h3 className='mb-2  text-[#139C32] text-xl font-medium'>Proofread the article carefully before publishing.</h3>
                                    </div>
                                    <p > 
                                    E-commerce companies provide customer support to help customers with their orders, returns, and other issues. This ensures that customers have a positive experience shopping online.
                                    </p>

                            </div>
                        </Content>
                    </Grid>
                </Grid>
        </>
    );
}
