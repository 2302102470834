import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import icon from "../../icons/mob-app/Vector (2).png"




const Infrastructure = () => {
    return (
       
    <>

    <Box sx={{ width: '100%'}} >
    <h1  className='text-3xl font-semibold text-center color-134CBC mt-5'>Why Choose Us for Development?</h1>
    <Grid 
            container 
            spacing={1}
            paddingTop={4}
            className='flex justify-center align-middle'
        >
            <Grid item xs={12} md={6} lg={6} className='component'>
                {/* Your icons and text here */}
                <div className='icons flex align-middle mb-5'>
                    <img src={icon} alt='SDKs icon' className='mr-2.5 h-5' />
                    <div>
                        <p>Understanding of various SDKs and their usage .</p>
                    </div>
                </div>
        
        <div className='icons flex align-middle mb-5' >
            
               <img src={icon} alt='mobile apps' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                Development of architectures and client-server-based mobile apps
                </p></div>
        </div>
        
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='approach' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Problem-solving approach
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5' >
            
            <img src={icon} alt='methodologies' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Follows Agile methodologies and analytical skills
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5'  >
            
            <img src={icon} alt='applications icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Provide a secure platform for applications
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5'  >
            
            <img src={icon} alt='UX icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             UX and UI-based applications
             </p></div>
     </div>
        
        </Grid>  


        <Grid item xs={12}  md={6} lg={6}  className='component'>
        <div className='icons flex align-middle mb-5'  style={{ display: 'flex', alignItems: 'center',}}>
            
               <img src={icon} alt='24/7 support icon' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                On-time deployment and 24/7 support
                </p></div>
        </div>
       
        <div className='icons flex align-middle mb-5'  >
            
               <img src={icon} alt='development icon' className='mr-2.5 h-5'></img>
               <div>
                <p > {/* Removed top margin */}
                Affordable and time-saving development methods
                </p></div>
        </div>
     
        <div className='icons flex align-middle mb-5'  >
            
            <img src={icon} alt='downloadable icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Easily downloadable apps from Play Stores and App Stores
             </p></div>
     </div>

        <div className='icons flex align-middle mb-5'  >
            
            <img src={icon} alt='technology icon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Always ready to implement newly arrived technology
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5'  >
            
            <img src={icon} alt='multipleicon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Easy migration to multiple mobile platforms
             </p></div>
     </div>
       
        <div className='icons flex align-middle mb-5'  >
            
            <img src={icon} alt='eleganticon' className='mr-2.5 h-5'></img>
            <div>
             <p > {/* Removed top margin */}
             Ability to keep code elegant and clean
             </p></div>
     </div>
     
        </Grid>  
      </Grid>
    </Box>
   
    
    </>
    );
};

export default Infrastructure;
