import React from 'react';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid2';

import FormButton from '../components/FormButton';

export default function RowAndColumnSpacing({ handleClose, handleDecline, handleAccept }) {
    return (
        <Card className='payment_form 
            cookiebox-card'
        >
            <Grid container spacing={3} className='cookie_main flex justify-center items-center'>
                <Grid item xs={12} className='cookie_sub flex items-center text-center'>
                    <div className='flex items-center'>
                    <img 
                        src={require("..//icons/services/surface2701.png")} 
                        alt="Service Icon" 
                      className='mb-[10px] max-w-full h-auto'
                    />
                    <p className='mb-[5px] grow text-[13px] text-left ml-6'>
                        We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.
                    </p>
                    </div>
                  
                    <div className='cookies_main_btn flex gap-[10px] justify-center items-center text-[#3B3B3B]'>
                        <FormButton 
                            label="Accept cookies" 
                            className='p-[10px] rounded-[56px] text-[12px] min-w-[150px]  h-full bg-[#134cbc] ml-[10px] shadow-md font-[400]'
                            onClick={handleAccept}
                        />
                        <FormButton 
                            label="Decline" 
                            className='cookie_btn p-[10px] min-w-[150px] h-full rounded-[56px] text-[12px] shadow-md font-normal'
                            onClick={handleDecline}
                        />
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
}