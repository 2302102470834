import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import green from "../../certificat_img/corporate/green-removebg-preview.png";
import veg_puro from "../../certificat_img/corporate/vegpure-removebg-preview.png";
import sheth from "../../certificat_img/corporate/sheth1-removebg-preview.png";
import neem from "../../certificat_img/corporate/neemax-removebg-preview.png";
import SEO from "../../components/SEO";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "100px",
  width: "200px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="E-Commerce | Leeway Softech Pvt Ltd"
        description="E-commerce is the buying and selling of goods and services over the internet. It is a rapidly growing industry."
      />
      <Grid container spacing={1} className="comm_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">E-Commerce Sector</h1>
            <p className="text-lg font-normal">
            Digital Transformation for E-commerce and Retail: Driving Growth and Innovation
            </p>
          </div>
        </Grid>
      </Grid>

      {/* <Box className="it_infra pt-[2%] w-full px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>A Trusted Corporate Partner</div>
                <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                    <img src={green} alt='green images' className='images'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                    <img src={veg_puro} alt='veg_puro'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle'>
                                    <img src={sheth} alt='sheth'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item>

                            <div className='flex justify-center align-middle mt-[7%]'>
                                    <img src={neem} alt='neem'></img>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

            </Box> */}

      <div className="text-3xl font-semibold text-[#139C39] text-center mb-5 mt-5">
        Diverse Range of Services to Fulfil Your Needs
      </div>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Enhancing E-Commerce & Retail Experiences with Advanced Communication Solutions{" "}
              </h4>
              <p>
              The integration of E-Commerce and retail has become an increasingly popular trend among businesses aiming to meet the rising demands of modern consumers. Customers today expect personalized, seamless and responsive service. To stay competitive and maintain a loyal customer base, businesses must adapt to these heightened expectations by offering advanced and efficient communication solutions.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Revolutionizing Customer Engagement with Smart Communication Tools{" "}
              </h4>
              <p>
              In order to provide better customer service, E-Commerce and retail businesses must leverage cutting-edge communication tools. Solutions such as 1-2 Way SMS, CPaaS, Bulk Email and WhatsApp Chatbot services can significantly enhance customer engagement, streamline support and increase overall satisfaction.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                1-2 Way SMS: Instant Communication for Quick Resolution{" "}
              </h4>
              <p>
              1-2 Way SMS is a highly effective messaging solution that enables customers to send inquiries directly to E-Commerce or retail businesses, receiving instant responses. This real-time interaction not only resolves customer queries quickly but also offers businesses an efficient way to monitor and improve their customer service. With this solution, customers enjoy a seamless experience, getting their questions answered promptly.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                CPaaS & Bulk Email: Scalable Communication for Large Audiences{" "}
              </h4>
              <p>
              Bulk messaging via CPaaS and email solutions allows businesses to reach large groups of customers simultaneously. Whether you are promoting a new product, running a sale or sending important updates, these tools help businesses communicate important information in a timely manner. With easy customization and scheduling, businesses can create targeted campaigns that enhance customer engagement and boost loyalty.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
               WhatsApp Chatbot Service{" "}
              </h4>
              <p>
              WhatsApp Chatbot services empower E-Commerce and retail businesses to provide round-the-clock customer support via an AI-powered chatbot. Available 24/7, the chatbot answers customer inquiries, provides information and offers personalized service, all via WhatsApp. This instant and accessible support ensures that customers always have the assistance they need, improving overall satisfaction and increasing the likelihood of return visits.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="icons text-center p-[5%]">
            <h4
                className="mb-2 text-[#139C32] color-134CBC  font-small"
                style={{ fontWeight: 600 }}
              >
                Building Lasting Relationships through Efficient Communication{" "}
              </h4>
              <p>
              By incorporating these advanced communication tools, E-Commerce and retail businesses can deliver an enhanced customer experience that keeps clients engaged and satisfied. Personalized, efficient, and timely responses foster stronger relationships, encouraging customer loyalty and long-term retention. Ultimately, these solutions not only help businesses meet modern customer expectations but also pave the way for building a robust base of loyal, repeat customers.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
    </>
  );
}
