import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import bank from "../../certificat_img/Group.png";
import iso from "../../certificat_img/Group (1).png";
import iso_iec from "../../certificat_img/Group (2).png";
import Mou from "../../certificat_img/Mou_Achivment.png";
import SEO from "../../components/SEO";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Our Achievements | Leeway Softech Pvt Ltd"
        description="Leeway Softech is recognized as a leading banking technology service provider by CIA Reviews. With ISO certifications and numerous achievements."
      />
      <Grid container spacing={1} className="achive_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-3">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Achievements</h1>
            <p className="text-lg font-normal">
              Committed to Excellence: Delivering Quality Standards Every Step
              of the Way
            </p>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 3, sm: 6, md: 12 }}
        className="px-[15%]"
      >
        <Grid items size={12}>
          <Item>
            <div className="icons text-center">
              <div className="text-[#134CBC] text-2xl font-bold my-4">
                TOP 10 PROMISING BANKING TECHNOLOGY
              </div>
              <p className="my-4">
                The proliferation of technology has revolutionized the banking
                and finance industry. With constantly evolving technology, it is
                crucial for banks to stay ahead of the curve when it comes to
                utilizing cutting edge technology and tools to provide superior
                customer experience while elevating risk management to the
                highest operational monitoring standard. CIO Review has
                recognized the top 10 most promising banking technology
                providers of 2021. These technology vendors represent an
                innovative solution for making banking operations more efficient
                at the enterprise level while providing smarter customer
                experiences.
              </p>
              <img
                src={bank}
                alt="Service Icon"
                className="mx-auto h-[200px] w-[250px]"
              />
            </div>
          </Item>
        </Grid>

        <Grid items size={12}>
          <Item>
            <div className="icons text-center">
              <div className="text-[#139C32] text-2xl font-bold my-4">
                ISO QUALITY MANAGEMENT SYSTEM (QMS)
              </div>
              <p className="my-4">
                The International Organization for Standardization (ISO) is a
                globally recognized body for the quality management standard and
                offers guidelines to ensure an efficient quality management
                system (QMS). Adopting these standards can help businesses meet
                their customer needs while also offering continual improvements.
                The standards are a set of principles and tools that ensure all
                aspects of businesses are motivated towards achieving the
                highest quality of products with regular reviews and
                improvements.
              </p>
              <img
                src={iso}
                alt="iso"
                className="mx-auto h-[200px] w-[250px] my-2"
              />

              <p className="my-4">
                The ISO’s QMS covers all areas of quality management, from
                monitoring the production process and service delivery to
                customer support. With the ISO QMS as a cornerstone of
                operations, organizations can be sure that their products meet
                customer requirements and safety regulations while also
                utilizing current technological advancements in the business
                process. The standard also requires organizations to be
                proactive in their approach, setting up departments to analyze
                customer feedback and look for constant improvements in products
                and services.
              </p>
            </div>
          </Item>
        </Grid>

        <Grid items size={12}>
          <Item>
            <div className="icons text-center">
              <div className="text-[#134CBC] text-2xl font-bold my-4">
                ISO/IEC 27001:2013 INFORMATION SECURITY MANAGEMENT SYSTEM
              </div>
              <p className="my-4">
                The ISO/IEC 27001 is the international standard for information
                security management systems that helps organizations protect
                their data and other forms of information against malicious
                cyber-attacks. It is designed to help organizations combine
                their information security strategies and compliance with the
                highest standards
              </p>
              <img
                src={iso_iec}
                alt="iso_iec"
                className="mx-auto h-[200px] w-[250px] py-2"
              />
              <p className="my-4">
                This standard also requires organizations to be mindful of many
                aspects of their information security system, such as access
                control, physical security, user rights management, and
                monitoring of security incidents. These processes are
                implemented to ensure organizations have the right processes and
                controls in place to protect information from unauthorized
                access, misuse, corruption, and destruction.
              </p>

              <p className="my-4">
                By following the standard, organizations can ensure their IT
                environment is well-managed and secure while also meeting
                customer and legal requirements. Additionally, with proper
                implementation, organizations can protect their information and
                data from cyber-attacks, limit downtime, and improve their
                competitive advantage.
              </p>
            </div>
          </Item>
        </Grid>

        <Grid items size={12}>
          <Item>
            <div className="icons text-center">
              <div className="text-[#139C32] text-2xl font-bold my-4">
                MOU Sign With Govt Of Gujarat For AI Which Generating 250+
                Employment in Gujarat
              </div>
              <p className="my-4">
                The International Organization for Standardization (ISO) is a
                globally recognized body for the quality management standard and
                offers guidelines to ensure an efficient quality management
                system (QMS). Adopting these standards can help businesses meet
                their customer needs while also offering continual improvements.
                The standards are a set of principles and tools that ensure all
                aspects of businesses are motivated towards achieving the
                highest quality of products with regular reviews and
                improvements.
              </p>
              <img
                src={Mou}
                alt="Mou"
                className="mx-auto h-[200px] w-[250px] my-2"
              />
            </div>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
