// import React from "react";
// import Instagram from "../media/socialmedia/Facebook.png";
// import facebook from "../media/socialmedia/Facebook.png";
// import twitter from "../media/socialmedia/Twitter.png";
// import linkedin from "../media/socialmedia/LinkedIn.png";
// import { Link } from "react-router-dom";

// function SocialMedia() {
//   return (
//     <div className="social_links flex align-middle ml-auto mt-1.5">
//       <Link
//         to="https://www.instagram.com/leewaysoftech_pvt_ltd/"
//         target="blank"
//         className="social_media"
//       >
//         <img
//           src={Instagram}
//           alt="Instagram"
//           className="ml-2.5 mr-2.5 w-10 h-10"
//         />
//       </Link>
//       <Link to="https://www.facebook.com/LEEWAY2372012/" target="blank">
//         <img
//           src={facebook}
//           alt="Facebook"
//           className="ml-2.5 mr-2.5 w-10 h-10"
//         />
//       </Link>
//       <Link to="https://twitter.com/leewaypvt" target="blank">
//         <img src={twitter} alt="Twitter" className="ml-2.5 mr-2.5 w-10 h-10" />
//       </Link>
//       <Link
//         to="https://www.linkedin.com/company/leeway-softech-pvt-ltd"
//         target="blank"
//       >
//         <img
//           src={linkedin}
//           alt="LinkedIn"
//           className="ml-2.5 mr-2.5 w-10 h-10"
//         />
//       </Link>
//     </div>
//   );
// }

// export default SocialMedia;

import { X } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
// import './SocialIcons.css';

const SocialIcons = () => {
  return (
    <nav className="social">
      <ul>
        <li>
          <Link to="/contact-us">
            <i className="fa fa-phone"></i>
            <b>Contact Us</b>
          </Link>
        </li>
        <li>
          <a
            href="https://www.instagram.com/leewaysoftech_pvt_ltd/"
            target="_blank"
          >
            <i className="fa fa-instagram"></i>
            {/* <img src={require("../media/socialmedia/facebook_icon.png")} style={{width: "30px"}} /> */}

            <b>Instagram</b>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/LEEWAY2372012/" target="_blank">
            <i className="fa fa-facebook"></i>
            <b>Facebook</b>
          </a>
        </li>

        <li>
          <a href="https://twitter.com/leewaypvt" target="_blank">
            {/* <i className="fa fa-twitter"></i> */}
            {/* <i className="fa fa-x-twitter"></i> */}

            {/* <i style={{height: 20}}> */}
              <X style={{fontSize: 13}} />
            {/* </i> */}

            <b>X</b>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/leeway-softech-pvt-ltd"
            target="_blank"
          >
            <i className="fa fa-linkedin"></i>
            <b>LinkedIn</b>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default SocialIcons;
