import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SEO from '../../components/SEO';



const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="The Impact of IoT Technology in Business | Leeway Softech"
             description="Explore IoT solutions at Leeway Softech, where they provide Internet of Things solutions that enable businesses to unlock the full potential of a connected world."
            />
            <Grid container spacing={1} className="internet_page main_content">
                <Grid item xs={12} sm={12} lg={12} style={{ padding: '5%' }}>
                    <div style={{}}>
                        <h1 style={{ color: 'white', textAlign: 'center' }}>Internet of Things</h1>
                        <p style={{ color: 'white', textAlign: 'center' }}>Our expertise and passion lie in providing innovative IoT solutions that empower businesses to harness the true potential of a connected world.</p>
                    </div>
                </Grid>
            </Grid>


            <Box sx={{ width: '100%' }} className="it_infra">
                <h1 style={{ color: '#134CBC', textAlign: 'center' }}>Lorem Ipsum Is Dummy Text Here Only </h1>
                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>

                <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>At Leeway Softech Pvt. Ltd.</h3>
                                    <p style={{}}>
                                    we are at the forefront of cutting-edge IoT (Internet of Things) technology. Our expertise and passion lie in providing innovative IoT solutions that empower businesses to harness the true potential of a connected world.    
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Connectivity</h3>
                                    <p style={{}}>
                                    We understand that seamless connectivity forms the foundation of any successful IoT ecosystem. Leeway Softech excels in creating robust and reliable IoT connectivity solutions that ensure your devices can communicate efficiently and securely, enabling real-time data exchange.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Devices</h3>
                                    <p style={{}}>
                                    Embrace the future with our wide range of smart and connected IoT devices. Whether it's sensors, wearables, or smart appliances, our IoT devices are designed to enhance efficiency, productivity, and user experiences.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Platforms</h3>
                                    <p style={{}}>
                                    Our versatile IoT platforms are designed to cater to diverse business needs. With powerful analytics, data management, and device management capabilities, our platforms enable you to effortlessly manage and control your IoT ecosystem.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Services</h3>
                                    <p style={{}}>
                                    Experience unparalleled support and services with Leeway Softech's IoT offerings. From installation and deployment to maintenance and upgrades, we are committed to ensuring that your IoT infrastructure runs flawlessly.
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Applications</h3>
                                    <p style={{}}>
                                    Explore the endless possibilities of IoT applications with us. We craft custom IoT applications that are tailored to your specific requirements, amplifying your business's capabilities and generating new opportunities.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={3} columns={16} justifyContent="center" padding={'10px'}>
                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Integration</h3>
                                    <p style={{}}>
                                    Integrating IoT seamlessly with your existing systems can be a complex task. At Leeway Softech, our expert teams make this process hassle-free, ensuring smooth integration of IoT into your operations and infrastructure.
                                    </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>IoT Consulting</h3>
                                    <p style={{}}>
                                    Leverage our in-depth expertise and experience through our IoT consulting services. Our consultants work closely with your team to strategize, plan, and implement the most suitable IoT solutions for your unique business goals.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                        <div className='icons' style={{ textAlign: 'center', padding: '5%' }}>
                                    <h3 style={{color:'#139C32'}}>Unlock the Power of IoT with Leeway Softech Pvt. Ltd.</h3>
                                    <p style={{}}>
                                    As a leading IoT solutions provider, we are committed to driving digital transformation for businesses across various industries. Our passion for innovation, dedication to quality, and focus on customer satisfaction set us apart. Join us on the journey to a smarter, connected future.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                </Grid>
            </Box>


        </>
    );
};

export default Infrastructure;
