import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import bfuosh from "../../certificat_img/BFUOHS-LOGO 1.png";
import gtu from "../../certificat_img/GTU-LOGO 1.png";
import nfsu from "../../certificat_img/NFSU-logo-new 1.png";
import panjab from "../../certificat_img/Panjab Uni Logo 1.png";
import vnsgu from "../../certificat_img/VNSGU-LOGO 1.png";
import pdeu from "../../certificat_img/PDEU-LOGO 1.png";
import SEO from "../../components/SEO";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  height: "170px",
  width: "230px",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  border: " 1.5px solid #C4D2EE",
  width: "30vw",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
}));

export default function RowAndColumnSpacing() {
  return (
    <>
      <SEO
        title="Education Sector | Leeway Softech Pvt Ltd"
        description="Leeway Softech has all the services you need to update your education institution's website and keep students and staff informed."
      />
      <Grid container spacing={1} className="edu_page main_content">
        <Grid item xs={12} sm={12} lg={12} className="p-[5%]">
          <div className="text-white text-center">
            <h1 className="text-3xl font-semibold">Education Sector</h1>
            <p className="text-lg font-normal">
              Empowering Educational Institutions with Smart, Secure Digital
              Solutions, Transforming Communication, Data Management and
              Operational Efficiency in Education.
            </p>
          </div>
        </Grid>
      </Grid>

      {/* <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">
            <div className='text-3xl font-semibold color-134CBC text-center mb-5'>A Trusted Partner</div>
                <Grid container spacing={4} columns={16} className='flex justify-center p-2.5'>
                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                    <img src={bfuosh} alt='bfuosh'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                    <img src={gtu} alt='gtu'></img>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                    <img src={nfsu} alt='nfsu'></img>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                    <img src={panjab} alt='panjab'></img>
                                </div>
                                
                        </Item>
                    </Grid>

                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                    <img src={vnsgu} alt='vnsgu'></img>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} >
                        <Item className="">

                            <div className='flex justify-center align-middle'>
                                    <img src={pdeu} alt='pdeu'></img>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

            </Box> */}

      <div className="text-3xl font-semibold text-[#139C32] text-center mb-5 mt-5">
        Diverse Range of Services to Fulfil Your Needs
      </div>
      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  Website Hosting & Secure Data Storage
                </h3>
              </div>
              <p>
                Leeway Softech provides robust cloud hosting and secure storage
                solutions, tailored to meet the unique data management needs of
                educational institutions. From storing sensitive student
                information to managing academic research and financial records,
                our secure servers ensure your institution’s data is safe and
                easily accessible. Hosting your institution’s website on our
                platform guarantees maximum uptime, fast performance and
                reliable service, supporting your institution's digital presence
                and online activities.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  Bulk Email Services for Efficient Communication
                </h3>
              </div>
              <p>
                Communication is crucial in education and bulk email services
                from Leeway Softech make it easier than ever to send important
                updates, newsletters, event notifications and announcements to
                students, parents, faculty and staff. Our solution offers easy
                customization, high deliverability rates, and scalability,
                allowing you to manage large communication campaigns with ease.
                Whether you’re sharing academic updates, extracurricular events
                or emergency alerts, our bulk email service ensures your
                messages reach the right people at the right time.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  SMS Services for Real-Time Notifications
                </h3>
              </div>
              <p>
                Our CPaaS (Communication Platform as a Service) and bulk SMS
                solutions allow educational institutions to send timely,
                automated notifications to large groups. Whether it’s reminders
                for upcoming exams, event updates, or emergency alerts, our SMS
                service enables you to instantly inform students and staff,
                regardless of their location. You can easily schedule and
                customize messages to ensure effective communication across your
                entire community.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  SSL Certificates
                </h3>
              </div>
              <p>
                Security is a top priority in education and our SSL certificates
                provide robust protection for your institution’s website. SSL
                encryption ensures that all data exchanged through your website
                is secure, protecting sensitive information such as student
                records, financial transactions and personal data. With Leeway
                Softech’s SSL solutions, you can rest assured that your
                institution’s online presence is secure and compliant with
                industry standards.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  Cloud Auditing for Enhanced Data Management
                </h3>
              </div>
              <p>
                Managing large volumes of data requires effective organization
                and security. Our cloud auditing services help your institution
                track, audit, and optimize data storage, backup, and recovery
                processes. With Leeway Softech’s cloud audit solutions, you can
                ensure your data is stored securely, recoverable in case of
                emergencies, and compliant with necessary regulations. Our
                comprehensive auditing tools give you the visibility and control
                needed to manage your institution’s digital assets effectively.
              </p>
            </div>
          </Content>
        </Grid>

        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  Chatbot Solutions for 24/7 Support
                </h3>
              </div>
              <p>
                Chatbots are designed to handle routine inquiries, provide
                information on academic programs, answer frequently asked
                questions and offer guidance on various processes. By automating
                responses, you free up valuable time and resources while
                ensuring that users receive timely support, enhancing the
                overall experience for students and staff alike.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="flex justify-center p-2.5">
        <Grid item xs={6}>
          <Content className="sub_card">
            <div className="flex justify-center align-middle flex-col text-center">
              <div>
                <h3 className="mb-2 text-[#139C32] color-134CBC text-xl font-medium">
                  Data Audit Services for Accurate Insights
                </h3>
              </div>
              <p>
                Our data audit services help your institution process and
                synthesize large volumes of data with accuracy and efficiency.
                Whether it’s analysing academic performance, student enrollment,
                or financial records our audit services ensure that your data is
                organized, accurate and actionable.
              </p>
            </div>
          </Content>
        </Grid>
      </Grid>
    </>
  );
}
