import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
// import bank from "../../icons/icon_new_7.svg.png"
import code from "../icons/mob-app/code.png"
import wordpress from "../icons/mob-app/wordpress_270832 1.png"
import joomal from "../icons/mob-app/joomla.png"
import magento from "../icons/mob-app/magento.png"
import open from "../icons/mob-app/images (5) 1.png"
import drupal from "../icons/mob-app/drupal.png"
import SEO from "../components/SEO"


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '10px',
  boxShadow: '0px 5px 24px 0px #0E1F3529',
  height:'150px',
  width:'200px',
  backgroundColor: '#fff', 
  padding: theme.spacing(1),
//   textAlign: 'left',
//   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
<>
    <SEO title=" Best PHP Popular Frameworks | Leeway Softech Pvt Ltd"
             description="Leeway Softech, based in Mumbai, offers bulk email and SMS services, website and mobile app development, as well as promotional and transactional SMS solutions, among other services.PHP is a robust, open-source scripting language utilized for creating dynamic web pages, especially with the help of PHP frameworks. It can be used to build both front-end and back-end of a web app."
            />
    <Grid container spacing={1} className="php_page main_content">
    <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
    <div className='text-white text-center'>
    <h1 className='text-3xl font-semibold'>Our Clients</h1>
            <p className='text-lg font-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
        </div>
    </Grid>
</Grid> 


<Box className="it_infra pr-[2%] pl-[2%] pt-[2%]">
<div className='text-[#134CBC] text-center text-2xl font-bold my-4'>We are looking for</div>
    <Grid container spacing={2} columns={16} className='flex justify-center p-2.5'>
      <Grid item xs={6} >
        <Item className="card">
          
            <div className='text-center'>
                <img src={code} alt='code' className='inline p-2.5'></img>
                <h3 className='p-2'>Codeigniter</h3>
                <a href="/php-codeigniter" className='no-underline color-134CBC text-sm '>View Case Study</a>
              
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card">
          
            <div className='text-center'>
                <img src={joomal} alt='joomal' className='inline p-2.5'></img>
                <h3 className='p-2'>Joomla</h3>
                <a href="/php-joomla"  className='no-underline color-134CBC text-sm'>View Case Study</a>
           
              
          </div>
        </Item>
      </Grid>
      <Grid item xs={6} >
        <Item className="card">
          
            <div className='text-center'>
                <img src={magento} alt='magento' className='inline p-2.5'></img>
                <h3 className='p-2'>Magento</h3>
                <a href="/php-magento"  className='no-underline color-134CBC text-sm'>View Case Study</a>
            
              
          </div>
        </Item>
      </Grid>

      <Grid item xs={6} >
      <Item className="card">
          
            <div className='text-center'>
                <img src={open} alt='open' className='inline p-2.5'></img>
                <h3 className='p-2'>OpenCart</h3>
                <a href="/php-opencart"  className='no-underline color-134CBC text-sm'>View Case Study</a>
            
              
          </div>
        </Item>
      </Grid>
  
      <Grid item xs={6} >
      <Item className="card">
         
            <div className='text-center'>
                <img src={drupal} className=' inline p-2.5' alt='drupal'></img>
                <h3 className='p-2'>Drupal</h3>
                <a href="/php-drupal"  className='no-underline color-134CBC text-sm'>View Case Study</a>
           
              
          </div>
        </Item>
      </Grid>
     
    </Grid>
  </Box>
  </>
  );
}
