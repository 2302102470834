import React from 'react';
import "../../App.css"
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import grp from "../../images/Mask group.png"
import icon from "../../icons/mob-app/Vector (2).png"
import SEO from '../../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    width: '30vw',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
  }));


const Infrastructure = () => {
    return (

        <>
            <SEO title="PBX Services | Leeway Softech Pvt Ltd"
             description="PBX services are an excellent way to enhance your business's communication and productivity. Discover the various types of PBX services available at Leeway Softech"
            />
            <Grid container spacing={1} className="pbx_page main_content">
                <Grid item xs={12} sm={12} lg={12} className='p-[5%]'>
                    <div className='text-white text-center'>
                        <h1  className='text-3xl font-semibold'>IPBX Service</h1>
                        <p className='text-lg font-normal'>IPBX provides communication services such as telephony, voice mail, conference calling and call forwarding. IPBX systems offer cost-effective solutions for businesses and improve call management.</p>
                    </div>
                </Grid>
            </Grid>



            <Box className="it_infra pt-[2%] w-full  px-6 md:px-24">


                <div className='text-3xl font-semibold color-134CBC text-center mb-5'>IPBX Service</div>
                {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 6, md: 6 }} padding={'10px'} className='flex  justify-center ml-[14%] w-[70vw] bg-[#F2F5FB] p-2.5'>
                    <Grid item xs={12} md={6} className=' h-[330px] w-[300px] flex justify-center'>
                        <img src={require("../../images/Mask group.png")} className=' object-contain' alt="Phone" />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className='flex flex-col text-left w-[40vw]'>
                        <h3 className='mb-2  text-xl font-medium'>Private Branch Exchange</h3>
                        <p>
                            A PBX system manages incoming and outgoing calls within an organization. It consists of hardware and software components. The hardware includes phone lines, switching equipment, and telephone sets, while the software controls system operations. A PBX system efficiently routes calls, enables voicemail services and conference calling, and offers features like call forwarding, caller ID, and call blocking.
                        </p>
                    </Grid>
                </Grid> */}

                <Grid container className='bg-[#F2F5FB]' spacing={{ xs: 2, md: 3, lg:3 }} >
                    <Grid size={{ xs: 12, md: 4, lg:4}}>
                        <div className=' w-full'>
                        <img src={require("../../images/Mask group.png")} className='object-contain' alt="object-contain" /></div>
                    </Grid>
                     <Grid size={{ xs: 12, md: 8, lg:8}} className=' flex flex-col justify-center px-12'>   
                        <h3 className='mb-2 text-xl font-medium'>Private Branch Exchange</h3>
                        <p>
                            A PBX system manages incoming and outgoing calls within an organization. It consists of hardware and software components. The hardware includes phone lines, switching equipment, and telephone sets, while the software controls system operations. A PBX system efficiently routes calls, enables voicemail services and conference calling, and offers features like call forwarding, caller ID, and call blocking.
                        </p>
                    </Grid>
                </Grid>


                <div className='text-3xl font-semibold text-[#139C32] text-center mt-5'>Why Choose Us For The PBX Services?</div>
                <Grid container spacing={5}  paddingTop={4}
           
           className='flex justify-center align-middle'>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='component'>
                        {/* Your icons and text here */}
                        <div className='icons text-center flex align-middle mb-5' >
                            <img src={icon} alt='Robusticon' className='mr-2.5 h-5' />
                            <div>
                                <p>Robust Options</p>
                            </div>
                        </div>

                        <div className='icons text-center flex align-middle mb-5' >
                            <img src={icon} alt='Hostedicon' className='mr-2.5 h-5' />
                            <div>
                                <p > {/* Removed top margin */}
                                    Hosted systems are cloud-based
                                </p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} className='component'>
                        <div className='icons text-center flex align-middle mb-5' >
                            <img src={icon} alt='management icon' className='mr-2.5 h-5' />
                            <div>
                                <p > {/* Removed top margin */}
                                    Offering simpler setup and management
                                </p>
                            </div>
                        </div>

                        <div className='icons text-center flex align-middle mb-5' >
                            <img src={icon} alt='Business icon' className='mr-2.5 h-5' />
                            <div>
                                <p > {/* Removed top margin */}
                                    Business to make an informed decision.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>


                <div className='text-3xl font-semibold color-134CBC  text-center mt-5'>Awesome Features </div>
                <Grid container spacing={3} columns={16} paddingTop={4}
           
           className='flex justify-center align-middle mb-5'>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div className='icons text-center p-[5%]' >

                                    <p >
                                    Implementing a PBX system brings several advantages. It reduces costs by eliminating the need for individual phone lines, resulting in lower monthly bills. PBX systems enhance productivity by accurately routing calls, allowing employees to focus on their tasks. They also facilitate efficient communication through features like voicemail and conference calling, enabling simultaneous interactions.
                                    </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid items xs={6}>
                        <Item className='sub_card'>
                            <div   className='icons text-center p-[5%]'>
                                    <p >
                                    Implementing a PBX system brings several advantages.PBX systems enhance security with features such as call blocking, caller ID, and call forwarding, protecting confidential information.PBX systems enhance productivity by accurately routing calls, allowing employees to focus on their tasks. They also facilitate efficient communication through features like voicemail and conference calling.
                                    </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>


                
            </Box>


        </>
    );
};

export default Infrastructure;
