import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useState } from "react";
import eve1 from "../event/IMC/Mask group (1).png";
import eve2 from "../event/IMC/Mask group (2).png";
import eve3 from "../event/IMC/Mask group (3).png";
import eve4 from "../event/IMC/Mask group (4).png";
import eve5 from "../event/IMC/Mask group (5).png";
import eve6 from "../event/IMC/Mask group (6).png";
import eve7 from "../event/IMC/Mask group (7).png";
import eve8 from "../event/IMC/Mask group (8).png";
import eve9 from "../event/IMC/Mask group (9).png";
import event from "../event/IMC/Mask group.png";
import SEO from "../components/SEO";
import FormButton from "../components/FormButton";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  // border: ' 1.5px solid #C4D2EE',
  padding: theme.spacing(1),
  //   textAlign: 'left',
  //   color: theme.palette.text.secondary,
}));

const Content = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "12px",
  boxShadow: "0px 6.3px 30px 0px #3D3D3D0D",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // height:'73px',
  // width:'500px'
}));
export default function RowAndColumnSpacing() {
  const [active, setActive] = useState("All");

  const onClickActive = (btnName) => {
    setActive(btnName);
  };
  return (
    <>
      <SEO
        title="IMC Event | Leeway Softech Pvt Ltd"
        description="Leeway Softech is participated in India Mobile Congress, the premier event showcasing the future of technology. At the 'India mobile congress' exhibition, they showcased their recent innovations in their domain."
      />

      <Grid container spacing={1} className="leeway_page main_content bg-color">
        <Grid item xs={12} sm={12} lg={12} className="p-10">
          <div>
            <h1 className="text-center text-white font-bold text-[32px] p-2 pt-5">
              Life@Leeway
            </h1>
            <p className="text-center text-white  text-[16px] p-3">
              Experience the Vibrant Life @Leeway. Dive into a culture that
              celebrates your individuality, where you're free to bring your
              true self to work and explore all aspects of life within our
              dynamic and diverse community.
            </p>
          </div>
        </Grid>
      </Grid>

      <Box
        sx={{ width: "100%" }}
        className="it_infra main_content pt-[3%] pb-[3%] "
      >
        <div className="flex justify-center align-middle mb-5">
          <button
            className={`mt-4 px-2 py-1 text-[9px] sm:text-base w-[70px] sm:w-[100px] border border-grey ${
              active === "All"
                ? "bg-[#134CBC] text-white"
                : "bg-white text-[#1610118C]"
            } rounded-l-lg focus:ring-0`}
            onClick={() => onClickActive("All")}
          >
            All
          </button>

          <button
            className={`mt-4 px-2 py-1 text-[9px] sm:text-base w-[70px] sm:w-[150px] border border-grey ${
              active === "festival"
                ? "bg-[#134CBC] text-white"
                : "bg-white text-[#1610118C]"
            }   focus:ring-0`}
            onClick={() => onClickActive("festival")}
          >
            FESTIVAL
          </button>
          <button
            className={`mt-4 px-2 py-1 text-[9px] sm:text-base w-[70px] sm:w-[150px] border border-grey ${
              active === "anniversary"
                ? "bg-[#134CBC] text-white"
                : "bg-white text-[#1610118C]"
            } focus:ring-0`}
            onClick={() => onClickActive("anniversary")}
          >
            ANNIVERSARY
          </button>

          <button
            className={`mt-4 px-2 py-1 text-[9px] sm:text-base w-[70px] sm:w-[100px] border border-grey ${
              active === "event"
                ? "bg-[#134CBC] text-white"
                : "bg-white text-[#1610118C]"
            }   focus:ring-0`}
            onClick={() => onClickActive("event")}
          >
            EVENT
          </button>

          <button
            className={`mt-4 px-2 py-1 text-[9px] sm:text-base w-[70px] sm:w-[100px] border border-grey ${
              active === "trip"
                ? "bg-[#134CBC] text-white"
                : "bg-white text-[#1610118C]"
            }   focus:ring-0`}
            onClick={() => onClickActive("trip")}
          >
            TRIP
          </button>
          <button
            className={`mt-4 px-2 py-1 text-[9px] sm:text-base w-[70px] sm:w-[100px] border border-grey ${
              active === "life"
                ? "bg-[#134CBC] text-white"
                : "bg-white text-[#1610118C]"
            } rounded-r-lg  focus:ring-0`}
            onClick={() => onClickActive("life")}
          >
            LIFE
          </button>
        </div>

        <Grid
          container
          spacing={2}
          columns={16}
          className="flex justify-center p-2.5"
        >
          {active === "All" || active === "festival" ? (
            <>
              {/* -----------------  Dhuleti  --------------------------------- */}
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Dhuleti2.png")}
                    alt="trip of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Dhuleti3.png")}
                    alt="trip of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Dhuleti1.png")}
                    alt="life of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Dhuleti4.png")}
                    alt="trip of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Dhuleti5.png")}
                    alt="trip of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              {/* ------------------------------------------------------------------ */}

              {/* -----------------  Ganesh Chaturthi  ------------------------------ */}
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Mask group (1).png")}
                    alt="life of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Mask group (2).png")}
                    alt="trip of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Mask group.png")}
                    alt="festival of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Mask group (4).png")}
                    alt="event of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Mask group (5).png")}
                    alt="img of leeway"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/Mask group (7).png")}
                    alt="leeway images"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              {/* ------------------------------------------------------------------ */}

              {/* ------------------------  15 Aug  ------------------------------------------ */}
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/15Aug1.png")}
                    alt="leeway images"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/15Aug2.png")}
                    alt="leeway images"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <div className="text-center">
                  <img
                    src={require("../event/leeway_life/15Aug3.png")}
                    alt="leeway images"
                    className="event_img h-[282px] "
                  />
                </div>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
    </>
  );
}
