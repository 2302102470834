import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import blog1 from "../event/blog/blog1.png"
import blog2 from "../event/blog/Flutter app development.png"

import BlogForm from "../pages/BlogForm"
import SEO from '../components/SEO';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: '10px',
    border: ' 1.5px solid #C4D2EE',
    height: '430px',
    width: '270px',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {

    const schemaMarkup = {
        "@context": "https://schema.org/",
        "@type": "Article",
        "name": "Biggest Advantage to Choose Flutter as Development compare to Other Languages",
        "description": "Advantages and know-hows of Flutter",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://new.leewaysoftech.com/flutter-blog"
         }
    }

    return (
        <>
            <SEO title="Flutter Blog | Leeway Softech Pvt Ltd"
                description="Flutter simplifies cross-platform app development created by Google. Explore its features, benefits, tips, and best practices to craft amazing Flutter aps in our latest blog article."
                schema={schemaMarkup}

            />
            <div className='px-6 md:px-24'>
            <Grid container spacing={1} className="blog_page">
                <Grid items xs={12} sm={12} lg={12} className='pt-[3%]'>
                    <h1 className='color-134CBC text-center font-bold text-[32px] d-flex'>Blogs</h1>
                    <div  className='p-2.5 align-middle flex flex-col justify-center main_content'>
                        <div className='text-center font-bold text-[32px] d-flex pb-5'>Biggest Advantage to Choose Flutter as Development compare to Other Languages?</div>
                        <div className='flex align-middle justify-center  mb-2'>
                            <p className='ml-2.5 color-134CBC text-center '>Flutter Developer</p>
                            <div className='blog_line'></div> {/* Add margin to the line */}
                            <p className='ml-1 color-134CBC'>Posted on 25th Jane 2022</p>
                        </div>

                        <div>
                            <img src={blog2} alt="Blog Flutter Languages Visual" className='image_component' />

                        </div>
                    </div>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%', }} >
                <Grid container spacing={2} columns={16} className='blog_card'>
                    <Grid item xs={6} className='flex justify-center'>
                        <div className='blog_content'> {/* Increased padding and added margin */}
                            <h2  className='text-[#161011]  align-left text-2xl pt-5 font-bold'>Biggest Advantage to Choose Flutter as Development compare to Other Languages?.</h2>
                            <p className='text-[#161011] text-[15px] align-left opacity-[85%] mt-2'>
                                Flutter have hot reload feature make the app development much quicker. With Flutter there is no need to reload the app to see every single change you make in the code. You can easily make changes in your app in real time, so you have more opportunity to experiment with the code and fix bugs on the go.
                            </p>

                        </div>
                        <div>

                        </div>
                    </Grid>
                </Grid>

            </Box>

            </div>
            <BlogForm />

        </>
    );


}


